import { Dropdown as SemanticDropdown, Form } from 'semantic-ui-react';

import './Dropdown.scss';

export default function Dropdown({ placeholder, label, onChange, options, translator, isForm, ...rest }) {
  let _placeholder = placeholder;
  let _options = options;

  if (translator) {
    _options = options.map((option) => {
      return {
        ...option,
        text: option.text ? translator(option.text) : undefined,
        key: !option.text && option.key ? translator(option.key) : undefined,
      };
    });
    if (placeholder) _placeholder = translator(placeholder);
  }

  const Dropdown = isForm ? Form.Dropdown : SemanticDropdown;

  return (
    <Dropdown
      {...rest}
      options={_options}
      onChange={onChange}
      placeholder={_placeholder}
      label={label}
      className={'custom-dropdown'}
      fluid
      selection
    />
  );
}
