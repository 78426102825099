import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Grid, Table } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';

import { InvoiceItem, calculateDiscountAmounts } from '../../../helpers/invoices';
import { formatCurrency, formatPercent } from '../../../helpers/utils';
import { useCharges } from '../../../hooks/useCharges';

import { Option, Select } from '@wonderschool/common-base-ui';
import './Billing.scss';
import InvoiceItemEditor from './InvoiceItemEditor';

export type InvoiceItemListProps = {
  invoiceItemList: InvoiceItem[];
  onChange?: (invoiceItems: InvoiceItem[]) => void;
  isEditable?: boolean;
};
export default function InvoiceItemList({ invoiceItemList = [], onChange, isEditable = true }: InvoiceItemListProps) {
  const { t } = useTranslation();
  const charges = useCharges();

  const [invoiceItem, setInvoiceItem] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([...invoiceItemList]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  initializeInvoiceItemIds();

  return (
    <>
      {!isEditFormOpen && (
        <Table stackable compact="very" basic="very">
          <Table.Header className={'grid-header'}>
            <Table.Row verticalAlign="middle">
              <Table.HeaderCell width={4} data-testid="create-recurring-plan-category">
                {t('Category')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} data-testid="create-recurring-plan-description">
                {t('Description')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4} data-testid="create-recurring-plan-notes">
                {t('Notes')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1} data-testid="create-recurring-plan-discounts"></Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right" data-testid="create-recurring-plan-amount">
                {t('Amount')}
              </Table.HeaderCell>
              {isEditable && <Table.HeaderCell width={2} />}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <InvoiceItems invoiceItems={invoiceItems} />
          </Table.Body>
        </Table>
      )}
      {isEditFormOpen && renderInvoiceItemEditor()}
      {!isEditFormOpen && isEditable && renderAddChargeButtons()}
    </>
  );

  function InvoiceItems({ invoiceItems }) {
    if (!invoiceItems?.length) return null;

    let total = 0;
    return (
      <>
        {invoiceItems.map((invoiceItem, _index) => {
          total += Number(invoiceItem.amount);
          return (
            <>
              <Table.Row key={invoiceItem.id} verticalAlign="middle">
                <Table.Cell data-testid={`create-recurring-plan-category-${_index}`}>
                  {t(invoiceItem.category)}
                </Table.Cell>
                <Table.Cell data-testid={`create-recurring-plan-description-${_index}`}>
                  {t(invoiceItem.item)}
                </Table.Cell>
                <Table.Cell data-testid={`create-recurring-plan-notes-${_index}`}>{invoiceItem.notes}</Table.Cell>
                <Table.Cell />
                <Table.Cell textAlign="right" data-testid={`create-recurring-plan-amount-${_index}`}>
                  {formatCurrency(invoiceItem.amount)}
                </Table.Cell>
                {isEditable && renderEditButtons(invoiceItem, _index)}
              </Table.Row>
              {invoiceItem.discounts?.map((discount, _j) => {
                const { percentAmount, currencyAmount } = calculateDiscountAmounts(discount, invoiceItem.amount);
                total -= currencyAmount;
                return (
                  <Table.Row disabled verticalAlign="middle" key={discount.id}>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell data-testid={`create-recurring-plan-discount-type-${_index}-${_j}`}>
                      {discount.discountType}
                    </Table.Cell>
                    <Table.Cell
                      disabled
                      textAlign="right"
                      data-testid={`create-recurring-plan-discount-amount-${_index}-${_j}`}
                    >
                      {formatPercent(percentAmount)}
                    </Table.Cell>
                    <Table.Cell
                      textAlign="right"
                      data-testid={`create-recurring-plan-discount-currency-${_index}-${_j}`}
                    >
                      (-{formatCurrency(currencyAmount)})
                    </Table.Cell>
                    {isEditable && <Table.Cell />}
                  </Table.Row>
                );
              })}
            </>
          );
        })}
        <Table.Row key={t('total')} verticalAlign="middle">
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell textAlign="right" className={`${total < 0 ? 'error' : ''}`}>
            {t('Total')}
          </Table.Cell>
          <Table.Cell textAlign="right" className={`${total < 0 ? 'error' : ''}`}>
            {formatCurrency(total)}
          </Table.Cell>
          {isEditable && <Table.Cell />}
        </Table.Row>
      </>
    );
  }
  function renderInvoiceItemEditor() {
    if (isEditFormOpen)
      return <InvoiceItemEditor invoiceItem={invoiceItem} onSave={onSaveInvoiceItem} onCancel={onCancelInvoiceItem} />;
  }

  function renderAddChargeButtons() {
    return charges?.length ? (
      <Grid stackable>
        <Grid.Column width={8} textAlign="right">
          {renderSavedChargesDropdown()}
        </Grid.Column>
        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          <strong>{t('or')}</strong>
        </Grid.Column>
        <Grid.Column width={7} textAlign="left" verticalAlign="middle">
          {renderCreateChargeLink()}
        </Grid.Column>
      </Grid>
    ) : (
      <div className="add-charge-wrapper">{renderCreateChargeLink()}</div>
    );
  }

  function renderSavedChargesDropdown() {
    const options: Option[] = charges.map((charge) => ({
      value: charge.id,
      label: t(charge.category) + ' - ' + t(charge.item),
    }));
    return (
      <Select
        data-testid="saved-charge-dropdown"
        name={'savedCharge'}
        placeholder={t('billing.InvoiceItemList.Selectasavedcharge')}
        options={options}
        onChange={(change) => onChangeSavedCharge(change.value)}
      />
    );
  }
  function renderCreateChargeLink() {
    return (
      <Link
        data-testid="create-new-charge-link"
        to="#"
        onClick={(e) => {
          e.preventDefault();
          setInvoiceItem(null);
          setIsEditFormOpen(true);
        }}
      >
        <strong>+</strong>
        {t('Create a new charge')}
      </Link>
    );
  }

  function renderEditButtons(invoiceItem, index) {
    return (
      <Table.Cell textAlign="right" singleLine>
        <Button
          data-testid={`delete-row-${index}`}
          basic
          key="#delete-row"
          icon="trash"
          onClick={(e) => {
            e.preventDefault();
            onDeleteInvoiceItem(invoiceItem);
          }}
        />

        <Button
          data-testid={`edit-row-${index}`}
          basic
          key="#edit-row"
          icon="pencil"
          onClick={(e) => {
            e.preventDefault();
            onEditInvoiceItem(invoiceItem);
          }}
        />
      </Table.Cell>
    );
  }

  // Invoice Items are an array, not a document, so we need to generate IDs for them with uuid
  function initializeInvoiceItemIds() {
    invoiceItems?.forEach((invoiceItem, _index) => {
      if (!invoiceItem.id) invoiceItem.id = uuidv4();

      invoiceItem.discounts?.forEach((discount, _j) => {
        if (!discount.id) discount.id = uuidv4();
      });
    });
  }

  function onSaveInvoiceItem(invoiceItem) {
    setIsEditFormOpen(false);
    const index = invoiceItems.findIndex((item) => item.id === invoiceItem.id);

    let _invoiceItems;
    if (index >= 0) {
      _invoiceItems = [...invoiceItems.slice(0, index), invoiceItem, ...invoiceItems.slice(index + 1)];
    } else {
      // new invoice item
      _invoiceItems = [...invoiceItems, invoiceItem];
    }
    onChangeInvoiceItems(_invoiceItems);
  }
  function onCancelInvoiceItem() {
    setInvoiceItem(null);
    setIsEditFormOpen(false);
  }

  function onEditInvoiceItem(invoiceItem) {
    setInvoiceItem(invoiceItem);
    setIsEditFormOpen(true);
  }

  function onDeleteInvoiceItem(invoiceItem) {
    const newInvoiceItems = invoiceItems.filter((i) => i !== invoiceItem);
    onChangeInvoiceItems(newInvoiceItems);
  }
  function onChangeInvoiceItems(_invoiceItems) {
    setInvoiceItems(_invoiceItems);
    if (onChange) onChange(_invoiceItems);
  }

  function onChangeSavedCharge(value) {
    const charge = charges.find((charge) => charge.id === value);
    setInvoiceItem({ ...charge, id: uuidv4() });
    setIsEditFormOpen(true);
  }
}
