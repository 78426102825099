import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { switchOrganization } from '../redux/actions/organizationActions';

const selectOrganizationImpersonationState = createSelector(
  [(state) => state.user, (state) => state.organizations],
  (user, organizations) => ({
    impersonatedOrganization: user?.impersonatedOrganization,
    defaultOrganization: user?.defaultOrganization,
    uid: user?.uid,
    currentOrganization: organizations.currentOrganization,
  })
);

export default function useOrganizationImpersonation() {
  const { impersonatedOrganization, defaultOrganization, uid, currentOrganization } = useSelector(
    selectOrganizationImpersonationState
  );
  const dispatch = useDispatch();

  const isImpersonatingOrganization = useMemo(() => {
    if (!impersonatedOrganization) return false;
    return impersonatedOrganization !== defaultOrganization;
  }, [impersonatedOrganization, defaultOrganization]);

  const isOwnOrganization = useCallback(
    (id) => {
      if (!id) return false;
      return defaultOrganization === id;
    },
    [defaultOrganization]
  );

  // If organization id is not supplied, clears the impersonation.
  const switchToOrganization = useCallback(
    async (organizationId = null) => {
      if (!uid) return;

      dispatch(switchOrganization(organizationId, uid));
    },
    [dispatch, uid]
  );

  return {
    isImpersonatingOrganization,
    impersonatedOrganizationId: impersonatedOrganization,
    activeOrganization: currentOrganization,
    isOwnOrganization,
    switchToOrganization,
  };
}
