import { Button, Tooltip } from '@wonderschool/common-base-ui';
import { CONTACT_STATUS, Student } from '@wonderschool/ws-types';
import { WsBlueBtnArrow, WsBtnArrow } from '../../icons';
import { toDateObject } from '../../helpers/dates';
import React, { useEffect, useState } from 'react';
import {
  SendInvitationConfirmationDialog,
  showInvitationErrorToast,
  showInvitationSuccessToast,
  useContacts,
} from '../../contacts';
import { inviteContact } from '../../contacts/contactsAPI';
import { WsSpinner, WsWidgetSizeEnum } from '../../common';

interface Invitation {
  invitationId: any;
  email: any;
  sentAt: any;
  displayName: any;
}

export function InviteStatus(student: Student, t: any) {
  const [allInvitations, setAllInvitations] = useState<Invitation[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [sendToContact, setSendToContact] = useState(null);
  const { contacts } = useContacts();

  const fetchAllInvitations = async () => {
    const invitations = contacts.map((invitation: any) => ({
      invitationId: invitation.invitationId,
      email: invitation.email,
      sentAt: invitation.sentAt,
      displayName: invitation.displayName,
    }));
    setAllInvitations(invitations);
  };

  useEffect(() => {
    fetchAllInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  async function onSendInvitation(contact: any) {
    try {
      setLoader(true);
      contact.status = CONTACT_STATUS.inviting;
      await inviteContact(contact);
      showInvitationSuccessToast(contact);
    } catch (error) {
      setLoader(false);
      showInvitationErrorToast(contact, error);
    } finally {
      setSendToContact(null);
      setLoader(false);
    }
    return null;
  }

  const handleClickSend = (family: any) => {
    const findContact = contacts.find((obj: any) => obj.email === family.email || obj.id === family.id);
    setSendToContact(findContact);
  };

  function calculateInvitationTimeDifference(member: any) {
    if (!allInvitations) return '';
    const invitation: any = allInvitations.find(
      (invitation: any) => invitation.email === member.email && invitation.displayName === member.displayName
    );

    if (!invitation || !invitation.sentAt) return '';

    const invitationSentDate = toDateObject(invitation.sentAt, 'MM/DD/YYYY');
    if (!invitationSentDate) return ''; // Add null check for invitationSentDate

    const timeDifference = Math.round((new Date().valueOf() - invitationSentDate.valueOf()) / (1000 * 3600 * 24));
    if (timeDifference > 0) {
      return `Last invited ${timeDifference} day${timeDifference > 1 ? 's' : ''} ago`;
    } else {
      const hoursDifference = Math.round((new Date().valueOf() - invitationSentDate.valueOf()) / (1000 * 3600));
      if (hoursDifference === 0) return 'Just Now';
      return `Last invited ${hoursDifference} hour${hoursDifference > 1 ? 's' : ''}`;
    }
  }

  function displayButton(member: any) {
    const isEmailEmpty = !!member.email;
    const button = (
      <Button
        primary={isEmailEmpty && member.status === CONTACT_STATUS.new}
        disabled={!isEmailEmpty}
        data-testid="parents-invite-btn"
        extraClasses="w-full w-36 min-h-58 py-1 min-h-10"
        onClick={() => handleClickSend(member)}
        preIcon={
          member.status === CONTACT_STATUS.invited ? (
            <WsBlueBtnArrow className="h-5 w-5 font-normal" />
          ) : (
            <WsBtnArrow className="h-5 w-5 font-normal text-white" />
          )
        }
        label={member.status === CONTACT_STATUS.invited ? t('Resend') : t('Invite')}
      />
    );

    return !isEmailEmpty ? (
      <Tooltip content={t('EmailRequiredTooltip')} action="hover">
        <div className="min-h-58 min-h-10 w-full max-w-36 py-1">{button}</div>
      </Tooltip>
    ) : (
      button
    );
  }

  if (student?.family && Object.values(student?.family).length > 0) {
    return Object.values(student?.family).map((member: any, index: number) => {
      return (
        <>
          <SendInvitationConfirmationDialog
            onClose={() => setSendToContact(null)}
            onYes={onSendInvitation}
            contact={sendToContact}
          />
          <div
            key={index}
            className={`my-3 min-h-12 content-center ${member.status === CONTACT_STATUS.inviting ? 'max-w-36 text-center' : 'vertical-middle m-auto'}`}
          >
            {member.status === CONTACT_STATUS.inviting && (
              <Button extraClasses="max-w-36 min-h-10 w-full" preIcon={<WsSpinner size={WsWidgetSizeEnum.SMALL} />}>
                {t('Sending')}
              </Button>
            )}
            {member.status === CONTACT_STATUS.invited || member.status === CONTACT_STATUS.new ? (
              <>
                {displayButton(member)}
                <div className="w-full max-w-36 text-center text-xs">
                  {loader && member.status === CONTACT_STATUS.invited ? (
                    <Button extraClasses="max-36 w-full text-center" />
                  ) : (
                    calculateInvitationTimeDifference(member)
                  )}
                </div>
              </>
            ) : (
              member.status === CONTACT_STATUS.verified && (
                <p className="max-w-36 py-3 text-center text-gray-800">{t('InviteAccepted')}</p>
              )
            )}
          </div>
        </>
      );
    });
  } else {
    return (
      <div>
        <Tooltip content={t('EmailRequiredTooltip')} action="hover">
          <Button
            primary
            disabled={true}
            data-testid="parents-invite-btn"
            extraClasses="disabled:border-gray-400 min-h-10 w-full min-w-36"
            preIcon={<WsBtnArrow className="font-grey-900 h-5 w-5 font-normal" />}
            label={t('Invite')}
          ></Button>
        </Tooltip>
      </div>
    );
  }
}
