import moment from 'moment';
import { DISPLAY_INTERVAL, SERVICE_DIRECTION } from '../contacts/enums';
import { DefaultDateFormat, dateFormatter } from './dates';

export const InvoicePlanStatus = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  ENDED: 'ended',
  SCHEDULED: 'scheduled',
};

const BILLING_INTERVAL = {
  daily: moment.duration(1, 'day'),
  weekly: moment.duration(1, 'week'),
  biweekly: moment.duration(2, 'weeks'),
  'bi-weekly': moment.duration(2, 'weeks'),
  'twice per month': moment.duration(2, 'weeks'), // TODO Fix - same as biweekly - UI needs to allow user to pick two dates
  monthly: moment.duration(1, 'month'),
  quarterly: moment.duration(3, 'months'),
  yearly: moment.duration(1, 'year'),
};

export function invoicePlanStatusFormatter({ status }) {
  switch (status) {
    case InvoicePlanStatus.PAUSED:
      return { text: 'Paused', color: 'bg-gray-700' };

    case InvoicePlanStatus.ENDED:
      return { text: 'Ended', color: 'bg-red-950' };

    // case InvoicePlanStatus.SCHEDULED:
    //   return {text: 'Scheduled', color: 'yellow'}

    default: {
      return { text: 'Active', color: 'bg-blue-950' };
    }
  }
}

export function getBillingDuration(interval) {
  if (!interval) return null;
  return BILLING_INTERVAL[interval.toLowerCase()];
}

/**
 * If the invoice plan is being created, set dates to start of day. If editing, keep the
 * time of day as is.
 */
const getMomentFromDate = (date, isEdit, timezone) => {
  const momentTime = isNaN(date) ? moment(date, DefaultDateFormat).tz(timezone, true) : moment(date);
  return isEdit ? momentTime : momentTime.startOf('day');
};

const getDateEnd = (date, timezone) => {
  if (!date) return null;
  if (!isNaN(date)) return date;

  const momentEnd = moment(date, DefaultDateFormat).tz(timezone, true).endOf('day');
  return momentEnd.utc().valueOf();
};

export function getUpdatedBillingDates(invoicePlan, timezone?: string, isEditing?: boolean) {
  const isEdit = isEditing || !!invoicePlan.updatedAt;

  timezone = timezone || 'utc';

  const momentNow = moment().tz(timezone, true).startOf('day');
  const momentSendPrevious = getMomentFromDate(invoicePlan.dateSend, isEdit, timezone);

  const isSendDateInThePast = momentSendPrevious.isBefore(momentNow);

  const momentStart = getMomentFromDate(invoicePlan.dateStart, isEdit, timezone);
  let momentDue = momentStart.clone();

  if (isSendDateInThePast) {
    while (momentDue.isBefore(momentNow)) {
      momentDue.add(getBillingDuration(invoicePlan.billingInterval));
    }
  } else if (isEdit && invoicePlan.dateDue) {
    momentDue = getMomentFromDate(invoicePlan.dateDue, isEdit, timezone);
  }

  const momentSend = momentDue.clone().subtract(parseInt(invoicePlan.notificationDays), 'days');

  return {
    dateStart: momentStart.utc().valueOf(),
    dateEnd: getDateEnd(invoicePlan.dateEnd, timezone),
    dateDue: momentDue.utc().valueOf(),
    dateSend: momentSend.utc().valueOf(),
  };
}

export const getBillingServiceInterval = (dateStart, billingInterval, direction, timezone = 'utc', isEdit = true) => {
  const momentBillingInterval = getBillingDuration(billingInterval);
  const momentStart = getMomentFromDate(dateStart, isEdit, timezone);
  const momentServiceStart =
    direction === SERVICE_DIRECTION.Previous
      ? momentStart.clone().subtract(momentBillingInterval).add(1, 'days')
      : momentStart.clone().add(1, 'days');
  const momentServiceEnd =
    direction === SERVICE_DIRECTION.Previous ? momentStart.clone() : momentStart.clone().add(momentBillingInterval);

  return dateFormatter(momentServiceStart) + ' - ' + dateFormatter(momentServiceEnd);
};

export const isRecurringPlanElegibleForArchive = (plan) => {
  if (plan.isArchived) return false;
  return [InvoicePlanStatus.ENDED, InvoicePlanStatus.PAUSED].includes(plan.status);
};

export const getInvoicePlanStatus = (dateDue, dateEnd) => {
  if (!dateEnd) return InvoicePlanStatus.ACTIVE;
  return moment(dateEnd).isAfter(dateDue) ? InvoicePlanStatus.ACTIVE : InvoicePlanStatus.ENDED;
};

export function getDisplayInterval(billingInterval) {
  switch (billingInterval) {
    case 'Monthly':
      return DISPLAY_INTERVAL.Month;
    case 'Bi-Weekly':
      return DISPLAY_INTERVAL.Biweekly;
    case 'Weekly':
      return DISPLAY_INTERVAL.Weekly;
    default:
      return '';
  }
}
