import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { groupBy, isEmpty, keyBy } from 'lodash';
import { withTranslation } from 'react-i18next';

// Import actions
import { getOrganizationInvoices } from '../../../../redux/actions/invoiceActions';

// Import component
import NoDataMessage from '../../../Shared/NoDataMessage';
import AgingList from './AgingList';

// Import utils
import { getPastDueInvoices, InvoiceStatus } from '../../../../helpers/invoices';
import { selectTotalByEmployee } from '../../../../helpers/utils';
import SectionCard from '../../../Shared/SectionCard';

type AgingContainerProps = {
  totalByEmployee: any;
  totalSummary: any;
  t: any;
};
function AgingContainer({ totalByEmployee, totalSummary, t }: AgingContainerProps) {
  if (isEmpty(totalByEmployee)) return <NoDataMessage message={t('agingContainer.missingInvoicesHelp')} />;

  return (
    <SectionCard
      header={t('Aging Report')}
      component={() => <AgingList totalByEmployee={totalByEmployee} totalSummary={totalSummary} />}
    />
  );
}

const mapStateToProps = (state) => {
  const {
    invoices: {
      all: { list },
    },
  } = state;

  const agingReportInvoices = Array.isArray(list)
    ? getPastDueInvoices(
        list.filter((invoice) => {
          return (
            invoice?.manuallyPaid !== true &&
            invoice?.status !== InvoiceStatus.PAID &&
            invoice?.status !== InvoiceStatus.VOID
          );
        })
      )
    : [];

  // Group invoices by student
  const invoiceGroupedByStudent = groupBy(agingReportInvoices, 'student.id');

  const studentsObj: Record<string, any> = {};

  // Find and convert the invoices for each student to an object.

  Object.keys(invoiceGroupedByStudent).forEach((studentId) => {
    studentsObj[studentId] = keyBy(invoiceGroupedByStudent[studentId], 'id');
  });

  const totalByEmployee = !isEmpty(studentsObj) ? selectTotalByEmployee(studentsObj).totalByEmployee : [];
  const totalSummary = !isEmpty(studentsObj) ? selectTotalByEmployee(studentsObj).totalSummary : {};

  return {
    totalByEmployee,
    totalSummary,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationInvoices,
    },
    dispatch
  );
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AgingContainer));
