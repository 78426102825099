import { StaffPage } from '@wonderschool/ccms-ui';
import { FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { useMemo } from 'react';
import { getCurrentEnvironment } from '../common/utils/environmentUtils';
import { useAuthUser } from '../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../navigation';

const Staff = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { token } = useAuthUser();
  const { gotoRouteByName } = useRoutes();
  const handleStaffRowClick = (staffId) => {
    return gotoRouteByName(RouteNameEnum.STAFF_DETAILS, [{ name: 'staffId', value: staffId }]);
  };

  if (!token) return null;
  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      <StaffPage onNavigateToStaffDetails={handleStaffRowClick} />
    </FoodProgramQueryProvider>
  );
};

export default Staff;
