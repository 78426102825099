import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { FILE_ACTIONS_CONSTS } from '../dictionary';

interface IStaffActionButtonsProps {
  areMultipleSelected: boolean;
  handleFileActionClicked: (action: FILE_ACTIONS_CONSTS) => void;
}

const StaffActionButtons: React.FC<IStaffActionButtonsProps> = ({
  areMultipleSelected = false,
  handleFileActionClicked,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        basic
        color="blue"
        onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.download)}
        data-testid="download-action-btn"
      >
        <Icon name="download" />
        {t('Download')}
      </Button>
      {/* to be released in docs V2 */}
      {false && (
        <>
          <Button
            basic
            color="blue"
            disabled={areMultipleSelected}
            onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.rename)}
            data-testid="rename-action-btn"
          >
            <Icon name="pencil" />
            {t('Rename')}
          </Button>
          <Button
            basic
            color="blue"
            disabled={areMultipleSelected}
            onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.archive)}
            data-testid="archive-action-btn"
          >
            <Icon name="archive" />
            {t('Archive')}
          </Button>
        </>
      )}
      <Button primary onClick={() => handleFileActionClicked(FILE_ACTIONS_CONSTS.share)} data-testid="share-action-btn">
        <Icon name="users" />
        {t('Share')}
      </Button>
    </>
  );
};

export default StaffActionButtons;
