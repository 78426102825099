export const EARLY_DAY_CITY = ['new york', 'houston', 'dallas', 'detroit', 'grand rapids'];

export enum REDIRECTION_LINKS {
  LEARNING_BEYOND_PAPER_HOTJAR_SURVEY = 'https://surveys.hotjar.com/87704c63-1ef3-408d-81dd-7f371f2e2512',
  EARLY_DAY_HOTJAR_SURVEY = 'https://surveys.hotjar.com/d87df4c3-1cda-4ad2-9827-38e34e555293',
  EARLY_DAY_START_HIRING = 'https://earlyday.com/',
  EARLY_DAY_LEARN_MORE = 'http://earlyday.com/centers?utm_source=wonderschool&utm_medium=website&utm_campaign=app-store&utm_content=learn-more',
  LEARNING_BEYOND_PAPER_GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSeJ818Mfw3GkGmcwkYr45Jap_OzqdxcJsXj0-auMaxoT3tkgg/viewform',
  EARLY_DAY_GOOGLE_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLScyFuX7ijvDodZUZMwZmaDxXbPRvX3IlnXAIC56R1dfDzhqVQ/viewform',
}
