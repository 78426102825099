import { useRef, useState } from 'react';
import { Button, Checkbox, Form, Icon, Label, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { saveCharge } from '../../../redux/actions/billingActions';
import { useOrganization } from '../../../hooks/useOrganizations';

import InlineError from '../../Messages/InlineError';
import { Dropdown } from '../../Shared/Dropdown';
import { CurrencyInput, PercentInput } from '../../Shared/NumericInput';
import { parseCurrency, formatCurrency, formatPercent } from '../../../helpers/utils';
import { addError } from '../../../helpers/errors';
import {
  DiscountAmountType,
  calculateDiscountAmounts,
  isDiscountPercent,
  isDiscountCurrency,
} from '../../../helpers/invoices';

import { CATEGORY_OPTIONS, ITEM_OPTIONS_BY_CATEGORY, DISCOUNT_OPTIONS } from './dropdownOptions';

import './Billing.scss';
import { getTranslatedOptionsObj } from '../../../helpers/filters';

const emptyInvoiceItem = {
  id: '',
  category: '',
  item: '',
  notes: '',
  amount: '',
  discounts: [],
};
const emptyDiscount = {
  id: '',
  discountType: '',
  amount: '',
  amountType: DiscountAmountType?.CURRENCY,
};

const getInputType = (amountType) => {
  switch (amountType) {
    case DiscountAmountType.PERCENT:
      return PercentInput;
    default:
      return CurrencyInput;
  }
};

export default function InvoiceItemEditor({ invoiceItem: invoiceItemToEdit, onSave, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useOrganization();
  const invoiceItemRef = useRef('no_id');
  const [invoiceItem, setInvoiceItem] = useState(invoiceItemToEdit ?? emptyInvoiceItem);
  const [errors, setErrors] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [saveToLibrary, setSaveToLibrary] = useState(false);
  const [itemOptions, setItemOptions] = useState();

  initializeInvoiceItem(invoiceItem);

  if (!invoiceItem) return null;

  initializeCategoryAndItemOptions(invoiceItem);
  initializeDiscountTypeOptions(discount);
  if (!itemOptions && invoiceItem.category) {
    setItemOptions(ITEM_OPTIONS_BY_CATEGORY[invoiceItem.category]);
  }
  return (
    <Segment>
      {!discount && renderInvoiceItemEditor()}
      {!discount && invoiceItem.discounts?.length > 0 && renderDiscountList()}
      {!!discount && renderDiscountEditor()}
      {renderCommands()}
    </Segment>
  );

  function renderInvoiceItemEditor() {
    let categoryOptions = [];
    if (itemOptions) categoryOptions = getTranslatedOptionsObj(itemOptions, t);
    return (
      <Form.Group>
        <Form.Field width={4} error={!!errors?.category}>
          <Label
            basic
            className="label-required"
            style={{ border: 'none' }}
            data-testid="create-recurring-plan-editor-category-label"
          >
            {t('Category')}
          </Label>
          <Dropdown
            data-testid="create-recurring-plan-editor-category-dropdown"
            allowAdditions
            search
            clearable
            required
            label={t('Category')}
            placeholder="Select or type new"
            name="category"
            value={invoiceItem.category}
            onAddItem={onAddCategory}
            onChange={onChangeCategory}
            translator={t}
            options={getTranslatedOptionsObj(CATEGORY_OPTIONS, t)}
          />
          {errors?.category && <InlineError text={errors.category} />}
        </Form.Field>
        <Form.Field width={4} error={!!errors?.item}>
          <Label
            basic
            className="label-required"
            style={{ border: 'none' }}
            data-testid="create-recurring-plan-editor-description-label"
          >
            {t('Description')}
          </Label>
          <Dropdown
            data-testid="create-recurring-plan-editor-description-dropdown"
            allowAdditions
            search
            clearable
            required
            placeholder={itemOptions?.length ? t('Select or type new') : t('Select a category first')}
            name="item"
            value={invoiceItem.item}
            onAddItem={onAddItem}
            onChange={onChangeInvoiceItem}
            options={categoryOptions}
          />

          {errors?.item && <InlineError text={errors.item} />}
        </Form.Field>
        <Form.Field width={5}>
          <Form.Input
            data-testid="create-recurring-plan-editor-notes"
            type="text"
            value={invoiceItem.notes}
            label={t('Notes')}
            onChange={onChangeInvoiceItem}
            placeholder={t('Add notes (optional)')}
            name="notes"
          />
        </Form.Field>
        <Form.Field width={3} error={!!errors?.amount}>
          <Form.Input
            data-testid="create-recurring-plan-editor-amount"
            required
            type="text"
            value={invoiceItem.amount}
            label={t('Amount')}
            onBlur={(e) =>
              onChangeInvoiceItem(e, {
                name: e.target.name,
                value: e.target.value,
              })
            }
            control={CurrencyInput}
            placeholder={t('Amount')}
            name="amount"
          />
          {errors?.amount && <InlineError text={errors.amount} />}
        </Form.Field>
      </Form.Group>
    );
  }
  function renderDiscountEditor() {
    const isPercent = isDiscountPercent(discount);
    const isCurrency = isDiscountCurrency(discount);

    return (
      <Form.Group className={'discount-edit-fields'}>
        <Form.Field width={8}>
          <Label basic className="label-required" style={{ border: 'none' }}>
            {t('Discount or Subsidy')}
          </Label>
          <Dropdown
            data-testid="create-recurring-plan-discount-type"
            allowAdditions
            search
            clearable
            required
            label={t('Discount or Subsidy')}
            placeholder={t('Select or type new')}
            name="discountType"
            value={discount.discountType}
            onAddItem={onAddDiscountType}
            onChange={onChangeDiscount}
            translator={t}
            options={DISCOUNT_OPTIONS}
          />
          {errors?.discountType && <InlineError text={errors.discountType} />}
        </Form.Field>
        <Form.Field width={6} errors={!!errors?.amount}>
          <Form.Input
            data-testid="create-recurring-plan-discount-amount"
            required
            label={isPercent ? t('Percentage (%)') : t('Amount ($)')}
            type="text"
            value={discount.amount}
            onBlur={(e) =>
              onChangeDiscount(e, {
                name: e.target.name,
                value: e.target.value,
              })
            }
            control={getInputType(discount.amountType)}
            placeholder={isPercent ? t('Enter percentage (%)') : t('Enter amount ($)')}
            name="amount"
          />
          {errors?.amount && <InlineError text={errors.amount} />}
        </Form.Field>
        <Form.Field width={2}>
          <Button.Group>
            <Button
              icon
              className={isCurrency ? 'active' : ''}
              onClick={onClickCurrency}
              data-testid="create-recurring-plan-discount-amount-type-currency"
            >
              <Icon name="dollar" size="small" />
            </Button>
            <Button
              icon
              className={isPercent ? 'active' : ''}
              onClick={onClickPercent}
              data-testid="create-recurring-plan-discount-amount-type-percent"
            >
              <Icon name="percent" size="small" />
            </Button>
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  }
  function renderDiscountList() {
    let totalDiscount = 0;
    return (
      <Table stackable compact="very" basic="very">
        <Table.Header>
          <Table.Row verticalAlign="middle">
            <Table.HeaderCell width={7} />
            <Table.HeaderCell width={4} data-testid="create-recurring-plan-discounts">
              {t('billing.invoiceItemEditor.DiscountorSubsidyType')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="right" data-testid="create-recurring-plan-discount-percent">
              {`${t('Percent')} (%)`}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="right" data-testid="create-recurring-plan-discount-amount">
              {`${t('Amount')} ($)`}
            </Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoiceItem.discounts?.map((discount, index) => {
            const { percentAmount, currencyAmount } = calculateDiscountAmounts(discount, invoiceItem.amount);
            totalDiscount += currencyAmount;

            return (
              <Table.Row key={discount.id} verticalAlign="middle">
                <Table.Cell />
                <Table.Cell disabled data-testid={`create-recurring-plan-discount-type-${index}`}>
                  {t(discount.discountType)}
                </Table.Cell>
                <Table.Cell disabled textAlign="right" data-testid={`create-recurring-plan-discount-percent-${index}`}>
                  {formatPercent(percentAmount)}
                </Table.Cell>
                <Table.Cell disabled textAlign="right" data-testid={`create-recurring-plan-discount-amount-${index}`}>
                  (-{formatCurrency(currencyAmount)})
                </Table.Cell>
                <Table.Cell>
                  <Button
                    data-testid={`create-recurring-plan-discount-delete-${index}`}
                    basic
                    icon="trash"
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteDiscount(index);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row key={'total'} verticalAlign="middle">
            <Table.Cell />
            <Table.Cell />
            <Table.Cell textAlign="right" data-testid="create-recurring-plan-total-label">
              {t('Total')}
            </Table.Cell>
            <Table.Cell textAlign="right" data-testid="create-recurring-plan-total-amount">
              {formatCurrency((invoiceItem.amount ?? 0) - totalDiscount)}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
  function renderCommands() {
    return (
      <Form.Group>
        {!discount && (
          <Form.Field width={4}>
            <a
              data-testid="create-recurring-plan-add-discount"
              href="#add-discount"
              onClick={(e) => {
                e.preventDefault();
                onAddDiscount();
              }}
              className="margin right"
            >
              {t('Add Discount or Subsidy')}
            </a>
          </Form.Field>
        )}
        <Form.Field width={discount ? 16 : 12}>
          <Button
            primary
            floated="right"
            disabled={false}
            content={t('Save')}
            onClick={onSaveLocal}
            data-testid="save-button"
          />
          <Button floated="right" basic content={t('Cancel')} onClick={onCancelLocal} data-testid="cancel-button" />
          {!discount && (
            <Checkbox
              data-testid=" "
              floated="right"
              label={t('Save to library')}
              checked={saveToLibrary}
              name={'saveToLibrary'}
              onChange={onChangeSaveToLibrary}
            />
          )}
        </Form.Field>
      </Form.Group>
    );
  }

  function clearInvoiceItem(emptyInvoiceItem) {
    setInvoiceItem(emptyInvoiceItem);
    invoiceItemRef.current = null;
  }

  function initializeInvoiceItem(_invoiceItem) {
    let newInvoiceItem;

    // This is an add operation, create a new empty invoice item
    if (!_invoiceItem?.id) {
      newInvoiceItem = {
        ...emptyInvoiceItem,
        discounts: [],
        id: uuidv4(),
      };

      // It's an edit operation.
    } else if (invoiceItemRef.current !== _invoiceItem.id) {
      newInvoiceItem = {
        ..._invoiceItem,
        discounts: [..._invoiceItem.discounts],
      };
    }
    if (newInvoiceItem) {
      setInvoiceItem(newInvoiceItem);
      invoiceItemRef.current = newInvoiceItem.id;
    }
  }

  // event handlers
  function onSaveLocal(e) {
    e.preventDefault();

    const errors = discount ? validateDiscount(discount, t) : validateInvoiceItem(invoiceItem, t);

    if (errors) setErrors(errors);
    else if (discount) onSaveDiscount();
    else onSaveInvoiceItem(invoiceItem);
  }

  function onCancelLocal(e) {
    e.preventDefault();

    if (discount) {
      setDiscount(null);
    } else if (onCancel) {
      clearInvoiceItem();
      onCancel(false);
    }
  }

  function onAddDiscount() {
    setDiscount({ ...emptyDiscount, id: uuidv4() });
  }
  function onDeleteDiscount(index) {
    invoiceItem.discounts.splice(index, 1);
    setInvoiceItem({ ...invoiceItem });
  }
  function onChangeDiscount(e, { name, value }) {
    if (name === 'amount') value = value ? +parseCurrency(value) : 0;

    setDiscount({ ...discount, [name]: value });
    setErrors(null);
  }
  function onSaveDiscount() {
    if (!discount) return;
    if (!invoiceItem.discounts) invoiceItem.discounts = [];

    invoiceItem.discounts.push(discount);
    setDiscount(null);
  }
  function onClickCurrency(e) {
    e.preventDefault();
    setDiscount({ ...discount, amountType: DiscountAmountType.CURRENCY });
  }
  function onClickPercent(e) {
    e.preventDefault();
    setDiscount({ ...discount, amountType: DiscountAmountType.PERCENT });
  }

  function onChangeInvoiceItem(e, { name, value }) {
    if (name === 'amount') value = value ? +parseCurrency(value) : 0;

    setInvoiceItem({ ...invoiceItem, [name]: value });
    setErrors(null);
  }
  function onSaveInvoiceItem(invoiceItem) {
    if (saveToLibrary) {
      let charge = { ...invoiceItem };
      if (!invoiceItem.id) charge.id = uuidv4();

      dispatch(saveCharge(organization.id, { ...invoiceItem }));
    }
    if (onSave) onSave(invoiceItem);
  }

  function onChangeSaveToLibrary(e, { checked }) {
    setSaveToLibrary(checked);
  }

  function onAddCategory(e, { value }) {
    addCustomCategoryMaybe(value);
    setItemOptions([]);
  }
  function onChangeCategory(e, data) {
    onChangeInvoiceItem(e, data);
    setItemOptions(ITEM_OPTIONS_BY_CATEGORY[data.value]);
  }
  function onAddItem(e, { value }) {
    addCustomItemMaybe(invoiceItem.category, value);
  }
  function onAddDiscountType(e, { value }) {
    addCustomDiscountTypeMaybe(value);
  }
}

function initializeCategoryAndItemOptions(invoiceItem) {
  if (!invoiceItem) return;
  addCustomCategoryMaybe(invoiceItem.category);
  addCustomItemMaybe(invoiceItem.category, invoiceItem.item);
}
function initializeDiscountTypeOptions(discount) {
  if (!discount) return;
  addCustomDiscountTypeMaybe(discount.discountType);
}
function addCustomDiscountTypeMaybe(discountType) {
  if (!discountType) return;

  const option = DISCOUNT_OPTIONS.find((o) => o.value === discountType);
  if (!option) {
    DISCOUNT_OPTIONS.push({
      key: discountType,
      value: discountType,
      text: discountType,
    });
  }
}

function addCustomCategoryMaybe(category) {
  if (!category) return;
  const option = CATEGORY_OPTIONS.find((o) => o.value === category);
  if (!option) {
    CATEGORY_OPTIONS.push({ key: category, value: category, text: category });
    ITEM_OPTIONS_BY_CATEGORY[category] = [];
  }
}

function addCustomItemMaybe(category, item) {
  if (!category || !item) return;

  let itemOptions = ITEM_OPTIONS_BY_CATEGORY[category];
  if (!itemOptions) {
    itemOptions = ITEM_OPTIONS_BY_CATEGORY[category] = [];
  }
  const itemOption = itemOptions.find((o) => o.value === item);
  if (!itemOption) {
    itemOptions.push({ key: item, value: item, text: item });
  }
}

function validateInvoiceItem(invoiceItem, _t) {
  let errors = null;

  if (!invoiceItem.category) errors = addError(errors, 'category', _t('Category is required'));
  if (!invoiceItem.item) errors = addError(errors, 'item', _t('billing.invoiceItemEditor.Descriptionisrequired'));
  if (!invoiceItem.amount) errors = addError(errors, 'amount', _t('Amount is required'));

  return errors;
}

function validateDiscount(discount, _t) {
  let errors = null;
  if (discount) {
    if (!discount.discountType)
      errors = addError(errors, 'discountType', _t('billing.invoiceItemEditor.DiscountorSubsidyisrequired'));
    if (!discount.amount) errors = addError(errors, 'amount', _t('Amount is required'));
  }
  return errors;
}
