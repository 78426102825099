import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Dimmer, Loader } from 'semantic-ui-react';
import { paths } from '../../navigation/paths';
import { buildHelpCenterLink } from '../../navigation/navigationUtils';
import { getCreatePayoutAccountUrl } from '../../api/firebase/organizations';

import CoinIcon from '../Shared/assets/CoinIcon';

import './SetupBanner.styles.css';

interface SetupBannerProps {
  color: string;
  message: string;
  organizationId: string;
  onError?: (error: string) => void;
  showLink?: boolean;
  isDismissable?: boolean;
  onDismiss?: () => void;
  items?: string[];
  linkText?: string;
  showHelpLink?: boolean;
  isMessageBold?: boolean;
}

export default function SetupBanner(props: SetupBannerProps) {
  const {
    color,
    message,
    organizationId,
    onError,
    showLink = false,
    isDismissable = false,
    onDismiss,
    items = [],
    linkText,
    showHelpLink,
    isMessageBold,
  } = props;

  const [isRequestInProgress, setIsRequestInProgress] = React.useState(false);

  const { t } = useTranslation();

  const handleSetupClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setIsRequestInProgress(true);
    try {
      const setupPayoutUri = await getCreatePayoutAccountUrl(organizationId);
      window.location.href = setupPayoutUri;
    } catch (error) {
      console.error(error);
      setIsRequestInProgress(false);

      const errorMesagge = error instanceof Error ? error.message : String(error);
      onError && onError(errorMesagge);
    }
  };

  return (
    <>
      {isRequestInProgress && (
        <Dimmer page active inverted>
          <Loader inverted size="large" content={t('setupPayoutBanner.loadingMessage')} />
        </Dimmer>
      )}
      <div className="payout-setup-banner-container" style={{ backgroundColor: color }}>
        {isDismissable && (
          <div className="payout-setup-banner-close">
            <Button compact size="mini" circular icon="cancel" onClick={() => onDismiss && onDismiss()} />
          </div>
        )}
        <h2 className={`payout-setup-banner-title${isMessageBold ? '' : 'regular'}`}>{message}</h2>
        <div className={items.length > 1 ? 'items-grid' : ''}>
          {items.map((item, index) => (
            <li key={index} className="payout-setup-banner-title left-align">
              {item}
            </li>
          ))}
        </div>
        {showLink && (
          <div style={{ display: 'flex' }}>
            <Link to="#" onClick={handleSetupClick}>
              <span className="payout-setup-banner-link">
                {linkText || (
                  <>
                    <CoinIcon />
                    {t('setupPayoutBanner.setupButton')}
                  </>
                )}
              </span>
            </Link>
            {showHelpLink ? (
              <>
                <span>&nbsp;|&nbsp;</span>
                <Link to={{ pathname: buildHelpCenterLink(paths.external.helpCenter) }} target="_blank">
                  <span className="payout-setup-banner-link">{t('Get Help')}</span>
                </Link>
              </>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
