import moment from 'moment';
import { AttendanceTotalsReportType, StudentAttendanceTotalsType } from '../types';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

import { formatHoursFromMinutes } from '../../../helpers/dates';

import { initializeAttendancePDFDoc } from './pdfUtils';

const COLUMNS = [
  { header: 'Student Name', dataKey: 'studentName' },
  { header: 'Room', dataKey: 'roomName' },
  { header: 'Days Present', dataKey: 'daysPresent' },
  { header: 'Days Absent', dataKey: 'daysAbsent' },
  { header: 'Total Hours Attended', dataKey: 'totalHoursAttended' },
];

export async function generateAttendanceTotalsPDF(
  report: AttendanceTotalsReportType
): Promise<{ filename: string; pdf: jsPDF }> {
  const { location, items: attendanceTotals, organization } = report;

  const startDate = moment(report.startDate).format('DD_MM_YYYY');
  const endDate = moment(report.endDate).format('DD_MM_YYYY');

  const filename = `Attendance Totals (${startDate} - ${endDate}).pdf`;
  const title = `Check in/Check out Report for ${location?.name || organization?.name || 'Unknown'}`;
  const pdf = initializeAttendancePDFDoc(title, report);
  const body = createPDFBody(attendanceTotals);

  autoTable(pdf, {
    columns: COLUMNS,
    body: body,
    startY: 65,
  });
  return { filename, pdf };
}

function createPDFBody(attendanceTotals: StudentAttendanceTotalsType[]) {
  return attendanceTotals.map((data) => {
    return {
      studentName: data.studentName || '-',
      roomName: data.roomName || '-',
      daysPresent: data.daysPresent,
      daysAbsent: data.daysAbsent,
      totalHoursAttended:
        !isNaN(data.totalMinutesAttended) && data.totalMinutesAttended !== 0
          ? formatHoursFromMinutes(data.totalMinutesAttended)
          : '-',
    };
  });
}
