import { getStudentsForContact } from '../../../students/studentsAPI';
import { getOrganizationById } from '../organizations';
import { getUserProfile } from '../users';
import { functions } from '../';

export async function getCanChargeAutomatically(user: any) {
  const parentUser = await getUserProfile(user.id ?? user.uid);
  if (!parentUser) return false;

  return (
    (getHasAgreedToAutomaticCharges(user) || (await computeEnforceAutomaticPaymentForUser(user))) &&
    hasDefaultPaymentSource(user)
  );
}

export function getHasAgreedToAutomaticCharges(user: { hasAgreedToAutomaticCharges: any }) {
  return user?.hasAgreedToAutomaticCharges || false;
}

function hasDefaultPaymentSource(user) {
  return !!getDefaultPaymentSourceId(user);
}

function getDefaultPaymentSourceId(user) {
  const customer = getCustomer(user);
  return customer.defaultPaymentSourceId;
}

function getCustomer(user: any) {
  const stripe = getStripe(user);
  if (!stripe.customer) stripe.customer = {};
  return stripe.customer;
}

function getStripe(user: any) {
  if (!user.stripe) user.stripe = {};
  return user.stripe;
}

async function computeEnforceAutomaticPaymentForUser(user: any) {
  const organization = await getOrganizationById(user.defaultOrganization);

  if (!user.id) return organization?.automaticPayment?.enforce ?? false;

  const children = await getStudentsForContact(user.contactId, user.defaultOrganization);

  // find latest automatic payment enforce modified date
  const childAutomaticPayment =
    children &&
    children?.reduce(
      (acc: any, child: any) =>
        child?.automaticPayment?.modifiedDate > (acc?.modifiedDate ?? null) ? child.automaticPayment : acc,
      organization?.automaticPayment ?? {}
    );

  return !!childAutomaticPayment?.enforce;
}

export const fetchBillingDetails = async (organizationId, studentId) => {
  const params = { organizationId, studentId };

  const data = await functions().httpsCallable('callables-students-getBillingDetails')(params);

  return { ...data, studentId };
};
