import React, { useState, useEffect, useCallback } from 'react';

// ui components
import { Button, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';

// hooks
import { useTranslation } from 'react-i18next';
import { useStudents } from '../../students/studentsHooks';

// utils
import ParentStudentProfileMessage from './ParentStudentProfileMessage';
import NewDocumentsMessage from './NewDocumentsMessage';
import ParentDocumentsTable from './ParentDocumentsTable';
import { useDownloadFiles, useUploadFile, FileCategory, FileServiceError } from '@wonderschool/file-service-client';
import DocumentUploader from '../../Components/Upload/DocumentUploader';
import { allowedMimeTypes, UPLOAD_STATE_CONSTS } from '../dictionary';
import UploadProgressModal from './modals/UploadProgressModal';
import { logError } from '../../rollbar';
import { useOrganization } from '../../hooks/useOrganizations';
import { showSuccessToast } from '../../Components/Shared/showToast';
import { getDisplayNameOrFormatFullName } from '../../helpers/utils';

interface StudentsFileIds {
  [studentId: string]: {
    fileIds: string[];
  };
}

const ParentsMain: React.FC = () => {
  const { t } = useTranslation();
  const { list: students } = useStudents();
  const { id: currentOrganizationId } = useOrganization();
  const [activeStudentIndexes, setActiveStudentIndexes] = useState<string[]>([]);
  const [studentsWithFiles, setStudentsWithFiles] = useState<StudentsFileIds>({});
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [studentIdUploadingFor, setStudentIdUploadingFor] = useState<null | string>(null);
  const [uploadState, setUploadState] = useState<UPLOAD_STATE_CONSTS>(UPLOAD_STATE_CONSTS.notUploading);

  const { downloadFiles, isLoading: isDownloading } = useDownloadFiles();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();

  const resetUploadingStates = useCallback(() => {
    // reset the file
    setFileToUpload(null);
    // reset active student id
    setStudentIdUploadingFor(null);
    // reset upload state
    setUploadState(UPLOAD_STATE_CONSTS.notUploading);
    // reset client
    resetUploadHook();
  }, [resetUploadHook]);

  const handleUploadError = useCallback(
    ({ apiError, uploadError }: { apiError?: unknown; uploadError?: FileServiceError }) => {
      setUploadState(UPLOAD_STATE_CONSTS.error);

      logError('Documents - Upload error: ', {
        clientError: apiError || 'upload student doc for parent failed',
        serverMessage: uploadError,
      });
    },
    []
  );

  const handleUploadSuccess = useCallback(() => {
    const targetStudent = students.find((student) => student.id === studentIdUploadingFor);
    const targetStudentName = targetStudent ? getDisplayNameOrFormatFullName(targetStudent, true) : 'the';

    const title = t('Upload success!');
    const message = t("{{fileName}} has been successfully uploaded into {{studentName}}'s student profile.", {
      fileName: fileToUpload?.name,
      studentName: targetStudentName,
    });

    showSuccessToast(title, message);
    // reset client side state
    resetUploadingStates();
    // reset the hook
    resetUploadHook();
  }, [resetUploadingStates, resetUploadHook, fileToUpload, students, studentIdUploadingFor, t]);

  // Handle upload error
  useEffect(() => {
    if (uploadError) {
      handleUploadError({ uploadError });
    }
  }, [uploadError, handleUploadError]);

  // Handle upload success
  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      handleUploadSuccess();
    }
  }, [uploadSuccess, fileToUpload, handleUploadSuccess]);

  const handleStudentCardExpand = (studentId: string) => {
    const isCurrentlyExpanded = activeStudentIndexes.indexOf(studentId) > -1;
    if (isCurrentlyExpanded) {
      setActiveStudentIndexes([...activeStudentIndexes.filter((id) => id !== studentId)]);
      return null;
    }

    setActiveStudentIndexes([studentId, ...activeStudentIndexes]);
    return null;
  };

  const downloadStudentFiles = (studentId: string) => {
    // prevent multi-click && do nothing when there are no files
    if (isDownloading || !studentsWithFiles[studentId]?.fileIds.length) {
      return null;
    }
    downloadFiles(studentsWithFiles[studentId].fileIds);
  };

  const handleFileSelectedForUpload = ({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  };

  const uploadStudentFile = async () => {
    if (!fileToUpload) {
      return null;
    }

    setUploadState(UPLOAD_STATE_CONSTS.uploading);

    try {
      // start the request
      await uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.ENTITY_SPECIFIC_FILE,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
            entityReferences: [`/students/${studentIdUploadingFor}`],
          },
        },
      });
    } catch (error) {
      // this is very unlikely to throw as the file-service uses an error hook instead.
      // ...but better safe than sorry
      handleUploadError({ apiError: error });
    }
  };

  const appendAvailableStudentFiles = (studentId: string, fileIds: string[]) => {
    if (studentsWithFiles[studentId]) {
      return null;
    }
    setStudentsWithFiles({
      ...studentsWithFiles,
      [studentId]: { fileIds: fileIds },
    });
  };

  return (
    <>
      {t('Please download, complete, and sign the documents and forms listed below.')}
      {/* new files alert */}
      {/* TODO: remove this condition when file-service-client implements file history features */}
      {false && <NewDocumentsMessage />}

      {/* student profile action */}
      {/* TODO: remove this condition when the proper student profile is selected */}
      {false && <ParentStudentProfileMessage />}

      {/* modals */}
      <UploadProgressModal
        isModalOpen={uploadState !== UPLOAD_STATE_CONSTS.notUploading}
        uploadState={uploadState}
        filename={fileToUpload?.name || ''}
        closeModal={resetUploadingStates}
      />

      {/* students files */}
      {students.map((student: any) => (
        <Segment key={student.id} className="student-document-segment">
          <Grid columns="equal">
            <Grid.Column stretched verticalAlign="middle">
              <Header as="h4">
                <Icon name="file alternate outline" />
                {t('Documents - {{displayName}}', {
                  displayName: student.displayName,
                })}
              </Header>
            </Grid.Column>

            {/* action column */}
            <Grid.Column className="text-right">
              <Button
                basic
                color="blue"
                size="small"
                icon="download"
                disabled={!studentsWithFiles[student.id]?.fileIds.length}
                onClick={() => downloadStudentFiles(student.id)}
                content={t('Download All')}
              />

              <DocumentUploader
                onFileSelected={handleFileSelectedForUpload}
                onSubmit={uploadStudentFile}
                onError={() => null}
                buttonOverrideProps={{
                  primary: true,
                  circular: false,
                  size: 'small',
                  color: 'blue',
                  icon: 'upload',
                  onClick: () => setStudentIdUploadingFor(student.id),
                  content: t('Upload Files'),
                }}
                shouldUseExternalStorageApi
                title={t('Upload Files')}
                allowedFileTypes={allowedMimeTypes}
                maxNumberOfFiles={1}
                maxFileSize={100000000}
              />
            </Grid.Column>

            {/* expand control */}
            <Grid.Column
              width="1"
              className="text-right"
              stretched
              verticalAlign="middle"
              onClick={() => handleStudentCardExpand(student.id)}
            >
              <Icon
                link
                color="blue"
                name={`chevron ${activeStudentIndexes.indexOf(student.id) > -1 ? 'up' : 'down'}`}
              />
            </Grid.Column>
          </Grid>

          {activeStudentIndexes.indexOf(student.id) > -1 && (
            <Grid.Row>
              <Divider />
              <ParentDocumentsTable student={student} liftStudentFileIds={appendAvailableStudentFiles} />
            </Grid.Row>
          )}
        </Segment>
      ))}
    </>
  );
};

export default ParentsMain;
