import { CheckboxProps, DropdownProps, InputProps, TextAreaProps } from 'semantic-ui-react';

export type SemanticFormProps = DropdownProps | TextAreaProps | InputProps | CheckboxProps;

export type TableChangeType = {
  sortField: string;
  sortOrder: 'asc' | 'desc';
};

export enum SHARE_TYPE {
  room = 'room',
  student = 'student',
  location = 'location',
}

export interface StudentDocument {
  id: string;
  displayName: string;
  picture?: string;
  firstName: string;
  lastName: string;
  location: string;
  organization: string;
  rooms?: string[];
}
