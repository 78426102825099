import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Icon, Menu } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';

import { useRoutes } from '../navigationHooks';
import { RouteNameEnum, RouteType } from '../types';
import styles from './navigation.module.scss';

import { Badge, ColorPaletteEnum } from '@wonderschool/common-base-ui';
import {
  WsApps,
  WsDocument,
  WsFoodProgram,
  WsHelp,
  WsHome,
  WsManagement,
  WsMessages,
  WsPayments,
  WsReports,
  WsSchool,
  WsWebsite,
} from '../../icons';

import { SettingsIcon } from '@wonderschool/common-base-ui';

import { LinkOutIcon } from '@wonderschool/common-base-ui';
type AccordionItemProps = {
  index: number;
};

type AccordionTitleProps = {
  title: string;
  icon?: React.ReactNode;
  onClick?: (index: number) => void;
  route?: RouteType | null;
  opensInANewTab?: boolean;
  isParent?: boolean;
} & AccordionItemProps;

type AccordionContentProps = {
  index: number;
  routes: RouteType[];
};

type LeftNavigationProps = {
  onClickMenuItem?: (route: RouteType) => void;
};
const LeftNavigation: React.FC<LeftNavigationProps> = ({ onClickMenuItem }) => {
  const { t } = useTranslation();
  const { getActiveRoutes, getActiveRoute, getRoutePath, gotoRoute } = useRoutes();
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeRoute, setActiveRoute] = useState<RouteType | null>(null);
  const { isAppStoreNewLabelEnabled } = useFlags();

  const onAccordionClick = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
    setActiveRoute(null);
  };

  const onClickMenuItemLocal = (e: any, route: RouteType, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveRoute(route);
    setActiveIndex(index);
    gotoRoute(route);
    if (onClickMenuItem) onClickMenuItem(route);
  };

  return (
    <>
      <Accordion fluid styled className="left-navigation md:mb-10" data-testid="left-navigation">
        <HomeAccordionItem index={1} />
        <SchoolManagementAccordionItem index={2} />
        <FoodProgramAccordionItem index={3} />
        <PaymentsAccordionItem index={4} />
        <MessagesAccordionItem index={5} />
        <DocumentsAccordionItem index={6} />
        <ReportsAccordionItem index={7} />
        <SchoolWebsiteAccordionItem index={8} />
        <SchoolSettingsAccordionItem index={9} />
        <HelpCenterAccordionItem index={10} />
        <AdminAccordionItem index={11} />
      </Accordion>
      <Accordion
        fluid
        styled
        className={`left-navigation bg-gray-100 md:sticky md:bottom-0 md:tall:fixed ${activeRoute || activeIndex === -1 || activeIndex === 1 ? 'md:small:fixed' : 'md:small:sticky'}`}
        data-testid="left-bottom-navigation"
      >
        <AppsAccordionItem index={12} />
      </Accordion>
    </>
  );

  function HomeAccordionItem({ index }: AccordionItemProps) {
    const route = getActiveRoute(RouteNameEnum.HOME);

    return route ? <AccordionTitle index={index} title="Home" icon={<WsHome />} route={route} /> : null;
  }

  function SchoolManagementAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.STUDENTS,
      RouteNameEnum.STAFF,
      RouteNameEnum.NEWSTAFF,
      RouteNameEnum.INTERESTED_FAMILIES,
      RouteNameEnum.ROOMS,
      RouteNameEnum.CALENDAR,
      RouteNameEnum.CONTACTS,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="School Management" icon={<WsManagement />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }
  function FoodProgramAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.FOOD_PROGRAM_MEALS,
      RouteNameEnum.FOOD_PROGRAM_CLAIMS,
      RouteNameEnum.FOOD_PROGRAM_REPORTS,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="Food Program" icon={<WsFoodProgram />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }

  function PaymentsAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.INVOICES,
      RouteNameEnum.RECURRING_PLANS,
      RouteNameEnum.TRANSACTIONS,
      RouteNameEnum.TIME_SPACE_CALCULATOR,
      RouteNameEnum.BILLING_SETTINGS,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="Finances & Payments" icon={<WsPayments />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }

  function MessagesAccordionItem({ index }: AccordionItemProps) {
    // (neftaly) messagingRoute is the new LV page for Archived Messages, route is the old one based on react
    const messagingRoute = getActiveRoute(RouteNameEnum.MESSAGING);
    const route = getActiveRoute(RouteNameEnum.MESSAGES);

    if (!route && !messagingRoute) return null;

    return (
      <AccordionTitle
        index={index}
        title="Messages"
        icon={<WsMessages />}
        route={messagingRoute || route}
        opensInANewTab={true}
      />
    );
  }
  function DocumentsAccordionItem({ index }: AccordionItemProps) {
    const route = getActiveRoute(RouteNameEnum.DOCUMENTS);

    return route ? <AccordionTitle index={index} title="Documents" icon={<WsDocument />} route={route} /> : null;
  }
  function ReportsAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.STAFF_TIMESHEET,
      RouteNameEnum.ATTENDANCE,
      RouteNameEnum.AGING_REPORTS,
      RouteNameEnum.FINANCIAL_SUMMARY,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="Reports" icon={<WsReports />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }
  function SchoolWebsiteAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.UPDATE_WEBSITE,
      RouteNameEnum.VIEW_WEBSITE,
      RouteNameEnum.TOURS,
      RouteNameEnum.TOUR_REQUESTS,
      RouteNameEnum.EVENTS,
      RouteNameEnum.MARKETING,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="School Website" icon={<WsWebsite />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }

  function SchoolSettingsAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([RouteNameEnum.LOCATIONS, RouteNameEnum.SHARED_DEVICES, RouteNameEnum.PLAN_DETAILS]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="School Settings" icon={<WsSchool />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }

  function HelpCenterAccordionItem({ index }: AccordionItemProps) {
    const route = getActiveRoute(RouteNameEnum.HELP_CENTER);

    return route ? (
      <AccordionTitle index={index} title="Help Center" icon={<WsHelp />} route={route} opensInANewTab={true} />
    ) : null;
  }

  function AdminAccordionItem({ index }: AccordionItemProps) {
    const routes = getActiveRoutes([
      RouteNameEnum.PROVIDERS,
      RouteNameEnum.USERS,
      RouteNameEnum.NETWORKS,
      RouteNameEnum.AREA_51,
      RouteNameEnum.ENROLLMENTS_ADMIN,
    ]);
    if (!routes.length) return null;

    return (
      <>
        <AccordionTitle index={index} title="Admin" icon={<SettingsIcon />} isParent />
        <AccordionContent index={index} routes={routes} />
      </>
    );
  }

  function AppsAccordionItem({ index }: AccordionItemProps) {
    const route = getActiveRoute(RouteNameEnum.APPS);
    return route ? (
      <div className="flex">
        <AccordionTitle index={index} title={t('apps.title')} icon={<WsApps />} route={route} />
        {isAppStoreNewLabelEnabled && (
          <Badge
            label={t('New').toUpperCase()}
            color={ColorPaletteEnum.BLUE}
            extraContainerClasses={`max-h-4 self-center px-3 py-0.5 text-xs text-white bg-${ColorPaletteEnum.BLUE}-900`}
            isCircleBadge={true}
          />
        )}
      </div>
    ) : null;
  }

  // Wrapper for Accordion.Title
  function AccordionTitle({ index, isParent, route, ...rest }: AccordionTitleProps) {
    const isActive = activeIndex === index;
    return (
      <Accordion.Title active={isActive} index={index} onClick={() => onAccordionClick(index)} data-testid="title">
        {isParent && <AccordionTitleDiv index={index} isParent={isParent} {...rest} />}
        {!isParent && route && (
          <Link to={getRoutePath(route)} onClick={(e) => onClickMenuItemLocal(e, route, index)} data-testid="link">
            <AccordionTitleDiv index={index} isParent={isParent} {...rest} />
          </Link>
        )}
      </Accordion.Title>
    );
  }
  function AccordionTitleDiv({ title, icon, index, isParent, opensInANewTab }: AccordionTitleProps) {
    const isActive = activeIndex === index;
    const iconDirection = isActive ? 'up' : 'down';
    return (
      <div className={styles.accordionTitle} data-testid="acc-title">
        <div className={styles.icon} data-testid="acc-icon">
          {icon}
        </div>
        <div className={styles.title} data-testid={`${title.toLowerCase()}-link`}>
          {t(title)}
          {opensInANewTab && <LinkOutIcon className="ml-2 size-4" />}
        </div>
        {!!isParent && <Icon name={`chevron ${iconDirection}`} data-testid="acc-icon" />}
      </div>
    );
  }
  function AccordionContent({ index, routes }: AccordionContentProps) {
    return (
      <Accordion.Content active={activeIndex === index} data-testid="acc-content">
        <Menu vertical className="rounded-lg">
          {routes.map((route) => {
            const path = getRoutePath(route);
            return (
              <Menu.Item
                key={route.name}
                as={Link}
                to={{ pathname: path }}
                active={activeRoute === route}
                onClick={(e) => onClickMenuItemLocal(e, route, index)}
                data-testid={`${route.name.toLowerCase()}-link`}
              >
                <div className="flex items-center">
                  <span>{t(route.name)}</span>

                  {route.shouldOpenInNewTab ? (
                    <span>
                      <LinkOutIcon className="ml-2 size-4" />
                    </span>
                  ) : null}
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      </Accordion.Content>
    );
  }
};

export default LeftNavigation;
