import React from 'react';
import { Container, Segment } from 'semantic-ui-react';

import { useOrganization, useOrganizationInvoicesListener } from '../hooks/useOrganizations';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import PageHeader from '../Components/Shared/PageHeader';

// Import components
import InvoicesList from '../Components/Billing/Invoices/InvoicesList';
import SetupPayoutModal from '../Components/SetupPayoutModal';

export default function Invoices() {
  const organization = useOrganization();

  useSegmentPage(PAGE_NAMES.invoices);
  useOrganizationInvoicesListener(organization.id);

  return (
    <Container className="no-padding no-margin">
      <PageHeader pageName={'Billing'} classes="billing" />
      <Segment basic>
        <InvoicesList invoiceType="all" />
      </Segment>
      <SetupPayoutModal />
    </Container>
  );
}
