import React from 'react';

const SpacePercentage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M16.002 3.247c-.69 0-1.25.56-1.25 1.25V5.8l-1.686-1.225v-.001a1.75 1.75 0 00-2.053 0h-.001l-7.435 5.36a.755.755 0 00-.036.027l-1.984 1.43a.75.75 0 10.878 1.216l.812-.585v8.23H1.996a.75.75 0 000 1.5h20.008a.75.75 0 000-1.5h-1.25v-8.235l.81.589a.75.75 0 10.881-1.213L20.46 9.95a.736.736 0 00-.033-.025l-.176-.128a.755.755 0 00.002-.056V4.497c0-.69-.56-1.25-1.25-1.25h-3.001zM11.25 20.254h8.003v-9.326L12.185 5.79a.25.25 0 00-.293 0h-.002l-7.143 5.151v9.313h1.501V15.5c0-.69.56-1.25 1.25-1.25H10c.69 0 1.25.56 1.25 1.25v4.753zm-1.5 0V15.75H7.748v4.503H9.75zM18.753 8.71V4.747h-2.502V6.89l2.502 1.819zM12.751 15.5c0-.69.56-1.25 1.25-1.25h3.001c.69 0 1.25.56 1.25 1.25v2.001c0 .69-.56 1.25-1.25 1.25h-3.001c-.69 0-1.25-.56-1.25-1.25v-2.001zm1.5.25v1.501h2.501v-1.501h-2.501z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SpacePercentage;
