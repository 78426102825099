import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
  header?: string | null;
  content?: string;
  iconName?: string;
  hideHeader?: boolean;
}

function ShowWarning({ iconName = 'warning', header = null, content, hideHeader = false }: Props) {
  const { t } = useTranslation();
  const headerLocal = !hideHeader ? header || t('Warning!') : null;

  return content ? <Message warning icon={iconName} visible content={content} header={headerLocal} /> : null;
}

export default ShowWarning;
