import { Container, Segment, Header, Button } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../config/env';

const Unauthorized = (_props) => {
  const { t } = useTranslation();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Unauthorized') })}
      </title>
      <body className="auth error" />
    </Helmet>
  );

  return (
    <Container style={{ width: 500 }} className="no-padding no-margin">
      {renderPageHead()}

      <Segment>
        <Header as="h1" content={t('Oh no')} />

        <p>
          {t(
            'It looks like you do not have permission to access this content. If you think this is by mistake, try again.'
          )}
        </p>

        <div>
          <img src="https://media.giphy.com/media/9Y5BbDSkSTiY8/giphy.gif" style={{ width: '100%' }} alt="Yes!" />
        </div>

        <Button fluid size="medium" content={t('Try again')} as={'a'} href="/" color="green" />
      </Segment>
    </Container>
  );
};

export default Unauthorized;
