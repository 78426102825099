import autoTable from 'jspdf-autotable';
import { initializePDFDoc } from '../../common';
import moment from 'moment';
import { DefaultDateFormat } from '../../helpers/dates';

const PARENTS_COLUMNS = [
  { header: 'Name', dataKey: 'studentName' },
  { header: 'Room', dataKey: 'rooms' },
  { header: 'Family & Contacts', dataKey: 'contacts' },
  { header: 'Email', dataKey: 'email' },
  { header: 'Phone number', dataKey: 'phone' },
  { header: 'Pick up allowed', dataKey: 'pickup' },
  { header: 'Payment', dataKey: 'payment' },
  { header: 'Autopay', dataKey: 'autopay' },
  { header: 'Mobile app', dataKey: 'app' },
  { header: 'Invite status', dataKey: 'invite' },
];

export async function generateParentsListingPDF(studentsList: any) {
  const selectedLocation = studentsList[0]?.locationName;
  const date = moment(new Date()).format(DefaultDateFormat);
  const filename = `Contact_list_${selectedLocation}_${date}.pdf`;
  const doc = initializePDFDoc('Contacts');
  autoTable(doc, {
    columns: PARENTS_COLUMNS,
    body: studentsList,
    startY: 45,
  });
  doc.setProperties({
    title: filename,
  });
  return { filename: filename, pdf: doc };
}
