import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getTitlePrefix } from '../../config/env';
import PageTitle from '../../Components/Shared/PageTitle';
import { Button, Card, LinkOutIcon } from '@wonderschool/common-base-ui';

import EarlyDayIcon from './assets/early-day.png';
import { EARLY_DAY_CITY, REDIRECTION_LINKS } from './enums';
import LearningBeyondPaperIcon from './assets/learning-beyond-paper-logo.png';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { usePrimaryLocation } from '../../hooks/useLocations';
import { addUserParamsToUrl } from './utils';

const AppStore = () => {
  const { t } = useTranslation();
  const { gotoRouteByName } = useRoutes();
  const location = usePrimaryLocation();
  const title = t('apps.title');

  const items = [
    {
      id: 'early-day',
      title: 'EarlyDay',
      description: t('apps.earlyDayDescription'),
      icon: EarlyDayIcon,
      tags: [{ title: 'staffing', color: 'yellow' }],
      button: {
        id: 'early-day-join-waitlist',
        showButton: true,
        label: t('apps.joinWaitlist'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.EARLY_DAY_GOOGLE_FORM),
      },
      learnMore: {
        showButton: true,
        title: t('apps.learnMore'),
      },
      externalLink: REDIRECTION_LINKS?.EARLY_DAY_LEARN_MORE || '',
      startHiring: REDIRECTION_LINKS?.EARLY_DAY_START_HIRING || '',
    },
    {
      id: 'learning-beyond-paper',
      title: 'Learning Beyond Paper',
      subTitle: '',
      pricing: '',
      description: t('apps.learningBeyondPaperDescription'),
      icon: LearningBeyondPaperIcon,
      tags: [{ title: 'lesson planning', color: 'purple' }],
      button: {
        id: 'learning-beyond-paper-join-waitlist',
        showButton: true,
        label: t('apps.joinWaitlist'),
        link: addUserParamsToUrl(REDIRECTION_LINKS?.LEARNING_BEYOND_PAPER_GOOGLE_FORM),
      },
      learnMore: {
        showButton: true,
        title: t('apps.learnMore'),
        link: RouteNameEnum.LEARNING_BEYOND_PAPER,
      },
    },
  ];

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
    </Helmet>
  );

  return (
    <div className="container mx-auto px-5">
      {renderPageHead()}
      <PageTitle title={t('apps.title')} />
      <div className="mt-10 grid grid-cols-1 gap-8 lg:grid-cols-2">
        {items.map((item, index) => (
          <Card key={index}>
            <div className="relative min-h-60 px-4 py-5 sm:px-6">
              {item?.tags.map((tag, index) => (
                <span
                  key={index}
                  className={`inline-flex items-center rounded-full bg-${tag.color}-200 px-3 py-0.5 text-xs font-semibold text-${tag.color}-950`}
                >
                  {tag.title.toUpperCase()}
                </span>
              ))}
              <div className="mt-4 flex space-x-3">
                <div className="h-10 w-10 place-content-center rounded-full border border-gray-300">
                  <img className="mx-auto flex-shrink-0 rounded-full" src={item?.icon} alt={item?.title} />
                </div>
                <div className="m-auto text-2xl font-bold text-gray-800">{item?.title}</div>
              </div>
              {item?.subTitle && (
                <div className="mt-2 text-sm font-bold text-gray-800">
                  {item.subTitle}
                  {item?.pricing && <span className="font-normal"> {item.pricing}</span>}
                </div>
              )}
              {item?.description && <p className="mt-2 text-sm text-gray-800">{item.description}</p>}
              <div className="mt-6">
                {item?.id === 'early-day' &&
                  (location?.city && !EARLY_DAY_CITY.includes(location.city.trim().toLowerCase()) ? (
                    <>
                      <Button
                        label={t('apps.joinWaitlist')}
                        id={item?.button?.id}
                        primary
                        postIcon={<LinkOutIcon className="h-5 w-5" />}
                        onClick={() => item?.button?.link && window.open(item.button.link, '_blank')}
                      />
                      <a
                        className="mx-4 cursor-pointer font-bold text-blue-900"
                        onClick={() => item?.externalLink && window.open(item.externalLink, '_blank')}
                      >
                        {item?.learnMore?.title || t('apps.learnMore')}
                      </a>
                    </>
                  ) : (
                    <Button
                      label={t('apps.startHiring')}
                      id={`${item.id}-start-hiring`}
                      primary
                      onClick={() => item?.startHiring && window.open(item.startHiring, '_blank')}
                      postIcon={<LinkOutIcon className="h-5 w-5" />}
                    />
                  ))}
                {item?.id !== 'early-day' && (
                  <>
                    <Button
                      label={t('apps.joinWaitlist')}
                      id={item?.button?.id}
                      primary
                      postIcon={<LinkOutIcon className="h-5 w-5" />}
                      onClick={() => item?.button?.link && window.open(item.button.link, '_blank')}
                    />
                    {item?.learnMore?.showButton && (
                      <a
                        className="mx-4 cursor-pointer font-bold text-blue-900"
                        onClick={() => item.learnMore.link && gotoRouteByName(item.learnMore.link)}
                      >
                        {item?.learnMore?.title || t('apps.learnMore')}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card>
        ))}
        {/* This div is for HotJar Survey */}
        <div className="rounded-lg p-4" id="appstore-page-hotjar"></div>
        {/* This div is for HotJar Survey */}
      </div>
    </div>
  );
};

export default AppStore;
