import React from 'react';

const TimeSpace = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M3.751 5.002c0-.69.56-1.25 1.251-1.25h11.005c.69 0 1.25.56 1.25 1.25v2.251H3.752v-2.25zm-1.5 3.001v-3A2.75 2.75 0 015.002 2.25h11.005a2.75 2.75 0 012.75 2.751v6.003a.75.75 0 01-1.5 0V8.753H3.752v9.255c0 .69.56 1.25 1.251 1.25h6.003a.75.75 0 010 1.5H5.002a2.75 2.75 0 01-2.75-2.75V8.003zm3.001 3.002a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm8.004 3.898c.001-.465.24-.832.51-1.087.27-.254.618-.449.988-.596.742-.297 1.716-.464 2.753-.464 1.037 0 2.011.166 2.753.463.37.148.719.342.988.597.27.256.51.624.51 1.09v2.813a.825.825 0 010 .09v2.8c0 .466-.24.834-.51 1.09-.27.254-.618.449-.987.596-.743.297-1.716.464-2.754.464s-2.012-.166-2.754-.463c-.369-.148-.718-.343-.987-.597-.27-.256-.51-.623-.51-1.089v-5.704m7.002 1.688c-.742.296-1.714.463-2.75.463-1.037 0-2.01-.167-2.752-.463v1.12a.41.41 0 00.041.044c.086.08.25.188.514.294.525.21 1.301.356 2.197.356.896 0 1.672-.146 2.197-.356.264-.106.428-.213.513-.294a.426.426 0 00.04-.042v-1.122zm-5.502 2.851v1.12c.008.01.02.025.04.044.086.08.25.188.514.294.525.21 1.301.356 2.197.356.895 0 1.672-.146 2.196-.356.265-.106.428-.213.514-.294a.43.43 0 00.04-.043v-1.12c-.741.296-1.713.462-2.75.462-1.036 0-2.01-.166-2.751-.463zm-4.752-9.19a.75.75 0 100 1.5h1a.75.75 0 000-1.5h-1zm-4.752 3.75a.75.75 0 01.75-.75h1a.75.75 0 110 1.5h-1a.75.75 0 01-.75-.75zm4.752-.75a.75.75 0 100 1.5h1a.75.75 0 000-1.5h-1zm-4.752 3.752a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm4.752-.75a.75.75 0 100 1.5h1a.75.75 0 000-1.5h-1zm3.252-5.252a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm2.055 3.607c-.265.106-.428.213-.514.294.085.08.25.188.514.294.525.21 1.301.357 2.197.357.896 0 1.672-.147 2.196-.357.264-.106.428-.214.513-.294-.085-.08-.249-.188-.513-.294-.525-.21-1.302-.356-2.197-.356s-1.672.146-2.196.356z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default TimeSpace;
