import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

const KinderConnectedStatus = ({
  enabled,
  size = enabled ? 'large' : 'small',
  color = enabled ? 'green' : '#D9D9D9',
}) => {
  const { t } = useTranslation();

  return (
    <>
      {enabled ? (
        <Icon name="check circle outline" size={size} color={color} />
      ) : (
        <Icon circular name="minus" size={size} style={{ color: color }} />
      )}
      {enabled ? t('KinderConnect') : t('Not Connected')}
    </>
  );
};

export default KinderConnectedStatus;
