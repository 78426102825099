import dayjs from 'dayjs';
import { TimecardFormDataType } from '../timecardTypes';

export type TimecardEditModalFormValidationErrors = {
  clockedInDate?: string;
  clockedInTime?: string;
  clockedOutDate?: string;
  clockedOutTime?: string;
  roomId?: string;
};

type ValidationData = {
  formData: TimecardFormDataType;
  timeDifferenceInMinutes: number;
};

// I suggest using a validation library for this kind of validation
export function validateTimeCards({
  formData,
  timeDifferenceInMinutes,
}: ValidationData): TimecardEditModalFormValidationErrors {
  const errors: TimecardEditModalFormValidationErrors = {};
  if (!formData.clockedInDate) {
    errors.clockedInDate = 'errors.validation.startDateRequired';
  }

  if (formData?.clockedOutDate && dayjs(formData?.clockedOutDate).isBefore(formData?.clockedInDate, 'day')) {
    errors.clockedOutDate = 'errors.validation.outDateGreater';
  }

  const isClockOutEarlierThanClockIn = Boolean(timeDifferenceInMinutes) && timeDifferenceInMinutes < 0;

  if (isClockOutEarlierThanClockIn) {
    errors.clockedOutTime = 'errors.validation.clockOutBeforeClockIn';
  }

  if (!formData.clockedInTime) {
    errors.clockedInTime = 'errors.validation.startTimeRequired';
  }
  if (!formData.roomId) {
    errors.roomId = 'errors.validation.roomRequired';
  }

  if (formData.clockedOutDate && !formData.clockedOutTime) {
    errors.clockedOutTime = 'errors.validation.endTimeRequired';
  }

  return errors;
}
