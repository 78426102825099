import { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Icon, Segment, Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

// Import actions
import {
  newOrganizationDevice,
  getOrganizationDevices,
  refreshOrganizationDevice,
  deviceSelected,
  deviceAddedToList,
} from '../../redux/actions/deviceActions';

// Import Components
import DeviceCard from './DeviceCard';
import DeviceForm from '../Forms/DeviceForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import PageTitle from '../Shared/PageTitle';

const SlidingDeviceFormAdd = withSlidingPanel(DeviceForm, {
  title: 'Add A Device',
});

const SlidingDeviceFormEdit = withSlidingPanel(DeviceForm, {
  title: 'Edit Device',
});

class DevicesList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
    loading: false,
    orderBy: [],
    defaultLimit: 10,
  };

  componentDidMount() {
    const {
      devices: { list },
    } = this.props;

    if (isEmpty(list)) this.getData();
  }

  getData = async (limit, last = null) => {
    const {
      organizations: { currentOrganization },
    } = this.props;

    if (!currentOrganization || !currentOrganization.id) return;
    try {
      this.setState({ loading: true });
      await this.props.getOrganizationDevices(
        currentOrganization.id,
        this.state.orderBy,
        limit || this.state.defaultLimit,
        last
      );
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  renderPageTitle = () => (
    <PageTitle list={this.props.devices.list} count={this.props.devices.count} title={this.props.t('Shared Devices')} />
  );

  refreshDevice = async (id) => {
    const {
      organizations: { currentOrganization },
    } = this.props;

    await this.props.refreshOrganizationDevice(currentOrganization.id, id);
  };

  renderDeviceList = () => {
    const {
      devices: { list },
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={4}>
        <Card
          className="add-new"
          onClick={() => {
            this.props.newOrganizationDevice();
            this.setState({ isAddOpen: true });
          }}
        >
          <Card.Content>
            <div className={'center'}>
              <Card.Header as={'h4'}>
                <Icon name="plus" />
              </Card.Header>
              <Card.Description>{this.props.t('Add a new device')}</Card.Description>
            </div>
          </Card.Content>
        </Card>

        {Object.keys(list).map((key) => (
          <DeviceCard
            key={key}
            device={list[key]}
            onClick={() => {
              this.props.deviceSelected(list[key]);
              this.setState({ isEditOpen: true });
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingDeviceFormAdd
          isOpen={this.state.isAddOpen}
          onClose={async (id) => {
            if (id) this.refreshDevice(id);
            this.setState({ isAddOpen: false });
          }}
        />
        <SlidingDeviceFormEdit
          isOpen={this.state.isEditOpen}
          onClose={(id) => {
            if (id) this.refreshDevice(id);
            this.setState({ isEditOpen: false });
          }}
        />
      </div>
    );
  };

  renderLoadMoreButton = () => {
    const {
      devices: { list, last },
      t,
    } = this.props;

    return (
      !isEmpty(list) &&
      last &&
      !this.state.loading && (
        <Segment basic textAlign="center">
          <Button
            primary
            fluid
            basic
            loading={this.state.loading}
            disabled={this.state.loading}
            content={t('Load More')}
            size="large"
            onClick={() => {
              this.getData(this.state.defaultLimit, last);
            }}
          />
        </Segment>
      )
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderDeviceList()}
        {this.renderSlidingForms()}
        {/* {this.renderLoadMoreButton()} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organizations: state.organizations,
  devices: state.devices,
});

export default withTranslation()(
  connect(mapStateToProps, {
    newOrganizationDevice,
    getOrganizationDevices,
    refreshOrganizationDevice,
    deviceAddedToList,
    deviceSelected,
  })(DevicesList)
);
