import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useInvitations } from '../hooks/useInvitations';
import {
  getEnrollmentDate,
  getEnrollmentStatus,
  getPrimaryLocation,
  getUnenrollmentData,
  isStudentArchived,
  searchStudents,
} from './studentsUtils';
import { getAgeFromBirthday, toDateObject } from '../helpers/dates';

const selectStudents = createSelector([(state) => state.students], (students) => students ?? {});
const selectRoomsList = createSelector([(state) => state.rooms], (rooms) => rooms?.list ?? []);
const selectLocationsList = createSelector([(state) => state.locations], (locations) => locations?.list ?? []);
const selectSelectedStudent = createSelector([(state) => state?.students], (students) => students?.selectedStudent);
const selectAddedStudent = createSelector([(state) => state?.students], (students) => students?.addedStudent);
const selectStudentById = createSelector(
  [(state, _studentId) => state?.students, (_state, studentId) => studentId],
  (students, studentId) => students?.list.find((student) => student.id === studentId)
);
const selectStudentsByIds = createSelector(
  [(state, _studentIds) => state?.students, (_state, studentIds) => studentIds],
  (students, studentIds) => students?.list.filter((student) => studentIds.includes(student.id))
);

/**
 * Maps and Filters students by family members relationship.
 * - If the student has a family member with the relationship, the student is returned with the filtered family memebers.
 * - If the student has no family members with the relationship, the student is not returned.
 * @param studentsListFiltered list of students to filter (might have other filters applied)
 * @param filters relationship filter
 * @returns an array of the filtered students
 */
const filterFamilyMembersByRelationship = (studentsListFiltered, filters) => {
  return studentsListFiltered
    .map((student) => {
      if (student.family) {
        let filterByParent = filters.relationship === 'parent';
        let filteredFamily = {};
        filteredFamily = Object.entries(student.family).filter(([, member]) => {
          return filterByParent ? member.relationship === 'parent' : member.relationship !== 'parent';
        });
        return { ...student, family: Object.fromEntries(filteredFamily) };
      } else {
        return student;
      }
    })
    .filter((student) => student.family && Object.values(student.family).length > 0);
};

/**
 * Grabs student data from redux store.
 * @returns {{list:Array, listFiltered:Array, count:number, getStudentLocation:React.useCallback }}
 */
export const useStudents = ({ filters, searchText } = { filters: {}, searchText: '' }) => {
  const students = useSelector(selectStudents);

  const invitations = useInvitations();

  // modify and return a cloned object of students rather than mutating the source
  let studentsListFiltered = [...students.list];

  // attach pendingParentInvites to the student
  let studentInvitationsDictionary = {};

  const addToStudentInvitationsDictionary = (studentId, invitation) => {
    const { firstName, lastName, displayName, id: invitationId } = invitation;

    if (studentInvitationsDictionary[studentId]) {
      studentInvitationsDictionary[studentId][invitationId] = {
        firstName,
        lastName,
        displayName,
      };
    } else {
      studentInvitationsDictionary[studentId] = {
        [invitationId]: {
          firstName,
          lastName,
          displayName,
        },
      };
    }
  };
  invitations.list.forEach((invitation) => {
    const { student, studentIds } = invitation;
    if (studentIds) {
      studentIds.forEach((studentId) => addToStudentInvitationsDictionary(studentId, invitation));
    } else if (student) {
      addToStudentInvitationsDictionary(student.id, invitation);
    }
  });

  studentsListFiltered.forEach((student) => {
    let combinedFamily = { ...student.family, ...student.contacts };
    const studentInviteKeys = studentInvitationsDictionary[student.id]
      ? Object.keys(studentInvitationsDictionary[student.id])
      : [];

    for (const inviteKey of studentInviteKeys) {
      const familyMemberInvite = studentInvitationsDictionary[student.id][inviteKey];
      combinedFamily = {
        [inviteKey]: familyMemberInvite,
        ...combinedFamily,
      };
    }

    student.combinedFamily = combinedFamily;
    return student;
  });

  const rooms = useSelector(selectRoomsList);
  const locations = useSelector(selectLocationsList);

  // Returns the location object of the selected student.
  const getStudentLocation = useCallback(() => {
    return getStudentLocationLocal(students?.selectedStudent, rooms, locations);
  }, [students, locations, rooms]);

  if (searchText) {
    studentsListFiltered = searchStudents(searchText, studentsListFiltered);
  }

  // Filter students by room.
  if (filters.room) {
    // Filter students without rooms.
    if (filters.room === 'no-room') {
      studentsListFiltered = studentsListFiltered.filter(
        (student) =>
          !student?.rooms || !student?.rooms?.length || (student.rooms.length === 1 && student.rooms[0] === '')
      );
    } else {
      studentsListFiltered = studentsListFiltered.filter((student) => student?.rooms?.includes(filters.room));
    }
  }

  // Filter students by relationship.
  if (filters.relationship) {
    studentsListFiltered = filterFamilyMembersByRelationship(studentsListFiltered, filters);
  }

  // Filter students by invite status.
  if (filters.invite) {
    // for each student map through the family members and filter based on the invite status and return the student with the filtered family members and filter out students with undefined family
    studentsListFiltered = studentsListFiltered
      .map(({ family, ...rest }) => {
        if (!family) return false;
        let filteredFamily = family ? Object.values(family) : [];

        if (filters.invite === 'verified') {
          filteredFamily = filteredFamily?.filter((member) => member.status === 'verified');
        } else if (filters.invite === 'invited') {
          filteredFamily = filteredFamily.filter(
            (member) => member.status === 'invited' || member.status === 'inviting'
          );
        } else {
          filteredFamily = filteredFamily?.filter((member) => member.status === 'new');
        }

        return { ...rest, family: filteredFamily.length > 0 ? filteredFamily : null };
      })
      .filter(({ family }) => family !== null);
  }

  // Filter students by enrollment status.
  if (filters.enrollmentStatus) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      const enrollmentStatus = getEnrollmentStatus(student);
      return enrollmentStatus === filters.enrollmentStatus;
    });
  }

  // Filter students by enrollment.source
  if (filters.enrollmentSource) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      if (filters.enrollmentSource.length && filters.enrollmentSource.includes(student?.enrollment?.source)) {
        return true;
      } else if (!filters.enrollmentSource.length) {
        return true;
      }
    });
  }

  // Filter students by age. (filters.age is an array of years)
  if (filters.age?.length) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      // returns null if !student.birthday
      const birthday = toDateObject(student.birthday);
      const age = getAgeFromBirthday(birthday);
      for (const filterAgeOption of filters.age) {
        if (!age) {
          return false;
        }
        if (filterAgeOption === age.years.toString()) {
          return true;
        }
        if (filterAgeOption === '12' && age.years > 11) {
          return true;
        }
      }

      return false;
    });
  }

  if (filters.schedule?.length) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      for (const day of filters.schedule) {
        if (!student.schedule?.includes(day)) {
          return false;
        }
      }

      return true;
    });
  }

  if (filters.hideArchivedStudents) {
    studentsListFiltered = studentsListFiltered.filter((student) => {
      return !isStudentArchived(student);
    });
  }

  return {
    ...students,
    listFiltered: studentsListFiltered,
    count: students.list.length,
    getStudentLocation,
  };
};

// The useStudents hook is doing too many things. If you just want all the currently loaded students, use useAllStudents.
export function useAllStudents() {
  return useSelector(selectStudents);
}
export function useSelectedStudent() {
  return useSelector(selectSelectedStudent);
}
export function useAddedStudent() {
  return useSelector(selectAddedStudent);
}

export function useStudent(studentId) {
  return useSelector((state) => selectStudentById(state, studentId));
}

export function useLoadedStudents(studentIds) {
  return useSelector((state) => selectStudentsByIds(state, studentIds));
}

export function useStudentOrPrimaryLocation(student) {
  const location = useStudentLocation(student);
  const locations = useSelector(selectLocationsList);
  return location ?? getPrimaryLocation(locations);
}

export function useStudentLocation(student) {
  const rooms = useSelector(selectRoomsList);
  const locations = useSelector(selectLocationsList);

  return useMemo(() => {
    return getStudentLocationLocal(student, rooms, locations);
  }, [student, rooms, locations]);
}

// Utility hook to return just enrollment data for a student. Not sure this doesn't belong in enrollmentsHooks instead.
export function useStudentEnrollment(student) {
  const unenrollmentData = useMemo(() => {
    return getUnenrollmentData(student);
  }, [student]);

  const enrollmentStatus = useMemo(() => {
    return getEnrollmentStatus(student);
  }, [student]);

  const enrollmentDate = useMemo(() => {
    return getEnrollmentDate(student);
  }, [student]);

  const automaticallyEnroll = useMemo(() => {
    return student?.enrollment?.automaticallyEnroll ?? true;
  }, [student]);

  return {
    automaticallyEnroll,
    enrollmentStatus,
    enrollmentDate,
    unenrollmentData,
  };
}
function getStudentLocationLocal(student, rooms, locations) {
  if (!student) return null;

  const studentRoomId = student.rooms?.length ? student.rooms[0] : null;

  if (!studentRoomId) return null;

  const studentRoom = rooms.find((r) => r.id === studentRoomId);

  if (studentRoom?.location) {
    const studentLocation = locations.find((location) => location.id === studentRoom.location);
    return studentLocation ?? null;
  }
  return null;
}
