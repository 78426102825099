import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import withPermission from '../Shared/withPermission';
import CenterOverview from '../Stats/CenterOverview';

const StatSummary = () => {
  return (
    <>
      <Grid.Row columns={1} style={{ display: 'block' }}>
        <Grid.Column>
          <CenterOverview />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default withPermission(connect(mapStateToProps)(StatSummary), 'can_view_stats');
