import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

// Import image
import noMessageImage from '../../styles/img/mirage/no-docs-image.svg';

export type NoDataMessageProps = {
  image?: string;
  message?: string;
  sub?: string;
  CallToActionComponent?: React.FC;
};

const NoDataMessage = ({ image, message, sub, CallToActionComponent }: NoDataMessageProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center">
      <img
        data-testid="no-data-image"
        src={!isEmpty(image) ? image : noMessageImage}
        className="h-full max-h-36 w-full max-w-36 object-contain object-scale-down"
      />

      <div className="text-2xl" data-testid="no-data-message">
        {!isEmpty(message) ? message : t('Hey, hey! You are new here...')}
      </div>

      {!isEmpty(sub) && <div className="text-xl text-gray-800">{sub}</div>}

      {CallToActionComponent && (
        <>
          <br />
          <br />
          <CallToActionComponent />
        </>
      )}
    </div>
  );
};

export default NoDataMessage;
