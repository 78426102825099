import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Button, Grid } from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import styles from './AttendanceList.module.scss';
import PageTitle from '../Shared/PageTitle';
import { LoadingIndicator } from '../Shared/BusyIndicator';
import { logError } from '../../rollbar';
import { fetchActivitiesForOrganization } from '../../api/firebase/activities';
import { formatStringAsLocaleMillisOrNull, DefaultDateFormat } from '../../helpers/dates';
import wsLogo from '../../styles/img/wonderschool/logo-base.png';
import { useOrganization } from '../../hooks/useOrganizations';
import useRooms from '../../hooks/useRooms';
import { useLocations } from '../../hooks/useLocations';

const TotalAttendanceReport = ({ filters, loading, setLoading }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const rooms = useRooms();
  const locations = useLocations();

  const [totalAttendanceData, setTotalAttendanceData] = useState([]);
  const [pdfLoading, setPdfLoading] = useState(false);
  const selectedLocation = locations.list.find((location) => location.id === filters.location);

  useEffect(() => {
    const _getTotalAttendanceReports = async () => {
      try {
        const conditions = [
          {
            field: 'type',
            operation: 'in',
            value: ['dropoff', 'pickup', 'absent'],
          },
          {
            field: 'activityTime',
            operation: '>=',
            value: formatStringAsLocaleMillisOrNull(moment(filters.timePeriod.from).startOf('day')),
          },
          {
            field: 'activityTime',
            operation: '<=',
            value: moment(filters.timePeriod.to, DefaultDateFormat).isSame(moment(new Date(), DefaultDateFormat))
              ? formatStringAsLocaleMillisOrNull(moment(new Date()))
              : formatStringAsLocaleMillisOrNull(moment(filters.timePeriod.to).endOf('day')),
          },
        ];

        if (filters.location) {
          conditions.push({
            field: 'location',
            operation: '==',
            value: filters.location,
          });
        }

        if (filters.room) {
          conditions.push({
            field: 'room',
            operation: '==',
            value: filters.room,
          });
        }

        if (filters.student) {
          conditions.push({
            field: 'student.id',
            operation: '==',
            value: filters.student,
          });
        }
        setLoading(true);
        const data = await fetchActivitiesForOrganization({
          organizationId: organization.id,
          conditions,
          orderBy: [{ field: 'activityTime', direction: 'desc' }],
          totalHoursReport: true,
        });
        setTotalAttendanceData(data);
      } catch (err) {
        logError('Error while getting attendance report data: ', err);
      } finally {
        setLoading(false);
      }
    };
    _getTotalAttendanceReports();
  }, [filters, organization, setLoading]);

  const getTableColumns = useCallback(() => {
    const tableColumns = [
      {
        dataField: 'student.fullName',
        text: t('Student'),
        sort: true,
        formatter: (cell) => cell,
        csvFormatter: (cell) => cell,
      },
      {
        dataField: 'room',
        text: t('Room'),
        sort: true,
        formatter: (cell) => rooms.find((room) => room.id === cell)?.name,
        csvFormatter: (cell) => rooms.find((room) => room.id === cell)?.name,
      },
      {
        dataField: 'daysPresent',
        text: t('Days Present'),
        sort: true,
        formatter: (cell) => cell,
        csvFormatter: (cell) => cell,
      },
      {
        dataField: 'daysAbsent',
        text: t('Days Absent'),
        sort: true,
        formatter: (cell) => cell,
        csvFormatter: (cell) => cell,
      },
      {
        dataField: 'totalMinutesAttended',
        text: t('Total Hours Attended'),
        sort: true,
        formatter: (cell) =>
          !isNaN(cell) && cell > 0 ? (
            <>
              {Math.floor(cell / 60) > 0 && (
                <>
                  <span className="bold">{Math.floor(cell / 60)}</span>h
                </>
              )}{' '}
              <span className="bold">{cell % 60}</span>m
            </>
          ) : (
            '-'
          ),
        csvFormatter: (cell) =>
          !isNaN(cell) && cell !== 0
            ? Math.floor(cell / 60) > 0
              ? `${Math.floor(cell / 60)}h ${cell % 60}m`
              : `${cell % 60}m`
            : '-',
      },
    ];
    return tableColumns;
  }, [rooms, t]);

  const formatData = async (datas) => {
    const formattedData = [];

    for (const data of datas) {
      const doc = {
        studentName: data.student.fullName,
        roomName: rooms.find((room) => room.id === data.room)?.name ?? '-',
        daysPresent: data.daysPresent,
        daysAbsent: data.daysAbsent,
        totalHoursAttended:
          !isNaN(data.totalMinutesAttended) && data.totalMinutesAttended !== 0
            ? Math.floor(data.totalMinutesAttended / 60) > 0
              ? `${Math.floor(data.totalMinutesAttended / 60)}h ${data.totalMinutesAttended % 60}m`
              : `${data.totalMinutesAttended % 60}m`
            : '-',
      };
      formattedData.push(doc);
    }
    return formattedData;
  };

  const exportPdf = async () => {
    setPdfLoading(true);
    const FILENAME = `Total Attendance ${organization.name} - ${
      selectedLocation.name
    } (${filters.timePeriod.from.format('MM/DD/YYYY')} - ${filters.timePeriod.to.format('MM/DD/YYYY')}).pdf`;
    const TITLE = `Total Attendance Report for ${selectedLocation.name}`;
    const doc = new jsPDF();

    const COLUMN_HEADERS = [
      { header: 'Student Name', dataKey: 'studentName' },
      { header: 'Room', dataKey: 'roomName' },
      { header: 'Days Present', dataKey: 'daysPresent' },
      { header: 'Days Absent', dataKey: 'daysAbsent' },
      { header: 'Total Hours Attended', dataKey: 'totalHoursAttended' },
    ];

    const BODY_DATA = await formatData(totalAttendanceData);

    doc.addImage(wsLogo, 'PNG', 15, 20, 40, 5);
    doc.setFontSize(14);
    doc.setFont(undefined, 'normal', 'bold');
    doc.text(TITLE, 15, 36);

    const ADDRESS = `${selectedLocation.address1}
${selectedLocation.city}, ${selectedLocation.state}, ${selectedLocation.zipcode}`;
    doc.setFont(undefined, 'normal', 'normal');
    doc.setFontSize(11);
    doc.text(ADDRESS, 15, 45);

    const TIME_PERIOD = `${filters.timePeriod.from.format('MM/DD/YYYY')} - ${filters.timePeriod.to.format(
      'MM/DD/YYYY'
    )}`;
    doc.setFont(undefined, 'normal', 'bold');
    doc.text('Time Period:', 15, 57);
    doc.setFont(undefined, 'normal', 'normal');
    doc.text(TIME_PERIOD, 40, 57);

    const ROOM_NAME = rooms.find((room) => room.id === filters.room)?.name;
    if (ROOM_NAME) {
      doc.setFont(undefined, 'normal', 'bold');
      doc.text('Room:', 90, 57);
      doc.setFont(undefined, 'normal', 'normal');
      doc.text(ROOM_NAME, 104, 57);
    }

    // Or use javascript directly:
    autoTable(doc, {
      columns: COLUMN_HEADERS,
      body: BODY_DATA,
      startY: 65,
    });

    setPdfLoading(false);
    doc.save(FILENAME);
  };

  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField="student.id"
        data={totalAttendanceData}
        columns={getTableColumns()}
        exportCSV={{
          fileName: `Total Attendance ${organization.name} - ${selectedLocation.name} (${filters.timePeriod.from.format(
            'MM/DD/YYYY'
          )} - ${filters.timePeriod.to.format('MM/DD/YYYY')}).csv`,
          ignoreHeader: false,
        }}
        data-testid="att-report-provider"
      >
        {({ baseProps, csvProps: _csvProps }) => (
          <Grid stackable>
            <Grid.Row>
              <Grid.Column computer={16} mobile={16} tablet={16}>
                <PageTitle
                  title={t('')}
                  RightComponent={() => (
                    <>
                      <Button
                        onClick={exportPdf}
                        secondary
                        floated="left"
                        disabled={totalAttendanceData.length === 0 || pdfLoading || loading}
                        data-testid="att-report-export-pdf"
                      >
                        {pdfLoading ? t('Loading...') : t('Print PDF')}
                      </Button>
                      <Button
                        onClick={() => _csvProps.onExport()}
                        primary
                        floated="right"
                        disabled={totalAttendanceData.length === 0 || pdfLoading || loading}
                        data-testid="att-report-export-csv"
                      >
                        {loading ? t('Loading...') : t('Export to CSV')}
                      </Button>
                    </>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <div className="bootstrap-iso">
              {loading && <LoadingIndicator />}
              <BootstrapTable
                noDataIndication={() => (
                  <div className={styles.attendanceTableEmpty} data-testid="att-report-no-results">
                    {t('No results')}
                  </div>
                )}
                classes="w-auto w-md-100 text-gray-800"
                wrapperClasses="table-responsive"
                bordered={false}
                hover={true}
                headerClasses={styles.attendanceTableHeader}
                pagination={paginationFactory()}
                {...baseProps}
              />
            </div>
          </Grid>
        )}
      </ToolkitProvider>
    </>
  );
};

export default TotalAttendanceReport;
