import { useTranslation } from 'react-i18next';
import { Label, Popup } from 'semantic-ui-react';

import styles from './InvoiceStatus.module.scss';
import { invoiceStatusFormatter, InvoiceStatus as INVOICE_STATUS } from '../../../helpers/invoices';

export default function InvoiceStatus({ invoice, withTooltip }) {
  const { t } = useTranslation();
  const { color, text, popupText } = invoiceStatusFormatter(invoice, t);

  if (!color || !text) return null;

  const { status } = invoice;

  const isFailedInvoice = status === INVOICE_STATUS.FAILED || status === INVOICE_STATUS.UNCOLLECTIBLE;
  const enablePopup = popupText && (isFailedInvoice || withTooltip);

  return (
    <Popup
      on="hover"
      disabled={!enablePopup}
      content={t(popupText)}
      trigger={<Label color={color} className={styles.invoiceStatus} content={t(text)} />}
    />
  );
}
