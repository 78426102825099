import { firestore } from '.';
import { getDocument, startChunkedCollectionListener, getDocumentsAsArray } from './firestore';

const paths = {
  room: (organizationId, roomId) => `organizations/${organizationId}/rooms/${roomId}`,
  rooms: (organizationId) => `organizations/${organizationId}/rooms`,
};

// lifted from https://github.com/wonderschool/ws-mobile-2/blob/main/src/api/firebase/rooms.ts#L13
export function organizationRoomsOnListen({ organizationId, validRoomIds }, next, error, complete) {
  const hasRoomIds = validRoomIds?.length > 0;
  const sortFn = hasRoomIds
    ? (roomA, roomB) => {
        // ascending
        return (roomA.name || '').localeCompare(roomB.name || '');
      }
    : undefined;
  return startChunkedCollectionListener(
    {
      path: paths.rooms(organizationId),
      orderBy: hasRoomIds
        ? undefined // rely on sortFn here; can't use orderBy w/ `in` queries
        : [{ field: 'name', direction: 'asc' }],
      conditionsBase: [],
      chunkableCondition: hasRoomIds
        ? {
            field: firestore.FieldPath.documentId(),
            operation: 'in',
            value: validRoomIds,
          }
        : undefined,
    },
    next,
    error,
    complete,
    sortFn
  );
}

export function addOrganizationRoom(organizationId, roomData) {
  return firestore().collection(`organizations/${organizationId}/rooms`).add(roomData);
}

export const updateOrganizationRoom = (organizationId, { id, ...roomData }) =>
  firestore().doc(paths.room(organizationId, id)).update(roomData);

export const getRoom = (organizationId, roomId) => {
  if (!organizationId || !roomId) return null;
  return getDocument({
    path: paths.room(organizationId, roomId),
  });
};

/**
 * Fetches all rooms for a given organization.
 */
export const fetchOrganizationRooms = (organizationId, conditions = []) => {
  if (!organizationId) return null;

  return getDocumentsAsArray({ path: paths.rooms(organizationId), conditions });
};
