import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';

const InvoiceResponsibleConfirmationDialog = ({ isOpen, onClose, onYes, isResponsibleForBilling, parentCount }) => {
  const { t } = useTranslation();

  let message = `billingResponsibleConfirmationDialogIsNotResponsibleDescription`;

  if (isResponsibleForBilling && parentCount <= 1) {
    message = `billingResponsibleConfirmationDialogIsOnlyResponsibleDescription`;
  } else if (isResponsibleForBilling && parentCount >= 2) {
    message = `billingResponsibleConfirmationDialogIsResponsibleDescription`;
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header data-testid="responsible-for-billing-title">{t('Responsible for Billing')}</Modal.Header>
      <Modal.Content>
        <p>{t(message)}</p>
      </Modal.Content>
      <Modal.Actions>
        {isResponsibleForBilling ? (
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content={t('Ok')}
            onClick={() => onClose()}
            data-testid="ok-btn"
          />
        ) : (
          <>
            <Button negative onClick={onClose} data-testid="cancel-btn">
              {t('Cancel')}
            </Button>
            <Button
              positive
              icon="checkmark"
              labelPosition="right"
              content={t('billingResponsibleConfirmationDialogYes')}
              onClick={() => onYes()}
              data-testid="responsible-for-billing-btn"
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default InvoiceResponsibleConfirmationDialog;
