import i18n from 'i18next';

import { getEnv, getDomainName, setupLogger, isTest } from './config/env';
import { getWSAuth } from '@wonderschool/common-base-sso';
import { auth } from './api/firebase';
import { getAuth as getAuth2 } from './api/firebase2';
import { routes } from './config/routes';

const env = getEnv();
setupLogger(`%c🔧 ENV loaded 🔧 ${env.toLocaleUpperCase()}`, 'color: #49fb35');
const WSA = getWSAuth({
  env,
  isMobileMode: false,
  firebaseAuthV8: isTest() ? undefined : auth(),
  firebaseAuthV9: isTest() ? undefined : getAuth2(),
  returnUrl: `${getDomainName()}${routes.authReturn}`,
  getLanguageKey: () => i18n.language,
});
setupLogger(`%c🔑 WSAuth loaded 🔑 ${WSA.env.toLocaleUpperCase()}`, 'color: #1e90ff');

export default WSA;
