import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { INVOICE_TYPE } from '../Components/Billing/Invoices/InvoicesList';

import {
  filterAmounts,
  filterBalanceDues,
  filterDates,
  filterDescriptions,
  filterInvoiceStatus,
  filterStudents,
  filterArchived,
} from '../helpers/filters';
import { selectFirstLocation } from '../Components/DateTime/DateTime';

const selectInvoicesState = createSelector([(state) => state.invoices], (invoices) => invoices ?? {});

/**
 * Grabs invoice data from redux store and applies filters/search.
 */
export const useInvoices = (filters) => {
  const invoices = useSelector(selectInvoicesState);

  const invoiceType = invoices.invoiceType || INVOICE_TYPE;
  const { list: invoiceList } = invoices[invoiceType];

  const {
    invoiceStatus,
    descriptionSearch,
    dueDateRange,
    amountSearch,
    balanceDueSearch,
    studentSearch,
    hideArchived,
  } = filters ?? {};

  const filteredArchived = hideArchived ? filterArchived(invoiceList) : invoiceList;

  const filteredStatuses = invoiceStatus ? filterInvoiceStatus(filteredArchived, invoiceStatus) : filteredArchived;

  const filteredDescriptions = descriptionSearch
    ? filterDescriptions(filteredStatuses, descriptionSearch)
    : filteredStatuses;

  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';

  const filteredDates = dueDateRange
    ? filterDates(filteredDescriptions, 'dateDue', dueDateRange, timezone)
    : filteredDescriptions;

  const filteredAmounts = amountSearch ? filterAmounts(filteredDates, amountSearch) : filteredDates;

  const filteredBalanceDues = balanceDueSearch ? filterBalanceDues(filteredAmounts, balanceDueSearch) : filteredAmounts;

  const filteredInvoices = studentSearch ? filterStudents(filteredBalanceDues, studentSearch) : filteredBalanceDues;

  return {
    ...invoices,
    [invoiceType]: {
      ...invoices[invoiceType],
      list: filteredInvoices,
    },
  };
};
