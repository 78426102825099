import { StaffDetail } from '@wonderschool/ccms-ui';
import { useMemo } from 'react';
import { getCurrentEnvironment } from '../common/utils/environmentUtils';
import { useAuthUser } from '../hooks/useUser';
import { FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { getTitlePrefix } from '../config/env';
import { useTranslation } from 'react-i18next';

const StaffDetailsPage = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const { token } = useAuthUser();

  const PageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('Staff Details - Wonderschool')}
      </title>
      <body />
    </Helmet>
  );

  if (!token) return null;
  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      <PageHead />
      <StaffDetail />
    </FoodProgramQueryProvider>
  );
};

export default StaffDetailsPage;
