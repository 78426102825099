import { Component } from 'react';
import { connect } from 'react-redux';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import { Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Button } from '@wonderschool/common-base-ui';

// Import API.
import { storage } from '../../api/firebase';

// Import helpers.
import FirebaseCloudStorage from './FirebaseCloudStorage';
import { getUppyLocale } from '../../hooks/useUppy';
import { WsDocumentUpload } from '../../icons';

class DocumentUploader extends Component {
  constructor(_props) {
    super();
    this.state = {
      modalOpen: false,
      uppyLocale: undefined,
    };
  }

  componentDidMount() {
    this.initUppy(this.props);
  }

  componentDidUpdate() {
    const { i18n } = this.props;
    const { uppyLocale } = this.state;

    if (i18n) {
      const newLocale = getUppyLocale(i18n);
      if (newLocale !== uppyLocale) {
        this.initUppy(this.props);
      }
    }
  }

  initUppy = (props) => {
    const { i18n, plugins } = props;

    const uppyLocale = getUppyLocale(i18n);
    this.setState({ uppyLocale });

    this.uppy = new Uppy({
      id: 'uppy1',
      locale: uppyLocale,
      autoProceed: props.autoProceed || false,
      restrictions: {
        maxFileSize: props.maxFileSize || 30000000,
        allowedFileTypes: props.allowedFileTypes || '',
        minNumberOfFiles: 1,
        maxNumberOfFiles: props.maxNumberOfFiles || null,
      },
    })
      // When individual files are added
      .on('file-added', (files) => this.props.onFileSelected(files) ?? {})
      .on('file-removed', () => {
        this.handleClose();
      })
      // When individual files are added
      .on('upload', (data) => {
        if (this.props.onSubmit) {
          this.handleClose();
          return this.props.onSubmit(data);
        }

        return {};
      })
      // When individual files are uploaded to remote storage.
      .on('upload-success', (_file, _body, _uploadURL) => undefined)
      // When there an error response received from the API.
      .on('error', (error) => this.props.onError(error) ?? {})
      .on('complete', (result) => {
        const { successful } = result;
        if (props.onUploaded && successful) props.onUploaded(successful);

        this.handleClose();
        this.clearFiles();
      });
    plugins?.forEach(({ plugin, options }) => {
      this.uppy.use(plugin, options);
    });
    if (!props.shouldUseExternalStorageApi) {
      const storageRef = storage().ref().child(props.uploadPath);

      this.uppy.use(FirebaseCloudStorage, {
        storageRef,
        refId: props.uniqueId || '',
        rename: true,
      });
    }
  };

  clearFiles = () => {
    if (this.uppy) {
      const currentFiles = this.uppy.getFiles();
      currentFiles.forEach((currentFile) => this.uppy.removeFile(currentFile.id));
    }
  };

  handleOpen = (e) => {
    if (e) e.preventDefault();
    const { meta } = this.props;
    if (meta) {
      this.uppy.setMeta(meta);
    }
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { t, buttonOverrideProps, modalOverrideProps } = this.props;

    return (
      <>
        <Modal
          trigger={
            <Button
              // note: order is important here
              // first load the default props,
              primary
              preIcon={<WsDocumentUpload />}
              label={t('Upload')}
              circular
              // then override any overlap
              {...buttonOverrideProps}
              // finally, make sure modal open works
              // and executes any additional onClick passed
              onClick={() => {
                this.handleOpen();
                buttonOverrideProps?.onClick?.();
              }}
              data-testid="docs-upload-btn"
            />
          }
          closeIcon
          open={this.state.modalOpen}
          size="small"
          header={this.props.title || t('Uploader')}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={() => {
            if (this.uppy) {
              this.uppy.reset();
            }
            this.handleClose();
          }}
          data-testid="docs-close-btn"
          content={
            this.uppy && (
              <Dashboard
                uppy={this.uppy}
                hideProgressAfterFinish
                width={1000}
                proudlyDisplayPoweredByUppy={false}
                closeModalOnClickOutside
                open={this.state.modalOpen}
                onRequestClose={this.handleClose}
                note={this.props.note || ''}
                data-testid="docs-browse-files"
              />
            )
          }
          {...modalOverrideProps}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
  selectedStudent: state.students.selectedStudent,
});

export default withTranslation()(connect(mapStateToProps)(DocumentUploader));
