import { HourEnum } from './enums';
import {
  BusinessHoursType,
  DocumentType,
  EnrollmentType,
  InvitationRecipientType,
  ScheduledDayType,
  TuitionAndFeesType,
} from './types';

import { DAY_ENUM_TO_LEGACY_MAP, LEGACY_TO_DAY_ENUM_MAP } from './consts';

// define these as constants so they are not redefined on every function call, causing unnecessary re-renders
const SCHEDULED_DAYS_EMPTY: ScheduledDayType[] = [];
const INVITATION_RECIPIENTS_EMPTY: InvitationRecipientType[] = [];
const DOCUMENTS_EMPTY: DocumentType[] = [];
const TUITION_AND_FEES_EMPTY: TuitionAndFeesType = {
  tuition: {},
  fees: [],
};

function createInvitationRecipientsFromFamily(student: any): InvitationRecipientType[] {
  const family = student?.family;
  if (!family) return INVITATION_RECIPIENTS_EMPTY;

  const invitationRecipients: InvitationRecipientType[] = [];

  Object.keys(family).forEach((key: string) => {
    const contact = family[key];
    invitationRecipients.push({
      contactId: key,
      uid: contact.uid ?? null,
      email: contact.email ?? null,
      displayName: contact.displayName ?? null,
      phone: contact.phone ?? null,
    });
  });
  return invitationRecipients;
}

// if the recipients are already defined on the enrollment, use those
// otherwise, create them from the family
function getInvitationRecipients(student: any): InvitationRecipientType[] {
  return student?.enrollment?.invitationRecipients ?? INVITATION_RECIPIENTS_EMPTY;
}

function getDocuments(student: any): DocumentType[] {
  return student?.enrollment?.documents ?? DOCUMENTS_EMPTY;
}

function getScheduledDays(student: any, defaultBusinessHours: BusinessHoursType): ScheduledDayType[] {
  const enrollment: EnrollmentType = student?.enrollment;
  const studentSchedule: string[] = student?.schedule;

  if (enrollment?.scheduledDays) {
    return enrollment.scheduledDays;
  } else if (studentSchedule && Array.isArray(studentSchedule)) {
    // legacy case
    return studentSchedule.map((day: string) => {
      return {
        day: LEGACY_TO_DAY_ENUM_MAP[day],
        startTime: defaultBusinessHours.startTime,
        endTime: defaultBusinessHours.endTime,
      };
    });
  }
  return SCHEDULED_DAYS_EMPTY;
}

function getScheduledDaysAbbreviated(student: any): string[] {
  if (!student.enrollment?.scheduledDays) {
    return student.schedule ?? [];
  } else if (student.enrollment?.scheduledDays) {
    return student.enrollment.scheduledDays.map(
      (scheduledDay: ScheduledDayType) => DAY_ENUM_TO_LEGACY_MAP[scheduledDay.day]
    );
  } else return [];
}

function getTuitionAndFees(student: any): TuitionAndFeesType {
  const enrollment = student?.enrollment;
  const studentTuitionAndFees = student?.tuitionAndFees;

  if (enrollment?.tuitionAndFees) {
    return enrollment.tuitionAndFees;
  } else if (studentTuitionAndFees) {
    // legacy case
    return studentTuitionAndFees;
  } else {
    return TUITION_AND_FEES_EMPTY;
  }
}

function formatHourForDisplay(hour: HourEnum): string {
  const hourSplit: string[] = hour.split(':');
  const hours = parseInt(hourSplit[0]);
  const minutes = parseInt(hourSplit[1]);
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hours12 = hours % 12;
  const hours12Display = hours12 === 0 ? 12 : hours12;
  const minutesDisplay = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12Display}:${minutesDisplay} ${ampm}`;
}
export {
  createInvitationRecipientsFromFamily,
  formatHourForDisplay,
  getDocuments,
  getInvitationRecipients,
  getScheduledDays,
  getScheduledDaysAbbreviated,
  getTuitionAndFees,
};
