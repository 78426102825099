import _ from 'lodash';
import {
  LOCATIONS_LISTENER_STARTED,
  LOCATIONS_FETCHED,
  LOCATION_ADDED,
  LOCATION_SELECTED,
  LOCATION_SELECTION_CLEARED,
  LOCATION_UPDATED,
} from './types';

import {
  organizationLocationsOnListen,
  addOrganizationLocation,
  updateOrganizationLocation,
} from '../../api/firebase/locations';

import store from '../store';
import { getUsersRoomsOrLocations } from '../../helpers/utils';

export const locationsListenerStarted = () => ({
  type: LOCATIONS_LISTENER_STARTED,
});

export const locationsFetched = (locations) => ({
  type: LOCATIONS_FETCHED,
  locations,
});

export const locationAdded = (location) => ({
  type: LOCATION_ADDED,
  location,
});

export const locationUpdated = () => ({
  type: LOCATION_UPDATED,
});

export const locationSelected = (location) => ({
  type: LOCATION_SELECTED,
  location,
});

export const locationSelectionCleared = () => ({
  type: LOCATION_SELECTION_CLEARED,
});

export const getLocations = () => {
  const locations = store.getState().locations.list;
  if (_.isEmpty(locations)) return null;
  return locations || null;
};

export const startOrganizationLocationsListener = (organizationId) => (dispatch) => {
  dispatch(locationsListenerStarted());
  const validLocationIds = getUsersRoomsOrLocations('locations');

  return organizationLocationsOnListen(
    { organizationId, validLocationIds },
    (data) => {
      if (data) dispatch(locationsFetched(data));
    },
    (error) => console.log('organizationLocationsOnListen error', error.message)
  );
};

export const organizationAddLocation =
  (organizationId, locationData, programType = null) =>
  (dispatch) =>
    addOrganizationLocation(organizationId, locationData, programType).then((location) =>
      dispatch(locationAdded(location))
    );

export const organizationUpdateLocation = (organizationId, locationData) => (dispatch) =>
  updateOrganizationLocation(organizationId, locationData).then((_location) => dispatch(locationUpdated()));
