import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { auth } from '../api/firebase';
import { useOrganization } from './useOrganizations';

const selectUserState = createSelector([(state) => state.user], (user) => user ?? {});
const selectUsersState = createSelector([(state) => state.users], (users) => users ?? { count: 0, list: [] });
const selectPermissionsState = createSelector([(state) => state.permissions], (permissions) => permissions ?? {});

export const useAuthUser = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!auth()?.currentUser) return;
    auth().currentUser?.getIdToken(true).then(setToken);
  }, []);

  return { currentUser: auth().currentUser, token };
};

export const useUser = () => {
  const user = useSelector(selectUserState);
  const organization = useOrganization();

  const isParent = useMemo(() => {
    return !!user?.claims?.parent;
  }, [user?.claims?.parent]);

  const isDirector = useMemo(() => {
    return !!user?.uid && !!organization?.createdBy && user.uid === organization.createdBy;
  }, [user, organization]);

  const isLocationAdminHighestClaim = useMemo(() => {
    return !user?.claims?.moxitAdmin && !user?.claims?.organizationAdmin && !!user?.claims?.locationAdmin;
  }, [user?.claims?.moxitAdmin, user?.claims?.organizationAdmin, user?.claims?.locationAdmin]);

  const isOrganizationAdmin = useMemo(() => {
    return !!user?.claims?.organizationAdmin;
  }, [user?.claims?.organizationAdmin]);

  const isLocationAdmin = useMemo(() => {
    return !!user?.claims?.locationAdmin;
  }, [user?.claims?.locationAdmin]);

  const isStaff = useMemo(() => {
    return !!(
      user?.claims?.moxitAdmin ||
      user?.claims?.organizationAdmin ||
      user?.claims?.locationAdmin ||
      user?.claims?.supportStaff ||
      user?.claims?.teacher
    );
  }, [user?.claims]);

  //This is both moxitAdmin and organizationAdmin. I didnt't want to change isOrganizationAdmin for fear of breaking something.
  const isStaffOrganizationAdmin = useMemo(() => {
    return !!(user?.claims?.moxitAdmin || user?.claims?.organizationAdmin);
  }, [user?.claims]);

  const isStaffNotOrganizationAdmin = useMemo(() => {
    return (
      !user?.claims?.moxitAdmin &&
      !user?.claims?.organizationAdmin &&
      !!(user?.claims?.locationAdmin || user?.claims?.supportStaff || user?.claims?.teacher)
    );
  }, [user?.claims]);

  return {
    ...user,
    isParent,
    isDirector,
    isLocationAdminHighestClaim,
    isOrganizationAdmin,
    isLocationAdmin,
    isStaff,
    isStaffOrganizationAdmin,
    isStaffNotOrganizationAdmin,
  };
};

export const useUsers = () => {
  const users = useSelector(selectUsersState);

  const getParents = useCallback(() => {
    const list = users?.list.filter((user) => !user.device && !user.staff && user.allowParentLogin);
    const count = list?.length ?? 0;
    return { list, count };
  }, [users]);

  return { users, getParents };
};

export const usePermissions = () => {
  return useSelector(selectPermissionsState);
};
