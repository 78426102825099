import React, { useCallback, useState } from 'react';
import { Header, Table } from 'semantic-ui-react';
import { FinancialSummaryReportProps, FormattedRow } from './types';
import { formatCurrency } from '../../../../helpers/utils';
import { useTranslation } from 'react-i18next';
import { ReportTitleEnum } from './enums';
import { WsDocument, WsDownload } from '../../../../icons';
import { Button } from '@wonderschool/common-base-ui';
import { convertListToCSV } from './FinancialSummaryUtils';

const FinancialSummaryReport: React.FC<FinancialSummaryReportProps> = ({ data, columns }) => {
  const { t } = useTranslation();

  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const onExportToCSV = useCallback(
    (e: any) => {
      e.stopPropagation();
      setIsExportLoading(true);
      const csvData = convertListToCSV(data, columns, t);
      const url = window.URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'financial-summary-report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExportLoading(false);
    },
    [columns, data, t]
  );

  function getCellValue(row: FormattedRow, key: string): string {
    const value = row[key];
    if (typeof value === 'number') {
      return row['title'] !== ReportTitleEnum.REVENUE && row['title'] !== ReportTitleEnum.EXPENSE
        ? formatCurrency(value, { precision: 2 })
        : '';
    } else {
      return t(value?.toString());
    }
  }

  function isBoldRow(title: string): string {
    switch (title) {
      case ReportTitleEnum.REVENUE:
      case ReportTitleEnum.EXPENSE:
        return 'fs-bold table-row';
      case ReportTitleEnum.TOTAL_REVENUE:
      case ReportTitleEnum.TOTAL_EXPENSE:
      case ReportTitleEnum.TOTAL_PROFIT:
        return 'fs-bold table-row bg-dark';
      default:
        return 'table-row';
    }
  }

  return (
    <>
      <div className="fs-hide-small-screen">
        <div className="items-top fs-report-header mb-4 mt-2 flex h-full w-full flex-row gap-5">
          <div>
            <Header as="h4" floated="left" data-testid={'report-title'}>
              {t('financialSummary.report.title')}
            </Header>
          </div>
          <div className="fs-report-action">
            <Button
              onClick={onExportToCSV}
              disabled={data?.length === 0 || isExportLoading}
              data-testid="download-btn"
              label={t('Download')}
              preIcon={<WsDownload data-testid="download-icon" />}
            />
          </div>
        </div>
        <div className="table-container">
          <Table striped className="frozen-table" data-testid="fs-summary-report-table">
            <Table.Header>
              <Table.Row>
                {columns.map((column, index) => (
                  <Table.HeaderCell className={index === 0 ? 'frozen-cell-header' : 'table-cell'} key={index}>
                    {t(column)}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((row, rowIndex) => {
                return (
                  <Table.Row key={rowIndex} className={isBoldRow(row['title'] || '')} data-testid={`row-${row.title}`}>
                    {Object.keys(row).map(
                      (key, cellIndex) =>
                        row[key] !== undefined && (
                          <Table.Cell
                            className={cellIndex === 0 ? 'frozen-cell' : 'table-cell'}
                            key={cellIndex}
                            data-testid={`cell-${key}`}
                          >
                            <span title={getCellValue(row, key)}>{getCellValue(row, key)}</span>
                          </Table.Cell>
                        )
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="fs-hide-web-screen">
        <div className="items-top mb-4 mt-2 flex h-full w-full flex-col gap-5">
          <div className="mb-2 mt-2 h-full w-full gap-5">
            <Header as="h4" floated="left" data-testid={'share-report-title'}>
              {t('financialSummary.report.share.title')}
            </Header>
          </div>
          <div className="fs-report-action mb-2 mt-2 h-full w-full gap-5">
            <Button
              onClick={onExportToCSV}
              primary
              loading={isExportLoading}
              disabled={data?.length === 0 || isExportLoading}
              extraClasses="w-full"
              data-testid="export-csv-button"
              preIcon={<WsDocument data-testid="download-icon" />}
              label={t('financialSummary.report.share.csv.label')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialSummaryReport;
