import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import WsLabel from './WsLabel';
import WsValidationError from './WsValidationError';
import { WsCheckboxProps } from './types';

/**
 * @deprecated The component should not be used anymore. Use Checkbox from ws-common instead.
 */
export function WsCheckbox({
  id,
  label,
  name,
  error,
  isChecked = false,
  onChange,
  wrapperClassName = '',
  'data-testid': dataTestId = '',
}: WsCheckboxProps) {
  const inputClassName = `${
    error
      ? 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
      : 'text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-sky-500'
  }
  h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600 cursor-pointer`;

  const onChangeLocal = (e) => {
    //e.preventDefault();
    onChange?.(e.target.checked);
  };

  return (
    <div className={wrapperClassName}>
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            id={id}
            name={name}
            type="checkbox"
            className={inputClassName}
            value={isChecked ? 'true' : 'false'}
            onChange={onChangeLocal}
            aria-invalid="true"
            aria-describedby={`${name}-label`}
            data-testid={`${dataTestId}`}
          />
        </div>
        <div className="ml-3 cursor-pointer text-sm leading-6">
          <WsLabel htmlFor={name} data-testid={`${dataTestId}-label`}>
            {label}
          </WsLabel>
          <span className="text-gray-500">
            <span className="sr-only"> {label} </span>
          </span>
        </div>
      </div>
      {!!error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
      <WsValidationError error={error} data-testid={`${dataTestId}-error`} />
    </div>
  );
}
export default WsCheckbox;
