import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Icon, Input, Label, Message, Popup, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { DefaultDateFormat, dateFormatter } from '../../../helpers/dates';
import { getBillingServiceInterval, getDisplayInterval } from '../../../helpers/invoicePlans';
import { addError } from '../../../helpers/errors';
import { useInvoiceEditDetails } from '../../../hooks/useInvoiceEdit';

import InlineError from '../../Messages/InlineError';
import { DatePicker } from '../../Shared/DatePicker';
import { Dropdown } from '../../Shared/Dropdown';
import SectionCard from '../../Shared/SectionCard';

import { StepsContainer } from './Steps';
import { BILLING_INTERVAL_OPTIONS } from './dropdownOptions';
import './Billing.scss';
import { SERVICE_DIRECTION } from '../../../contacts/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';

const selectFirstLocation = createSelector([(state) => state.locations], (locations) => locations?.list[0]);

export default function StepInvoicePlanDetails({ onSave, isEdit }) {
  const { t } = useTranslation();
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const details = useInvoiceEditDetails();

  const getInitialDateEnd = (initialDateEnd) => {
    if (typeof initialDateEnd === 'string') return initialDateEnd;
    return dateFormatter(initialDateEnd, DefaultDateFormat, false);
  };

  const [billingInterval, setBillingInterval] = useState(details?.billingInterval);
  const [dateStart, setDateStart] = useState(details?.dateStart);
  const [dateDue] = useState(details?.dateDue);
  const [displayInterval, setDisplayInterval] = useState('');
  const [displayPreviousServiceInterval, setDisplayPreviousServiceInterval] = useState('');
  const [displayNextServiceInterval, setDisplayNextServiceInterval] = useState('');
  const [dateEnd, setDateEnd] = useState(getInitialDateEnd(details?.dateEnd));
  const [isServiceDatesEnabled, setIsServiceDatesEnabled] = useState(
    details?.isServiceDatesEnabled !== undefined ? details?.isServiceDatesEnabled : true
  );
  const [serviceDirection, setServiceDirection] = useState(details?.serviceDirection ?? t(SERVICE_DIRECTION.Next));
  const [errors, setErrors] = useState();

  const { isInvoicePlanServiceDatesEnabled } = useFlags();

  const calculateServiceDate = useCallback(() => {
    return {
      calculatedPreviousServiceInterval: getBillingServiceInterval(
        dateDue || dateStart,
        billingInterval,
        SERVICE_DIRECTION.Previous,
        timezone
      ),
      calculatedNextServiceInterval: getBillingServiceInterval(
        dateDue || dateStart,
        billingInterval,
        SERVICE_DIRECTION.Next,
        timezone
      ),
    };
  }, [dateStart, dateDue, billingInterval, timezone]);

  useEffect(() => {
    if (dateStart && billingInterval && serviceDirection) {
      const { calculatedPreviousServiceInterval, calculatedNextServiceInterval } = calculateServiceDate();
      setDisplayPreviousServiceInterval(calculatedPreviousServiceInterval);
      setDisplayNextServiceInterval(calculatedNextServiceInterval);
    }
  }, [dateStart, billingInterval, serviceDirection, calculateServiceDate]);

  useEffect(() => {
    setDisplayInterval(getDisplayInterval(billingInterval));
  }, [billingInterval]);

  return (
    <>
      <StepsContainer isInvoice={false} CurrentForm={DetailsCard} onNext={onSaveLocal} />
    </>
  );
  function DetailsCard() {
    return (
      <SectionCard data-testid="invoice-plan-details" header={t('Recurring Plan Details')}>
        <DetailsForm />
      </SectionCard>
    );
  }
  function RenderNotificationDays() {
    const [notifyDays, setNotifyDays] = useState(details?.notificationDays ?? 1);
    // apply default
    details.notificationDays = notifyDays;

    function onBlurNotificationDays() {
      details.notificationDays = Number(notifyDays ?? 0);
      setErrors(null);
    }

    function onChangeNotifyDays(e, { value }) {
      setNotifyDays(value);
    }

    return (
      <Form.Group>
        <Form.Field inline>
          <label data-testid="notification-days-label">{t('Notify parents')}</label>
          <Input
            data-testid="notification-days-input"
            maxLength={2}
            className="notifications-days"
            name={'notificationDays'}
            value={notifyDays}
            placeholder="0"
            onChange={onChangeNotifyDays}
            onBlur={onBlurNotificationDays}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <label className="label-after" data-testid="notification-days-label-after">
            {t('days before due date')}
            <Popup
              trigger={<Icon size="small" color="grey" className={'plan-info'} name="question" circular />}
              content={t('Set this date if you want to start the recurring plan earlier than the start date.')}
            />
          </label>
        </Form.Field>
      </Form.Group>
    );
  }

  function DetailsForm() {
    const displayDateStartWithDay = dateFormatter(dateStart, 'dddd, MM/DD/YYYY');
    const displayDateDueWithDay = dateFormatter(dateDue, 'dddd, MM/DD/YYYY');
    const displayDateStart = dateFormatter(dateStart);
    return (
      <>
        <Form.Group>
          <Form.Field width={4} error={!!errors?.billingInterval}>
            <Label data-testid="billing-interval-label" basic className="label-required" style={{ border: 'none' }}>
              {t('Billing Interval')}
            </Label>
            <Dropdown
              data-testid="billing-interval-dropdown"
              clearable={!isEdit}
              required
              disabled={isEdit}
              onChange={onChangeBillingInterval}
              placeholder={t('Billing frequency')}
              name={'billingInterval'}
              value={billingInterval}
              options={BILLING_INTERVAL_OPTIONS}
              translator={t}
            />
            {errors?.billingInterval && <InlineError text={errors.billingInterval} />}
          </Form.Field>
          <Form.Field width={4}>
            <Label data-testid="date-start-label" basic className="label-required" style={{ border: 'none' }}>
              {t('Plan Start Date')}
            </Label>
            <span data-testid="date-start-datepicker">
              <DatePicker
                required
                disabled={isEdit}
                minDate={moment()}
                name={'dateStart'}
                value={displayDateStart}
                onChange={onChangeDateStart}
              />
            </span>

            {errors?.dateStart && <InlineError text={errors.dateStart} />}
          </Form.Field>
          <Form.Field width={4}>
            <label data-testid="date-end-label" className={'plan-wrapper'}>
              {t('Plan End Date')}
              <Popup
                trigger={<Icon size="small" color="grey" className={'plan-info'} name="question" circular />}
                content={t('The date where the recurring plan ends.')}
              />
            </label>
            <span data-testid="date-end-datepicker">
              <DatePicker
                name={'dateEnd'}
                value={dateEnd}
                onChange={onChangeDateEnd}
                minDate={displayDateStart}
                disabled={displayDateStart ? false : true}
              />
            </span>
          </Form.Field>
        </Form.Group>

        <RenderNotificationDays />

        {isInvoicePlanServiceDatesEnabled && dateStart && billingInterval && (
          <Form.Group>
            <Form.Field inline className="flex">
              <Checkbox
                data-testid="service-dates-toggle"
                toggle
                label={t('invoiceplan.servicedate.toggleTitle')}
                checked={isServiceDatesEnabled}
                onChange={onChangeServiceDatesEnabled}
              />
            </Form.Field>
          </Form.Group>
        )}
        {isInvoicePlanServiceDatesEnabled &&
          dateStart &&
          billingInterval &&
          isServiceDatesEnabled &&
          serviceDirection && (
            <>
              <Form.Group>
                <Form.Field inline>
                  <label className="label-required" data-testid="service-date-label">
                    {t('invoiceplan.servicedate.title', {
                      displayInterval: t(displayInterval),
                    })}
                  </label>
                  <div className="billing-direction-group margin top bottom">
                    <Button.Group id="serviceDirection">
                      <Button
                        data-testid="service-date-previous-button"
                        className={serviceDirection === SERVICE_DIRECTION.Previous ? 'primary' : 'basic'}
                        onClick={() => onChangeServiceDirection(SERVICE_DIRECTION.Previous)}
                      >
                        {t('Previous')} {t(displayInterval)}
                        <p>{displayPreviousServiceInterval}</p>
                      </Button>
                      <Button
                        data-testid="service-date-next-button"
                        className={serviceDirection === SERVICE_DIRECTION.Next ? 'primary' : 'basic'}
                        onClick={() => onChangeServiceDirection(SERVICE_DIRECTION.Next)}
                      >
                        {t('Next')} {t(displayInterval)} <p>{displayNextServiceInterval}</p>
                      </Button>
                    </Button.Group>
                  </div>
                </Form.Field>
              </Form.Group>
              <Message info data-testid="service-date-info-message">
                <Icon name="info circle" size="big" data-testid="service-date-info" />
                {!dateDue &&
                  t('invoiceplan.invoice.info', {
                    dateStart: displayDateStartWithDay,
                    billingInterval: t(billingInterval?.toLowerCase()),
                    serviceDate:
                      serviceDirection === SERVICE_DIRECTION.Next
                        ? displayNextServiceInterval
                        : displayPreviousServiceInterval,
                  })}
                {dateDue &&
                  t('invoiceplan.invoice.info.next', {
                    dateDue: displayDateDueWithDay,
                    billingInterval: t(billingInterval?.toLowerCase()),
                    serviceDate:
                      serviceDirection === SERVICE_DIRECTION.Next
                        ? displayNextServiceInterval
                        : displayPreviousServiceInterval,
                  })}
              </Message>
            </>
          )}

        {(!isInvoicePlanServiceDatesEnabled || !isServiceDatesEnabled) && dateStart && (
          <Message info data-testid="service-date-shortInfo-message">
            <Icon name="info circle" size="big" />
            {!dateDue &&
              t('invoiceplan.invoice.shortInfo', {
                dateStart: displayDateStartWithDay,
                billingInterval: t(billingInterval?.toLowerCase()),
              })}
            {dateDue &&
              t('invoiceplan.invoice.shortInfo.next', {
                dateDue: displayDateDueWithDay,
                billingInterval: t(billingInterval?.toLowerCase()),
              })}
          </Message>
        )}
      </>
    );
  }

  function validate() {
    let errors = null;
    if (!billingInterval)
      errors = addError(errors, 'billingInterval', t('billing.stepInvoicePlanDetail.BillingIntervalisrequired'));
    if (!dateStart) errors = addError(errors, 'dateStart', t('enrollments.enrollmentInfoStartDateRequired'));

    return errors;
  }
  function onSaveLocal(doValidate = true) {
    const errors = doValidate ? validate() : false;

    if (errors) {
      setErrors(errors);
      return false;
    } else if (onSave) {
      isInvoicePlanServiceDatesEnabled
        ? onSave({
            billingInterval,
            dateStart,
            dateEnd,
            dateDue,
            notificationDays: details.notificationDays,
            serviceDirection: isServiceDatesEnabled ? serviceDirection : null,
            isServiceDatesEnabled,
          })
        : onSave({
            billingInterval,
            dateStart,
            dateEnd,
            dateDue,
            notificationDays: details.notificationDays,
          });
      return true;
    }
  }

  function onChangeBillingInterval(e, { value }) {
    setBillingInterval(value);
    setErrors(null);
  }
  function onChangeDateStart(e, { value }) {
    const date = moment(value, DefaultDateFormat);
    const now = moment().startOf('day');

    if (date.valueOf() < now.valueOf()) {
      value = now.format(DefaultDateFormat);
    }

    setDateStart(value);
    setDateEnd(null);
    setErrors(null);
  }
  function onChangeDateEnd(e, { value }) {
    setDateEnd(value);
    setErrors(null);
  }
  function onChangeServiceDirection(value) {
    setServiceDirection(value);
    setErrors(null);
  }
  function onChangeServiceDatesEnabled() {
    setIsServiceDatesEnabled(!isServiceDatesEnabled);
    setErrors(null);
  }
}
