import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Form } from 'semantic-ui-react';

// Import styles
import './LocationPicker.module.scss'; // for globals/children overrides
import styles from './LocationPicker.module.scss';

const selectLocationsList = createSelector([(state) => state.locations], (locations) => locations?.list ?? []);
const LocationPicker = ({ className, ...rest }) => {
  const locations = useSelector(selectLocationsList);

  const options = useMemo(
    () =>
      locations?.map((location) => ({
        text: location.name,
        value: location.id,
      })),
    [locations]
  );

  return (
    <Form.Dropdown
      clearable
      options={options}
      selectOnBlur={false}
      renderLabel={(label) => ({
        color: 'yellow',
        content: label.text,
      })}
      className={[styles.overflowFix, className].join(' ')}
      {...rest}
      data-testid="dropdown-item"
    />
  );
};

export default LocationPicker;
