import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable, { SelectRowProps } from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Checkbox } from 'semantic-ui-react';

import { InlineError } from '../../Components/Messages';

import { dateFormatter, formatISOStringAsUtcDateOrNull } from '../../helpers/dates';
import {
  InterestedChildType,
  InterestedFamilyType,
  InterestedParentType,
  useInterestedFamilies,
} from '../../integrations';
import { FormComponentProps } from '../../common';
import { useAllStudents } from '../studentsHooks';

// We are only addin isSaving and onSave so we can fail validation if the user hasn't
// selected a family before hitting Save
type InterestedFamiliesFormProps = FormComponentProps<InterestedFamilyType> & {
  onSelect?: (interestedFamily: InterestedFamilyType) => void;
};

function InterestedFamiliesForm({ title, hasTitle = true, isSaving, onSave, onSelect }: InterestedFamiliesFormProps) {
  const { t } = useTranslation();
  const interestedFamilies = useInterestedFamilies();
  const { list: studentsList } = useAllStudents();

  const [error, setError] = useState<string>();
  const viewTitle = hasTitle ? t(title || 'students.interestedFamiliesFormTitle') : '';

  const interestedFamiliesFiltered = useMemo(() => {
    return interestedFamilies.filter((family: any) => {
      return !studentsList?.find((student: any) => student.marketplaceId === family.student?.id);
    });
  }, [interestedFamilies, studentsList]);

  // isSaving will always return an error, never data
  useEffect(() => {
    if (!isSaving) return;
    const errorLocal = t('students.interestedFamiliesNotSelected');
    setError(errorLocal);
    onSave?.({ errors: { error: errorLocal } });
  }, [isSaving, onSave, t]);

  const columns: any = [
    {
      text: t('Parents'),
      dataField: 'parents',
      sort: true,
      formatter: (cell: InterestedParentType[], _row: any) => {
        return cell.map((parent, index) => <div key={index}>{`${parent.firstName} ${parent.lastName}`}</div>);
      },
    },
    {
      text: t('Added'),
      dataField: 'insertedAt',
      sort: true,
      formatter: (cell: string) => {
        return cell ? dateFormatter(formatISOStringAsUtcDateOrNull(cell)) : '';
      },
    },
    {
      text: t('Child'),
      dataField: 'student',
      sort: true,
      formatter: (cell: InterestedChildType, row: any) => {
        return <div key={row.id}>{`${cell.firstName} ${cell.lastName}`}</div>;
      },
    },
    {
      text: t('Birthday'),
      dataField: 'student.dob',
      sort: true,
      formatter: (cell: string) => {
        return cell ? dateFormatter(formatISOStringAsUtcDateOrNull(cell)) : '';
      },
    },
  ];

  const selectRow: SelectRowProps<InterestedFamilyType> = useMemo(
    () => ({
      mode: 'radio',
      style: {},
      clickToSelect: true,
      selectionRenderer: ({ mode, rowKey, rowIndex, ...rest }: any) => (
        <Checkbox radio data-testid={`familytype-radio-${rowIndex}`} {...rest} />
      ),
      onSelect: (row: InterestedFamilyType, _isSelected: boolean) => {
        if (onSelect) onSelect(row);
      },
    }),
    [onSelect]
  );
  return (
    <div className="interested-families-form" data-testid="int-families-form">
      {!!viewTitle && <h2 data-testid="int-families-form-title">{viewTitle}</h2>}
      {!!error && <InlineError text={error} data-testid="error-message-inline" />}
      <ToolkitProvider bootstrap4 keyField="key" data={interestedFamiliesFiltered} columns={columns}>
        {({ baseProps }) => (
          <div className="bootstrap-iso">
            <BootstrapTable
              remote
              hover
              bordered={false}
              selectRow={selectRow}
              headerClasses="table-header"
              noDataIndication={<div data-testid="int-fam-error-msg">{t('No interested families found')}</div>}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default InterestedFamiliesForm;
