import { useTranslation } from 'react-i18next';
import './InvoiceList.scss';
import { WsEmail } from '../../../icons';
import { Dimmer } from 'semantic-ui-react';

const EnablePaymentsModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <Dimmer active inverted />
      <div className="enable-payments-modal">
        <b className="top-text">{t('billing.enablePayModal.topText')}</b>
        <>
          <li>{t('billing.enablePayModal.bullet1')}</li>
          <li>{t('billing.enablePayModal.bullet2')}</li>
        </>
        <div className="link-section">
          <a href="mailto:support@wonderschool.com" target="_blank" rel="noreferrer" style={{ display: 'flex' }}>
            <WsEmail className="email-icon" />
            {t('support@wonderschool.com')}
          </a>
        </div>
      </div>
    </>
  );
};

export default EnablePaymentsModal;
