import { Segment, Header, Checkbox } from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';

import { useFeatureFlags } from './useFeatureFlags';

export const FeatureFlagsTable = () => {
  const { t } = useTranslation();
  const allFlags = useFeatureFlags();
  return (
    <>
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {t('Feature Flags')}
        </Header>
      </Segment>
      <Segment basic clearing className="bootstrap-iso">
        <BootstrapTable
          bootstrap4
          keyField="name"
          bordered={false}
          hover={false}
          wrapperClasses="table-responsive"
          headerClasses="table-header"
          columns={[
            {
              dataField: 'name',
              text: t('Name'),
              sort: false,
            },
            {
              dataField: 'enabled',
              text: t('Enabled?'),
              sort: false,
              formatter: (cell, flag) => {
                return (
                  <Checkbox
                    toggle
                    fitted
                    checked={flag.enabled}
                    onChange={() => {
                      flag.toggle();
                    }}
                  />
                );
              },
            },
          ]}
          data={Object.values(allFlags)}
        />
      </Segment>
    </>
  );
};
