import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FoodProgramQueryProvider, MealSchedulingPage } from '@wonderschool/common-food-program-ui';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import { getTitlePrefix } from '../config/env';
import { useAuthUser } from '../hooks/useUser';
import { getCurrentEnvironment } from '../common/utils/environmentUtils';

const Calendar = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.calendar);
  const { token } = useAuthUser();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Calendar') })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}

      <MealSchedulingPage onRecordMeal={() => {}} onViewOnCalendar={() => {}} />
    </FoodProgramQueryProvider>
  );
};
export default Calendar;
