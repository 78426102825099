import React, { useState } from 'react';
import { Segment, Header, Accordion, Icon, Input, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';

import './Calculator.scss';

import PageHeader from '../Components/Shared/PageHeader';
import { useSegmentPage, PAGE_NAMES } from '../segment';
import CalculatorIllustration from '../Components/Icons/CalculatorIllustration';
import TimePercentage from '../Components/Icons/TimePercentage';
import SpacePercentage from '../Components/Icons/SpacePercentage';
import Information from '../Components/Icons/Information';
import TimeSpace from '../Components/Icons/TimeSpace';
import Star from '../Components/Icons/Star';
import {
  getDisplayPercentage,
  getSpacePercentage,
  getTimePercentage,
  getTimeSpacePercentage,
} from '../helpers/timeSpacePercentage';
import { useOrganization } from '../hooks/useOrganizations';
import { updateMoxitOrganization } from '../redux/actions/organizationActions';
import { toggleSpaceSection, toggleTimeSection } from '../redux/actions/layoutActions';
import { DefaultDateFormat } from '../helpers/dates';

const selectLayoutFields = createSelector([(state) => state.layout], (layout) => ({
  isTimeSectionActive: layout.isTimeSectionActive,
  isSpaceSectionActive: layout.isSpaceSectionActive,
}));

export default function TimeSpaceCalculator() {
  useSegmentPage(PAGE_NAMES.calculator);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganization = useOrganization();

  const { isTimeSectionActive, isSpaceSectionActive } = useSelector(selectLayoutFields);

  const [activePopup, setActivePopup] = useState(null);

  const { timeSpaceValues: initialTimeSpaceValues } = currentOrganization;
  const initialTimeValues = initialTimeSpaceValues?.timeValues || {};
  const initialSpaceValues = initialTimeSpaceValues?.spaceValues || {};

  const [timeValues, setTimeValues] = useState(initialTimeValues);
  const [spaceValues, setSpaceValues] = useState(initialSpaceValues);

  const getUsedFootageError = (homeSquareFootage, useValue, otherUseValue) => {
    const homeSquareFootageOrZero = homeSquareFootage || 0;
    const useValueOrZero = useValue || 0;
    const otherUseValueOrZero = otherUseValue || 0;

    return useValueOrZero + otherUseValueOrZero > homeSquareFootageOrZero;
  };

  const [spaceInputError, setSpaceInputError] = useState(
    getUsedFootageError(
      initialSpaceValues?.homeSquareFootage,
      initialSpaceValues?.regularUseSquareFootage,
      initialSpaceValues?.exclusiveUseSquareFootage
    )
  );

  const getPositiveValue = (value) => (value >= 0 ? value : 0);

  const onTimeValueChange = (e) => {
    const value = e.target.value;

    if (!value) return setTimeValues({ ...timeValues, [e.target.name]: null });

    setTimeValues({
      ...timeValues,
      [e.target.name]: getPositiveValue(Number(value)),
    });
  };

  const onHomeSquareFootageChange = (e) => {
    const homeSquareFootage = Number(e.target.value);

    const hasSpaceError = getUsedFootageError(
      homeSquareFootage,
      spaceValues?.regularUseSquareFootage,
      spaceValues?.exclusiveUseSquareFootage
    );
    setSpaceInputError(hasSpaceError);

    setSpaceValues({
      ...spaceValues,
      homeSquareFootage: getPositiveValue(homeSquareFootage),
    });
  };

  const onUseFootageChange = (value, otherValue, name) => {
    const newValue = getPositiveValue(value);

    const hasSpaceError = getUsedFootageError(spaceValues?.homeSquareFootage, newValue, otherValue);
    setSpaceInputError(hasSpaceError);

    setSpaceValues({ ...spaceValues, [name]: newValue });
  };

  const onRegularUseSquareFootageChange = (e) => {
    onUseFootageChange(
      e.target.value ? Number(e.target.value) : null,
      spaceValues?.exclusiveUseSquareFootage,
      'regularUseSquareFootage'
    );
  };

  const onExclusiveUseSquareFootageChange = (e) => {
    onUseFootageChange(
      e.target.value ? Number(e.target.value) : null,
      spaceValues?.regularUseSquareFootage,
      'exclusiveUseSquareFootage'
    );
  };

  const timePercentage = getDisplayPercentage(getTimePercentage(timeValues));
  const regularSpacePercentage = getDisplayPercentage(
    getSpacePercentage(spaceValues.regularUseSquareFootage, spaceValues.homeSquareFootage)
  );
  const exclusiveSpacePercentage = getDisplayPercentage(
    getSpacePercentage(spaceValues.exclusiveUseSquareFootage, spaceValues.homeSquareFootage)
  );
  const timeSpacePercentage = getDisplayPercentage(getTimeSpacePercentage(timeValues, spaceValues));

  const onInputBlur = () => {
    dispatch(
      updateMoxitOrganization({
        id: currentOrganization.id,
        timeSpaceValues: {
          timeValues,
          spaceValues,
          ...(timeSpacePercentage !== '--' ? { updatedAt: Date.now() } : {}),
        },
      })
    );
  };

  const getLastCalculated = (updatedAt) =>
    updatedAt
      ? `Last calculated on ${moment(updatedAt).format(DefaultDateFormat)}`
      : "You haven't calculated this value yet.";

  return (
    <>
      <Segment basic className="calculator">
        <PageHeader pageName={PAGE_NAMES.calculator} />
        <Header as="h1" className="title">
          {t('timeSpaceCalculator.title')}
        </Header>

        <div className="illustration-mobile">
          <CalculatorIllustration size={136} />
        </div>

        <div className="container">
          <div>
            <div className="bottom-text">
              <span>{t('timeSpaceCalculator.intro')}</span>
              <a
                href="https://www.tomcopelandblog.com/blog/the-time-space-percentage-quiz"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                {t('timeSpaceCalculator.click')}
              </a>
              <span> {t('timeSpaceCalculator.learnMore')}</span>
            </div>

            <Accordion>
              <div className="calculator-section row-shadow">
                <Accordion.Title onClick={() => dispatch(toggleTimeSection(!isTimeSectionActive))}>
                  <div className="row">
                    <TimePercentage />
                    <span className="row-title">{t('timeSpaceCalculator.timePercentage')}</span>
                    <span className="row-percent">
                      {timePercentage}
                      {timePercentage === '--' ? ' ' : ''}%
                    </span>
                    <Icon
                      className="chevron-down"
                      name={`chevron ${isTimeSectionActive ? 'up' : 'down'}`}
                      color="blue"
                    />
                  </div>
                </Accordion.Title>

                <Accordion.Content active={isTimeSectionActive}>
                  <div className="section-body">
                    <span>{t('timeSpaceCalculator.timeIntro')}</span>
                    <div className="section-gray">
                      <div className="star-icon">
                        <Star />
                      </div>
                      <div>
                        <span>
                          <span className="bold">{t('timeSpaceCalculator.timeTip')}: </span>
                          {t('timeSpaceCalculator.timeTipBodyFirst')}
                        </span>
                        <span className="bold"> {t('timeSpaceCalculator.timeTipBodySecond')}</span>
                      </div>
                    </div>
                    <div className="section-title">
                      <span className="bold">{t('timeSpaceCalculator.workingHours')}</span>
                    </div>

                    <div className="input-container">
                      <div className="input-section left">
                        <span>{t('timeSpaceCalculator.weeklyActiveHoursLabel')}</span>
                        <Input
                          className="input-box"
                          placeholder="-- hrs"
                          type="number"
                          min="0"
                          name="weeklyActiveHours"
                          onChange={onTimeValueChange}
                          onBlur={onInputBlur}
                          defaultValue={initialTimeValues.weeklyActiveHours}
                          value={timeValues?.weeklyActiveHours}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="bottom-input-margin">{t('timeSpaceCalculator.inactiveWeeksLabel')}</div>
                        <Input
                          className="input-box"
                          placeholder="-- wks"
                          type="number"
                          min="0"
                          name="inactiveWeeks"
                          onChange={onTimeValueChange}
                          onBlur={onInputBlur}
                          defaultValue={initialTimeValues.inactiveWeeks}
                          value={timeValues?.inactiveWeeks}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                      <div className="input-section">
                        <div className="input-title">
                          {t('timeSpaceCalculator.weeklyAdminHoursLabel')}
                          <div
                            className="input-title-icon"
                            onMouseEnter={() => setActivePopup('weeklyAdminHours')}
                            onMouseLeave={() => setActivePopup(null)}
                          >
                            <Popup
                              open={activePopup === 'weeklyAdminHours'}
                              content={t('timeSpaceCalculator.timeToolTip')}
                              trigger={<Information />}
                              basic
                            />
                          </div>
                        </div>
                        <Input
                          className="input-box"
                          placeholder="-- hrs"
                          type="number"
                          min="0"
                          name="weeklyAdminHours"
                          onChange={onTimeValueChange}
                          onBlur={onInputBlur}
                          defaultValue={initialTimeValues.weeklyAdminHours}
                          value={timeValues?.weeklyAdminHours}
                          onWheel={(e) => e.target.blur()}
                        />
                        <div className="bottom-input">
                          {t('timeSpaceCalculator.weeklyHoursWhenClosedLabel')}
                          <div
                            className="input-title-icon"
                            onMouseEnter={() => setActivePopup('weeklyHoursWhenClosed')}
                            onMouseLeave={() => setActivePopup(null)}
                          >
                            <Popup
                              open={activePopup === 'weeklyHoursWhenClosed'}
                              content={t('timeSpaceCalculator.timeToolTip')}
                              trigger={<Information />}
                              basic
                            />
                          </div>
                        </div>
                        <Input
                          className="input-box"
                          placeholder="-- hrs"
                          type="number"
                          min="0"
                          name="weeklyHoursWhenClosed"
                          onChange={onTimeValueChange}
                          onBlur={onInputBlur}
                          defaultValue={initialTimeValues.weeklyHoursWhenClosed}
                          value={timeValues?.weeklyHoursWhenClosed}
                          onWheel={(e) => e.target.blur()}
                        />
                      </div>
                    </div>

                    <div className="section-gray bold">
                      {t('timeSpaceCalculator.timePercentage')}:&nbsp;
                      <span className="percentage">
                        {timePercentage}
                        {timePercentage !== '--' ? '%' : ''}
                      </span>
                    </div>
                  </div>
                </Accordion.Content>
              </div>

              <div className="calculator-section row-shadow">
                <Accordion.Title onClick={() => dispatch(toggleSpaceSection(!isSpaceSectionActive))}>
                  <div className="row">
                    <SpacePercentage />
                    <span className="row-title">{t('timeSpaceCalculator.spacePercentage')}</span>
                    <span className="row-percent row-percent-left">
                      {regularSpacePercentage}
                      {regularSpacePercentage === '--' ? ' ' : ''}% (R)
                    </span>
                    <span className="row-percent">
                      {exclusiveSpacePercentage}
                      {exclusiveSpacePercentage === '--' ? ' ' : ''}% (E)
                    </span>
                    <Icon
                      className="chevron-down"
                      name={`chevron ${isSpaceSectionActive ? 'up' : 'down'}`}
                      color="blue"
                    />
                  </div>
                </Accordion.Title>

                <Accordion.Content active={isSpaceSectionActive}>
                  <div className="section-body">
                    <div className="intro-paragraph">{t('timeSpaceCalculator.spaceIntro')}</div>

                    <div>
                      <span className="bold">{t('timeSpaceCalculator.regularUse')} </span>
                      {t('timeSpaceCalculator.spaceInfo')}
                      <span className="bold"> {t('timeSpaceCalculator.spaceInfoBold')} </span>
                      {t('timeSpaceCalculator.spaceInfoSecond')}
                    </div>

                    <div className="section-title">
                      <span className="bold">{t('timeSpaceCalculator.squareFootage')}</span>
                    </div>

                    <div>
                      <div className="input-title">
                        <span>{t('timeSpaceCalculator.homeSquareFootage')}</span>
                        <div
                          className="input-title-icon"
                          onMouseEnter={() => setActivePopup('homeSquareFootage')}
                          onMouseLeave={() => setActivePopup(null)}
                        >
                          <Popup
                            open={activePopup === 'homeSquareFootage'}
                            content={t('timeSpaceCalculator.homeTooltip')}
                            trigger={<Information />}
                            basic
                          />
                        </div>
                      </div>
                      <Input
                        className="input-box home-square-footage-input"
                        placeholder="-- sqft."
                        type="number"
                        min="1"
                        name="homeSquareFootage"
                        onChange={onHomeSquareFootageChange}
                        onBlur={onInputBlur}
                        defaultValue={initialSpaceValues.homeSquareFootage}
                        value={spaceValues?.homeSquareFootage || ''}
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>

                    <div className="input-container">
                      <div className="input-section left">
                        <div className="input-title">
                          {t('timeSpaceCalculator.exclusiveUseSquareFootage')}
                          <div
                            className="input-title-icon"
                            onMouseEnter={() => setActivePopup('exclusiveUseSquareFootage')}
                            onMouseLeave={() => setActivePopup(null)}
                          >
                            <Popup
                              open={activePopup === 'exclusiveUseSquareFootage'}
                              content={t('timeSpaceCalculator.exclusiveUseTooltip')}
                              trigger={<Information />}
                              basic
                            />
                          </div>
                        </div>
                        <Input
                          className="input-box"
                          placeholder="-- sqft."
                          type="number"
                          min="0"
                          name="exclusiveUseSquareFootage"
                          onChange={onExclusiveUseSquareFootageChange}
                          onBlur={onInputBlur}
                          defaultValue={initialSpaceValues.exclusiveUseSquareFootage}
                          value={spaceValues?.exclusiveUseSquareFootage}
                          onWheel={(e) => e.target.blur()}
                          error={spaceInputError}
                        />
                      </div>

                      <div className="input-section">
                        <div className="input-title">
                          <span>{t('timeSpaceCalculator.regularUseSquareFootage')}</span>
                          <div
                            className="input-title-icon"
                            onMouseEnter={() => setActivePopup('regularUseSquareFootage')}
                            onMouseLeave={() => setActivePopup(null)}
                          >
                            <Popup
                              open={activePopup === 'regularUseSquareFootage'}
                              content={t('timeSpaceCalculator.regularUseTooltip')}
                              trigger={<Information />}
                              basic
                            />
                          </div>
                        </div>
                        <Input
                          className="input-box"
                          placeholder="-- sqft."
                          type="number"
                          min="0"
                          name="regularUseSquareFootage"
                          onChange={onRegularUseSquareFootageChange}
                          onBlur={onInputBlur}
                          defaultValue={initialSpaceValues.regularUseSquareFootage}
                          value={spaceValues?.regularUseSquareFootage}
                          onWheel={(e) => e.target.blur()}
                          error={spaceInputError}
                        />
                      </div>
                    </div>

                    <div className={`section-gray bold ${spaceInputError ? 'section-error' : ''}`}>
                      {t('timeSpaceCalculator.exclusivePercentage')}:&nbsp;
                      <span className="percentage">
                        {exclusiveSpacePercentage}
                        {exclusiveSpacePercentage !== '--' ? '%' : ''}
                      </span>
                    </div>
                    <div className={`section-gray bold section-gray-bottom ${spaceInputError ? 'section-error' : ''}`}>
                      {t('timeSpaceCalculator.regularPercentage')}:&nbsp;
                      <span className="percentage">
                        {regularSpacePercentage}
                        {regularSpacePercentage !== '--' ? '%' : ''}
                      </span>
                    </div>
                  </div>
                </Accordion.Content>
              </div>
            </Accordion>

            <div className={`row total-row ${spaceInputError ? 'section-error' : ''}`}>
              <TimeSpace />
              <span className="row-title">{t('timeSpaceCalculator.timeSpacePercentage')}</span>
              <span className="bold">{timeSpacePercentage}%</span>
              <div className="disclaimer-asterisk">*</div>
              <div data-tooltip={getLastCalculated(initialTimeSpaceValues?.updatedAt)} className="info-icon">
                <Information />
              </div>
            </div>

            <span className="disclaimer">* Please consult your tax advisor to verify this number.</span>
          </div>

          <div className="illustration">
            <CalculatorIllustration />
          </div>
        </div>
      </Segment>
    </>
  );
}
