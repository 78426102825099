import { RouteNameEnum } from './types';

const FLORIDA = process.env.REACT_APP_FLORIDA_NETWORK_ID || '';

// Map with network ids and menu names
export const hideMenuForNetworks = [FLORIDA];

// This config will consist of menu which we need to show for each network
export const menuConfigForNetworks = {
  [FLORIDA]: {
    [RouteNameEnum.WELCOME]: false,
    [RouteNameEnum.INTERESTED_FAMILIES]: true,
    [RouteNameEnum.MESSAGES]: true,
    [RouteNameEnum.VIEW_WEBSITE]: true,
    [RouteNameEnum.UPDATE_WEBSITE]: true,
    [RouteNameEnum.TOURS]: true,
    [RouteNameEnum.TOUR_REQUESTS]: true,
    [RouteNameEnum.EVENTS]: true,
    [RouteNameEnum.MARKETING]: true,
    [RouteNameEnum.DOCUMENTS]: false,
    [RouteNameEnum.PROFILE]: true,
  },
};
