import { Component } from 'react';
import { Header, Button, Segment, Icon, Sidebar } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';

// Import style
import './asset/style.scss';
import { connect } from 'react-redux';

import { openSlider } from '../../redux/actions/layoutActions';

const withSlidingPanel = (
  WrappedComponent,
  {
    icon = null,
    image = null,
    title = '',
    subTitle = '',
    width = '50%',
    direction = 'right',
    className = 'action-sheet summary',
  }
) => {
  class HOC extends Component {
    state = {
      isOpen: false,
      visible: true,
    };

    componentDidMount = () => {
      this.sliderMounted = true;
    };

    componentWillUnmount = () => {
      this.sliderMounted = false;
      if (this.state.isOpen) {
        this.props.onClose();
        this.props.openSlider(false);
      }
    };

    componentDidUpdate = (prevProps) => {
      if (prevProps.isOpen !== this.props.isOpen) {
        this.props.openSlider(this.props.isOpen);
        this.setState({ isOpen: this.props.isOpen });
        if (this.props.isOpen) {
          document.body.classList.add('slider-open');
        } else {
          document.body.classList.remove('slider-open');
        }
      }
    };

    closeButton = () => (
      <Segment clearing basic className="no-margin no-padding">
        <Button
          icon="cancel"
          floated="right"
          circular
          inverted
          onClick={() => {
            if (this.props.onClose) this.props.onClose();
            else this.setState({ isOpen: false });
          }}
          data-testid="with-sliding-panel-close-btn"
        />
      </Segment>
    );

    addHeader = () => {
      const { t } = this.props;

      return (
        <Segment basic textAlign="center">
          {!image && !this.props.image && (icon || this.props.icon) && (
            <Icon name={this.props.icon || icon} size="huge" circular style={{ color: 'white' }} />
          )}

          {(image || this.props.image) && <Avatar src={this.props.image || image} round size={150} />}

          {(title || this.props.title) && (
            <Header as="h1" content={t(this.props.title || title)} inverted data-testid="sliding-panel-title" />
          )}

          {(subTitle || this.props.subTitle) && (
            <Header
              as="h4"
              content={t(this.props.subTitle || subTitle)}
              inverted
              data-testid="sliding-panel-subtitle"
            />
          )}
        </Segment>
      );
    };

    showSidePanel = () => this.setState({ isOpen: true });

    handleSidebarHide = () => {
      this.setState({ isOpen: false });
    };

    render() {
      return this.state.isOpen ? (
        <Sidebar
          animation="overlay"
          icon="labeled"
          onHidden={() => {
            if (this.props.onClose) this.props.onClose();
            else this.setState({ isOpen: false });
          }}
          visible={this.state.isOpen}
          className={className}
          direction={direction}
          style={{ width: width }}
        >
          <Segment basic className="summary">
            {this.closeButton()}
            {this.addHeader()}
          </Segment>
          <Segment clearing basic>
            <WrappedComponent {...this.props} />
          </Segment>
        </Sidebar>
      ) : null;
    }
  }

  return withTranslation()(connect(null, { openSlider })(HOC));
};

export default withSlidingPanel;
