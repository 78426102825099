import { AddCircleIcon, Button, Input, SlideOver, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import moment from 'moment';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../api/firebase';
import { RoomDropdown, StaffDropdown, WsInputEventType } from '../../common';
import { useContacts } from '../../contacts';
import { formatHoursFromMinutes } from '../../helpers/dates';
import { useAuthUser, useUser } from '../../hooks/useUser';
import { logError } from '../../rollbar';
import { TimecardStatusEnum } from '../enums';
import { convertTo24Hour, parseDateString } from '../timecardsUtils';

type FormDataType = {
  contactId: any;
  id: any;
  roomId: string;
  staffId: string;
  clockedInDate: string;
  clockedOutDate: string;
  clockedInTime: string;
  clockedOutTime: string;
  locationId: string;
  uid: string;
};

const AddTimecard = ({ onSave, setRefreshTimeCard }) => {
  const { t } = useTranslation();
  const { staffContacts } = useContacts();
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [isPastDate, setIsPastDate] = useState(false);
  const [isOvernight, setIsOvernight] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<FormDataType>({
    clockedInTime: convertTo24Hour(dayjs(new Date()).format('hh:mm a')),
    clockedInDate: dayjs(new Date()).format('YYYY-MM-DD'),
    clockedOutDate: dayjs(new Date()).format('YYYY-MM-DD'),
  } as FormDataType);
  const { defaultOrganization } = useUser();
  const { currentUser } = useAuthUser();

  const { staffContacts: staff } = useContacts();

  const staffOptions = useMemo(() => {
    const staffRooms: string[] = [];
    staffRooms.length = 0;
    staff?.map(({ rooms, id }) => {
      rooms?.length && formData.staffId === id && staffRooms.push(...rooms);
    });
    return staffRooms;
  }, [staff, formData.staffId]);

  const handleAddTimecard = () => {
    setSliderOpen(true);
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
    setTimeout(() => {
      setFormData({
        clockedInTime: convertTo24Hour(dayjs(new Date()).format('hh:mm a')),
        clockedInDate: dayjs(new Date()).format('YYYY-MM-DD'),
        clockedOutDate: dayjs(new Date()).format('YYYY-MM-DD'),
      } as FormDataType);
      setErrors({});
    }, 500);
  };

  const onChange = useCallback(({ name, value }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onChangeTime = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setErrors((prev) => ({ ...prev, [event.target.name]: '' }));
  }, []);

  const onChangeStaff = useCallback(
    ({ name, value }: WsInputEventType<string>) => {
      const staffContact = staffContacts?.find((staff: any) => staff.id === value);
      setFormData((prev) => ({
        ...prev,
        [name]: staffContact.id ?? '',
        ['uid']: staffContact.uid ?? '',
        ['contactId']: staffContact.id ?? '',
        ['locationId']: staffContact.defaultLocation ?? '',
      }));
      setErrors((prev) => ({ ...prev, [name]: '' }));
    },
    [staffContacts]
  );

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!formData.clockedInDate) {
      errorsLocal.clockedInDate = t('errors.validation.startDateRequired');
    }
    if (!formData.clockedInTime) {
      errorsLocal.clockedInTime = t('errors.validation.startTimeRequired');
    }
    if (isPastDate) {
      errorsLocal.clockedOutTime = t('errors.validation.clockoutRequired');
    }
    if (!formData.roomId) {
      errorsLocal.roomId = t('errors.validation.roomRequired');
    }
    if (!formData.staffId) {
      errorsLocal.staffName = t('errors.validation.staffNameRequired');
    }
    if (isOvernight) {
      if (!formData.clockedOutDate) {
        errorsLocal.clockedOutDate = t('errors.validation.endDateRequired');
      }
      if (!formData.clockedOutTime) {
        errorsLocal.clockedOutTime = t('errors.validation.endTimeRequired');
      }
    }
    return errorsLocal;
  }, [
    formData.clockedInDate,
    formData.clockedInTime,
    formData.clockedOutDate,
    formData.clockedOutTime,
    formData.roomId,
    formData.staffId,
    isOvernight,
    isPastDate,
    t,
  ]);

  const onCancel = useCallback(() => {
    setFormData({
      clockedInTime: convertTo24Hour(dayjs(new Date()).format('hh:mm a')),
      clockedInDate: dayjs(new Date()).format('YYYY-MM-DD'),
      clockedOutDate: dayjs(new Date()).format('YYYY-MM-DD'),
    } as FormDataType);
    setErrors({});
    handleCloseSlider();
  }, []);

  const onSaveLocal = useCallback(() => {
    const errorsLocal = validate();
    if (!currentUser?.uid && !currentUser?.displayName) {
      return;
    }
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      return;
    }
    setIsSaving(true);
    const { clockedInDate, clockedInTime, clockedOutDate, clockedOutTime } = formData;
    const clockedIn = parseDateString(moment(clockedInDate).format('MM/DD/YYYY') + ' ' + clockedInTime);
    const clockedOut =
      clockedOutDate && clockedOutTime
        ? parseDateString(
            moment(clockedOutDate).format('MM/DD/YYYY') + (clockedOutTime ? ' ' + clockedOutTime : ' 00:00 am')
          )
        : null;
    let status = TimecardStatusEnum.CLOCKED_OUT;
    if (clockedOut) {
      status = TimecardStatusEnum.CLOCKED_OUT;
    } else {
      status = TimecardStatusEnum.CLOCKED_IN;
    }
    try {
      const timecardLocal = {
        uid: formData.uid,
        contactId: formData.contactId,
        organizationId: defaultOrganization,
        locationId: formData.locationId,
        roomId: formData.roomId,
        status,
        clockedIn: clockedIn,
        clockedOut: clockedOut,
        createdAt: firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          displayName: currentUser?.displayName,
        },
        updatedAt: firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          displayName: currentUser.displayName,
        },
      };
      onSave?.(timecardLocal);
    } catch (error) {
      logError('AddTimecard: failed saving timecard', error);
    } finally {
      setIsSaving(false);
      setRefreshTimeCard();
      setTimeout(() => onCancel(), 500);
    }
  }, [
    currentUser?.displayName,
    currentUser?.uid,
    defaultOrganization,
    formData,
    onCancel,
    onSave,
    setRefreshTimeCard,
    validate,
  ]);

  const checkOvernight = () => {
    if (!formData.clockedInDate || !formData.clockedOutDate) {
      setIsOvernight(false);
      return '';
    }
    const date1 = moment(formData.clockedInDate);
    const date2 = moment(formData.clockedOutDate);
    setIsOvernight(date2.diff(date1, 'days') === 1);
    return date2.diff(date1, 'days') === 1 ? '(Overnight)' : '';
  };

  const showTimeDifferece = () => {
    const {
      clockedInDate,
      clockedInTime,
      clockedOutDate = new Date(),
      clockedOutTime = new Date().toTimeString().slice(0, 5),
    } = formData;
    if (!clockedInDate || !clockedInTime) {
      return ' ';
    }
    const clockedIn = moment(clockedInDate).format('MM/DD/YYYY') + ' ' + clockedInTime;
    const clockedOut = moment(clockedOutDate).format('MM/DD/YYYY') + ' ' + clockedOutTime;
    const clockedInMoment = moment(clockedIn || new Date());
    const clockedOutMoment = moment(clockedOut || new Date());
    const totalMinutes = clockedOutMoment.diff(clockedInMoment, 'minutes');
    setIsPastDate(totalMinutes < 0);
    return `Total Hours: ${formatHoursFromMinutes(totalMinutes)} ${checkOvernight()}`;
  };

  const disabled = useCallback(
    () => !(!isSaving && formData.roomId && formData.contactId && formData.clockedInDate && formData.clockedInTime),
    [formData.clockedInDate, formData.clockedInTime, formData.contactId, formData.roomId, isSaving]
  );

  const LabelView = ({ label }) => <label className="text-base font-bold leading-5 text-gray-900">{label}</label>;

  const AddTimeCardContent = () => (
    <div className="size-full text-gray-800">
      <div className="mb-4">
        <StaffDropdown
          name="staffId"
          label="Staff Member"
          value={formData.staffId}
          onChange={onChangeStaff}
          error={errors.staffName}
          wrapperClassName="mb-8"
          required={true}
          data-testid="timesheet-filter-staff"
        />
      </div>
      <RoomDropdown
        name="roomId"
        value={formData?.roomId}
        staffRooms={staffOptions}
        disabled={!formData.staffId}
        error={errors.roomId}
        onChange={onChange}
        required={true}
        data-testid="timecard-add-room"
      />
      <hr className="mt-4 text-gray-400" />
      <div className="mb-2 mt-4">
        <LabelView label={t('timecards.clockedInLabel')} />
      </div>
      <div className="mb-8 flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
        <Input
          name="clockedInDate"
          label={t('Date')}
          inputType="date"
          onChange={(event) => {
            setFormData((prev) => ({ ...prev, clockedInDate: event.target.value }));
          }}
          value={formData.clockedInDate}
          required={true}
          placeholder="Select Date"
          error={errors.clockedInDate}
          max={dayjs(new Date()).format('YYYY-MM-DD')}
          data-testid="timecard-add-clocked-in-date"
        />
        <Input
          inputType="time"
          name="clockedInTime"
          required={true}
          label={t('Time')}
          value={formData?.clockedInTime && convertTo24Hour(formData?.clockedInTime)}
          error={errors.clockedInTime}
          onChange={onChangeTime}
        />
      </div>
      <div className="mb-2">
        <LabelView label={t('timecards.clockedOutLabel')} />
      </div>
      <div className="mb-8 flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
        <Input
          name="clockedOutDate"
          label={t('Date')}
          inputType="date"
          onChange={(event) => {
            setFormData((prev) => ({ ...prev, clockedOutDate: event.target.value }));
          }}
          value={formData.clockedOutDate}
          required={true}
          placeholder="Select Date"
          error={errors.clockedOutDate}
          min={formData.clockedInDate}
          max={dayjs(new Date()).format('YYYY-MM-DD')}
          data-testid="timecard-add-clocked-out-date"
        />
        <Input
          inputType="time"
          name="clockedOutTime"
          required={isOvernight}
          label={t('Time')}
          value={formData?.clockedOutTime && convertTo24Hour(formData?.clockedOutTime)}
          error={errors.clockedOutTime}
          onChange={onChangeTime}
        />
      </div>
      <hr className="mt-6 text-gray-400" />
      <div className="py-2">
        <LabelView label={showTimeDifferece()} />
      </div>
      <div className="absolute bottom-4 right-0 w-full">
        <hr className="mt-6 text-gray-400" />
        <div className="flex w-full flex-row items-center justify-end gap-3 bg-white px-2 pt-2">
          <div className="w-4/6 gap-3 md:w-1/6">
            <Button onClick={onCancel} extraClasses="w-full text-center py-2 px-8" data-testid="timecard-add-cancel">
              {t('Cancel')}
            </Button>
          </div>
          <div className="w-4/6 gap-3 md:w-1/6">
            <Button
              primary
              onClick={onSaveLocal}
              disabled={disabled()}
              extraClasses="w-full text-center py-2 px-8"
              data-testid="timecard-add-save"
            >
              {t('Add')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SlideOver hasOverlay onClose={handleCloseSlider} isOpen={isSliderOpen} title={t('timecards.addTimecards')}>
        <AddTimeCardContent />
      </SlideOver>
      <Button
        type="button"
        primary
        size={WidgetSizeEnum.SMALL}
        onClick={handleAddTimecard}
        extraClasses="sm:hidden block"
        data-testid="add-timecard-btn"
      >
        <span className="">{t('Add')} </span>
        <AddCircleIcon className="h-6" />
      </Button>
      <Button
        type="button"
        primary
        size={WidgetSizeEnum.SMALL}
        onClick={handleAddTimecard}
        extraClasses="hidden sm:block m-1"
        data-testid="add-timecard-btn"
      >
        <span className="m-1">{t('timecards.addTimecard')}</span>
        <span className="h-6">
          <AddCircleIcon className="h-6" />
        </span>
      </Button>
    </>
  );
};
export default AddTimecard;
