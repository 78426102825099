import { firestore } from '.';
import { getInvoicesStatusesPerMonth, getRevenueAndExpencesPerMonth } from '../../helpers/financeAtGlance';
import { paths as transactionsPath } from '../../transactions/transactionsAPI';
import { paths as invoicesPath } from './invoices';

export async function getAllInvoicesPerMonth(organizationId, selectedMonth, t) {
  const allInvoicesSnapshot = await firestore()
    .collection(invoicesPath.invoices(organizationId))
    .where('dateDue', '>=', selectedMonth.firstdate)
    .where('dateDue', '<=', selectedMonth.lastdate)
    .get();

  const allInvoices = allInvoicesSnapshot?.docs?.filter((doc) => doc.exists).map((doc) => doc.data()) || [];
  const filteredInvoices = getInvoicesStatusesPerMonth(allInvoices, selectedMonth, t);
  return filteredInvoices;
}

export type Transaction = {
  expense: number;
  revenue: number;
};
export async function getAllTransactionsPerMonth(organizationId, selectedDates): Promise<Transaction> {
  // Transaction data is in UTC format hence need to change selected month in to UTC as well.
  const TransactionsInMonthSnapshot = await firestore()
    .collection(transactionsPath.transactions(organizationId))
    .where('date', '>=', selectedDates.firstDate.toDate())
    .where('date', '<=', selectedDates.lastDate.toDate())
    .get();

  const allTransactionInMonth =
    TransactionsInMonthSnapshot?.docs?.filter((doc) => doc.exists).map((doc) => doc.data()) || [];
  const filteredTransactions = getRevenueAndExpencesPerMonth(allTransactionInMonth, selectedDates);
  return filteredTransactions;
}
