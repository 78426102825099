import React from 'react';

const CalculatorIllustration = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '341'}
      height={props.size || '341'}
      fill="none"
      viewBox="0 0 341 341"
    >
      <path
        stroke="#484848"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M161.641 116l-4.922-2.936v-6.328"
      ></path>
      <path
        stroke="#484848"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M163.364 105.636a9 9 0 010 12.728 9 9 0 01-12.728 0 9 9 0 010-12.728 9 9 0 0112.728 0"
      ></path>
      <path
        fill="#F2FAFE"
        d="M170.5 341c94.165 0 170.5-76.335 170.5-170.5S264.665 0 170.5 0 0 76.335 0 170.5 76.335 341 170.5 341z"
      ></path>
      <path
        fill="#FCFCFC"
        d="M121.704 55.831a20.053 20.053 0 00-12.216 4.253c-1.329-2.083-3.407-3.412-5.739-3.412a6.628 6.628 0 00-4.936 2.35c-2.299-5.102-6.776-8.564-11.916-8.564-2.49.021-4.91.832-6.91 2.316a14.74 14.74 0 01-7.405 2.012 15.34 15.34 0 01-10.541-4.36l-.046.045c-5.868-5.623-13.29-9.013-21.362-9.013-14.577 0-27.018 10.958-32.037 26.43h132.062c-4.144-7.271-11.087-12.057-18.954-12.057z"
      ></path>
      <path fill="#1E8BCC" d="M296.456 151.387H176.872l-66.164-66.618h119.584l66.164 66.618z"></path>
      <path fill="#FDD736" d="M44.96 283.299V151.562l65.748-66.202 65.748 66.202v131.737H44.96z"></path>
      <path
        fill="#1D2F5B"
        d="M110.708 85.952l65.332 65.781v131.15H45.377v-131.15l65.331-65.781zm0-1.183l-66.164 66.639v132.308h132.329V151.387l-66.165-66.614v-.004zM296.456 151.387H176.873v132.324h119.583V151.387z"
      ></path>
      <path
        stroke="#1E8BCC"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M262.087 190.388h-50.829v54.335h50.829v-54.335z"
      ></path>
      <path
        stroke="#1E8BCC"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M236.673 190.388l-.025 54.335M211.258 217.955h50.708"
      ></path>
      <path
        stroke="#1E8BCC"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M211.258 218.11c11.116-6.144 20.471-15.915 25.406-27.722h-25.406v27.722zM262.116 218.11c-11.116-6.144-20.5-15.915-25.444-27.722h25.444v27.722zM217.764 244.582l-6.506-26.473"
      ></path>
      <path
        stroke="#1E8BCC"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M262.116 218.109l-4.74 26.627h4.59l.15-26.627z"
      ></path>
      <path
        fill="#86CDAD"
        d="M238.817 283.715c1.45-6.568 5.161-13.44 10.783-19.679 11.246-12.474 25.898-16.456 32.72-8.888 3.515 3.894 4.261 10.096 2.628 16.897 6.527-4.631 13.457-5.414 17.106-1.37 1.628 1.803 2.386 4.34 2.357 7.23 4.969-3.194 10.087-3.598 12.853-.533 1.408 1.562 2.003 3.795 1.87 6.343"
      ></path>
      <path
        fill="#E31D1C"
        d="M109.142 190.454a23.25 23.25 0 0123.249 23.249v69.167H85.885v-69.167a23.25 23.25 0 0123.257-23.249z"
      ></path>
      <path fill="#86CDAD" d="M184.761 168.255l8.505-8.813-11.883-2.957 3.378 11.77z"></path>
      <path fill="#1E8BCC" d="M287.072 168.767l-8.213-9.083 11.974-2.57-3.761 11.653z"></path>
      <path fill="#F5841F" d="M205.419 171.862l7.351-9.796-12.157-1.466 4.806 11.262z"></path>
      <path fill="#EF4539" d="M226.127 173.707l6.443-10.412-12.237-.375 5.794 10.787z"></path>
      <path fill="#FEBE19" d="M268.767 171.599l4.698-11.307-12.14 1.586 7.442 9.721z"></path>
      <path fill="#F69689" d="M248.493 173.865l5.514-10.933-12.224.692 6.71 10.241z"></path>
      <path
        fill="#86CDAD"
        d="M55.29 232.453a5.263 5.263 0 015.585 0 13.204 13.204 0 006.201 2.016 5.27 5.27 0 014.515 3.282 13.261 13.261 0 003.815 5.29 5.242 5.242 0 011.725 5.306 13.25 13.25 0 000 6.51 5.25 5.25 0 01-1.725 5.289 13.229 13.229 0 00-3.827 5.269 5.26 5.26 0 01-4.503 3.286c-2.2.141-4.329.831-6.193 2.007a5.255 5.255 0 01-5.585 0 13.19 13.19 0 00-6.193-2.007 5.27 5.27 0 01-4.52-3.286 13.229 13.229 0 00-3.827-5.269 5.25 5.25 0 01-1.72-5.31 13.25 13.25 0 000-6.51 5.242 5.242 0 011.712-5.285 13.26 13.26 0 003.836-5.29 5.27 5.27 0 014.519-3.282 13.189 13.189 0 006.185-2.016z"
      ></path>
      <path
        stroke="#1E8BCC"
        strokeMiterlimit="10"
        strokeWidth="2.5"
        d="M58.642 231.583l.046 52.133M77.451 247.189l-18.609 20.083M58.925 254.224l-15.19-14.794M58.926 261.499l-18.697-18.3M58.825 248.859l12.212-12.561"
      ></path>
      <path
        fill="#86CDAD"
        d="M151.175 242.899a2.275 2.275 0 012.288-2.268 2.279 2.279 0 012.268 2.289l6.535.025a8.81 8.81 0 00-14.951-6.17 8.814 8.814 0 00-2.671 6.099l6.531.025z"
      ></path>
      <path fill="#EF4539" d="M164.582 242.708h-22.258v6.843h22.258v-6.843z"></path>
      <path fill="#1E8BCC" d="M164.582 260.15h-22.258v8.618h22.258v-8.618z"></path>
      <path fill="#1D2F5B" d="M164.582 274.815h-22.258v8.617h22.258v-8.617z"></path>
      <path fill="#F5841F" d="M166.168 268.768h-22.257v6.047h22.257v-6.047z"></path>
      <path fill="#FEBE19" d="M166.431 249.555h-5.848v10.595h5.848v-10.595z"></path>
      <path fill="#1D2F5B" d="M148.963 249.555h-5.847v10.595h5.847v-10.595z"></path>
      <ellipse cx="226.5" cy="87" fill="#00C49A" rx="32.5" ry="32"></ellipse>
      <ellipse cx="165.95" cy="67.5" fill="#EC8133" rx="23" ry="22.5"></ellipse>
      <ellipse cx="158" cy="122.5" fill="#A428A4" rx="23" ry="22.5"></ellipse>
      <path
        fill="#fff"
        d="M161.498 67.977c1.924 0 3.548-1.59 3.548-3.5 0-1.909-1.624-3.477-3.548-3.477-1.948 0-3.548 1.568-3.548 3.477 0 1.91 1.6 3.5 3.548 3.5zm10.968-6.75h-3.177l-10.017 14.546h3.177l10.017-14.546zm-10.991 4.569c-.742 0-1.299-.569-1.299-1.319s.557-1.295 1.299-1.295c.788 0 1.345.545 1.345 1.295 0 .75-.557 1.319-1.345 1.319zM170.379 76c1.925 0 3.571-1.568 3.571-3.477 0-1.91-1.646-3.5-3.571-3.5-1.925 0-3.548 1.59-3.548 3.5 0 1.909 1.623 3.477 3.548 3.477zm0-2.182c-.742 0-1.299-.545-1.299-1.295 0-.75.557-1.319 1.299-1.319.788 0 1.322.569 1.322 1.319s-.534 1.295-1.322 1.295zM235 92.854c0-3.98-2.231-6.088-6.088-7.571l-2.572-.976c-2.382-.897-3.554-1.717-3.554-3.317 0-1.56 1.437-2.614 3.403-2.614 2.382 0 3.668 1.21 3.895 3.16h4.651c-.075-3.824-2.533-6.594-6.542-7.258V71h-3.932v3.278c-3.82.624-6.58 3.083-6.58 6.985 0 3.669 2.382 5.58 5.558 6.79L226 89.108c2.647.976 3.895 2.108 3.895 3.825 0 1.873-1.702 2.692-3.63 2.692-2.61 0-4.084-1.365-4.311-3.902H217c.151 4.41 2.723 7.376 7.261 8.039V103h3.932v-3.278c3.782-.546 6.807-2.77 6.807-6.868z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M163.157 126.445l-5.469-3.263v-7.031"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M165.071 114.929c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0"
      ></path>
      <path fill="#EAF5FE" stroke="#946400" strokeWidth="1.5" d="M83.75 138.75H131.25V175.25H83.75z"></path>
      <path stroke="#946400" strokeWidth="1.5" d="M107 139.5v36M83 157h49"></path>
    </svg>
  );
};

export default CalculatorIllustration;
