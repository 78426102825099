import { functions } from '../api/firebase';
import moment from 'moment';

import i18next from 'i18next';

import { getDocumentsAsArray, updateDocument, addDocument } from '../api/firebase/firestore';
import { isAnyTransactionType } from './types';
import { DefaultDateFormat } from '../helpers/dates';

export const paths = {
  transactions: (organizationId) => `organizations/${organizationId}/transactions`,
  transaction: (organizationId, transactionId) => `organizations/${organizationId}/transactions/${transactionId}`,
  transactionCount: (organizationId) => `organizations/${organizationId}/stats/count`,
};

export async function seedTransactionsData(organizationId) {
  return await functions().httpsCallable('callables-transactions-seedTransactionsData')({ organizationId });
}

export async function saveTransaction({ id, ...transaction }) {
  transaction.searchTerms = createSearchTerms(transaction);

  if (id) {
    await updateDocument({
      path: paths.transaction(transaction.organizationId, id, transaction),
      data: transaction,
    });
    return { id, ...transaction };
  } else {
    const docRef = await addDocument({
      path: paths.transactions(transaction.organizationId),
      data: transaction,
    });
    return { id: docRef.id, ...transaction };
  }
}

export async function fetchCategories(organizationId) {
  const categories = await functions().httpsCallable('callables-transactions-getCategories')({ organizationId });
  return categories?.data;
}

export async function fetchTransactions(organizationId, searchConditions, limit, startAfter = null) {
  let conditions = searchConditions ?? [];

  return await getDocumentsAsArray({
    path: paths.transactions(organizationId),
    conditions,
    orderBy: [{ field: 'date', direction: 'desc' }],
    limit,
    startAfter: startAfter,
  });
}

export function createSearchConditions(searchField, searchText, transactionType = null, timezone = 'utc') {
  const fullSearchField = 'searchTerms.' + searchField;
  const searchConditions = [];

  if (!isAnyTransactionType(transactionType)) {
    searchConditions.push({
      field: 'transactionType',
      operation: '==',
      value: transactionType,
    });
  }

  if (!searchField) return searchConditions;
  if (searchField === 'date')
    return [...searchConditions, ...createSearchConditionsFromDateRange(fullSearchField, searchText, timezone)];
  return [...searchConditions, ...createSearchConditionsFromText(fullSearchField, searchText)];
}

export function createSearchConditionsFromText(fullSearchField, searchText) {
  const searchTerms = createSearchTermsFromText(searchText);
  if (!searchTerms?.length) return [];

  return [
    {
      field: fullSearchField,
      operation: 'array-contains-any',
      value: searchTerms,
    },
  ];
}
export function createSearchConditionsFromDateRange(fullSearchField, searchText, timezone = 'utc') {
  const dates = searchText.split('-');
  if (dates.length === 0) return [];

  const startDate = moment(dates[0].trim(), DefaultDateFormat).tz(timezone, true).toDate();
  const endDate =
    dates.length === 2
      ? moment(dates[1].trim(), DefaultDateFormat).tz(timezone, true).endOf('day').toDate()
      : moment().tz(timezone, true).endOf('day').toDate();

  return [
    {
      field: 'date',
      operation: '>=',
      value: startDate,
    },
    {
      field: 'date',
      operation: '<=',
      value: endDate,
    },
  ];
}
function createSearchTerms(transaction) {
  const categoryText = i18next.t(transaction.category);
  return {
    category: createSearchTermsFromText(categoryText),
    payer: createSearchTermsFromText(transaction.payer),
    payee: createSearchTermsFromText(transaction.payee),
    notes: createSearchTermsFromText(transaction.notes),
  };
}

function createSearchTermsFromText(searchText) {
  if (!searchText) return [];

  const punctuationless = searchText.replace(/[^\p{L}\s]/gu, '').toLowerCase();
  const whitespaceCollapsed = punctuationless.replace(/\s{2,}/g, ' ');
  return whitespaceCollapsed.split(' ');
}
