import { useCallback, useMemo } from 'react';
import { FileCategory, FileDocument, useChangeFileState, useFilesAccess } from '@wonderschool/file-service-client';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'semantic-ui-react';
import useRooms from '../../../hooks/useRooms';
import { SHARE_TYPE, StudentDocument } from '../../types';
import { documentTestIds } from '../../dictionary';

interface DeleteFileProps {
  isModalOpen: boolean;
  selectedDocument: FileDocument | null;
  student?: StudentDocument;
  closeModal?: () => void;
}

const DeleteFileModal: React.FC<DeleteFileProps> = ({
  isModalOpen = false,
  student,
  selectedDocument,
  closeModal = () => undefined,
}) => {
  const { t } = useTranslation();
  const { deleteFile } = useChangeFileState();
  const { unshareFiles } = useFilesAccess();
  const rooms = useRooms();

  const sharedForKeys = useMemo(() => Object.keys(selectedDocument?.sharedFor || {}), [selectedDocument]);

  // reliably indicates this is a standard form/policy doc delete
  const isStandardDeleteRequest = useMemo(() => {
    // if no student is passed, proceed with standard delete
    if (!student) {
      return true;
    }

    // doc is student specific, proceed with standard delete
    if (
      student &&
      selectedDocument?.fileCategory === FileCategory.ENTITY_SPECIFIC_FILE &&
      !!selectedDocument.metadata?.entityReferences?.find((ref) => ref.includes(student.id))
    ) {
      return true;
    }

    // deletion will require additional considerations.
    return false;
  }, [student, selectedDocument]);

  const getStudentRoom = useCallback(
    () => rooms.find((room) => room.id === student?.rooms?.[0]) || null,
    [student, rooms]
  );

  // doc has been shared with the student's entire room
  const isSharedForStudentRoom = useMemo(() => {
    const room = getStudentRoom();

    return (
      (room &&
        !!sharedForKeys.find((key) => {
          // `key` format = `room-${roomId}` | `student-${studentId}` | `location-${locationId}`
          const isRoomShare = key.includes(`${SHARE_TYPE.room}-`);
          if (isRoomShare && key === `${SHARE_TYPE.room}-${room.id}`) {
            return true;
          }
        })) ||
      false
    );
  }, [sharedForKeys, getStudentRoom]);

  // doc has been shared directly with the student
  const isSharedForStudent = useMemo(() => {
    return (
      (student &&
        !!sharedForKeys.find((key) => {
          // `key` format = `room-${roomId}` | `student-${studentId}` | `location-${locationId}`
          const split = key.split('-');
          if (split[0] === SHARE_TYPE.student && split[1] === student?.id) {
            return true;
          }
        })) ||
      false
    );
  }, [sharedForKeys, student]);

  const handleDelete = async () => {
    if (!selectedDocument) {
      return null;
    }

    if (isStandardDeleteRequest) {
      await deleteFile(selectedDocument.id);
      closeModal();
      return;
    }

    // is actually a revoke access request

    // is accessible via student access only
    if (isSharedForStudent && !isSharedForStudentRoom) {
      await unshareFiles({
        files: [selectedDocument?.id || ''],
        students: [student?.id || ''],
      });
    }

    const room = getStudentRoom();

    // is accessible via room access only
    if (isSharedForStudentRoom && !isSharedForStudent) {
      await unshareFiles({
        files: [selectedDocument.id],
        rooms: [room?.id || ''],
      });
    }

    // is accessible via room access AND direct student share
    if (isSharedForStudentRoom && isSharedForStudent) {
      await unshareFiles({
        files: [selectedDocument.id],
        students: [student?.id || ''],
        rooms: [room?.id || ''],
      });
    }

    closeModal();
  };

  const renderModalMessage = useCallback(() => {
    if (isStandardDeleteRequest) {
      return (
        <div data-testid={documentTestIds.deleteModal.content.inner.standard}>
          <p>
            {t(
              'Are you sure you want to delete this document? This action will delete the document and will revoke access from parent(s) that the document has been shared with.'
            )}
          </p>
          <p>
            <strong>{selectedDocument?.file.originalname}</strong>
          </p>
        </div>
      );
    }

    const room = getStudentRoom();

    // is accessible via student access only
    if (isSharedForStudent && !isSharedForStudentRoom) {
      return (
        <div data-testid={documentTestIds.deleteModal.content.inner.standard}>
          <p>
            {t(
              'Are you sure you want to delete this document? This action will delete the document and will revoke access from parent(s) that the document has been shared with.'
            )}
          </p>
          <p>
            <strong>{selectedDocument?.file.originalname}</strong>
          </p>
        </div>
      );
    }

    // is accessible via room access only
    if (isSharedForStudentRoom && !isSharedForStudent) {
      return (
        <div data-testid={documentTestIds.deleteModal.content.inner.room}>
          <p>{t('This student has access to this document because it has been shared with their assigned room.')}</p>
          <p>
            <strong>Room: {room?.name}</strong>
          </p>
          <p>
            <strong>{t('Are you sure you want to revoke access to this document for the entire room?')}</strong>
          </p>
          <p>
            <strong>{selectedDocument?.file.originalname}</strong>
          </p>
        </div>
      );
    }

    // is accessible via room access AND direct student share
    if (isSharedForStudentRoom && isSharedForStudent) {
      return (
        <div data-testid={documentTestIds.deleteModal.content.inner.roomAndStudent}>
          <p>{t('This document has been shared directly with the student and their assigned room.')}</p>
          <p>
            <strong>{t('Are you sure you want to revoke access for this student and this room?')}</strong>
          </p>
          <p>
            <strong>
              {t('Room')}: {room?.name}
            </strong>
          </p>
          <p>
            <strong>{selectedDocument?.file.originalname}</strong>
          </p>
        </div>
      );
    }
  }, [selectedDocument, isStandardDeleteRequest, isSharedForStudent, isSharedForStudentRoom, getStudentRoom, t]);

  return (
    <Modal
      open={isModalOpen}
      size="small"
      onClose={() => {
        closeModal();
      }}
      closeIcon
    >
      <Modal.Header data-testid={documentTestIds.deleteModal.header}>{t('Delete Document')}</Modal.Header>
      <Modal.Content>
        <div data-testid={documentTestIds.deleteModal.content.outer}>{renderModalMessage()}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={closeModal} content={t('No')} data-testid={documentTestIds.deleteModal.btns.no} />
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={handleDelete}
          data-testid={documentTestIds.deleteModal.btns.yes}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteFileModal;
