import { useState } from 'react';
import FinanceAtGlanceHeader from './FinanceAtGlanceHeader';
import InvoiceSection from './InvoiceSection';
import classNames from 'classnames/bind';
import { useFinanceAtGlance } from '../../../hooks/useFinanceAtGlance';
import fStyle from '../../../Components/Billing/FinanceAtGlance/FinanceAtGlance.module.scss';
import { useSelector } from 'react-redux';
import { selectFirstLocation } from '../../DateTime/DateTime';
import moment from 'moment';

const fx = classNames.bind(fStyle);

function InvoicesAtGlance() {
  const firstLocation = useSelector(selectFirstLocation);
  const timezone = firstLocation?.timezone ?? 'utc';
  const [selectedMonth, setSelectedMonth] = useState(moment().tz(timezone, true));
  const [isRefreshToggle, setIsRefreshToggle] = useState(false);
  const refreshHandler = () => {
    setIsRefreshToggle(!isRefreshToggle);
  };
  const { isLoadingInvoices, invoicesData } = useFinanceAtGlance(selectedMonth, 'invoices', isRefreshToggle);

  return (
    <div className={fx('invoiceListWrapperFinanceAtGlance')}>
      <div className={fx('mt-10')}>
        <FinanceAtGlanceHeader
          title="financeAtGlanceInvoicesHeader"
          selectedMonth={setSelectedMonth}
          refreshHandler={refreshHandler}
          isLoading={isLoadingInvoices}
        />
        <div className={fx('mt-10')}>
          <InvoiceSection
            upcoming={invoicesData.statusUpcoming}
            processing={invoicesData.statusProcessing}
            transferring={invoicesData.statusTransferring}
            depositedPaid={invoicesData.statusDepositedOrPaid}
            pastDue={invoicesData.statusPastDue}
            failed={invoicesData.statusFailed}
          />
        </div>
      </div>
    </div>
  );
}

export default InvoicesAtGlance;
