import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Header, Modal, Segment, Popup, Icon, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';

// Import components
import InlineError from '../../Messages/InlineError';
import ShowError from '../../Messages/ShowError';
import StatePicker from '../../Shared/StatePicker';
import TimezonePicker from './TimezonePicker';
import HourPicker from './HourPicker';
import LocationTypePicker from '../../Locations/LocationTypePicker';
import LocationInput from './LocationInput';

// Import utils
import { phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';

// Import actions
import {
  organizationAddLocation,
  organizationUpdateLocation,
  locationSelectionCleared,
} from '../../../redux/actions/locationActions';
import { organizationAddRoom } from '../../../redux/actions/roomActions';

class LocationForm extends Component {
  state = {
    data: {
      id: '',
      name: `${this.props.currentOrganization.name}` || '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      geometry: {
        lat: '',
        long: '',
      },
      phone: '',
      locationType: `${this.props.currentOrganization.programType}` || '',
      enabled: true,
      active: true,
      timezone: '',
      capacityGoal: '',
      licenseCapacity: '',
      businessHoursStart: -62167176238000,
      businessHoursEnd: -62167136638000,
      primary: this.props.locations?.length === 0 ? true : false,
      ein: '',
      overnightCare: false,
    },
    loading: false,
    errors: {},
    disableModalOpen: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      locations: { selectedLocation },
    } = nextProps;

    if (!_.isEmpty(selectedLocation.id) && prevState.data.id !== selectedLocation.id) {
      const { phone, ...rest } = selectedLocation;
      return {
        data: {
          ...prevState.data,
          phone: phoneNumberParse(phone),
          ...rest,
        },
      };
    }
    return prevState;
  }

  componentWillUnmount() {
    // Clear selection from store.
    this.props.locationSelectionCleared();
  }

  onChange = (e, { name, value, type, checked }) => {
    if (type === 'checkbox') {
      value = checked;
    }
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  onLocationSelected = ({ formatted }) => {
    const { address1, city, state, zipcode, timezone, geometry } = formatted;

    this.setState({
      data: {
        ...this.state.data,
        address1,
        city,
        state,
        zipcode,
        timezone: timezone?.value,
        geometry: {
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        },
      },
    });
  };

  maskedOnChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  tryToggleLocationEnabled = (e, { name, checked }) => {
    // if disabling, show a confirmation first
    if (checked === false) {
      this.setState({
        disableModalOpen: true,
      });
      return;
    }

    this.setState({
      data: {
        ...this.state.data,
        enabled: checked,
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  forceDisableLocation = () => {
    this.setState({
      data: {
        ...this.state.data,
        enabled: false,
      },
      errors: _.omit(this.state.errors, 'enabled'),
      disableModalOpen: false,
    });
  };

  validate = (data) => {
    const { t } = this.props;
    const errors = {};

    const locationEnabled = data.enabled;

    if (!data.name) errors.name = t('Name is required');

    // If location is disabled, don't validate the fields below.
    if (!locationEnabled) return errors;

    if (!data.address1) errors.address1 = t('Address is required');
    if (!data.city) errors.city = t('City is required');
    if (!data.state) errors.state = t('State is required');
    if (!data.zipcode) errors.zipcode = t('Zip code is required');

    if (!data.phone) {
      errors.phone = t('Phone is required');
    } else {
      if (!Validator.isMobilePhone(data.phone, 'en-US')) errors.phone = t('Phone is invalid');
    }
    if (!data.locationType) errors.locationType = t('Location type is required');
    if (!data.businessHoursStart) errors.businessHoursStart = t('Opening time is required');
    if (!data.businessHoursEnd) errors.businessHoursEnd = t('Closing time is required');
    if (!data.timezone) errors.timezone = t('Timezone is required');
    if (!data.capacityGoal) errors.capacityGoal = t('Capacity goal is required');
    if (data?.capacityGoal <= 0) errors.capacityGoal = t('Capacity goal has to be greater than 0');
    if (!data.licenseCapacity) errors.licenseCapacity = t('License capacity has to be greater than 0');
    if (data?.licenseCapacity <= 0) errors.licenseCapacity = t('License capacity has to be greater than 0');

    if (!data.overnightCare && data.businessHoursStart && data.businessHoursEnd) {
      if (data.businessHoursStart >= data.businessHoursEnd) {
        errors.businessHoursEnd = t('Closing time should be after opening time');
      }
    }

    return errors;
  };

  onSubmit = (e) => {
    const { t } = this.props;
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      const {
        data: { id, phone, ...rest },
      } = this.state;
      const {
        currentOrganization,
        currentOrganization: { programType },
      } = this.props;

      this.setState({ loading: true });
      const formData = {
        phone: phoneNumberFormat(phone),
        ...rest,
      };

      // Update.
      if (id) {
        if (currentOrganization && currentOrganization.id)
          this.props
            .organizationUpdateLocation(currentOrganization.id, {
              id,
              ...formData,
            })
            .then(() => {
              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('updated');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { [t('Unable to Update')]: error.message },
              })
            );
      } else {
        // New entry.
        if (currentOrganization && currentOrganization.id)
          this.props
            .organizationAddLocation(currentOrganization.id, formData, programType)
            .then(() => {
              // If the provider is FCC, add classroom automatically.
              if (programType && programType === 'familyChildCare') {
                const locationId = `${currentOrganization.id}-primaryLocation`;

                this.props
                  .organizationAddRoom(
                    currentOrganization.id,
                    {
                      organization: currentOrganization.id,
                      location: locationId,
                      name: t('Primary Room'),
                    },
                    programType
                  )
                  .then(() => {
                    // Used to close wrapper HOC. e.g Sliding panel, modal...
                    if (this.props.onClose) {
                      this.props.onClose('added');
                      return;
                    }
                    this.setState({ loading: false });
                  })
                  .catch((error) =>
                    this.setState({
                      loading: false,
                      errors: { [t('Unable to Add')]: error.message },
                    })
                  );
              }

              // Used to close wrapper HOC. e.g Sliding panel, modal...
              if (this.props.onClose) {
                this.props.onClose('added');
                return;
              }
              this.setState({ loading: false });
            })
            .catch((error) =>
              this.setState({
                loading: false,
                errors: { [t('Unable to Add')]: error.message },
              })
            );
      }
    }
  };

  render() {
    const { data, loading, errors, disableModalOpen } = this.state;
    const {
      currentOrganization: { programType },
      t,
    } = this.props;

    const locationEnabled = data.enabled;

    return (
      <Segment basic textAlign="left">
        <ShowError errors={errors} />
        <Form id="location-form" onSubmit={this.onSubmit} loading={loading} noValidate autoComplete="off">
          <Form.Field error={!!errors.name}>
            <Form.Input
              required
              type="text"
              id="name"
              name="name"
              label={t('Name')}
              placeholder={t('Name')}
              value={data.name}
              onChange={this.onChange}
              autoComplete="off"
            />
            {errors.name && <InlineError text={errors.name} />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.address1}>
              <LocationInput
                required={locationEnabled}
                id="address1"
                name="address1"
                label={t('Address 1')}
                value={data.address1}
                onChange={this.onChange}
                onLocationSelected={this.onLocationSelected}
              />
              {errors.address1 && <InlineError text={errors.address1} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.address2}>
              <Form.Input
                type="text"
                id="address2"
                name="address2"
                label={t('Address 2')}
                placeholder={t('Address Line 2')}
                value={data.address2}
                onChange={this.onChange}
              />
              {errors.address2 && <InlineError text={errors.address2} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.city}>
              <Form.Input
                required={locationEnabled}
                type="text"
                id="city"
                name="city"
                label={t('City')}
                placeholder={t('City')}
                value={data.city}
                onChange={this.onChange}
              />
              {errors.city && <InlineError text={errors.city} />}
            </Form.Field>

            <Form.Field error={!!errors.state}>
              <Form.Field
                required={locationEnabled}
                id="state"
                name="state"
                label={t('State')}
                control={StatePicker}
                placeholder={t('State')}
                value={data.state}
                selection
                search
                onChange={this.onChange}
              />
              {errors.state && <InlineError text={errors.state} />}
            </Form.Field>

            <Form.Field error={!!errors.zipcode}>
              <Form.Input
                required={locationEnabled}
                type="text"
                id="zipcode"
                name="zipcode"
                label={t('Zip code')}
                placeholder={t('Zip code')}
                value={data.zipcode}
                onChange={this.onChange}
                maxLength="5"
              />
              {errors.zipcode && <InlineError text={errors.zipcode} />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.phone}>
              <Form.Field
                required={locationEnabled}
                type="text"
                id="phone"
                name="phone"
                label={t('Phone')}
                placeholder={t('Phone')}
                control={MaskedInput}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={data.phone}
                guide={false}
                onChange={this.maskedOnChange}
              />
              {errors.phone && <InlineError text={errors.phone} />}
            </Form.Field>
          </Form.Group>

          {(programType === null || (programType && programType !== 'familyChildCare')) && (
            <Form.Field error={!!errors.locationType}>
              <Form.Field
                translator={t}
                isForm
                required={locationEnabled}
                id="locationType"
                name="locationType"
                label={t('Location Type')}
                control={LocationTypePicker}
                placeholder="Select location type"
                value={data.locationType}
                selection
                search
                onChange={this.onChange}
              />
              {errors.locationType && <InlineError text={errors.locationType} />}
            </Form.Field>
          )}

          <Header as="h3" dividing>
            {t('License Information')}
          </Header>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.licenseCapacity}>
              <Form.Input
                required={locationEnabled}
                type="number"
                min={0}
                id="licenseCapacity"
                name="licenseCapacity"
                label={t('License Capacity')}
                placeholder={t('How many students are you licensed for?')}
                value={data.licenseCapacity}
                onChange={this.onChange}
              />
              {errors.licenseCapacity && <InlineError text={errors.licenseCapacity} />}
            </Form.Field>

            <Form.Field error={!!errors.capacityGoal}>
              <Form.Input
                required={locationEnabled}
                type="number"
                min={0}
                id="capacityGoal"
                name="capacityGoal"
                label={t('Capacity Goal')}
                placeholder={t('What is your capacity goal?')}
                value={data.capacityGoal}
                onChange={this.onChange}
              />
              {errors.capacityGoal && <InlineError text={errors.capacityGoal} />}
            </Form.Field>
          </Form.Group>

          <Header as="h3" dividing>
            {'Tax Information'}
          </Header>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.ein}>
              <Form.Input
                type="number"
                min={0}
                id="ein"
                name="ein"
                label={t('EIN Number')}
                placeholder={t('EIN Number')}
                value={data.ein}
                onChange={this.onChange}
              />
              {errors.ein && <InlineError text={errors.ein} />}
            </Form.Field>
          </Form.Group>

          <Header as="h3" dividing>
            {t('Business Hours')}
          </Header>

          <Form.Group widths="equal">
            <Form.Field>
              <Form.Checkbox
                checked={data.overnightCare}
                id="overnightCare"
                name="overnightCare"
                label={t('My business runs overnight')}
                onChange={this.onChange}
                toggle
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.businessHoursStart}>
              <Form.Field
                required={locationEnabled}
                id="businessHoursStart"
                name="businessHoursStart"
                label={t('Opening Time')}
                control={HourPicker}
                placeholder={t('What time do you open?')}
                value={data.businessHoursStart}
                selection
                search
                onChange={this.onChange}
              />
              {errors.businessHoursStart && <InlineError text={errors.businessHoursStart} />}
            </Form.Field>

            <Form.Field error={!!errors.businessHoursEnd}>
              <Form.Field
                required={locationEnabled}
                id="businessHoursEnd"
                name="businessHoursEnd"
                label={t('Closing Time')}
                control={HourPicker}
                placeholder={t('What time do you close?')}
                value={data.businessHoursEnd}
                selection
                search
                onChange={this.onChange}
              />
              {errors.businessHoursEnd && <InlineError text={errors.businessHoursEnd} />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.timezone}>
            <Form.Field
              translator={t}
              isForm
              required={locationEnabled}
              id="timezone"
              name="timezone"
              label={t('Timezone')}
              control={TimezonePicker}
              placeholder="Select a timezone"
              value={data.timezone}
              selection
              search
              onChange={this.onChange}
            />
            {errors.timezone && <InlineError text={errors.timezone} />}
          </Form.Field>

          {
            // @TODO: Replace the condition with a feature flag
            false && (
              <Form.Field error={!!errors.enabled}>
                <label>
                  {t('Location enabled?')}
                  <Popup
                    content={t(
                      "Locations are enabled as default. This is a flag for you to keep track of your locations. Disabling it won't impact anything."
                    )}
                    position="right center"
                    offset={[4, 0]}
                    trigger={<Icon name="info circle" />}
                  />
                </label>
                <Form.Radio
                  toggle
                  id="enabled"
                  name="enabled"
                  onChange={this.tryToggleLocationEnabled}
                  control={Checkbox}
                  checked={data.enabled}
                />
                {errors.enabled && <InlineError text={errors.enabled} />}
              </Form.Field>
            )
          }

          <Modal open={disableModalOpen}>
            <Modal.Header>{t('Are you sure?')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>{t('Are you sure you want to disable this location?')}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => this.setState({ disableModalOpen: false })}>
                {t('No')}
              </Button>
              <Button
                content="Yes"
                labelPosition="right"
                icon="checkmark"
                onClick={this.forceDisableLocation}
                positive
              />
            </Modal.Actions>
          </Modal>

          <br />

          <Form.Group>
            <Form.Button primary content={data.uid ? t('Update') : t('Save')} />
            <Form.Button
              basic
              content={t('Cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (this.props.onClose) this.props.onClose();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

LocationForm.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  locations: PropTypes.shape({
    selectedLocation: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  organizationAddLocation: PropTypes.func.isRequired,
  organizationUpdateLocation: PropTypes.func.isRequired,
  organizationAddRoom: PropTypes.func.isRequired,
  locationSelectionCleared: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  locations: state.locations,
});

export default withTranslation()(
  connect(mapStateToProps, {
    organizationAddLocation,
    organizationUpdateLocation,
    organizationAddRoom,
    locationSelectionCleared,
  })(LocationForm)
);
