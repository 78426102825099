import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Import utils
import { dateFormatter } from '../../helpers/dates';
import { capitalize } from '../../helpers/utils';

const StudentPersonalInformation = ({
  selectedStudent: {
    id,
    displayName,
    nickName,
    birthday,
    gender,
    allergies,
    medications,
    doctorName,
    notes,
    address1,
    address2,
    city,
    state,
    zipcode,
    race,
    ethnicity,
    photosAllowed,
    foodProgramTier,
  },
}) => {
  const { t } = useTranslation();
  const { foodProgramFlag } = useFlags();

  if (!id) return null;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>{t('Name')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{displayName}</strong>
        </Grid.Column>
      </Grid.Row>
      {/* Show Nickname only if available. */}
      {nickName?.length ? (
        <Grid.Row>
          <Grid.Column width={6}>{t('Nickname')}</Grid.Column>
          <Grid.Column width={10}>
            <strong>{nickName}</strong>
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row>
        <Grid.Column width={6}>{t('Birthday')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{birthday ? dateFormatter(birthday) : t('No birthday')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Gender')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{gender ? capitalize(gender) : t('No gender selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Allergies')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{allergies || t('No allergies added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Medications')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{medications || t('No medications added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Doctor')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{doctorName || t('No doctor information added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Notes')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{notes || t('There are no notes added')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Address')}</Grid.Column>
        <Grid.Column width={10}>
          {address1 ? (
            <p>
              <strong>{address1}</strong>
            </p>
          ) : (
            <strong>{t('No address added')}</strong>
          )}
          {address2 && (
            <p>
              <strong>{address2}</strong>
            </p>
          )}

          <p>
            <strong>
              {city && `${city}, `}
              {state && `${state}, `}
              {zipcode && `${zipcode}`}
            </strong>
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Race')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{race || t('Race is not selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Ethnicity')}</Grid.Column>
        <Grid.Column width={10}>
          <strong>{ethnicity || t('Ethnicity is not selected')}</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('Photos Allowed')}</Grid.Column>
        <Grid.Column width={10}>
          <strong> {photosAllowed ? t('Yes') : t('No')} </strong>
        </Grid.Column>
      </Grid.Row>
      {foodProgramFlag && (
        <Grid.Row>
          <Grid.Column width={6}>{t('food.tierLabel')}</Grid.Column>
          <Grid.Column width={10}>
            <strong>{!foodProgramTier ? t('No') : t(`Yes Tier ${foodProgramTier}`)}</strong>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

StudentPersonalInformation.propTypes = {
  selectedStudent: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    birthday: PropTypes.number || PropTypes.object, // can be millis (number) or Date (object)
    gender: PropTypes.string,
    allergies: PropTypes.string,
    medications: PropTypes.string,
    doctorName: PropTypes.string,
    notes: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
  }),
};
const mapStateToProps = (state) => ({
  selectedStudent: state.students.selectedStudent,
});
export default connect(mapStateToProps)(StudentPersonalInformation);
