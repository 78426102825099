import { storage } from '../api/firebase';
import FirebaseCloudStorage from '../Components/Upload/FirebaseCloudStorage';

import Uppy from '@uppy/core';
import { v4 as uuidv4 } from 'uuid';

export { initializeUppy, resetUppy, createUploadPath };

function initializeUppy(uploadPath, onUploaded, onAdded, unique, uppyOptions = {}) {
  const storageRef = storage().ref().child(uploadPath);

  const uppy = new Uppy({
    id: 'uppy1',
    autoProceed: false,
    restrictions: {
      maxFileSize: 5000000,
      allowedFileTypes: ['image/*', '.pdf'],
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
    },
    ...uppyOptions,
    onBeforeFileAdded: (file) => {
      if (!unique) return;

      const fileName = file.name.split('.')[0];
      const fileNameUnique = `${fileName}_${Date.now()}.${file.extension}`;

      return {
        ...file,
        name: fileNameUnique,
      };
    },
  })
    .use(FirebaseCloudStorage, {
      storageRef,
      rename: false,
    })
    .on('upload-success', (_file, _body, _uploadURL) => undefined) // When individual files are uploaded to remote storage.
    .on('file-added', (result) => {
      if (onAdded) onAdded(result);
    })
    .on('complete', (result) => {
      if (onUploaded) onUploaded(result.successful[0]);
    });
  return uppy;
}

function resetUppy(uppy) {
  if (!uppy) return;

  uppy.reset();

  const currentFiles = uppy.getFiles();
  currentFiles.forEach((currentFile) => uppy.removeFile(currentFile.id));
}

function createUploadPath(rootPath, document) {
  const id = document?.id ?? document?.uid ?? uuidv4();
  return `${rootPath}/${id}`;
}
