import { useMemo, useState } from 'react';
import { Checkbox, Form, Header, Icon, Menu, Modal, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Validator from 'validator';
import MaskedInput from 'react-text-mask';

import { useOrganization } from '../../../hooks/useOrganizations';
import { useContacts } from '../../../contacts/contactsHooks';

import { createContactForStudent, updateContactForStudent } from '../../../contacts/contactsAPI';

import { fetchEmailUsage } from '../../../api/firebase/users';

import { addContactToStudent, saveStudent } from '../../studentsAPI';

import { formatFullName, phoneNumberFormat, phoneNumberParse } from '../../../helpers/utils';

import InlineError from '../../../Components/Messages/InlineError';
import ShowErrors from '../../../Components/Messages/ShowError';
import { showSuccessToast, showErrorToast } from '../../../Components/Shared/showToast';
import { Dropdown } from '../../../Components/Shared/Dropdown';

import { CONTACT_STATUS, CONTACT_TYPES, INVITATION_TYPES } from '../../../contacts/enums';

import { RELATIONSHIP_TYPES, RELATIONSHIP_TYPE_OPTIONS, RESPONSIBLE_FOR_BILLING_TYPE } from '../../consts';

import { getOrCreateRelationshipNotes, transformRelationship } from '../../studentsUtils';

import { useStudentOrPrimaryLocation } from '../../studentsHooks';

import ExistingContactConfirmationDialog from './ExistingContactConfirmationDialog';
import InvoiceResponsibleConfirmationDialog from '../InvoiceResponsibleConfirmationDialog';
import ExistingStaffDialog from './ExistingStaffDialog';
import { FamilyLoginPinGenerator } from '../../../Components/Shared/LoginPinGenerator';

const PHONE_NUMBER_MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const initialFormData = {
  type: 'family',
  status: CONTACT_STATUS.new,
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  email: '',
  relationship: '',
  relationshipNotes: '',
  loginPin: '',
  allowLoginWithPin: true,
  enabled: true,
  isResponsibleForBilling: false,
  sendInvitation: true,
  invitationType: INVITATION_TYPES.parent,
};

export default function FamilyEditContactModal({
  student,
  contact: initialContact,
  isOpen,
  onClose,
  onReplaceExistingContact,
}) {
  const { t } = useTranslation();

  const isAdd = !initialContact || Object.keys(initialContact).length === 0;

  const title = isAdd ? t('Add Parent or Emergency Contact') : t('Edit Parent or Emergency Contact');

  return (
    <Modal
      centered={false}
      open={isOpen}
      closeOnEscape={false}
      closeIcon
      size="small"
      closeOnDimmerClick={false}
      onClose={onClose}
      data-testid="edit-parent-modal"
    >
      <Modal.Header data-testid={`${title}-title`}>{title}</Modal.Header>
      <Modal.Content>
        <EditContactForm
          student={student}
          contact={initialContact}
          isAdd={isAdd}
          onClose={onClose}
          onReplaceExistingContact={onReplaceExistingContact}
        />
      </Modal.Content>
    </Modal>
  );
}

function EditContactForm({ student, contact: initialContact, isAdd, onClose, onReplaceExistingContact }) {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = useStudentOrPrimaryLocation(student);
  const { contacts, familyContacts } = useContacts();

  const [isSaving, setIsSaving] = useState(false);

  const [isInitialized, setIsInitialized] = useState(false);
  const [errors, setErrors] = useState({});
  const [shouldEditContact, setShouldEditContact] = useState(true);
  const [existingContact, setExistingContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [formData, setFormData] = useState({ ...initialFormData });

  const [isOpenInvoiceResponsibleConfirmation, setIsOpenInvoiceResponsibleConfirmation] = useState(false);
  const [isOpenExistingContactConfirmationDialog, setIsOpenExistingContactConfirmationDialog] = useState(false);
  const [isOpenExistingStaffDialog, setIsOpenExistingStaffDialog] = useState(false);

  const parentCount = useMemo(() => {
    const { family } = student;
    return family
      ? Object.keys(family).filter((key) => family[key].relationship === RELATIONSHIP_TYPES.parent).length
      : 0;
  }, [student]);

  const contactsNotInStudent = useMemo(() => {
    const { familyMembers = [] } = student;

    return familyContacts.filter(
      (contact) => contact.status !== CONTACT_STATUS.disabled && !familyMembers.includes(contact.id)
    );
  }, [familyContacts, student]);

  const selectContactOptions = useMemo(() => {
    const options = contactsNotInStudent.map((contact) => ({
      key: contact.id,
      text: contact.displayName,
      value: contact.id,
    }));
    return options;
  }, [contactsNotInStudent]);

  const isEmailRequired = useMemo(() => {
    const isBaseUseCase = formData.sendInvitation;
    const isResponsibleForBilling = formData.isResponsibleForBilling;
    const isInvited = formData.status === CONTACT_STATUS.invited;
    const isVerified = formData.status === CONTACT_STATUS.verified;
    return isBaseUseCase || isResponsibleForBilling || isInvited || isVerified;
  }, [formData]);

  if (!isInitialized) initializeFormData();

  return (
    <>
      {isAdd && (
        <Menu pointing secondary>
          <Menu.Item
            name="editContact"
            active={shouldEditContact}
            onClick={() => {
              setShouldEditContact(true);
              setErrors({});
            }}
            data-testid="new-contact"
          >
            {t('New Contact')}
          </Menu.Item>

          <Menu.Item
            name="selectContact"
            active={!shouldEditContact}
            onClick={() => {
              setShouldEditContact(false);
              setErrors({});
            }}
            data-testid="existing-contact"
          >
            {t('Existing Contact')}
          </Menu.Item>
        </Menu>
      )}
      <ShowErrors errors={errors} />

      <Form loading={isSaving} noValidate>
        {!shouldEditContact && renderSelectContactForm()}
        {shouldEditContact && renderEditContactForm()}
        <Form.Group>
          <Form.Button
            primary
            content={isAdd ? t('Add Contact') : t('Save Contact')}
            onClick={onSave}
            loading={isSaving}
            disabled={isSaving}
            data-testid={`${isAdd ? 'add-contact-btn' : 'save-contact-btn'}`}
          />
          <Form.Button content={t('Cancel')} onClick={onCloseLocal} data-testid="cancel-btn" />
        </Form.Group>
      </Form>

      <ExistingStaffDialog
        existingContact={existingContact}
        isOpen={isOpenExistingStaffDialog}
        onClose={() => {
          setExistingContact(null);
          setIsOpenExistingStaffDialog(false);
        }}
      />
      <ExistingContactConfirmationDialog
        existingContact={existingContact}
        isOpen={isOpenExistingContactConfirmationDialog}
        onYes={onSaveExistingContact}
        onClose={() => {
          setExistingContact(null);
          setIsOpenExistingContactConfirmationDialog(false);
        }}
      />
      <InvoiceResponsibleConfirmationDialog
        isResponsibleForBilling={formData.isResponsibleForBilling}
        parentCount={parentCount}
        isOpen={isOpenInvoiceResponsibleConfirmation}
        onClose={() => {
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
        onYes={() => {
          setFormData({ ...formData, isResponsibleForBilling: true });
          setIsOpenInvoiceResponsibleConfirmation(false);
        }}
      />
    </>
  );

  function renderEditContactForm() {
    return (
      <>
        <Form.Field>
          <Header as="h3" data-testid="personal-info-title">
            {t('Personal Information')}
          </Header>
        </Form.Field>

        <Form.Group widths="equal">
          <Form.Field error={!!errors?.relationship}>
            <Form.Field
              translator={t}
              control={Dropdown}
              label={t('Relationship')}
              fluid
              search
              selection
              clearable
              required
              id="relationship"
              name="relationship"
              placeholder="Relationship"
              options={RELATIONSHIP_TYPE_OPTIONS}
              value={formData.relationship}
              onChange={onChange}
              data-testid="relationship"
            />
            {errors.relationship && <InlineError text={errors.relationship} data-testid="relationship-error" />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              disabled={formData.relationship === 'parent'}
              type="text"
              id="relationshipNotes"
              name="relationshipNotes"
              value={formData.relationshipNotes ?? ''}
              onChange={onChange}
              label={t('Type of Relationship')}
              placeholder={t('Tell us about this relationship')}
              data-testid="relationship-notes"
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.firstName}>
            <Form.Input
              required
              type="text"
              id="firstName"
              key="contact-firstName"
              name="firstName"
              value={formData.firstName}
              onChange={onChange}
              label={t('First Name')}
              placeholder={t('First Name')}
              data-testid="first-name"
            />
            {errors.firstName && <InlineError text={errors.firstName} data-testid="first-name-error" />}
          </Form.Field>
          <Form.Field>
            <Form.Input
              type="text"
              id="middleName"
              name="middleName"
              value={formData.middleName}
              onChange={onChange}
              label={t('Middle Name')}
              placeholder={t('Middle Name')}
              data-testid="middle-name"
            />
          </Form.Field>
        </Form.Group>
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            required
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
            label={t('Last Name')}
            placeholder={t('Last Name')}
            data-testid="last-name"
          />
          {errors.lastName && <InlineError text={errors.lastName} data-testid="last-name-error" />}
        </Form.Field>

        <Header as="h3" dividing data-testid="contact-info-title">
          {t('Contact Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field error={!!errors.email}>
            <Form.Input
              required={isEmailRequired}
              type="text"
              id="email"
              name="email"
              value={formData.email && formData.email.indexOf('moxit_') !== -1 ? '' : formData.email}
              onChange={onChange}
              label={t('Email')}
              placeholder={t('Email')}
              data-testid="contact-email"
            />
            {errors.email && <InlineError text={errors.email} data-testid="contact-email-error" />}
          </Form.Field>

          <Form.Field error={!!errors.phone}>
            <Form.Input
              type="text"
              id="phone"
              name="phone"
              onChange={maskedOnChange}
              label={t('Cell Phone')}
              control={MaskedInput}
              mask={PHONE_NUMBER_MASK}
              guide={false}
              value={formData.phone}
              placeholder={'(123) 456-7890'}
              data-testid="contact-phone"
            />
            {errors.phone && <InlineError text={errors.phone} data-testid="contact-phone-error" />}
          </Form.Field>
        </Form.Group>
        <Header as="h3" dividing data-testid="acc-info-title">
          {t('Account Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              {t('Approved Pick Up')}
              <Popup
                content={t(
                  "Parents by default are set to be able to log in to the Wonderschool App to check in and check out their children. If you disable this, email field becomes optional but they won't be able to log in to the app. Use this feature if you want to add emergency contact and you don't want to add emails."
                )}
                position="right center"
                offset={[-1, 0]}
                trigger={<Icon name="info circle" />}
              />
            </label>
            <Form.Radio
              toggle
              id="allowLoginWithPin"
              name="allowLoginWithPin"
              label={formData.allowLoginWithPin ? t('Yes') : t('No')}
              onChange={onChange}
              checked={!!formData.allowLoginWithPin}
              control={Checkbox}
              data-testid="allow-login-with-pin"
            />
          </Form.Field>
          {formData.allowLoginWithPin && formData.status === CONTACT_STATUS.verified && formData.uid && (
            <Form.Field error={!!errors.loginPin} data-testid="login-pin-error-field">
              <FamilyLoginPinGenerator
                uid={formData.uid}
                organizationId={organization.id}
                loginPin={formData.loginPin}
                onGenerating={() => setIsSaving(true)}
                onGenerated={(loginPin) => {
                  setIsSaving(false);
                  setFormData({ ...formData, loginPin });
                }}
                onError={() => {
                  setIsSaving(false);
                  setErrors({
                    ...errors,
                    loginPin: 'Failed to generate PIN Code.',
                  });
                }}
              />
              {errors.loginPin && <InlineError text={errors.loginPin} data-testid="login-pin-error" />}
            </Form.Field>
          )}
        </Form.Group>

        {formData.relationship === RELATIONSHIP_TYPES.parent && (
          <Form.Field>
            <label>
              {t('Primary payer')}
              <Popup
                content={t('PrimaryPayerToolTip')}
                position="right center"
                offset={[-1, 0]}
                trigger={<Icon name="info circle" />}
              />
            </label>
            <Form.Radio
              toggle
              id="responsible"
              name="responsible"
              label={formData.isResponsibleForBilling ? t('Yes') : t('No')}
              onChange={onChangeResponsibleForBilling}
              checked={!!formData.isResponsibleForBilling}
              control={Checkbox}
              data-testid="responsible-checkbox"
            />
          </Form.Field>
        )}
        {formData.status === CONTACT_STATUS.new && (
          <Form.Field>
            <label>{t('Invite contact to join Wonderschool & create an account to access mobile app')}</label>
            <Form.Radio
              toggle
              id="sendInvitation"
              name="sendInvitation"
              label={formData.sendInvitation ? t('Yes') : t('No')}
              onChange={onChange}
              checked={!!formData.sendInvitation}
              control={Checkbox}
              data-testid="send-invitation-checkbox"
            />
          </Form.Field>
        )}
      </>
    );
  }

  function renderSelectContactForm() {
    return (
      <>
        <Header as="h3" data-testid="personal-info">
          {t('Personal Information')}
        </Header>

        <Form.Group widths="equal">
          <Form.Field error={!!errors.selectedContact} data-testid="contact-name-error-field">
            <Form.Field
              control={Dropdown}
              label={t('Contact Name')}
              fluid
              search
              selection
              clearable
              required
              id="users"
              name="users"
              placeholder="Select Contact"
              options={selectContactOptions}
              value={selectedContact?.id ?? ''}
              onChange={onChangeSelectedContact}
              data-testid="contact-name-field"
            />
            {errors.selectedContact && (
              <InlineError text={errors.selectedContact} data-testid="contact-name-field-error" />
            )}
          </Form.Field>
          <Form.Field>
            <label>{t('Relationship')}</label>
            <div>{selectedContact?.relationship ? getOrCreateRelationshipNotes(selectedContact, t) : '--'}</div>
          </Form.Field>
        </Form.Group>

        <Header as="h3" dividing data-testid="contact-info-title">
          {t('Contact Information')}
        </Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t('Email')}</label>
            <div>{selectedContact?.email || '--'}</div>
          </Form.Field>
          <Form.Field>
            <label>{t('Phone Number')}</label>
            <div>{phoneNumberParse(selectedContact?.phone) || '--'}</div>
          </Form.Field>
        </Form.Group>
      </>
    );
  }

  function initializeFormData() {
    if (initialContact) {
      const { relationship, relationshipNotes, phone, ...rest } = transformRelationship(initialContact, t);

      let isResponsibleForBilling = false;

      if (relationship === RELATIONSHIP_TYPES.parent) {
        if (
          student.responsibleForBilling &&
          student.responsibleForBilling.id === (initialContact.id || initialContact.uid)
        ) {
          isResponsibleForBilling = true;
        }
      }

      setFormData({
        ...rest,
        relationship,
        relationshipNotes,
        phone: phoneNumberParse(phone),
        isResponsibleForBilling,
        sendInvitation: false,
      });
    } else {
      setFormData({ ...initialFormData });
    }
    setIsInitialized(true);
  }

  function onCloseLocal(e) {
    if (e) e.preventDefault();
    setFormData({});
    setErrors({});
    setIsSaving(false);
    if (onClose) onClose();
  }

  async function onSaveExistingContact() {
    setErrors({});
    if (!existingContact) return;

    setShouldEditContact(false);
    setSelectedContact(existingContact);
    setExistingContact(null);
  }

  async function onSave(e) {
    if (e) e.preventDefault();
    if (isSaving) return; // prevent double click

    setIsSaving(true);
    try {
      const errors = validate(formData);

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const success = shouldEditContact ? await saveEditContact() : await saveSelectedContact();

      if (success && onClose) onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  }

  async function saveEditContact() {
    let {
      sendInvitation,
      status,
      phone,
      isResponsibleForBilling,
      uid,
      invitationId,
      allowParentLogin,
      allowStaffLogin,
      ...rest
    } = formData;

    if (!student?.id || !organization?.id) {
      setIsSaving(false);
      return false;
    }

    rest.phone = phoneNumberFormat(phone);
    rest.displayName = formatFullName(rest, true);

    const email = formData.email?.toLowerCase();
    const originalEmail = initialContact?.email?.toLowerCase();
    const isEditEmail = originalEmail !== email;

    // this is not an update of an existing user.
    if ((isAdd || isEditEmail) && !!email) {
      const matchingContact = contacts.find((_contact) => _contact?.email?.toLowerCase() === email);
      let emailUsage = null;
      if (matchingContact) {
        setExistingContact(matchingContact);
        if (matchingContact.type === CONTACT_TYPES.staff) setIsOpenExistingStaffDialog(true);
        else setIsOpenExistingContactConfirmationDialog(true);
        return false;
      } else if (email) {
        // invoke callable to check for root org or auth user
        emailUsage = await fetchEmailUsage(email, organization.id);
        if (emailUsage?.hasRootUser) {
          setErrors({
            email: t('User already exists, but in ANOTHER organization. Please use a different email address.'),
          });
          return false;
        }
      }
      // if auth exists and no root user
      // and if shouldNotSendNetworkMigrationMail is true, than update status to new and add uid. No email will be sent as it is 1.0 to 2.0 Migration
      // and if shouldNotSendNetworkMigrationMail is false, than update status to verified and add uid. Welcome email will be sent.
      if (emailUsage && emailUsage.hasAuthUser && !emailUsage.hasRootUser && emailUsage.uid) {
        rest.status = organization?.shouldNotSendNetworkMigrationMail ? CONTACT_STATUS.new : CONTACT_STATUS.verified;
        if (rest.status === CONTACT_STATUS.verified) rest.uid = emailUsage.uid;
      } else {
        // this ensures a new invitation can be generated
        rest.invitationId = null;
        // verified users need to be disconnected from the previous user account
        // in order to allow a new invite to be sent.
        if (status === CONTACT_STATUS.verified) {
          rest.uid = null;
        }
        rest.status = sendInvitation ? CONTACT_STATUS.inviting : CONTACT_STATUS.new;
      }
    } else if (sendInvitation && (status === CONTACT_STATUS.new || status === CONTACT_STATUS.invited)) {
      rest.status = CONTACT_STATUS.inviting;
    } else {
      rest.status = status;
    }
    try {
      const { contactDocRef, contact } = isAdd
        ? await createContactForStudent(organization, location, student, rest)
        : await updateContactForStudent(organization, location, student, rest);

      // Add the contact to the student.family map so the UI is responsive.
      // The Student firestore listener will overwrite this with the correct data. DO NOT PERIST THIS HERE.
      if (!student.family) student.family = {};
      if (!student.family[contactDocRef.id]) student.family[contactDocRef.id] = contact;

      if (rest.relationship && isResponsibleForBilling) {
        const responsibleForBillingType =
          rest.status === CONTACT_STATUS.verified
            ? RESPONSIBLE_FOR_BILLING_TYPE.parent
            : RESPONSIBLE_FOR_BILLING_TYPE.invitation;

        let data = {
          id: student.id,
          responsibleForBilling: {
            id: contactDocRef.id,
            displayName: rest.displayName,
            type: responsibleForBillingType,
            email: rest.email,
            modifiedDate: Date.now(),
            contactStatus: rest.status,
            uid: uid ?? null,
          },
        };
        if (uid) data.uid = uid;
        if (invitationId) data.invitationId = invitationId;
        await saveStudent(organization.id, data);
      }

      showSaveSuccessToast(rest, isAdd, t);
      return true;
    } catch (error) {
      showSaveErrorToast(rest, error, t);
      console.log('Error updating parent', error);
      return false;
    }
  }

  async function saveSelectedContact() {
    if (!selectedContact) return false;

    // we tried to update a contact's email but it was already taken
    // we want to delete the original contact
    const isReplaceContactViaEmail = initialContact?.id && initialContact.id !== selectedContact.id;
    if (isReplaceContactViaEmail) {
      onReplaceExistingContact(initialContact);
    }

    await addContactToStudent(organization.id, student.id, selectedContact);
    showSaveSuccessToast(selectedContact, true, t);
    return true;
  }

  function validate() {
    const errors = {};

    if (shouldEditContact) {
      if (!formData.relationship) errors.relationship = t('Relationship is required');
      if (!formData.firstName) errors.firstName = t('First Name is required');
      if (!formData.lastName) errors.lastName = t('Last Name is required');

      if (formData.phone) {
        if (!Validator.isMobilePhone(formData.phone, 'en-US')) errors.phone = t('Phone is invalid');
      }
      if (!!formData.email && !Validator.isEmail(formData.email)) {
        errors.email = t('Email is invalid');
      } else if (isEmailRequired) {
        if (!formData.email || formData.email.indexOf('moxit_') > -1) errors.email = t('Email is required');
      }

      if (
        formData.relationship &&
        formData.relationship !== RELATIONSHIP_TYPES.contact &&
        formData.isResponsibleForBilling &&
        (!formData.email || !Validator.isEmail(formData.email))
      ) {
        errors.email = t('Email is required to set this parent responsible for billing.');
      }
    } else {
      if (!selectedContact) errors.selectedContact = t('Please select a contact from the list');
    }
    return errors;
  }
  function removeError(name) {
    if (errors[name]) {
      const { [name]: _extracted, ...restErrors } = errors;
      setErrors(restErrors);
    }
  }
  function onChangeSelectedContact(e, { name, value }) {
    e.preventDefault();
    removeError(name);
    const contact = familyContacts.find((u) => u.id === value);
    setSelectedContact(contact ?? null);
  }

  function onChange(e, { name, value, checked }) {
    removeError(name);
    let isResponsibleForBilling = formData.isResponsibleForBilling;

    if (name === 'relationship' && value === 'parent' && parentCount <= 0) {
      isResponsibleForBilling = true;
    }

    setFormData({
      ...formData,
      [name]: checked ?? value,
      isResponsibleForBilling,
    });
  }
  function maskedOnChange(e) {
    onChange(e, { name: e.target.name, value: e.target.value });
  }

  function onChangeResponsibleForBilling(_event, { checked }) {
    if (formData.relationship !== RELATIONSHIP_TYPES.parent) return;

    const isCurrentlyResponsibleForBilling =
      parentCount <= 0 ||
      (student.responsibleForBilling && student.responsibleForBilling.id === (formData.id || formData.uid));

    if (!checked && !isCurrentlyResponsibleForBilling) {
      removeError('email');
      setFormData({ ...formData, isResponsibleForBilling: false });
      return;
    }

    if (parentCount <= 0) {
      setFormData({ ...formData, isResponsibleForBilling: checked });
    } else {
      setIsOpenInvoiceResponsibleConfirmation(true);
    }
  }
}

function showSaveSuccessToast({ displayName }, isAdd, t) {
  const title = isAdd ? t('Family Member Added!') : t('Family Member Updated!');
  const message = isAdd
    ? t('Added a new family member: {{displayName}}', { displayName })
    : t('Updated family member: {{displayName}}', { displayName });
  showSuccessToast(title, message);
}

function showSaveErrorToast({ displayName }, error, t) {
  const message = t('Update family member failed for {{displayName}}', {
    displayName,
  });
  showErrorToast(t('Family Member Update Failed'), message, error);
}
