import { Grid } from 'semantic-ui-react';
import userflow from 'userflow.js';

import { useOnboardingGoals } from './onboardingHooks';
import { OnboardingGoalType } from './types';

import {
  EnrollmentsGoalButton,
  /* //FamiliesGoalButton, commented code for 2434 'Communicate with families' This block of code will be enabled in future. */
  PaymentsGoalButton,
  OperationsGoalButton,
} from './OnboardingGoalButtons';

const OnboardingGoalButtonsContainer: React.FC<unknown> = () => {
  const { enrollmentsGoal, paymentsGoal, operationsGoal } = useOnboardingGoals(); //familiesGoal

  const onClick = (onboardingGoal: OnboardingGoalType) => {
    userflow.start(onboardingGoal.id);
  };

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column computer={12}>
          <EnrollmentsGoalButton onboardingGoal={enrollmentsGoal} />
        </Grid.Column>
        {/* commented code for 2434 'Communicate with families' This block of code will be enabled in future. */}
        {/* <Grid.Column computer={8}>
          <FamiliesGoalButton onboardingGoal={familiesGoal} onClick={onClick} />
        </Grid.Column> */}
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column computer={12}>
          <PaymentsGoalButton onboardingGoal={paymentsGoal} onClick={onClick} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={12}>
          <OperationsGoalButton onboardingGoal={operationsGoal} onClick={onClick} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default OnboardingGoalButtonsContainer;
