import { Spinner, WidgetSizeEnum } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import refreshImg from '../../../icons/assets/svg/refresh.svg';
import FilterPerMonth from './FilterPerMonth';

type FinanceAtGlanceHeaderProps = {
  title?: string;
  selectedMonth: (month: moment.Moment) => void;
  refreshHandler: () => void;
  isLoading: boolean;
};
function FinanceAtGlanceHeader({
  title = 'financeAtGlance',
  selectedMonth,
  refreshHandler,
  isLoading,
}: FinanceAtGlanceHeaderProps) {
  const { t } = useTranslation();
  return (
    <div className="grid gap-2 text-xl sm:grid-cols-2">
      <div className="flex gap-2">
        <span className="shrink">{t(title)}</span>
        <button className="my-auto flex" type="submit" onClick={refreshHandler} disabled={isLoading}>
          {!isLoading ? (
            <img className="h-4 w-4" src={refreshImg} alt="refresh" />
          ) : (
            <Spinner size={WidgetSizeEnum.X_SMALL} />
          )}
        </button>
      </div>
      <div className="ml-auto mr-0 flex gap-2">
        <FilterPerMonth onChangeMonth={selectedMonth} />
      </div>
    </div>
  );
}

export default FinanceAtGlanceHeader;
