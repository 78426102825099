import { Button, Dialog } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';

type TimecardDiscardConfirmationModalProps = {
  openCancelModal: boolean;
  onKeepEditing: () => void;
  onDiscardEditing: () => void;
};

export function TimecardDiscardConfirmationModal({
  openCancelModal,
  onKeepEditing,
  onDiscardEditing,
}: TimecardDiscardConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      hasCloseButton={true}
      title={t('timecards.deleteTimecard')}
      onClose={onKeepEditing}
      isOpen={openCancelModal}
    >
      <Dialog.Panel>
        <Dialog.Title>{t('timecards.closeTimecard')}</Dialog.Title>
        <div>{t('timecards.closeTimecardModal.unsavedChanges')}</div>
        <div>{t('timecards.closeTimecardModal.cancelChangesConfirmation')}</div>

        <Dialog.Actions>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
            <Button
              label={t('timecards.closeWithoutSaving')}
              extraClasses="w-full sm:w-auto"
              onClick={onDiscardEditing}
            />
            <Button
              primary
              label={t('timecards.keepEditing')}
              onClick={onKeepEditing}
              extraClasses="w-full sm:w-auto"
              data-testid="timecard-edit-save"
            />
          </div>
        </Dialog.Actions>
      </Dialog.Panel>
    </Dialog>
  );
}
