import { Container, Segment } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { getTitlePrefix } from '../config/env';
import PageTitle from '../Components/Shared/PageTitle';
import { ParentMain, StaffMain } from '../documents';

import { useUser } from '../hooks/useUser';
import { useOrganization, useOrganizationUsersListener } from '../hooks/useOrganizations';

const FormsAndDocuments: React.FC = () => {
  const { t } = useTranslation();
  const { isParent } = useUser();
  const { id: currentOrganizationId } = useOrganization();
  useOrganizationUsersListener(currentOrganizationId);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Documents') })}
      </title>
      <body />
    </Helmet>
  );

  const renderPageTitle = () => <PageTitle title={t('Documents')} />;

  return (
    <Container className="no-padding no-margin">
      {renderPageHead()}

      <Segment basic>
        {renderPageTitle()}
        {isParent ? <ParentMain /> : <StaffMain />}
      </Segment>
    </Container>
  );
};

export default FormsAndDocuments;
