import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { CalendarDaysIcon } from '@heroicons/react/20/solid';

import { t } from 'i18next';
import Datepicker from 'react-tailwindcss-datepicker';
import { WsInputEventType, WsInputFieldProps } from './types';
import { WsLabel } from './WsLabel';
import WsValidationError from './WsValidationError';

export type DatepickerDateType = Date;

type DateValueType = {
  startDate: DatepickerDateType;
  endDate: DatepickerDateType;
};

export type WsDatepickerProps = WsInputFieldProps<Date> & {
  onChange: (data: WsInputEventType<Date>) => void;
  maxDate?: Date;
  minDate?: Date;
};

export function WsDatepicker({
  error,
  label,
  name,
  onChange,
  required,
  value,
  maxDate,
  minDate,
  placeholder,
  disabled,
  'data-testid': dataTestId,
}: WsDatepickerProps) {
  const now = new Date();

  const [selected, setSelected] = useState<DateValueType>({
    startDate: value ?? now,
    endDate: value ?? now,
  });

  useEffect(() => {
    const now = new Date();
    setSelected({ startDate: value ?? now, endDate: value ?? now } as DateValueType);
  }, [value]);

  const onChangeLocal = useCallback(
    (name: string, value: DateValueType) => {
      const date = moment(value.startDate).utc(false).toDate();
      setSelected({ ...value, endDate: date });
      onChange?.({ name, value: date });
    },
    [onChange]
  );
  const ToggleIcon = useCallback(
    () => <CalendarDaysIcon className="h-6 w-6 text-gray-400" aria-hidden="true" data-testid={`$testId}-icon`} />,
    []
  );

  return (
    <div className="relative">
      <WsLabel htmlFor={name} required={required} data-testid={`${dataTestId}-label`}>
        {label}
      </WsLabel>
      <Datepicker
        inputName={name}
        inputClassName="w-full rounded-lg border-0 pl-3 pr-10 bg-white text-gray-800 placeholder:text-gray-700 disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-700 disabled:ring-gray-100 shadow-sm sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-blue-900 ring-1 ring-inset ring-gray-400 font-bold text-sm h-9 min-w-[72px]"
        value={selected}
        placeholder={placeholder || t('Select a date')}
        onChange={(value) => onChangeLocal(name ?? '', value as DateValueType)}
        asSingle={true}
        useRange={false}
        disabled={disabled}
        displayFormat="MM/DD/YYYY"
        toggleIcon={ToggleIcon}
        minDate={minDate}
        maxDate={maxDate}
        data-testid={dataTestId}
      />
      <WsValidationError error={error} data-testid={`${dataTestId}-error`} />
    </div>
  );
}

export default WsDatepicker;
