import { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Icon, Header, Button, Loader, Dimmer } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import StudentPersonalInformationForm from '../../students/components/StudentPersonalInformationForm';

import StudentsGalleryStaff from '../../students/components/gallery/StudentsGalleryStaff';

import { getOrganizationStudentDocRef } from '../../students/studentsAPI';

// Import actions
import { studentSelected } from '../../students/studentsRedux';

const SlidingStudentsForm = withSlidingPanel(StudentPersonalInformationForm, {
  title: 'Add New Student',
  width: '70%',
});

class SetupStudents extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      currentOrganization,
      students: { list },
      t,
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="group" size="huge" color="red" />
          <Header as={'h2'}>{t('Add your students')}</Header>
          <Header as={'h3'} className="no-weight">
            {t('First center information, then rooms and then staff.')}
            <br />
            {t("Now the important stuff. Let's add your student roster&mdash;the real reason we all are here.")}
          </Header>
        </Segment>
        <Segment basic>
          <SlidingStudentsForm
            isOpen={this.state.isOpen}
            onClose={(status) => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button
            content={t('Add Students')}
            primary
            size="large"
            onClick={() => {
              if (currentOrganization && currentOrganization.id) {
                const docRef = getOrganizationStudentDocRef(currentOrganization.id);
                // Initialize new student's id. The student id is useful to upload to firebase storage.
                this.props.studentSelected({
                  id: docRef.id,
                });
              }

              this.setState({ isOpen: true });
            }}
            data-testid="add-students"
          />
        </Segment>
      </Segment>
    );
  };

  /**
   * This loader will be displayed only during first student creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
      t,
    } = this.props;

    if (this.state.added && !setup.firstStudentCreated)
      return (
        <Dimmer active inverted>
          <Loader inline="centered" indeterminate content={t('Just a moment while we add your first student...')} />
        </Dimmer>
      );
  };

  renderListBlock = () => {
    const {
      students: { list },
    } = this.props;

    if (!list.length) return null;
    return <StudentsGalleryStaff />;
  };
  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupStudents.propTypes = {};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  students: state.students,
});
export default withTranslation()(connect(mapStateToProps, { studentSelected })(SetupStudents));
