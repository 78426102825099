import moment from 'moment';
import { firestore, functions } from '../api/firebase';
import {
  saveDocument,
  setDocument,
  startCollectionListener,
  startChunkedCollectionListener,
  updateDocument,
  getDocumentsAsArray,
} from '../api/firebase/firestore';

import { createStudentFamilyFromContact } from './studentsUtils';

const paths = {
  student: (organizationId, studentId) => `organizations/${organizationId}/students/${studentId}`,
  students: (organizationId) => `organizations/${organizationId}/students`,
  user: (organizationId, userId) => `organizations/${organizationId}/users/${userId}`,
  rootStudents: () => `students`,
};

export const ResponsibleForBillingType = {
  PARENT: 'parent',
  INVITATION: 'invitation',
};

export function parentStudentsOnListen(organizationId, userId, next, error, complete) {
  return startCollectionListener(
    {
      path: paths.students(organizationId),
      conditions: [
        {
          field: 'familyMembers',
          operation: 'array-contains',
          value: userId,
        },
      ],
      orderBy: [{ field: 'firstName', direction: 'asc' }],
    },
    next,
    error,
    complete
  );
}

export function organizationStudentsOnListen({ organizationId, validRoomIds }, next, error, complete) {
  const hasRoomIds = validRoomIds?.length > 0;
  const sortFn = hasRoomIds
    ? (studentA, studentB) => {
        // ascending
        return (studentA.firstName || '').localeCompare(studentB.firstName || '');
      }
    : undefined;
  return startChunkedCollectionListener(
    {
      path: paths.students(organizationId),
      orderBy: hasRoomIds
        ? undefined // rely on sortFn here; can't use orderBy w/ `array-contains-any` queries
        : [{ field: 'firstName', direction: 'asc' }],
      conditionsBase: [],
      chunkableCondition: hasRoomIds
        ? {
            field: 'rooms',
            operation: 'array-contains-any',
            value: validRoomIds,
          }
        : undefined,
    },
    next,
    error,
    complete,
    sortFn
  );
}

export const getOrganizationStudentDocRef = (organizationId) => {
  if (!organizationId) throw new Error('Organization Id is required');
  return firestore().collection(paths.students(organizationId)).doc();
};

export const saveStudent = async (organizationId, student, firestoreOptions = { merge: true }) => {
  if (!organizationId) throw new Error('Organization Id is required');
  if (!student) throw new Error('Student is required');

  const { key, combinedFamily, ...rest } = student;

  const data = {
    ...rest,
    organization: organizationId,
  };

  return saveDocument({
    collectionPath: paths.students(organizationId),
    data,
    firestoreOptions,
  });
};

export const updateOrganizationStudent = async (organizationId, { id, combinedFamily, ...studentData }) => {
  try {
    await setDocument({
      path: paths.student(organizationId, id),
      data: studentData,
    });
  } catch (error) {
    return error;
  }
};

export const createContactDataForSave = (contact) => {
  const familyMemberIds = contact.uid ? [contact.uid, contact.id] : [contact.id];

  const data = createStudentFamilyFromContact(contact);

  return {
    family: { [contact.id]: data },
    familyMembers: firestore.FieldValue.arrayUnion(...familyMemberIds),
  };
};

export const createContactDataForDelete = (contact) => {
  const familyMemberIds = contact.uid ? [contact.uid, contact.id] : [contact.id];
  return {
    family: {
      [contact.id]: firestore.FieldValue.delete(),
    },
    familyMembers: firestore.FieldValue.arrayRemove(...familyMemberIds),
  };
};

export const addContactToStudent = async (organizationId, studentId, contact) => {
  if (!contact?.id || !organizationId || !studentId) throw new Error('Required data is missing');

  const path = paths.student(organizationId, studentId);
  try {
    // Update student.family map.
    await setDocument({
      path: path,
      data: createContactDataForSave(contact),
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const removeContactFromStudent = async (organizationId, student, contact) => {
  const data = createContactDataForDelete(contact);
  return setDocument({
    path: paths.student(organizationId, student.id),
    data,
  });
};

export const setResponsibleForBilling = async (organizationId, studentId, contact, responsibleForBillingType) => {
  if (!organizationId || !studentId || !contact || !responsibleForBillingType)
    throw new Error(`${moment().format()} setResponsibleForBilling: Required data is missing`);

  const data = {
    responsibleForBilling: {
      id: contact.id || contact.uid,
      displayName: contact.displayName,
      email: contact.email,
      type: responsibleForBillingType ?? '',
      modifiedDate: Date.now(),
    },
  };
  if (responsibleForBillingType) data.responsibleForBilling.type = responsibleForBillingType;

  try {
    await updateDocument({
      path: paths.student(organizationId, studentId),
      data,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};
export function createResponsibleForBillingDataForDelete() {
  return {
    responsibleForBilling: firestore.FieldValue.delete(),
  };
}

export const clearResponsibleForBilling = async (organizationId, studentId) => {
  if (!organizationId || !studentId)
    throw new Error(`${moment().format()} clearResponsibleForBilling: Required data is missing`);

  try {
    await updateDocument({
      path: paths.student(organizationId, studentId),
      data: createResponsibleForBillingDataForDelete(),
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchOrganizationStudents = (organizationId, conditions = []) => {
  if (!organizationId) return null;

  return getDocumentsAsArray({
    path: paths.students(organizationId),
    conditions,
  });
};

export const fetchBillingDetails = async (organizationId, studentId) => {
  const params = { organizationId, studentId };

  const { data } = await functions().httpsCallable('callables-students-getBillingDetails')(params);

  return data;
};

export const getStudentsForContact = async (contactId, organizationId = null) => {
  if (!contactId) throw new Error('Contact id missing');

  const path = organizationId ? paths.students(organizationId) : paths.rootStudents();

  const studentsSnapshot = await firestore().collection(path).where('familyMembers', 'array-contains', contactId).get();

  // If no students exist, return empty array.
  if (studentsSnapshot.empty) return [];

  const students = studentsSnapshot.docs.filter((doc) => doc.exists).map((doc) => ({ id: doc.id, ...doc.data() }));
  return students;
};
