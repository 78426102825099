import React from 'react';

const TimePercentage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M6.166 6.166a8.25 8.25 0 1111.667 11.667A8.25 8.25 0 016.166 6.167zm12.728 12.728c3.808-3.807 3.808-9.98 0-13.788-3.807-3.808-9.98-3.808-13.788 0-3.808 3.807-3.808 9.98 0 13.788 3.807 3.808 9.98 3.808 13.788 0zM12.47 6.736a.75.75 0 00-1.5 0v6.328a.75.75 0 00.366.644l4.922 2.936a.75.75 0 00.768-1.288l-4.556-2.718V6.736z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default TimePercentage;
