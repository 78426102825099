import moment from 'moment';
import { USER_STATUS } from './userStatus';
import { invoiceStatusFormatter } from './invoices';
import { TransactionType } from '../transactions/types';

export const YYMMDDFORMAT = 'YYYY-MM-DD';
export const getRevenueAndExpencesPerMonth = (transactionArray, selectedDates) => {
  const { firstDate: startDate, lastDate: endDate } = selectedDates;
  const utcOffset = startDate.utcOffset();

  let expense = 0;
  let revenue = 0;
  transactionArray.map((transaction) => {
    let compareDate = moment(transaction.date['seconds'] * 1000).utcOffset(utcOffset);

    if (
      transaction.transactionType === TransactionType.EXPENSE &&
      compareDate.isBetween(startDate, endDate, undefined, '[]')
    ) {
      expense = expense + transaction.amount;
    }
    if (
      transaction?.transactionType === TransactionType.REVENUE &&
      compareDate.isBetween(startDate, endDate, undefined, '[]')
    ) {
      revenue = revenue + transaction.amount;
    }
  });
  return { expense: expense, revenue: revenue };
};

export const getInvoicesStatusesPerMonth = (allInvoices, datesOfMonths, t) => {
  let statusDepositedOrPaid = 0;
  let statusFailed = 0;
  let statusTransferring = 0;
  let statusProcessing = 0;
  let statusPastDue = 0;
  let statusUpcoming = 0;

  allInvoices?.map((invoice) => {
    if (
      moment(invoice.dateDue).isBetween(
        moment(datesOfMonths.firstdate),
        moment(datesOfMonths.lastdate),
        undefined,
        '[]'
      )
    ) {
      const invoiceStatus = invoiceStatusFormatter(invoice, t).userStatus;
      if (invoiceStatus === USER_STATUS.PAID_MANUALLY || invoiceStatus === USER_STATUS.PAID) {
        statusDepositedOrPaid = statusDepositedOrPaid + invoice.total;
      }
      if (
        invoiceStatus === USER_STATUS.CHARGE_FAILED ||
        invoiceStatus === USER_STATUS.TRANSFER_FAILED ||
        invoiceStatus === USER_STATUS.UNCOLLECTIBLE
      ) {
        statusFailed = statusFailed + invoice.total;
      }
      if (invoiceStatus === USER_STATUS.TRANSFERRING) {
        statusTransferring = statusTransferring + invoice.total;
      }
      if (invoiceStatus === USER_STATUS.PROCESSING) {
        statusProcessing = statusProcessing + invoice.total;
      }
      if (invoiceStatus === USER_STATUS.PAST_DUE) {
        statusPastDue = statusPastDue + invoice.total;
      }
      if (
        invoiceStatus === USER_STATUS.SCHEDULED ||
        invoiceStatus === USER_STATUS.INVOICE_SENT ||
        invoiceStatus === USER_STATUS.NOTIFICATION_SENT
      ) {
        statusUpcoming = statusUpcoming + invoice.total;
      }
    }
  });
  return {
    statusDepositedOrPaid: statusDepositedOrPaid,
    statusFailed: statusFailed,
    statusTransferring: statusTransferring,
    statusProcessing: statusProcessing,
    statusPastDue: statusPastDue,
    statusUpcoming: statusUpcoming,
  };
};

export function getFinanceAtGlanceTooltip(boxType) {
  const tooltip = {
    upcoming: 'financeAtGlance.tooltip.upcoming',
    processing: 'financeAtGlance.tooltip.processing',
    transferring: 'financeAtGlance.tooltip.transferring',
    depositedPaid: 'financeAtGlance.tooltip.Deposited/Paid(O)',
    pastDue: 'financeAtGlance.tooltip.pastDue',
    failed: 'financeAtGlance.tooltip.failed',
    expenses: 'financeAtGlance.tooltip.expenses',
    revenue: 'financeAtGlance.tooltip.revenue',
  };
  return tooltip[boxType] || 'financeAtGlance.tooltip.default';
}

export function getBoxTypeTitle(boxType) {
  const title = {
    upcoming: 'financeAtGlance.staus.upcoming',
    processing: 'financeAtGlance.staus.processing',
    transferring: 'financeAtGlance.status.transferring',
    depositedPaid: 'financeAtGlance.status.Deposited/Paid(O)',
    pastDue: 'financeAtGlance.status.pastDue',
    failed: 'financeAtGlance.status.paymentfailed',
    expenses: 'Expenses',
    revenue: 'Revenue',
  };
  return title[boxType] || 'financeAtGlance.tooltip.default';
}
