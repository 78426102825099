import { Image, Modal } from 'semantic-ui-react';

export default function ImageModal({ url, onClose, open, type }) {
  if (!onClose) throw new Error('onClose is required');

  const isPdf = type === 'pdf';

  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      closeOnDimmerClick
      closeOnDocumentClick
      style={isPdf ? { height: '80%' } : {}}
    >
      <Modal.Content image style={{ height: '100%' }}>
        {isPdf ? <object data={url} type="application/pdf" width="100%" height="100%" /> : <Image src={url} centered />}
      </Modal.Content>
    </Modal>
  );
}
