import _ from 'lodash';
import { organizationRolesOnListen, getUserRoles } from '../../api/firebase/_.roles';
import { synchronizeRolesAndClaims } from '../../api/firebase/users';
import { ROLES_CHANGED, ROLES_LISTENER_STARTED } from './types';
import store from '../store';

export const roleListenerStarted = () => ({
  type: ROLES_LISTENER_STARTED,
});

export const organizationRoleChanged = (roles) => ({
  type: ROLES_CHANGED,
  roles,
});

export const getRoleById = (id) => {
  if (!id) return null;
  const roles = store.getState().roles;
  return roles[id] || null;
};

export const getParentRoles = () => {
  const roles = store.getState().roles;
  if (_.isEmpty(roles)) return null;
  return _.pickBy(roles, { parentRole: true }) || null;
};

export const getStaffRoles = () => {
  const roles = store.getState().roles;
  if (_.isEmpty(roles)) return null;
  return _.pickBy(roles, { staffRole: true }) || null;
};

export const startOrganizationRolesListener = (organizationId) => (dispatch) => {
  dispatch(roleListenerStarted());
  return organizationRolesOnListen(
    organizationId,
    async (allowRoles) => {
      if (allowRoles) {
        dispatch(organizationRoleChanged(allowRoles));

        // check for out of date claims
        const user = store.getState()?.user || {};
        const userId = user?.id || user?.uid;
        const claims = user?.claims || {};

        const ccmsClaims = [
          'organizationAdmin',
          'locationAdmin',
          'teacher',
          'supportStaff',
          'parent',
          'guardian',
          'emergencyContact',
          'organizationDevice',
        ];

        const foundCcmsClaims = _.pick({ ...claims }, ccmsClaims);
        const hasCcmsClaims = !_.isEmpty(foundCcmsClaims);

        if (!hasCcmsClaims) {
          const userRolesWithId = await getUserRoles(organizationId, userId);
          const userRoles = _.omit(userRolesWithId, ['id']);

          if (userId && !_.isEmpty(userRoles)) {
            const allowedClaims = [...ccmsClaims, 'moxitAdmin', 'billingAccount'];
            const customClaims = _.pick({ ...claims }, allowedClaims);
            const newClaims = _.merge({ ...customClaims }, { ...userRoles });
            if (!_.isEqual(newClaims, claims)) {
              synchronizeRolesAndClaims(userId, organizationId);
            }
          }
        }
      }
    },
    (error) => console.log(error.message)
  );
};
