import { Segment } from 'semantic-ui-react';

import { PAGE_NAMES, useSegmentPage } from '../segment';

import InvoicePlansList from '../Components/Forms/Billing/InvoicePlans/InvoicePlansList';
import SetupPayoutModal from '../Components/SetupPayoutModal';
import PageHeader from '../Components/Shared/PageHeader';

export default function InvoicePlans() {
  useSegmentPage(PAGE_NAMES.invoicePlans);

  return (
    <>
      <PageHeader pageName={'Billing'} classes="billing" />
      <Segment basic>
        <InvoicePlansList />
      </Segment>
      <SetupPayoutModal />
    </>
  );
}
