import * as React from 'react';
import { Card } from 'semantic-ui-react';
import { ComponentType, ReactNode } from 'react';

type SectionCardProps = {
  header: ReactNode;
  component?: ComponentType;
  extra?: ReactNode;
  noPadding?: boolean;
  children?: ReactNode;
  [restProps: string]: any;
};

const SectionCard = ({
  header,
  noPadding,
  component: PassedComponent,
  extra = false,
  children,
  ...rest
}: SectionCardProps) => (
  <Card fluid className="no-padding" {...rest}>
    {header ? (
      <Card.Content>
        <Card.Header as="h3" data-testid="section-card-header">
          {header}
        </Card.Header>
      </Card.Content>
    ) : (
      ''
    )}

    <Card.Content className={noPadding ? 'no-padding' : ''}>
      {PassedComponent ? <PassedComponent /> : children}
    </Card.Content>

    {extra && <Card.Content extra>{extra}</Card.Content>}
  </Card>
);

export default SectionCard;
