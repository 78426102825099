import dayjs from 'dayjs';
import { TimecardFormDataType } from '../timecardTypes';

type ClockInOutTimes = Pick<
  TimecardFormDataType,
  'clockedInTime' | 'clockedOutTime' | 'clockedInDate' | 'clockedOutDate'
>;

export function calculateClockInOutTimeDifference({
  clockedInDate,
  clockedInTime,
  clockedOutDate,
  clockedOutTime,
}: ClockInOutTimes) {
  const safeClockedOutDate = clockedOutDate ?? new Date();
  const safeClockedOutTime =
    clockedOutTime && clockedOutTime !== '' ? clockedOutTime : new Date().toTimeString().slice(0, 5);

  const clockedIn = `${dayjs(clockedInDate).format('MM/DD/YYYY')} ${clockedInTime}`;
  const clockedOut = `${dayjs(safeClockedOutDate).format('MM/DD/YYYY')} ${safeClockedOutTime}`;
  const clockedInParsed = dayjs(clockedIn || new Date());
  const clockedOutParsed = dayjs(clockedOut || new Date());
  const totalMinutes = clockedOutParsed.diff(clockedInParsed, 'minutes');

  return {
    totalTime: totalMinutes,
    isOvernight: calculateIfClockInOutDatesAreOvernight(clockedInParsed.toDate(), clockedOutParsed.toDate()),
  };
}

export function calculateIfClockInOutDatesAreOvernight(
  clockedInDate: Date | undefined,
  clockedOutDate: Date | undefined
): boolean {
  if (!clockedInDate || !clockedOutDate) {
    return false;
  }

  const date1 = dayjs(clockedInDate);
  const date2 = dayjs(clockedOutDate);

  return date2.isAfter(date1, 'day');
}
