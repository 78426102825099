import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useUser } from './useUser';

import {
  startOrganizationInvoicesListener,
  startOrganizationParentInvoicesListener,
} from '../redux/actions/invoiceActions';
import { startOrganizationLocationsListener } from '../redux/actions/locationActions';
import { startOrganizationPermissionsListener } from '../redux/actions/permissionActions';
import { startOrganizationRolesListener } from '../redux/actions/roleActions';
import { startOrganizationStatsListener } from '../redux/actions/statsActions';
import { startOrganizationUsersListener } from '../redux/actions/usersActions';

import { startOrganizationStudentsListener } from '../students/studentsRedux';
import { startOrganizationContactsListener } from '../contacts/contactsRedux';

import { useListenerWithRedux } from './useListenerWithRedux';

export {
  useOrganization,
  useOrganizationSetupState,
  useOrganizationInvoicesListener,
  useOrganizationLocationsListener,
  useOrganizationPermissionsListener,
  useOrganizationRolesListener,
  useOrganizationStaffListener,
  useOrganizationStatsListener,
  useOrganizationStudentsListener,
  useOrganizationUsersListener,
  useOrganizationContactsListener,
  useOrganizationFeatureFlag,
};

const selectCurrentOrganization = createSelector(
  [(state) => state.organizations],
  (organizations) => organizations?.currentOrganization
);

function useOrganization() {
  return useSelector(selectCurrentOrganization);
}

/**
 * Organization Onboarding Process:
 * 1) Authenticated user is found and loaded
 * 2) UserProfile Listener (userActions:startUserProfileListener) checks the user profile for a defaultOrganization id
 * ((needsOrgOnboarding = true))
 *  2>A) Missing: will dispatch ORGANIZATION_MISSING (currentOrg.fetching = undefined -> false)
 *  2>B) App.js will redirect to CompleteSignup where the user will begin the org provision process.
 *    ORGANIZATION_PROVISIONING_STARTED,
 *    await document(s) creation,
 *    brief sleep
 *    USER_PROFILE_UPDATED (now contains defaultOrganization id)
 *    ORGANIZATION_PROVISIONING_COMPLETE (fetching: false -> undefined) (relinquish control)
 *  2>C) App's user profile listener will receive a update trigger and find ORGANIZATION_SELECTED
 *    which merges this branch into the next step >> (3)
 * 3) If found: ORGANIZATION_SELECTED (currentOrg.fetching = undefined -> true or (whatever previous value), id -> exists)
 * ((canStartOrgListeners = true))
 * 4) App.js will start org listeners which rely only on the org id
 * 5) organizationActions.startOrganizationListener will dispatch ORGANIZATION_FETCHED (currentOrg.fetching: true -> false)
 *  most up to date organization data will now be loaded.
 * ((isOrgLoaded = true))
 * 6) User will be routed or navigate to the Setup wizard and eventually create all their first entities (defined in currentOrg.setup)
 *  once the last of them are set to true, setupComplete will be set to true.
 *  which will trigger an update event (ORGANIZATION_SELECTED) that will update the latest.
 * ((isOrgComplete = true))
 */
function useOrganizationSetupState() {
  const organization = useOrganization();

  const { fetching, id: organizationId, programType, setupComplete } = organization;

  const needsOnboarding = fetching === false && !organizationId;
  const canStartListeners = fetching === true && !!organizationId;
  const isLoaded = canStartListeners && !!programType;
  const isComplete = isLoaded && setupComplete;

  return {
    organizationId,
    needsOnboarding,
    canStartListeners,
    isLoaded,
    isComplete,
  };
}

function useOrganizationInvoicesListener(organizationId) {
  const dependencyArray = [organizationId];
  const { isLocationAdminHighestClaim, locations, isParent, contactId } = useUser();
  // if locationAdmin is highest claim - pass in locations
  const _locations = isLocationAdminHighestClaim ? locations : undefined;

  const startListenerAction = isParent
    ? startOrganizationParentInvoicesListener(organizationId, contactId)
    : startOrganizationInvoicesListener(organizationId, _locations);

  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationLocationsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationLocationsListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}
function useOrganizationPermissionsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationPermissionsListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationRolesListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationRolesListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationStatsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationStatsListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationStudentsListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationStudentsListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationUsersListener(organizationId, firestoreOptions = {}) {
  const dependencyArray = [organizationId];
  const startListenerAction = startOrganizationUsersListener(organizationId, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}

function useOrganizationContactsListener(organizationId, conditions, firestoreOptions = {}, otherDependencies = []) {
  const dependencyArray = [...otherDependencies, organizationId];
  const startListenerAction = startOrganizationContactsListener(organizationId, conditions, firestoreOptions);
  return useListenerWithRedux(startListenerAction, dependencyArray);
}
function useOrganizationStaffListener(organizationId, firestoreOptions = {}) {
  return useOrganizationContactsListener(
    organizationId,
    [{ field: 'type', operator: '==', value: 'staff' }],
    firestoreOptions
  );
}

function useOrganizationFeatureFlag() {
  const organization = useOrganization();

  const featureFlags = useMemo(() => (organization ? organization.features : []), [organization]);

  const getFeatureFlag = useCallback(
    (featureFlagName) => featureFlags?.find((flag) => flag.name === featureFlagName),
    [featureFlags]
  );

  const isFeatureEnabled = useCallback(
    (featureFlagName) => {
      const featureFlag = getFeatureFlag(featureFlagName);
      return featureFlag ? featureFlag.enabled : true; // a missing feature flag should be enabled by default
    },
    [getFeatureFlag]
  );

  return {
    featureFlags,
    isFeatureEnabled,
    getFeatureFlag,
  };
}
