import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatHoursFromMinutes } from '../../../helpers/dates';
import { ExportToolbar } from '../../components';
import { useOrganization } from '../../../hooks/useOrganizations';
import { usePrimaryLocation } from '../../../hooks/useLocations';
import useRooms from '../../../hooks/useRooms';

import { useAttendanceTotals } from '../attendanceHooks';
import { generateAttendanceTotalsCSV, generateAttendanceTotalsPDF } from '../attendanceUtils';
import {
  TABLE_CELL_CLASSES,
  SortDirectionEnum,
  SortDirectionType,
  WsTableHeader,
  sortCollection,
} from '../../../common';
import { PDFModal } from '../../components/PDFModal';
import { AttendanceTableProps, AttendanceTotalsReportType, StudentAttendanceTotalsType } from '../types';

const AttendanceTotalsTable: React.FC<AttendanceTableProps> = ({ filters, activities = [] }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();
  const rooms = useRooms();

  const attendanceTotals: StudentAttendanceTotalsType[] = useAttendanceTotals(activities);
  const [attendanceSorted, setAttendanceSorted] = useState<StudentAttendanceTotalsType[]>(attendanceTotals);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

  const [sortDirection, setSortDirection] = useState<SortDirectionType>({
    name: '',
    direction: SortDirectionEnum.NONE,
  });

  const onSort = useCallback((sortDirection: SortDirectionType) => {
    setSortDirection(sortDirection);
  }, []);

  const attendanceReport = useMemo<AttendanceTotalsReportType>(() => {
    return {
      startDate: filters.startDate,
      endDate: filters.endDate,
      organization,
      location,
      room: filters.roomId ? rooms.find((room) => room.id === filters.roomId) : null,
      items: attendanceTotals,
    };
  }, [attendanceTotals, filters, location, organization, rooms]);

  const onExportPDF = useCallback(() => {
    setIsPDFModalOpen(true);
  }, []);

  const onExportCSV = useCallback(() => {
    generateAttendanceTotalsCSV(attendanceReport);
  }, [attendanceReport]);

  const onClosePDFModal = useCallback(() => {
    setIsPDFModalOpen(false);
  }, []);

  useEffect(() => {
    if (!attendanceTotals?.length) return;
    setAttendanceSorted(sortCollection<StudentAttendanceTotalsType>(attendanceTotals, sortDirection));
  }, [attendanceTotals, sortDirection]);

  return (
    <div>
      <ExportToolbar onExportPDF={onExportPDF} onExportCSV={onExportCSV} disabled={!attendanceSorted?.length} />
      <table className="min-h-full min-w-full" data-testid="daily-attendance-table">
        <thead className="bg-gray-100" data-test-id="daily-attendance-thead">
          <tr className="border-y-2 border-gray-300">
            <WsTableHeader label={t('Name')} sortKey="studentName" onSort={onSort} sortDirection={sortDirection} />
            <WsTableHeader label={t('Room')} sortKey="roomName" onSort={onSort} sortDirection={sortDirection} />
            <WsTableHeader
              label={t('Days Present')}
              sortKey="daysPresent"
              onSort={onSort}
              sortDirection={sortDirection}
            />
            <WsTableHeader
              label={t('Days Absent')}
              sortKey="daysAbsent"
              onSort={onSort}
              sortDirection={sortDirection}
            />
            <WsTableHeader
              label={t('Total Hours Attended')}
              sortKey="totalMinutesAttended"
              onSort={onSort}
              sortDirection={sortDirection}
            />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300">
          {attendanceSorted.map((attendance) => {
            const { studentId, studentName, roomName, daysPresent, daysAbsent, totalMinutesAttended } = attendance;
            return (
              <tr key={studentId} className="cursor-none">
                <td className={TABLE_CELL_CLASSES} data-testid={`student-name-${studentId}`}>
                  {studentName || '-'}
                </td>
                <td className={TABLE_CELL_CLASSES} data-testid={`room-name-${studentId}`}>
                  {roomName || '-'}
                </td>
                <td className={TABLE_CELL_CLASSES} data-testid={`days-present-${studentId}`}>
                  {daysPresent || '0'}
                </td>
                <td className={TABLE_CELL_CLASSES} data-testid={`days-absent-${studentId}`}>
                  {daysAbsent || '0'}
                </td>
                <td className={TABLE_CELL_CLASSES} data-testid={`total-minutes-attended-${studentId}`}>
                  {formatHoursFromMinutes(totalMinutesAttended) || '0'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <PDFModal
        isOpen={isPDFModalOpen}
        report={attendanceReport}
        pdfGenerator={generateAttendanceTotalsPDF as any}
        onClose={onClosePDFModal}
      />
    </div>
  );
};

export default AttendanceTotalsTable;
