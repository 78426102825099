import { useMemo, useState, useCallback, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

// Import utils
import { formatStringAsUtcMillisOrNull, formatUtcMillisAsString } from '../../helpers/dates';
import { humanReadableFileSize } from '../../helpers/utils';
import { organizationUpdateStudent } from '../studentsRedux';
import { omit } from 'lodash';
import moment from 'moment';

// Import components
import {
  Button,
  DropdownItemProps,
  DropdownProps,
  Form,
  Header,
  Icon,
  InputOnChangeData,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { InlineError, ShowError } from '../../Components/Messages';
import MimeIcon from '../../Components/Shared/MimeIcon';
import DeleteFileModal from '../../documents/components/modals/DeleteFileModal';
import { DatePicker } from '../../Components/Shared/DatePicker';
import { logError } from '../../rollbar';

// Import hooks
import { useTranslation } from 'react-i18next';
import { useOrganization } from '../../hooks/useOrganizations';
import { useSelectedStudent } from '../studentsHooks';
import { useUser } from '../../hooks/useUser';
import { FileDocument, MalwareStatus, useDownloadFiles, useGetStudentFiles } from '@wonderschool/file-service-client';
import { deleteFile, updateDocument } from '../../api/firebase/firestore';
import { storage } from '../../api/firebase';
import { documentTestIds } from '../../documents/dictionary';
import { StudentDocumentTableCast } from '../types';
import { SHARE_TYPE } from '../../documents/types';

interface StudentTableDocument {
  id: string;
  name: string;
  expirationDate: string;
  tags: string[];
  documentId: string;
}

enum FILE_CLIENT_SOURCE {
  legacy = 'legacy',
  fileService = 'file-service',
}
type DeleteControls = {
  document: FileDocument | StudentDocumentTableCast;
  method: FILE_CLIENT_SOURCE;
  deleting: boolean;
};

const StudentDocumentList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selectedStudent = useSelectedStudent();
  const studentRoomId = useMemo(() => selectedStudent?.rooms?.[0] ?? null, [selectedStudent]);
  const { isParent, isOrganizationAdmin, isLocationAdmin } = useUser();
  const currentOrganization = useOrganization();
  const documents = useMemo(() => selectedStudent?.documents || [], [selectedStudent?.documents]);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteControls, setDeleteControls] = useState<DeleteControls | null>(null);

  const [data, setData] = useState<StudentTableDocument>({
    id: '',
    name: '',
    expirationDate: '',
    tags: [],
    documentId: '',
  });

  const [rowData, setRowData] = useState({});
  const [errors, setErrors] = useState<{ name?: string; tags?: string; expirationDate?: string }>({});
  const [tagOptions, setTagOptions] = useState<DropdownItemProps[]>([]);

  const { downloadFiles } = useDownloadFiles();

  const { data: fileServiceData, isLoading: isStudentFilesLoading } = useGetStudentFiles({
    studentId: selectedStudent.id,
    roomId: studentRoomId || '',
    locationId: selectedStudent.location,
  });

  const handleAddition = (_e: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
    setTagOptions((prev) => [{ text: value, value: value as keyof DropdownItemProps }, ...prev]);
  };

  const handleChange = (_e: SyntheticEvent<HTMLElement, Event>, { name, value }: InputOnChangeData | DropdownProps) => {
    setData({ ...data, [name]: value });
  };

  const handleExpirationDate = (e, { name, value, valid }) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => (valid ? omit(prev, name) : prev));
  };

  const saveChanges = async () => {
    if (!currentOrganization?.id) return;

    const { documentId, name, expirationDate, tags } = data;

    if (selectedStudent?.id) {
      const studentData = {
        ...selectedStudent,
        documents: {
          ...selectedStudent.documents,
          [documentId]: {
            ...rowData,
            documentId,
            name,
            expirationDate: formatStringAsUtcMillisOrNull(expirationDate),
            tags,
          },
        },
      };

      // Dispatch is since this is an action creator.
      dispatch(organizationUpdateStudent(currentOrganization.id, studentData));

      setTagOptions([]);
      setData({
        id: '',
        name: '',
        expirationDate: '',
        tags: [],
        documentId: '',
      });
    }
  };

  const removeDocument = (refId) => {
    if (!currentOrganization?.id) return;

    if (selectedStudent?.id) {
      const updatedDocuments = omit(selectedStudent?.documents, [refId]);

      // TODO: Create action creator.
      return updateDocument({
        path: `organizations/${currentOrganization.id}/students/${selectedStudent.id}`,
        data: { documents: updatedDocuments },
      });
    }
  };

  const renderFileForm = () => {
    return (
      <Segment basic clearing>
        <ShowError errors={errors} />
        <Form>
          <Form.Input
            name="name"
            label={t('Name')}
            fluid
            value={data?.name}
            onChange={handleChange}
            error={!!errors.name}
            data-testid="name"
          >
            {errors.name && <InlineError text={errors.name} data-testid="name-error" />}
          </Form.Input>

          <Form.Field>
            <DatePicker
              id="expirationDate"
              name="expirationDate"
              value={data?.expirationDate}
              onChange={handleExpirationDate}
              label={t('Expiration Date')}
              placeholder={t('Select Expiration Date')}
              minDate={moment()}
              error={!!errors.expirationDate}
              closable
            >
              {errors.expirationDate && <InlineError text={errors.expirationDate} data-testid="date-error" />}
            </DatePicker>
          </Form.Field>

          <Form.Dropdown
            name="tags"
            label={t('Tags')}
            search
            selection
            fluid
            multiple
            allowAdditions
            value={data?.tags || []}
            options={tagOptions}
            onAddItem={handleAddition}
            onChange={handleChange}
            error={!!errors.tags}
            data-testid="tags"
          >
            {errors.tags && <InlineError text={errors.tags} data-testid="tags-error" />}
          </Form.Dropdown>
        </Form>
      </Segment>
    );
  };

  const renderNoData = () => {
    return (
      <Segment basic clearing textAlign="center">
        <Header as={'h3'}>
          <Icon circular inverted name="info" color="grey" />
        </Header>
        <Header as={'h4'} data-testid="no-files-found">
          {t('No files found.')}
        </Header>
        <p className="text-muted">{t('You can upload files by clicking the upload button.')}</p>
      </Segment>
    );
  };

  const openFile = useCallback(
    async (row) => {
      // check if file-service-document
      if (row.fileStatus === MalwareStatus.CLEAN && row.scanResult?.status === MalwareStatus.CLEAN) {
        try {
          await downloadFiles([row.id]);
        } catch (error) {
          logError('error downloading student files: ', error, {
            studentId: selectedStudent.id,
            organizationId: currentOrganization.id,
          });
        }

        return null;
      }

      // handle legacy documents stored in the student record
      const { path = null, documentId = null } = row?.rowData ?? {};
      if (!path || !documentId) return;
      try {
        // Get a valid URL with token.
        const url = await storage().ref(`${path}/${documentId}`).getDownloadURL();

        if (url?.length) {
          // Open URL in a new tab.
          if (!window.open(url, '_blank')) {
            // Handle if the popup was blocked
            alert('Popup was blocked. Please check your browser settings.');
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [downloadFiles, currentOrganization.id, selectedStudent.id]
  );

  const columns = useMemo(() => {
    const columns: ColumnDescription[] = [
      {
        text: '',
        dataField: 'documentId',
        hidden: true,
      },
      {
        text: 'Name',
        dataField: 'name',
        sort: true,
        formatter: (cell, row, rowIndex) => (
          <div>
            <Header
              key={rowIndex}
              as="h4"
              className="no-margin"
              icon={row.type ? <MimeIcon mime={row.type} /> : null}
              content={
                <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    rel="noopener noreferrer"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openFile(row);
                    }}
                    data-testid="uploaded-doc-name"
                  >
                    {cell}
                  </a>
                  {row.size && <em className="text-muted" data-testid="doc-size">{` (${row.size})`}</em>}
                </>
              }
              data-testid="name-field"
            />
            <div style={{ margin: '5px 0' }}>
              <div className="edited-by text-muted" style={{ fontSize: '12px' }} data-testid="edited-by-name">
                {t('Edited by: {{editedBy}}', { editedBy: row.addedBy })}
              </div>
              <div className="uploaded-date text-muted" style={{ fontSize: '12px' }} data-testid="uploaded-date">
                {t('Uploaded date: {{uploadedDate}}', {
                  uploadedDate: row.uploadedDate,
                })}
              </div>
            </div>
          </div>
        ),
        headerStyle: () => {
          return { width: '45%' };
        },
        style: { verticalAlign: 'middle' },
      },
      {
        text: 'Tags',
        dataField: 'tags',
        style: { verticalAlign: 'middle' },
        formatter: (cell) => cell.join(', '),
        headerStyle: () => {
          return { width: '20%' };
        },
      },
      {
        text: 'Expiration',
        dataField: 'expirationDate',
        style: { verticalAlign: 'middle' },
        formatter: (cell) => {
          return formatUtcMillisAsString(cell);
        },
      },
    ];

    // parents can't edit
    if (!isParent) {
      columns.push({
        text: '',
        dataField: '',
        formatter: (_cell, row) => {
          const isLegacy = selectedStudent.documents?.[row.documentId];
          const isIneligibleForDelete =
            studentRoomId &&
            !!Object.keys(row?.sharedFor || {}).find((key) => {
              // `key` format = `room-${roomId}` | `student-${studentId}` | `location-${locationId}`
              const isRoomShare = key.includes(`${SHARE_TYPE.room}-`);
              if (isRoomShare && key === `${SHARE_TYPE.room}-${studentRoomId}`) {
                return true;
              }
            });

          return (
            <div>
              {/* only show EDIT BTN for legacy docs */}
              {isLegacy ? (
                <Button
                  circular
                  icon="edit outline"
                  color="green"
                  onClick={(e) => {
                    e?.stopPropagation();

                    if (documents && row?.documentId) {
                      const selectedDocument = documents[row.documentId];
                      setModalOpen(true);
                      setRowData(selectedDocument);
                      setTagOptions(
                        selectedDocument?.tags?.length
                          ? selectedDocument.tags.map((tag) => ({
                              text: tag,
                              value: tag,
                            }))
                          : []
                      );
                      setData({
                        id: selectedDocument.id,
                        documentId: selectedDocument.documentId,
                        name: selectedDocument.name,
                        tags: selectedDocument.tags,
                        expirationDate: formatUtcMillisAsString(selectedDocument.expirationDate) ?? '',
                      });
                    }
                  }}
                  data-testid="edit-btn"
                />
              ) : null}

              {/* only show DELETE BTN for authorized staff */}
              {isLocationAdmin || isOrganizationAdmin ? (
                <Popup
                  content={
                    <div data-testid={documentTestIds.studentDocList.deletePopupText}>
                      {t(
                        "This document has been shared with the student's room and can only be deleted from the documents page."
                      )}
                    </div>
                  }
                  disabled={!isIneligibleForDelete}
                  trigger={
                    // !! DANGER !!
                    // The div wrapper here is required and must not be removed.
                    // `<Button disabled={true} />` turns off all pointer events, including hover,
                    // which breaks the <Popover /> functionality.
                    <div data-testid={documentTestIds.studentDocList.deleteBtnWrap}>
                      <Button
                        icon="trash alternate outline"
                        color="red"
                        circular
                        disabled={isIneligibleForDelete}
                        onClick={(e) => {
                          if (isIneligibleForDelete) {
                            return null;
                          }
                          e?.stopPropagation();
                          const isLegacy = selectedStudent.documents?.[row.documentId];
                          setDeleteControls({
                            document: row,
                            method: isLegacy ? FILE_CLIENT_SOURCE.legacy : FILE_CLIENT_SOURCE.fileService,
                            deleting: false,
                          });
                        }}
                        data-testid={documentTestIds.studentDocList.btns.delete}
                      />
                    </div>
                  }
                />
              ) : null}
            </div>
          );
        },
        style: { verticalAlign: 'middle' },
        align: 'right',
      });
    }

    return columns;
  }, [
    documents,
    openFile,
    setDeleteControls,
    selectedStudent,
    studentRoomId,
    isParent,
    isLocationAdmin,
    isOrganizationAdmin,
    t,
  ]);

  const castNewDocumentListToTableFormat = (documents: FileDocument[]): StudentDocumentTableCast[] => {
    return documents.map((doc) => ({
      documentId: doc.id,
      tags: [],
      addedBy: doc.createdBy.displayName,
      uploadedDate: moment(doc.createdAt).format('YYYY-MM-DD - h:m A'),
      type: doc.file.mimetype,
      ...doc,
    }));
  };

  const tableData: StudentDocumentTableCast[] = useMemo(() => {
    // handle legacy data
    const tableData: StudentDocumentTableCast[] = documents
      ? Object.keys(documents).map((key) => ({
          documentId: documents[key].documentId,
          name: documents[key].name,
          addedBy: documents[key]?.uploadedBy?.name || 'UNKNOWN',
          type: documents[key].type,
          uploadedDate: formatUtcMillisAsString(documents[key].uploadedDate, 'YYYY-MM-DD - h:m A'),
          expirationDate: documents[key].expirationDate || null,
          downloadUrl: documents[key].downloadUrl,
          path: documents[key].path,
          refId: documents[key]?.meta?.refId || key,
          size: humanReadableFileSize(documents[key].size) || '',
          tags: documents[key].tags || [],
          rowData: documents[key],
        }))
      : [];

    // due to legacy file storage, we need to combine 2 sources of document data
    if (!isStudentFilesLoading && fileServiceData?.documents) {
      const cast = castNewDocumentListToTableFormat(fileServiceData?.documents ?? []);
      tableData.push(...cast);
    }

    return tableData;
  }, [documents, fileServiceData, isStudentFilesLoading]);

  const renderTable = () => {
    return tableData?.length ? (
      <div className="bootstrap-iso" data-testid={documentTestIds.docsTable}>
        <BootstrapTable
          classes="w-auto w-md-100"
          wrapperClasses="table-responsive"
          noDataIndication="no results to display"
          keyField="documentId"
          bordered={false}
          data={tableData}
          columns={columns}
        />
      </div>
    ) : (
      renderNoData()
    );
  };

  const handleLegacyDelete = async () => {
    const { path, refId } = deleteControls?.document as StudentDocumentTableCast;
    if (path && refId) {
      try {
        await removeDocument(refId);
        await deleteFile({ path, refId });
      } catch (error) {
        console.log('error: ', error);
        setErrors((prev) => ({ ...prev, error }));
      }
    }

    setDeleteControls(null);
  };

  const renderConfirmationDialog = () => (
    <Modal
      size="small"
      open={!!deleteControls?.document && deleteControls.method === FILE_CLIENT_SOURCE.legacy}
      onClose={() => setDeleteControls(null)}
    >
      <Modal.Header data-testid="delete-document-modal-title">{t('Delete Document')}</Modal.Header>
      <Modal.Content>
        <p>{t('Are you sure you want to delete this document?')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={() => setDeleteControls(null)}
          content={t('No')}
          data-testid={documentTestIds.deleteModal.legacyBtns.no}
        />
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={handleLegacyDelete}
          data-testid={documentTestIds.deleteModal.btns.yes}
        />
      </Modal.Actions>
    </Modal>
  );

  const renderEditModal = () => (
    <Modal
      style={{ overflow: 'visible' }}
      key="documents-modal"
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      header={t('Edit Student Document')}
      content={renderFileForm()}
      actions={[
        'Cancel',
        {
          key: 'done',
          content: 'Update',
          primary: true,
          onClick: () => {
            saveChanges();
          },
        },
      ]}
      data-testid="edit-student-document"
    />
  );

  return (
    <div>
      {!!deleteControls?.document && deleteControls.method === FILE_CLIENT_SOURCE.fileService ? (
        <DeleteFileModal
          isModalOpen={!!deleteControls.document}
          selectedDocument={deleteControls.document as FileDocument}
          student={selectedStudent}
          closeModal={() => setDeleteControls(null)}
        />
      ) : null}
      {renderEditModal()}
      {renderConfirmationDialog()}
      {renderTable()}
    </div>
  );
};

export default StudentDocumentList;
