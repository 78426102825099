import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Icon } from 'semantic-ui-react';
import { CustomRefinementList } from '../../Search/CustomRefinementList';

export const OrganizationFilters = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (_e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <Accordion>
      <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
        <h3 data-testid="provider-filter">
          <Icon name="filter" size="small" data-testid="provider-filter-icon" />
          {t('Filter')}
        </h3>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <CustomRefinementList attribute="programTypeFilter" title="Program type" data-testid="provider-program-type" />
        <CustomRefinementList
          attribute="onboardingStatusFilter"
          title="Onboarding status"
          data-testid="provider-onboarding-status"
        />
        <CustomRefinementList
          attribute="kinderConnectFilter"
          title="Integration"
          data-testid="provider-kinder-connect"
        />
      </Accordion.Content>
    </Accordion>
  );
};
