import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import components.
import PageHeader from '../../../Components/Shared/PageHeader';
import PageTitle from '../../../Components/Shared/PageTitle';
import EmptyReport from '../../components/EmptyReport';
import { WsSpinner, WsWidgetSizeEnum } from '../../../common';

import AttendanceFiltersForm from './AttendanceFiltersForm';
import DailyAttendanceTable from './DailyAttendanceTable';
import AttendanceTotalsTable from './AttendanceTotalsTable';

import { useAttendanceActivities } from '../attendanceHooks';
import { ReportTypeEnum } from '../../enums';
import { AttendanceFiltersType } from '../types';

const Attendance: React.FC = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<AttendanceFiltersType>();
  const { activities, isLoading } = useAttendanceActivities(filters);

  const onFilter = useCallback((filters: AttendanceFiltersType) => {
    setFilters(filters);
  }, []);

  const onClear = useCallback(() => {
    setFilters(undefined);
  }, []);

  const emptyTitle = useMemo<string | undefined>(() => {
    return filters ? t('No data was found for the selected options.') : undefined;
  }, [filters, t]);

  return (
    <>
      {isLoading && <WsSpinner hasOverlay={true} spinnerColor="sky" size={WsWidgetSizeEnum.LARGE} />}
      <div className="container px-5">
        <PageHeader pageName={'Attendance'} classes="attendance" />
        <PageTitle title={t('Attendance')} />
        <AttendanceFiltersForm onFilter={onFilter} onClear={onClear} loading={isLoading} />

        {isLoading ? null : activities?.length === 0 ? (
          <EmptyReport title={emptyTitle} />
        ) : filters?.reportType === ReportTypeEnum.ATTENDANCE_DAILY ? (
          <DailyAttendanceTable filters={filters} activities={activities} isLoading={isLoading} />
        ) : filters?.reportType === ReportTypeEnum.ATTENDANCE_TOTAL ? (
          <AttendanceTotalsTable filters={filters} activities={activities} isLoading={isLoading} />
        ) : null}
      </div>
    </>
  );
};

export default Attendance;
