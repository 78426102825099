import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { Icon, Button, Header, Dropdown, Input, Grid, Popup, Checkbox, Label } from 'semantic-ui-react';
import DateTime from '../../DateTime/DateTime';
import { didStripeOnboardingSucceed } from '../../../helpers/stripe';

// Import actions
import { invoiceSelected } from '../../../redux/actions/invoiceActions';

// Import components
import { InvoiceDetailContainer } from '../../Forms/Billing/InvoiceDetail';
import withSlidingPanel from '../../Shared/withSlidingPanel';
import NoDataMessageBilling from '../../Shared/NoDataMessageBilling';
import { LoadingIndicator } from '../../Shared/BusyIndicator';
import SectionCard from '../../Shared/SectionCard';

import { currencyFormatter, convertListToCSV } from '../../../helpers/utils';
import { DefaultDateFormat } from '../../../helpers/dates';
import { resendInvoiceToStripe, markInvoicesAsArchive } from '../../../api/firebase/invoices';
import { InvoiceStatus as InvoiceStatusComponent } from './index';

// Import styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from './InvoicesList.module.scss';
import './InvoiceList.scss';
import { routes } from '../../../config/routes';
import AlertMessage from '../../Shared/AppAlert';
import { isLocal } from '../../../config/env';
import { DateRangePicker } from '../../Shared/DatePicker';
import { useInvoices } from '../../../hooks/useInvoices';
import {
  InvoiceStatus,
  invoiceStatusFormatter,
  isInvoiceElegibleForArchive,
  isV2Invoice,
} from '../../../helpers/invoices';
import { USER_STATUS } from '../../../helpers/userStatus';
import SetupPayoutBanner from '../../SetupPayoutBanner';
import { ShowErrors } from '../../Messages';
import { showSetupPayoutRibbon } from '../../../helpers/stripe';
import { useOrganization } from '../../../hooks/useOrganizations';

import SetupPayoutRequirements from '../SetupPayoutRequirements';
import EnablePaymentsModal from './EnablePaymentsModal';
import InvoicesAtGlance from '../FinanceAtGlance/InvoicesAtGlance';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

export const INVOICE_TYPE = 'all';
const SLIDER_WIDTH = '80%';

const SlidingInvoiceDetail = withSlidingPanel(InvoiceDetailContainer, {
  width: SLIDER_WIDTH,
});

const selectCurrentOrganization = createSelector(
  [(state) => state.organizations],
  (organizations) => organizations?.currentOrganization
);

const PageFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const { invoiceStatus, dueDateRange, hideArchived } = filters;

  const statusOptions = [
    { key: InvoiceStatus.DRAFT, value: InvoiceStatus.DRAFT, text: t('New') },
    {
      key: USER_STATUS.SCHEDULED,
      value: USER_STATUS.SCHEDULED,
      text: t('status.scheduled'),
    },
    {
      key: InvoiceStatus.OPEN,
      value: InvoiceStatus.OPEN,
      text: t('Invoice Sent'),
    },
    {
      key: USER_STATUS.NOTIFICATION_SENT,
      value: USER_STATUS.NOTIFICATION_SENT,
      text: t('status.notificationSent'),
    },
    {
      key: USER_STATUS.PAST_DUE,
      value: USER_STATUS.PAST_DUE,
      text: t('status.pastDue'),
    },
    {
      key: USER_STATUS.TRANSFERRING,
      value: USER_STATUS.TRANSFERRING,
      text: t('status.transferring'),
    },
    {
      key: InvoiceStatus.PAID,
      value: InvoiceStatus.PAID,
      text: t('status.deposited'),
    },
    {
      key: `${InvoiceStatus.PAID}(O)`,
      value: `${InvoiceStatus.PAID}(O)`,
      text: t('status.paidManually'),
    },
    {
      key: InvoiceStatus.PROCESSING,
      value: InvoiceStatus.PROCESSING,
      text: t('Processing'),
    },
    {
      key: InvoiceStatus.FAILED,
      value: InvoiceStatus.FAILED,
      text: t('Failed'),
    },
    {
      key: USER_STATUS.CHARGE_FAILED,
      value: USER_STATUS.CHARGE_FAILED,
      text: t('status.chargeFailed'),
    },
    {
      key: USER_STATUS.TRANSFER_FAILED,
      value: USER_STATUS.TRANSFER_FAILED,
      text: t('status.transferFailed'),
    },
    {
      key: InvoiceStatus.UNCOLLECTIBLE,
      value: InvoiceStatus.UNCOLLECTIBLE,
      text: t('Uncollectible'),
    },
    { key: InvoiceStatus.VOID, value: InvoiceStatus.VOID, text: t('Void') },
  ];

  const onChange =
    (updateField) =>
    (_, { value }) => {
      setFilters({ ...filters, [updateField]: value });
    };

  const onInputChange = (updateField) => (e) => {
    setFilters({ ...filters, [updateField]: e.currentTarget.value });
  };

  return (
    <>
      <div className="invoice-filter-row">
        <Dropdown
          data-testid="invoice-status-filter"
          placeholder={t('All Statuses')}
          selection
          clearable
          value={invoiceStatus}
          onChange={onChange('invoiceStatus')}
          options={statusOptions}
        />
        <Input
          data-testid="invoice-description-filter"
          placeholder={t('Search Description')}
          onChange={onInputChange('descriptionSearch')}
        />
        <DateRangePicker
          data-testid="invoice-due-date-filter"
          placeholder={t('All Due Dates')}
          value={dueDateRange}
          clearable
          allowSameEndDate
          onChange={onChange('dueDateRange')}
          className="date-range-picker"
          closeOnMouseLeave={false}
        />
        <Input
          data-testid="invoice-amount-filter"
          placeholder={t('Search Amount')}
          onChange={onInputChange('amountSearch')}
          className="filter-input"
        />
        <Input
          data-testid="invoice-student-filter"
          placeholder={t('Search Student/Parent')}
          icon="search"
          iconPosition="left"
          onChange={onInputChange('studentSearch')}
        />
      </div>
      <div className="invoice-archive-row">
        <Checkbox
          data-testid="invoice-hide-archived-filter"
          label={t('invoiceList.hideArchiveInvoices')}
          onChange={(e, data) => setFilters({ ...filters, hideArchived: data.checked })}
          checked={hideArchived}
        />
      </div>
    </>
  );
};

function InvoicesList() {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [isExportLoading, setIsExportLoading] = React.useState(false);
  const csvDownloadColumns = [
    'Id',
    'Students',
    'Parent',
    'Description',
    'Due Date',
    'Invoice Date',
    'Amount',
    'Status',
  ];
  const [filters, setFilters] = React.useState({
    invoiceStatus: '',
    dueDateRange: '',
    descriptionSearch: '',
    amountSearch: '',
    studentSearch: '',
    hideArchived: true,
  });
  const [selected, setSelected] = React.useState([]);
  const [isArchiveButtonEnabled, setIsArchiveButtonEnabled] = React.useState(false);

  const invoices = useInvoices(filters);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const invoiceType = invoices.invoiceType || INVOICE_TYPE;
  const invoicesList = invoices[invoiceType];

  const isEmpty = !!invoices?.isLoaded && invoicesList && !invoicesList.count;
  const invoiceListTable = React.useRef();

  const accountRequirements = currentOrganization?.stripe?.onboardingRequirements;
  const arePaymentsEnabled = currentOrganization?.stripe?.paymentsEnabled;

  const organization = useOrganization();
  const { isFinanceAtAGlanceEnabled } = useFlags();

  return (
    <>
      <Header as="h1" className="invoice-header">
        <span data-testid="all-invoices">{t('All Invoices')}</span>
        <AddButton
          options={{ disabled: !arePaymentsEnabled || !didStripeOnboardingSucceed(organization) }}
          floated="right"
        />
      </Header>
      <Grid container>
        {isFinanceAtAGlanceEnabled && !isEmpty && !showSetupPayoutRibbon(organization) && <InvoicesAtGlance />}
        {arePaymentsEnabled ? <PageFilters filters={filters} setFilters={setFilters} /> : null}
      </Grid>
      <SectionCard header={renderInvoiceListHeader()} className={!arePaymentsEnabled ? 'no-shadow' : ''}>
        {arePaymentsEnabled ? null : <EnablePaymentsModal />}
        <SetupPayoutRequirements
          organizationId={currentOrganization?.id}
          accountRequirements={accountRequirements}
          onboardingStatus={currentOrganization?.stripe?.onboardingStatus}
          onError={(error) => setErrorMessage(error)}
        />
        <SetupPayoutBanner onError={(error) => setErrorMessage(error)} />
        {errorMessage && <ShowErrors content={errorMessage} />}
        {!invoices.isLoaded && <LoadingIndicator />}
        {isEmpty && renderNoDataMessage()}
        {!isEmpty && invoices.isLoaded && renderInvoicesList()}
      </SectionCard>
    </>
  );

  function renderInvoiceListHeader() {
    return (
      <>
        <Button
          data-testid="csv-download-invoices-button"
          disabled={invoicesList.list?.length === 0 || isExportLoading}
          basic
          primary
          size="small"
          floated="right"
          className="archive-button"
          onClick={onClick_CSVDownloadButton}
        >
          {isExportLoading && <LoadingIndicator />}
          <Icon name="download" data-testid="csv-download-invoices-icon" /> {t('Download')}
        </Button>
        <Button
          data-testid="archive-invoices-button"
          disabled={!isArchiveButtonEnabled}
          basic
          primary
          size="small"
          floated="right"
          className="archive-button"
          onClick={onClick_ArchiveButton}
        >
          <Icon name="archive" data-testid="archive-invoices-icon" /> {t('invoiceList.archiveButton.text')}
        </Button>
      </>
    );
  }

  function renderInvoicesList() {
    const rowEvents = {
      onClick: (e, row, _rowIndex) => {
        e.preventDefault();
        dispatch(invoiceSelected(row, invoiceType));
      },
    };

    const selectRow = {
      mode: 'checkbox', // multi select
      onSelect: (row, isSelected) => {
        if (isSelected) {
          const isSelectedApproved = isInvoiceElegibleForArchive(row);

          if (isSelectedApproved) {
            setIsArchiveButtonEnabled(true);
            setSelected([...selected, row]);
          }

          return isSelectedApproved;
        } else {
          const remainingSelected = selected.filter((invoice) => invoice.id !== row.id);
          setSelected(remainingSelected);
          setIsArchiveButtonEnabled(remainingSelected.length > 0);
        }
      },
      onSelectAll: (isSelected, rows) => {
        if (isSelected) {
          // selected all approved selected
          const approvedInvoicesSelected = rows.filter((invoice) => isInvoiceElegibleForArchive(invoice));
          setSelected(approvedInvoicesSelected);
          setIsArchiveButtonEnabled(approvedInvoicesSelected.length > 0);
          return approvedInvoicesSelected.map((invoice) => invoice.id);
        } else {
          // unselect all
          setSelected([]);
          setIsArchiveButtonEnabled(false);
        }
      },
      nonSelectable: invoicesList.list
        .filter((invoice) => !isInvoiceElegibleForArchive(invoice))
        .map((invoice) => invoice.id),
    };

    return (
      <>
        <AlertMessage alert={alertMessage} setAlert={setAlertMessage} />
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={invoicesList.list}
          columns={getTableColumns(t, currentOrganization, setAlertMessage)}
        >
          {(props) => (
            <div className="bootstrap-iso">
              <BootstrapTable
                ref={invoiceListTable}
                headerClasses={'table-header'}
                classes="w-auto w-md-100"
                wrapperClasses="table-responsive"
                bordered={false}
                hover
                rowClasses={styles.bstRow}
                rowEvents={rowEvents}
                selectRow={selectRow}
                defaultSorted={[
                  {
                    dataField: 'dateDue',
                    order: 'desc',
                  },
                ]}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
        {renderInvoiceDetail()}
      </>
    );
  }

  function renderInvoiceDetail() {
    const selectedInvoice = invoicesList.selected;
    const displayName = selectedInvoice?.student?.displayName || '';
    const invoiceId = selectedInvoice?.id || '';

    return (
      <SlidingInvoiceDetail
        title={t('Invoice for {{displayName}}', { displayName })}
        subTitle={t('Invoice # {{invoiceId}}', { invoiceId })}
        invoiceType={invoiceType}
        isOpen={!!selectedInvoice}
        onClose={(id) => {
          if (id) refreshInvoice(currentOrganization, id);
          dispatch(invoiceSelected(null, invoiceType));
        }}
      />
    );
  }
  function refreshInvoice(_currentOrganization, _invoiceId) {
    //    refreshOrganizationInvoice(currentOrganization.id, invoiceId, INVOICE_TYPE);
  }
  function renderNoDataMessage() {
    return <NoDataMessageBilling title={t('invoiceList.emptyTitle')} subTitle={t('invoiceList.emptySubtitle')} />;
  }
  function AddButton({ options = {} }) {
    return (
      <div className="add-button-tooltip">
        <Popup
          on="hover"
          disabled={arePaymentsEnabled && didStripeOnboardingSucceed(organization)}
          content={t('invoiceList.connectPayoutAccount')}
          position="top center"
          trigger={
            <span>
              <Button
                data-testid="add-invoice-button"
                className="add-button"
                primary
                as={Link}
                to={routes.billing.invoices.edit}
                size="large"
                {...options}
              >
                {t('Create New Invoice')}
              </Button>
            </span>
          }
        />
      </div>
    );
  }
  async function onClick_ArchiveButton() {
    invoiceListTable.current.selectionContext.selected = [];
    await markInvoicesAsArchive(currentOrganization.id, selected);
    setSelected([]);
    setIsArchiveButtonEnabled(false);
  }

  function onClick_CSVDownloadButton() {
    setIsExportLoading(true);
    const formattedList = invoicesList.list.map((invoice) => {
      const invoiceItemDescription = isV2Invoice(invoice)
        ? invoice.itemsDescription
        : invoice.invoiceItemList.map((c) => t(c.item)).join(', ');

      const { text: statusText } = invoiceStatusFormatter(invoice, t);

      return {
        invoiceId: invoice.id,
        student: invoice.student.displayName,
        parent: getParentName(invoice),
        description: t(invoiceItemDescription),
        dateDue: moment(invoice.dateDue).format(DefaultDateFormat),
        invoiceDate: moment(invoice.createdAt).format(DefaultDateFormat),
        total: currencyFormatter(invoice.total, { precision: 2 }),
        status: t(statusText),
      };
    });

    //sort formattedList by datedue
    formattedList.sort((x, y) => new Date(y.dateDue) - new Date(x.dateDue));

    try {
      const csvData = convertListToCSV(formattedList, csvDownloadColumns, t);
      const url = window.URL.createObjectURL(new Blob([csvData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${currentOrganization.name}-invoices.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExportLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsExportLoading(false);
    }
  }
}

export function getParentName(invoice) {
  if (isV2Invoice(invoice)) {
    return invoice.billedTo?.displayName || '';
  } else {
    return invoice.student?.responsibleForBilling
      ? invoice.student?.family[invoice.student?.responsibleForBilling.id]?.displayName || ''
      : '';
  }
}

// Note: any changes here might also need to be applied to the invoices table in the StudentInvoicesTable.js
function getTableColumns(t, currentOrganization, setAlertMessage) {
  const columns = [
    {
      dataField: 'student.displayName',
      text: t('Students'),
      headerFormatter: (column, _, { sortElement }) => (
        <div>
          <span data-testid="student-column">{column.text}</span>
          <span data-testid="student-sort-column">{sortElement}</span>
        </div>
      ),
      sort: true,
      formatter: (cell, invoice, rowIndex) => {
        return (
          <>
            {isV2Invoice(invoice) ? (
              <Label data-testid={`v2-label-${rowIndex}`} className="v2-label" size="mini">
                v2
              </Label>
            ) : null}
            <div data-testid={`student-column-value-${rowIndex}`}>{cell}</div>
            {invoice.isArchived ? (
              <div data-testid={`invoice-archived-label-${rowIndex}`} className="invoice-archived-label">
                {t('invoiceList.archived.text')}
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      dataField: 'invoiceItemList',
      text: t('Description'),
      headerFormatter: (column) => (
        <div>
          <span data-testid="description-column">{column.text}</span>
        </div>
      ),
      formatter: (cell, invoice, rowIndex) => {
        if (isV2Invoice(invoice)) {
          return <span data-testid={`description-column-value-${rowIndex}`}>{invoice.itemsDescription}</span>;
        } else {
          return (
            <span data-testid={`description-column-value-${rowIndex}`}>
              {cell
                .map((c) => {
                  return t(c.item);
                })
                .join(', ')}
            </span>
          );
        }
      },
    },
    {
      dataField: 'isInvoice',
      text: '',
      width: 2,
      classes: 'text-center',
      formatter: (cell, _, rowIndex) =>
        !cell ? (
          <Popup
            trigger={<Icon data-testid={`recurring-plan-icon-${rowIndex}`} name="sync" />}
            content={<span data-testid={`recurring-plan-tooltip-${rowIndex}`}>{t('Recurring plan')}</span>}
            offset={[-15, 0]}
          />
        ) : (
          ''
        ),
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'dateDue',
      text: t('Due Date'),
      headerFormatter: (column, _, { sortElement }) => (
        <div>
          <span data-testid="due-date-column">{column.text}</span>
          <span data-testid="due-date-sort-column">{sortElement}</span>
        </div>
      ),
      sort: true,
      formatter: (cell, row, rowIndex) => (
        <span data-testid={`due-date-column-value-${rowIndex}`}>
          <DateTime epoch={row.dateDue} format={DefaultDateFormat} />
        </span>
      ),
    },
    {
      dataField: 'total',
      text: t('Amount'),
      headerFormatter: (column, _, { sortElement }) => (
        <div>
          <span data-testid="amount-column">{column.text}</span>
          <span data-testid="amount-sort-column">{sortElement}</span>
        </div>
      ),
      sort: true,
      formatter: (cell, _, rowIndex) => (
        <span data-testid={`amount-column-value-${rowIndex}`}>{currencyFormatter(cell, { precision: 2 })}</span>
      ),
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'status',
      text: t('Status'),
      headerFormatter: (column, _, { sortElement }) => (
        <div>
          <span data-testid="status-column">{column.text}</span>
          <span data-testid="status-sort-column">{sortElement}</span>
        </div>
      ),
      sort: true,
      formatter: (cell, invoice, rowIndex) => (
        <span data-testid={`status-column-value-${rowIndex}`}>
          <InvoiceStatusComponent invoice={invoice} withTooltip />
        </span>
      ),
    },
  ];

  if (isLocal()) {
    columns.push({
      isDummyField: true,
      dataField: '',
      text: '',
      classes: 'text-center align-middle',
      formatter: (cell, row) => {
        return (
          <Button
            basic
            primary
            size="mini"
            content={t('Resend')}
            onClick={(event) => onResendClick(event, row, setAlertMessage)}
          />
        );
      },
    });
  }
  return columns;
}

async function onResendClick(event, invoice, setAlertMessage) {
  try {
    event.stopPropagation();
    await resendInvoiceToStripe(invoice);
    setAlertMessage({
      type: 'success',
      message: 'Invoice successfully resent',
    });
  } catch (error) {
    console.error(error);
    setAlertMessage({
      type: 'error',
      message: 'Something went wrong, please try again',
    });
  }
}

export default InvoicesList;
