import { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

class FormInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
  };

  shouldComponentUpdate(nextProps, _nextState) {
    if (this.props.value !== nextProps.value) {
      return true;
    }

    return false;
  }

  render() {
    return <Form.Input {...this.props} />;
  }
}

export default FormInput;
