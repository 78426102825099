import { useTranslation } from 'react-i18next';
import { ViewComponentProps } from '../../../common';
import { DocumentType } from '../../types';
import { SummaryViewTitle } from '../widgets';

type DocumentsViewProps = ViewComponentProps & {
  documents: DocumentType[];
};

const DocumentsView: React.FC<DocumentsViewProps> = ({ title, documents, hasTitle = true }) => {
  const { t } = useTranslation();
  const viewTitle = hasTitle ? t(title || 'enrollments.documentsViewTitle') : '';

  return (
    <div className="documents-view" data-testid="documents-view">
      <SummaryViewTitle title={viewTitle} />
      <div className="documents">
        {documents.map((document) => (
          <div key={document.id} className="document">
            <div className="document-name">{document.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DocumentsView;
