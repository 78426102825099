import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Header, Segment, Icon, Label } from 'semantic-ui-react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { useOrganizationInvoicesListener } from '../../hooks/useOrganizations';
import { InvoiceStatus as InvoiceStatusComponent } from '../../Components/Billing/Invoices';
import styles from './students.module.scss';
import { LoadingIndicator } from '../../Components/Shared/BusyIndicator';
import { currencyFormatter } from '../../helpers/utils';
import { getProcessingFeeAmount, isV2Invoice } from '../../helpers/invoices';
import { useOrganization } from '../../hooks/useOrganizations';
import { useInvoices } from '../../hooks/useInvoices';
import { useSelectedStudent } from '../studentsHooks';
import DateTime from '../../Components/DateTime/DateTime';

const LoadingIndicatorStyle = {
  marginTop: '28px',
};

function StudentInvoicesTable() {
  const { t } = useTranslation();
  const currentOrganization = useOrganization();
  const selectedStudent = useSelectedStudent();
  const invoices = useInvoices();

  useOrganizationInvoicesListener(currentOrganization.id);

  if (!invoices.isLoaded) return renderLoading();

  const filteredInvoices = invoices.all.list.filter((invoice) => invoice.student.id === selectedStudent.id);

  return filteredInvoices.length ? renderTable({ t, currentOrganization, filteredInvoices }) : renderNoData({ t });
}

const renderTable = ({ t, currentOrganization, filteredInvoices }) => {
  const rowEvents = {
    onClick: (e, _row, _rowIndex) => {
      e.preventDefault();
      // do nothing for now
    },
  };

  return (
    <ToolkitProvider bootstrap4 keyField="id" data={filteredInvoices} columns={getTableColumns(t, currentOrganization)}>
      {(props) => (
        <div className="bootstrap-iso">
          <BootstrapTable
            classes="w-auto w-md-100"
            wrapperClasses="table-responsive"
            headerClasses={styles.invoicesListRow}
            bordered={false}
            rowEvents={rowEvents}
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};

const renderLoading = () => {
  return (
    <Segment basic clearing textAlign="center" className="no-shadow no-border top-border">
      <LoadingIndicator style={LoadingIndicatorStyle} />
    </Segment>
  );
};

const renderNoData = ({ t }) => {
  return (
    <Segment basic clearing textAlign="center" className="no-shadow no-border top-border">
      <Header as={'h3'}>
        <Icon circular inverted name="info" color="grey" />
      </Header>
      <Header as={'h4'}>{t('No invoices found')}</Header>
    </Segment>
  );
};

// Note: any changes here might also need to be applied to the invoices table in InvoicesList.js
const getTableColumns = (t) => {
  const columns = [
    {
      dataField: 'invoiceItemList',
      text: t('Description'),
      formatter: (cell, invoice) => {
        return (
          <>
            {isV2Invoice(invoice)
              ? invoice.itemsDescription
              : cell
                  .map((c) => {
                    return t(c.item);
                  })
                  .join(', ')}
            {isV2Invoice(invoice) && (
              <Label className="v2-label" size="mini">
                v2
              </Label>
            )}
          </>
        );
      },
    },
    {
      dataField: 'isInvoice',
      text: '',
      width: 2,
      classes: 'text-center',
      formatter: (cell) => (!cell ? <Icon name="sync" /> : ''),
      headerStyle: () => ({ width: '5%' }),
    },
    {
      dataField: 'dateDue',
      text: t('Due Date'),
      sort: true,
      formatter: (cell, row) => <DateTime epoch={row.dateDue} format={'MM/DD/YYYY'} />,
    },
    {
      dataField: 'total',
      text: t('Amount'),
      sort: true,
      formatter: (cell) => currencyFormatter(cell, { precision: 2 }),
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'paidAmount',
      text: t('Balance Due'),
      sort: true,
      sortValue: (cell, row) => row.total - cell,
      formatter: (cell, row) => {
        const ProcessingFeeAmount = getProcessingFeeAmount(row);
        return (
          <>
            <span>{currencyFormatter(row.total + ProcessingFeeAmount - (cell ?? 0), { precision: 2 })}</span>
          </>
        );
      },
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, invoice) => <InvoiceStatusComponent invoice={invoice} />,
    },
  ];

  return columns;
};

const mapStateToProps = (state) => {
  return {
    currentOrganization: state.organizations.currentOrganization,
    invoices: state.invoices,
  };
};

export default connect(mapStateToProps)(StudentInvoicesTable);
