import { Segment } from 'semantic-ui-react';

import PageHeader from '../../Components/Shared/PageHeader';
import TransactionsList from './TransactionsList';

import { useSegmentPage, PAGE_NAMES } from '../../segment';
import { useOrganization } from '../../hooks/useOrganizations';

export default function TransactionsPage() {
  const organization = useOrganization();
  useSegmentPage(PAGE_NAMES.transactions);

  return (
    <>
      <Segment basic>
        <PageHeader pageName={PAGE_NAMES.transactions} classes="transactions" />
        <TransactionsList organization={organization} />
      </Segment>
    </>
  );
}
