import { useEffect, useState } from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { routes } from '../config/routes';
import {
  invoiceEditDetails,
  invoiceEditItems,
  invoiceEditStudents,
  invoiceEditSelectionCleared,
} from '../redux/actions/billingActions';
import { useInvoiceEditSelected, useInvoiceEditDetails } from '../hooks/useInvoiceEdit';
import { useChargesListener } from '../hooks/useCharges';
import { useOrganization } from '../hooks/useOrganizations';
import { useSegmentPage, PAGE_NAMES } from '../segment';

import { saveOrganizationInvoicePlan } from '../redux/actions/invoiceActions';

import PageHeader from '../Components/Shared/PageHeader';
import PageTitle from '../Components/Shared/PageTitle';

import StepInvoiceItemList from '../Components/Forms/Billing/StepInvoiceItemList';
import StepInvoicePlanDetails from '../Components/Forms/Billing/StepInvoicePlanDetails';
import StepStudents from '../Components/Forms/Billing/StepStudents';
import StepConfirmAndSave from '../Components/Forms/Billing/StepConfirmAndSave';

import '../Components/Forms/Billing/Billing.scss';

export default function InvoicePlanEdit() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const organization = useOrganization();
  const invoicePlan = useInvoiceEditSelected();
  const details = useInvoiceEditDetails();
  useSegmentPage(PAGE_NAMES.invoicePlanEdit);
  useChargesListener(organization.id);

  const [invoicePlanSaved, setInvoicePlanSaved] = useState(false);
  const isEdit = !!invoicePlan;
  const title = isEdit ? t('Edit Recurring Plan') : t('Create Recurring Plan');

  if (!details?.id && isEdit) destructureInvoicePlanAndWriteToRedux(invoicePlan);

  let currentHash = location.hash || routes.billing.hashes.details;

  useEffect(() => {
    if (invoicePlanSaved) {
      history.push(routes.billing.plans.list);
    }
  }, [invoicePlanSaved, history]);

  return (
    <Segment basic>
      <PageHeader pageName={'Billing'} classes="billing" />
      <PageTitle title={title} />

      <Form autoComplete="off">
        {currentHash === routes.billing.hashes.details && (
          <StepInvoicePlanDetails onSave={onSaveDetails} isEdit={isEdit} />
        )}

        {currentHash === routes.billing.hashes.items && (
          <StepInvoiceItemList onSave={onSaveInvoiceItems} isInvoice={false} />
        )}

        {currentHash === routes.billing.hashes.students && <StepStudents onSave={onSaveStudents} isInvoice={false} />}

        {currentHash === routes.billing.hashes.confirm && (
          <StepConfirmAndSave onSave={onSaveInvoicePlan} isInvoice={false} />
        )}
      </Form>
    </Segment>
  );

  function destructureInvoicePlanAndWriteToRedux(invoicePlan) {
    const { students, invoiceItemList, ...details } = invoicePlan;
    const editableDetails = {
      ...details,
      dateStart: details.dateStart,
      dateEnd: details.dateEnd,
      dateDue: details.dateDue || details.startDate,
      dateSend: details.dateSend,
      oldBillingInterval: details.billingInterval,
    };
    onSaveDetails(editableDetails);
    onSaveInvoiceItems([...invoiceItemList]);
    onSaveStudents(students);
  }
  function onSaveDetails(details) {
    dispatch(invoiceEditDetails(details));
  }
  function onSaveInvoiceItems(invoiceItems) {
    dispatch(invoiceEditItems(invoiceItems));
  }
  function onSaveStudents(students) {
    dispatch(invoiceEditStudents(students));
  }

  function onSaveInvoicePlan(invoicePlan) {
    dispatch(saveOrganizationInvoicePlan(organization.id, invoicePlan));
    dispatch(invoiceEditSelectionCleared());
    setInvoicePlanSaved(true);
  }
}
