import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Form } from 'semantic-ui-react';

// Import styles
import './RoomPicker.module.scss'; // for globals/children overrides
import styles from './RoomPicker.module.scss';

const selectRoomsList = createSelector([(state) => state.rooms], (rooms) => rooms?.list ?? []);

const RoomPicker = ({
  className = '', // needed to add defaults because typescript complains
  location = undefined,
  dependent = false,
  needToAddAllRooms = false,
  returnAllRooms = false,
  customOptions = [],
  ...rest
}) => {
  let rooms = useSelector(selectRoomsList);

  const options = useMemo(() => {
    if (!rooms?.length) return [];

    // Not sure why this is important, copied from legacy RoomPicker.js
    if (dependent && !location?.length) return [];

    let allRooms = rooms;

    // If location is set, filter rooms by location
    if (location?.length) {
      allRooms = allRooms.filter((room) => room?.location === location);
    }

    // Build dropdown options. { value, text }
    allRooms = allRooms?.map((room) => ({
      key: room.id,
      text: room.name,
      value: room.id,
    }));

    // Add custom options
    allRooms = [...allRooms, ...customOptions];

    return allRooms;
  }, [dependent, location, customOptions, rooms]);

  useEffect(() => {
    if (needToAddAllRooms) {
      returnAllRooms(options);
    }
  }, [needToAddAllRooms, options, returnAllRooms]);

  return (
    <Form.Dropdown
      clearable
      options={options}
      selectOnBlur={false}
      renderLabel={(label) => ({
        color: 'yellow',
        content: label.text,
      })}
      className={[styles.overflowFix, className].join(' ')}
      style={{ zIndex: 20, width: '100%' }}
      {...rest}
      data-testid="dropdown-room-picker"
    />
  );
};

export default RoomPicker;
