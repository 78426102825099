import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { useOrganization } from '../../../hooks/useOrganizations';
import { ViewComponentProps } from '../../../common';

const InvitationMessageView: React.FC<ViewComponentProps> = ({ title, hasTitle = true }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const viewTitle = hasTitle ? t(title || 'enrollments.invitationMessageViewTitle') : '';

  return (
    <div className="invitation-message-view">
      <h2>{viewTitle}</h2>
      <div className="invitation-message">
        <p>Hi [Recipient name],</p>
        <p>We are excited to welcome you to {organization.name}</p>
        <p>
          We just sent you an invitation to join Wonderschool and create an account and view the documents we shared
          with you.
        </p>
        <p>
          <Button disabled>Get started and view documents</Button>
        </p>
        <p>
          <strong>What is Wonderschool?</strong>
        </p>
        <p>
          Wonderschool connects parents with childcare providers which allows you to record your childs activities, do
          touchless check in/check out, post photos and videos, and many more things.
        </p>
        <p>Please follow the link below to get started and set your password.</p>
        <p>
          <Button disabled>Get started and view documents</Button>
        </p>
        <p>After you set your password, download the app on your phone and you are all set!</p>
      </div>
    </div>
  );
};
export default InvitationMessageView;
