import { DayEnum } from './enums';

// Map legacy days to DayEnum days
export const LEGACY_TO_DAY_ENUM_MAP: Record<string, DayEnum> = {
  M: DayEnum.MONDAY,
  T: DayEnum.TUESDAY,
  W: DayEnum.WEDNESDAY,
  Th: DayEnum.THURSDAY,
  F: DayEnum.FRIDAY,
  S: DayEnum.SATURDAY,
  Su: DayEnum.SUNDAY,
};

export const DAY_ENUM_TO_LEGACY_MAP: Record<DayEnum, string> = {
  [DayEnum.MONDAY]: 'M',
  [DayEnum.TUESDAY]: 'T',
  [DayEnum.WEDNESDAY]: 'W',
  [DayEnum.THURSDAY]: 'Th',
  [DayEnum.FRIDAY]: 'F',
  [DayEnum.SATURDAY]: 'S',
  [DayEnum.SUNDAY]: 'Su',
};
