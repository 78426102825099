import { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function SendInvitationConfirmationDialog({ onYes, onClose, contact }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  if (!onYes || !onClose) throw new Error('onYes and onClose are required');

  return (
    <Modal open={!!contact} onClose={onClose} size="tiny" data-testid="send-invite-modal">
      <Modal.Header>{t('Send Invitation')}</Modal.Header>
      <Modal.Content>
        <p>
          {t('Are you sure you want to send an invitation to {{displayName}}', {
            displayName: contact?.displayName,
          })}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button loading={isLoading} negative onClick={onClose} content={t('No')} data-testid="send-invite-no-btn" />
        <Button
          loading={isLoading}
          positive
          icon="checkmark"
          labelPosition="right"
          content={t('Yes')}
          onClick={onYesLocal}
          data-testid="send-invite-yes-btn"
        />
      </Modal.Actions>
    </Modal>
  );

  async function onYesLocal(e) {
    if (e) e.preventDefault();

    try {
      setIsLoading(true);
      await onYes(contact);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
}
