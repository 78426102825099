import { useMemo } from 'react';
import { ClaimCreatePage, FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { useAuthUser } from '../../../hooks/useUser';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';
import { getCurrentEnvironment } from '../../../common/utils/environmentUtils';

export const ClaimCreate = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.claims.create');
  const { gotoRouteByName } = useRoutes();
  const { token } = useAuthUser();
  useSegmentPage(PAGE_NAMES.foodProgramClaimCreate);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  const handleGoBack = () => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIMS);
  };

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <ClaimCreatePage onClickBack={handleGoBack} onSubmit={handleGoBack} />
    </FoodProgramQueryProvider>
  );
};
export default ClaimCreate;
