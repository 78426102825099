import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { firestore } from '../../api/firebase';
// import moment from 'moment';

// Import components
import { Activity } from '@wonderschool/ws-types';
import NoDataMessage from '../Shared/NoDataMessage';
import noRecentPhotosImage from '../../styles/img/mirage/no-recent-photos.svg';

// const startTime = moment().subtract(7, 'days').startOf('day').utc().valueOf();
// const endTime = moment().endOf('day').utc().valueOf();

const PhotoGallery = ({ currentOrganization: { id = null } }) => {
  const { t } = useTranslation();

  const [photos, setPhotos] = useState<string[]>([]);
  const [called, setCalled] = useState(false);

  const fetchActivities = useCallback(async () => {
    if (!id || called) {
      return;
    }

    const snapshot = await firestore()
      .collection(`organizations/${id}/activities`)
      .where('type', '==', 'photo')
      .orderBy('activityTime', 'desc')
      .limit(14)
      .get();

    const activities = snapshot.docs.map((doc) => doc.data() as Activity);
    const photoUrls = activities.map((activity) => {
      const photoData = activity.photo?.data as { base64?: string; uri?: string };
      return photoData.base64 ? `data:image/gif;base64,${photoData.base64}` : photoData.uri!;
    });

    setPhotos(photoUrls);
    setCalled(true);
  }, [called, id]);

  useEffect(() => {
    if (!id || called) {
      return;
    }

    fetchActivities();
  }, [called, fetchActivities, id]);

  return (
    <div className="m-auto flex w-full flex-wrap gap-4">
      {photos.length > 0 ? (
        photos.map((photo, idx) => {
          return <img key={idx} className="h-full max-h-48 w-full max-w-32 rounded-md" src={photo} alt="photo" />;
        })
      ) : (
        <div className="h-full w-full">
          <NoDataMessage
            message={t('Oh no! There have not been any photos added in the last week.')}
            sub={t('Adding photos is the #1 way to engage parents.')}
            image={noRecentPhotosImage}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms.list,
});

export default connect(mapStateToProps)(PhotoGallery);
