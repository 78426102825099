import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { WsCheckSuccessCircle, WsCrossCircle } from '../../icons';

const LocationCard = ({ location, onClick }) => {
  const { t } = useTranslation();

  return (
    <Card className="shadow" key={location.id} onClick={onClick}>
      <Card.Content>
        <Avatar name={location.name} round size={40} className="right floated" />
        <Card.Header>
          <Header as={'h3'}>{location.name}</Header>
        </Card.Header>
        <Card.Meta>{location.phone}</Card.Meta>
        <Card.Description>
          <p>{`${location.address1 || ''} ${location.address2 || ''}`}</p>
          <p>{`${location.city || ''}, ${location.state || ''} ${location.zipcode || ''}`}</p>
        </Card.Description>
      </Card.Content>

      <Card.Content extra className="status-style">
        <span className={location.enabled !== false ? 'successTag tag' : 'tag'}>
          {location.enabled !== false ? <WsCheckSuccessCircle /> : <WsCrossCircle />}
          {location.enabled !== false ? t('Enabled') : t('Disabled')}
        </span>
      </Card.Content>
    </Card>
  );
};

LocationCard.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LocationCard;
