import { isEmpty } from 'lodash';
import { firebaseConfig, wsConfig } from '../api/firebase/config';

const ENV_KEY = 'envName';

const LOCAL = 'local';
const DEV = 'dev';
const STAGING = 'staging';
const PROD = 'prod';

/**
 * Tries to detect the environment from config
 * @returns {'local' | 'dev' | 'staging' | 'prod' | undefined}
 */
const getEnvFromConfig = () => {
  return wsConfig?.[ENV_KEY] || undefined;
};

let savedEnv;
/**
 * Either uses a memoized env value,
 * the config-specified env value,
 * or the detected env value
 * @returns {'local' | 'dev' | 'staging' | 'prod'}
 * If not a proper env, fails fast and loud and throws an error
 */
const getEnv = () => {
  const wasEnvSet = !!savedEnv;
  savedEnv = savedEnv || getEnvFromConfig();
  if (!wasEnvSet) {
    if (!isTest()) {
      if (isLocal()) {
        console.log('💻 LOCAL environment 💻');
      } else if (isDev()) {
        console.log('🪵 DEV environment 🪵');
      } else if (isStaging()) {
        console.log('🌲 STAGING environment 🌲');
      }
    }
  }
  if (!savedEnv || ![LOCAL, DEV, STAGING, PROD].includes(savedEnv)) {
    throw new Error('Invalid env');
  }
  return savedEnv;
};

export const getTitlePrefix = (env = getEnv()) => {
  if (isLocal(env)) {
    return '💻 ';
  } else if (isDev(env)) {
    return '🪵 ';
  } else if (isStaging(env)) {
    return '🌲 ';
  } else {
    return '';
  }
};

/**
 * gets the hosted domain name for this app
 * @returns {string}
 */
const getDomainName = () => {
  if (getEnv() === LOCAL) {
    return '//127.0.0.1:3000';
  } else {
    return process.env.REACT_APP_WS_CCMS_APP_URL;
  }
};

const getConfig = () => {
  return {
    firebaseConfig,
    wsConfig: {
      ...wsConfig,
      domainName: getDomainName(),
    },
  };
};

/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isLocal = (env = getEnv()) => env === LOCAL;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isDev = (env = getEnv()) => env === DEV;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isStaging = (env = getEnv()) => env === STAGING;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isProd = (env = getEnv()) => env === PROD;
/**
 * @param {string} env env name
 * @returns {boolean}
 */
const isTest = (env = getEnv()) =>
  (isLocal(env) && isEmpty(process.env.REACT_APP_GIT_SHA)) || !!process.env.REACT_APP_IS_TEST;

/**
 * whether the emulation env variable has been set
 * @returns {boolean}
 */
const shouldEmulateFirebase = () => process.env.REACT_APP_FIREBASE_EMULATOR === 'ACTIVE';

// this logger needs to be independent of rollbar logger to prevent ciruclar reference
const setupLogger = (...args) => {
  if (isLocal() && !isTest()) {
    console.log(...args);
  }
};

export {
  getEnv,
  getConfig,
  getDomainName,
  isLocal,
  isDev,
  isStaging,
  isProd,
  isTest,
  shouldEmulateFirebase,
  setupLogger,
};
