import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Step from './Step';
import { resetPaymentData } from '../../../../../redux/actions/billingActions';
import { routes } from '../../../../../config/routes';

import './Steps.scss';

class Steps extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    loading: PropTypes.bool,
    isInvoice: PropTypes.bool,
  };

  state = {
    steps: [
      {
        link: routes.billing.invoices.edit,
        label: this.props.t('Details'),
        step: 1,
      },
      {
        link: routes.billing.select,
        label: this.props.t('Select'),
        step: 2,
      },
      {
        link: routes.billing.confirm,
        label: this.props.t('Confirm'),
        step: 3,
      },
    ],
    activeStep: 1,
    isConfirm: false,
  };

  componentDidMount() {
    let activeStep = this.state.steps.filter((step) => step && step.link === this.props.match.path);

    activeStep = activeStep && activeStep.length ? activeStep[0].step : '';

    this.setState({
      activeStep,
      isConfirm: activeStep === 3,
    });
  }
  getIsInvoice() {
    return this.props.isInvoice ?? this.props.paymentDetails?.isInvoice ?? true;
  }
  render() {
    let { activeStep, steps, isConfirm } = this.state;
    let { t, paymentDetails, paymentEmployees, loading, history, disabled } = this.props;

    let disableBtn = disabled;
    if (isConfirm && (!paymentDetails || !paymentEmployees || !paymentEmployees.length)) {
      disableBtn = true;
    }

    const actionButtonContent = isConfirm
      ? this.getIsInvoice()
        ? t('SendInvoice')
        : t('billing.steps.CreateAPlan')
      : t('Next');

    return (
      <Segment>
        <div className={'step-container'}>
          {steps.map((step, index) => {
            let stepIndex = index + 1;
            let disabled = false;
            if (
              ((!paymentDetails || (paymentDetails && paymentDetails.total < 0)) && stepIndex === 2) ||
              ((!paymentDetails || !paymentEmployees || !paymentEmployees.length) && stepIndex === 3)
            ) {
              disabled = true;
            }
            return (
              <Step
                isActive={stepIndex === activeStep}
                disabled={disabled}
                step={stepIndex}
                label={step.label}
                link={step.link}
                key={`step${index}`}
              />
            );
          })}
        </div>

        <br />
        <Button
          data-testid="create-invoice-next-button"
          disabled={disableBtn}
          loading={loading}
          size="large"
          primary
          fluid
          content={actionButtonContent}
        />

        <br />
        <Button
          data-testid="create-invoice-cancel-button"
          disabled={disableBtn}
          loading={loading}
          size="large"
          basic
          fluid
          content={t('Cancel')}
          onClick={(e) => {
            e.preventDefault();
            this.props.resetPaymentData();

            if (this.getIsInvoice()) history.push(routes.billing.invoices.list);
            else history.push(routes.billing.plans.list);
          }}
        />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentDetails: state.paymentDetails,
    paymentEmployees: state.paymentEmployees,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetPaymentData,
    },
    dispatch
  );
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Steps)));
