import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useFilesAccess } from '@wonderschool/file-service-client';

import { logError } from '../../../rollbar';
import { useOrganizationContactsListener, useOrganization } from '../../../hooks/useOrganizations';

import { useRoutes, RouteNameEnum } from '../../../navigation';
import { useStudent } from '../../../students/studentsHooks';
import { useContacts } from '../../../contacts/contactsHooks';
import { getScheduledDaysAbbreviated } from '../../enrollmentsUtils';

import PageHeader from '../../../Components/Shared/PageHeader';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';

import { useDocuments, useScheduledDays, useTuitionAndFees } from '../../enrollmentsHooks';
import { DocumentsForm, InvitationRecipientsForm, ScheduleForm, TuitionAndFeesForm } from '../forms';
import { saveStudentAndContacts } from '../../enrollmentsAPI';
import { DocumentType, InvitationRecipientType, OnSaveDataType, OnSaveParamsType } from '../../types';
import { createInvitationRecipientsFromFamily } from '../../enrollmentsUtils';

type EnrollmentPageProps = {
  onClick?: () => void;
};

const EnrollmentPage: React.FC<EnrollmentPageProps> = () => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [isWritingData, setIsWritingData] = useState(false);
  const [dataToSave, setDataToSave] = useState<OnSaveDataType>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const params: any = useParams();
  const student = useStudent(params.studentId);
  const { familyContacts, contacts: _contacts } = useContacts();

  const { goBack, gotoRouteByName } = useRoutes();

  const organization = useOrganization();
  useOrganizationContactsListener(organization?.id);

  const documents = useDocuments(student);
  const tuitionAndFees = useTuitionAndFees(student);
  const scheduledDays = useScheduledDays(student);

  const invitationRecipients = useMemo<InvitationRecipientType[]>(() => {
    return student ? createInvitationRecipientsFromFamily(student) : [];
  }, [student]);

  const {
    shareFiles,
    data: _shareData,
    isLoading: _isSharing,
    error: _shareError,
    isError: isError,
    isSuccess: isSuccess,
  } = useFilesAccess();

  const shouldSaveToFirestore = useCallback(() => {
    return isSaving && Object.keys(dataToSave).length === 4 && isEmpty(errors) && !isWritingData;
  }, [isSaving, dataToSave, errors, isWritingData]);

  // If the user entered an email address, add it to the contact and save
  const contactsToSaveToFirestore = useMemo(() => {
    if (!familyContacts || !dataToSave.invitationRecipients) return [];

    const recipients = (dataToSave.invitationRecipients ?? []) as InvitationRecipientType[];
    const changedContacts: any[] = [];

    for (const contact of familyContacts) {
      const recipient = recipients.find((r) => r.contactId === contact.id && r.email !== contact.email);

      if (recipient) {
        changedContacts.push({ id: contact.id, email: recipient.email });
      }
    }
    return changedContacts;
  }, [dataToSave.invitationRecipients, familyContacts]);

  const studentToSaveToFirestore = useMemo(() => {
    const data: any = {
      id: student?.id ?? '',
      enrollment: {
        invitationRecipients: dataToSave.invitationRecipients ?? [],
        documents: dataToSave.documents ?? [],
        tuitionAndFees: dataToSave.tuitionAndFees ?? {},
        scheduledDays: dataToSave.scheduledDays ?? [],
      },
    };
    // This is legacy and should be deprecated
    data.schedule = getScheduledDaysAbbreviated(data);
    return data;
  }, [dataToSave, student?.id]);

  const shareDocuments = useCallback(() => {
    const documents = dataToSave.documents as DocumentType[] | undefined;
    const documentIds = documents?.map((doc: DocumentType) => doc.id) ?? [];
    shareFiles({
      files: documentIds,
      students: [studentToSaveToFirestore.id],
    });
  }, [dataToSave.documents, shareFiles, studentToSaveToFirestore.id]);

  const onSave = useCallback(
    ({ data, errors: errorsParam }: OnSaveParamsType, fieldName: string) => {
      if (!isSaving) return false;

      if (!isEmpty(errorsParam)) {
        setIsSaving(false);
        setErrors((prev) => ({ ...prev, ...errorsParam }));
        setDataToSave({});
      } else {
        setDataToSave((prev) => ({ ...prev, [fieldName]: data }));
      }
    },
    [isSaving]
  );

  useEffect(() => {
    const saveLocal = async () => {
      try {
        setIsWritingData(true);
        await saveStudentAndContacts({
          organization,
          student: studentToSaveToFirestore,
          contacts: contactsToSaveToFirestore,
        });
        shareDocuments();
      } catch (error) {
        setIsWritingData(false);
        setIsSaving(false);
        setErrors({ save: `Error while saving student or sharing documents: ${error}` });
        logError('Error while saving student or sharing documents', error);
      }
    };
    if (shouldSaveToFirestore()) {
      saveLocal();
    }
  }, [contactsToSaveToFirestore, organization, shareDocuments, shouldSaveToFirestore, studentToSaveToFirestore]);

  // Redirect to summary page when document sharing has completed
  useEffect(() => {
    if (isSuccess || isError) {
      setIsSaving(false);
      setIsWritingData(false);
      gotoRouteByName(RouteNameEnum.STUDENT_ENROLLMENT_SUMMARY, [
        { name: 'studentId', value: studentToSaveToFirestore.id },
      ]);
    }
  }, [gotoRouteByName, isError, isSuccess, studentToSaveToFirestore.id]);

  if (!student) return <LoadingComponent />;

  return (
    <Segment basic clearing>
      <Form loading={isSaving}>
        <PageHeader pageName={'Enrollment'} classes="enrollments-page" />
        <Header as="h1">{t('enrollments.pageTitle', { name: student.displayName ?? 'N/A' })}</Header>
        <Segment>
          <InvitationRecipientsForm
            invitationRecipients={invitationRecipients}
            isSaving={isSaving && !dataToSave.invitationRecipients}
            onSave={(d) => onSave(d, 'invitationRecipients')}
          />
        </Segment>
        <Segment>
          <DocumentsForm
            documents={documents}
            isSaving={isSaving && !dataToSave.documents}
            onSave={(d) => onSave(d, 'documents')}
          />
        </Segment>
        <Segment>
          <TuitionAndFeesForm
            tuitionAndFees={tuitionAndFees}
            isSaving={isSaving && !dataToSave.tuitionAndFees}
            onSave={(d) => onSave(d, 'tuitionAndFees')}
          />
        </Segment>
        <Segment>
          <ScheduleForm
            scheduledDays={scheduledDays}
            isSaving={isSaving && !dataToSave.scheduledDays}
            onSave={(d) => onSave(d, 'scheduledDays')}
          />
        </Segment>
        <CommandButtons />
      </Form>
    </Segment>
  );
  function CommandButtons() {
    return (
      <div className="ws-form-buttons" data-testid="ws-form-buttons">
        <Button
          secondary
          basic
          loading={isSaving}
          onClick={() => {
            setDataToSave({});
            goBack();
          }}
          data-testid="ws-cancel-btn"
        >
          <Icon name="cancel" data-testid="ws-cancel-icon" />
          {t('Cancel')}
        </Button>
        <Button
          primary
          loading={isSaving}
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            setErrors({});
          }}
          data-testid="ws-finish-btn"
        >
          {t('Continue')}
          <Icon name="arrow alternate circle right outline" data-testid="ws-continue-icon" />
        </Button>
      </div>
    );
  }
};

export default EnrollmentPage;
