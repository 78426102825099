import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../helpers/utils';
import fStyle from './FinanceAtGlance.module.scss';
import FinanceBox, { FinanceBoxTypeEnum } from './FinanceBox';

const fx = classNames.bind(fStyle);

export type InvoiceSectionProps = {
  upcoming: number;
  processing: number;
  transferring: number;
  depositedPaid: number;
  pastDue: number;
  failed: number;
  title: string;
};
function InvoiceSection({
  upcoming,
  processing,
  transferring,
  depositedPaid,
  pastDue,
  failed,
  title,
}: InvoiceSectionProps) {
  const { t } = useTranslation();
  return (
    <>
      <span className={fx('textFontSize', 'mt-10')}>{t(title)}</span>
      <div className={fx('mt-1')}>
        <div className={fx('financeAtGlance')}>
          <FinanceBox
            boxType={FinanceBoxTypeEnum.UPCOMING}
            amount={formatCurrency(upcoming)}
            label={t('status.upcoming')}
          />
          <FinanceBox
            boxType={FinanceBoxTypeEnum.PROCESSING}
            amount={formatCurrency(processing)}
            label={t('Processing')}
          />
          <FinanceBox
            boxType={FinanceBoxTypeEnum.TRANSFERRING}
            amount={formatCurrency(transferring)}
            label={t('status.transferring')}
          />
          <FinanceBox
            boxType={FinanceBoxTypeEnum.DEPOSITED_OR_PAID}
            amount={formatCurrency(depositedPaid)}
            label={`${t('status.deposited')}/${t('status.paidManually')}`}
          />
          <FinanceBox
            boxType={FinanceBoxTypeEnum.PAST_DUE}
            amount={formatCurrency(pastDue)}
            label={t('status.pastDue')}
          />
          <FinanceBox boxType={FinanceBoxTypeEnum.FAILED} amount={formatCurrency(failed)} label={t('Failed')} />
        </div>
      </div>
    </>
  );
}

export default InvoiceSection;
