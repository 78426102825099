import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid } from 'semantic-ui-react';
import { formatNumberShorthand } from '../../../../helpers/utils';
import './AgingTotalSummary.scss';

const ReportTotalSummary = ({ totalSummary }) => {
  const { t } = useTranslation();
  const currencyOptions = {
    symbol: '$',
    decimal: '.',
    thousand: ',',
    precision: 0,
  };

  const items = [
    {
      key: 'firstTotal',
      label: '1-6',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.first)}`,
    },
    {
      key: 'secondTotal',
      label: '7-13',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.second)}`,
    },
    {
      key: 'thirdTotal',
      label: '14-20',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.third)}`,
    },
    {
      key: 'fourthTotal',
      label: '21-28',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.fourth)}`,
    },
    {
      key: 'fifthTotal',
      label: '29+',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.fifth)}`,
    },
    {
      key: 'total',
      label: 'Total',
      value: `${currencyOptions.symbol}${formatNumberShorthand(totalSummary.total)}`,
    },
  ];

  const stats = items.map((item, key) => (
    <Grid.Column key={key}>
      <div className="aging-total-summary-label">
        <p>{item.label}</p>
        <p className="aging-total-summary-description">{item.key !== 'total' ? t('Days total') : null}</p>
      </div>
      <header className="aging-total-summary-amount">{item.value}</header>
    </Grid.Column>
  ));

  return (
    <Grid container stackable>
      <Grid.Row columns={items.length}>{stats}</Grid.Row>
    </Grid>
  );
};

ReportTotalSummary.propTypes = {
  totalSummary: PropTypes.object,
};

export default ReportTotalSummary;
