import { useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModalProps } from '../../common';

export default function StudentArchiveConfirmationModal({ isOpen, onYes, onNo }: ConfirmationModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);

  return (
    <Modal size="tiny" open={isOpen} onClose={onNo} closeOnDimmerClick={false} closeOnEscape={true}>
      <Modal.Header data-testid="student-archive-modal-header">{t('students.archiveStudentModalHeader')}</Modal.Header>
      <Modal.Content>{t('students.archiveStudentModalContent')}</Modal.Content>
      <Modal.Actions>
        <div>
          <Button
            fluid
            primary
            loading={isLoading}
            disabled={isLoading}
            content={t('Yes')}
            onClick={async () => {
              setIsLoading(true);
              await onYes();
            }}
            data-testid="student-archive-modal-yes-btn"
          />
          <br />
          <Button
            fluid
            loading={isLoading}
            disabled={isLoading}
            content={t('No')}
            onClick={async () => {
              setIsLoading(true);
              await onNo();
            }}
            data-testid="student-archive-modal-no-btn"
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
}
