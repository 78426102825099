import { Dropdown } from 'semantic-ui-react';

const options = [
  { key: 'en', value: 'en', text: 'English' },
  { key: 'es', value: 'es', text: 'Español' },
];

const LanguagePicker = ({ ...rest }) => {
  return <Dropdown options={options} selectOnBlur={false} clearable={false} {...rest} />;
};

export default LanguagePicker;
