// Please keep these in alphabetical order
export const paths = {
  agingReports: '/aging-reports',
  area51: '/admin/area-51',
  attendance: '/attendance',
  authError: '/auth-error',
  authReturn: '/auth-return',
  billingSettings: '/billing/settings',
  calendar: '/calendar',
  completeSignup: '/complete-signup',
  devices: '/devices',
  documents: '/documents',
  enrolledFamilies: '/enrolled-families',
  enrollmentsAdmin: '/enrollments-admin',
  error: '/error',
  events: '/events',
  featureFlags: '/feature-flags',
  financialSummary: '/financial-summary',
  foodProgramClaims: '/food/claims',
  foodProgramClaimCreate: '/food/claims/new',
  foodProgramClaimUpdate: '/food/claims/:claimId',
  foodProgramMeals: '/food/meals',
  foodProgramMealCreate: '/food/meals/new',
  foodProgramMealUpdate: '/food/meals/:mealId',
  foodProgramReports: '/food/reports',
  formsBuilder: '/forms',
  home: '/',
  invoices: '/billing/invoices',
  invoiceEditConfirm: '/billing/invoice/confirm',
  invoiceEditDetails: '/billing/invoices/edit',
  invoiceEditStudents: '/billing/invoice/select',
  locations: '/locations',
  login: '/login',
  messages: '/messages',
  networks: '/admin/networks',
  parentsListing: '/family-contacts',
  profile: '/profile',
  providers: '/admin/providers',
  r404: '/404',
  r401: '/401',
  recurringPlans: '/billing/plans',
  recurringPlanEditDetails: '/billing/plans/edit',
  rooms: '/rooms',
  setup: '/setup',
  signin: '/signin',
  signout: '/signout',
  signoutFull: '/signout-full',
  signup: '/signup/:networkId?',
  staff: '/staff',
  newStaff: '/new-staff',
  staffDetails: '/new-staff/:staffId',
  staffTimesheet: '/staff-timesheet',
  studentAdd: '/student-add',
  studentAddChild: '/student-add-child/:marketplaceChildId',
  studentEnrollment: '/enrollment/:studentId',
  studentEnrollmentSummary: '/enrollment-summary/:studentId',
  students: '/students',
  studentsFilter: '/students/:filter?',
  studentsImport: '/import/students',
  timeSpaceCalculator: '/timespace-calculator',
  transactions: '/transactions',
  users: '/admin/users',
  verify: '/verify',
  welcome: '/welcome',
  updateWebsite: '/update-website',
  appstore: '/appstore',
  learningBeyondPaper: '/appstore/learning-beyond-paper',
  external: {
    apiLeads: '/firebase-api/schools/{marketplaceSchoolId}/leads',
    apiLead: '/firebase-api/schools/{marketplaceSchoolId}/leads/{leadId}',
    events: '/admin/{marketplaceSchoolId}/events',
    helpCenter: '/hc/en-us/categories/4416555627287-Wonderschool-2-0-Support',
    interestedFamilies: '/admin/{marketplaceSchoolId}/interested',
    locationEdit: '/admin/{marketplaceSchoolId}/settings/info?back=/admin/{marketplaceSchoolId}/settings',
    marketing: '/admin/{marketplaceSchoolId}/interested/marketing',
    messages: '/admin/{marketplaceSchoolId}/messages/{channelId}',
    messaging: '/admin/{marketplaceSchoolId}/messaging/{channelId}',
    onboarding: '/onboarding',
    tours: '/admin/{marketplaceSchoolId}/interested/calendar',
    tourRequests: '/admin/{marketplaceSchoolId}/tours',
    updateWebsite: '/admin/{marketplaceSchoolId}/website',
    viewWebsite: '/{marketplaceSchoolName}-{marketplaceSchoolId}',
  },
  headless: {
    updateWebsite: '/headless/{marketplaceSchoolId}/website',
    events: '/headless/{marketplaceSchoolId}/events',
    interestedFamilies: '/headless/{marketplaceSchoolId}/interested',
    locationEdit: '/admin/{marketplaceSchoolId}/settings/info?back=/admin/{marketplaceSchoolId}/settings',
    marketing: '/headless/{marketplaceSchoolId}/interested/marketing',
    messages: '/headless/{marketplaceSchoolId}/messages/{channelId}',
    messaging: '/headless/{marketplaceSchoolId}/messaging/{channelId}',
    tours: '/headless/{marketplaceSchoolId}/interested/calendar',
    tourRequests: '/headless/{marketplaceSchoolId}/tours',
  },
  // These are legacy and should be deprecated at some point.
  billing: {
    hashes: {
      details: '#details',
      items: '#items',
      students: '#students',
      confirm: '#confirm',
    },
    plans: {
      list: '/billing/plans',
      edit: '/billing/plans/edit',
    },
    invoices: {
      list: '/billing/invoices',
      edit: '/billing/invoices/edit',
      reports: '/billing/invoices/reports',
    },
    reports: '/billing/reports',
    details: '/billing/invoice/details',
    items: '/billing/invoice/items',
    detailsRecurringHash: '#recurring-plans',
    select: '/billing/invoice/select',
    confirm: '/billing/invoice/confirm',
  },
};
