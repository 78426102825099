import { Card } from '@wonderschool/common-base-ui';
import { useTranslation } from 'react-i18next';
import { Grid, Table } from 'semantic-ui-react';
import { InvoicePlanStatus } from '../../../../helpers/invoicePlans';
import DateTime from '../../../DateTime/DateTime';
import { FeeDisclaimer } from '../../../Shared/FeeDisclaimer';
import InvoiceItemList from '../InvoiceItemList';

export function InvoicePlanSummary({ selectedInvoicePlan, responsibleForBilling, monetization }) {
  const { t } = useTranslation();
  const isEnded = selectedInvoicePlan.status === InvoicePlanStatus.ENDED;
  return (
    <Card
      title={
        <div className="flex flex-row gap-2 p-2">
          <div className="text-3xl font-bold text-black" data-testid="invoice-plan-detail-title">
            {t('Summary')}
          </div>
        </div>
      }
    >
      <div className="p-4">
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Table compact basic="very" className="billing-summary">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell data-testid="invoice-plan-detail-billed-to-label">{t('Billed to')}</Table.Cell>
                    <Table.Cell data-testid="invoice-plan-detail-billed-to-value">
                      {responsibleForBilling.map((student) => student.displayName).join(', ')}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>

            <Grid.Column>
              <Table singleLine compact basic="very" className="billing-summary">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell data-testid="invoice-plan-detail-plan-number-label">
                      {t('billing.invoiceDetail.RecurringPlannumber')}
                    </Table.Cell>
                    <Table.Cell data-testid="invoice-plan-detail-plan-number-value">
                      {selectedInvoicePlan && selectedInvoicePlan.id}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell data-testid="invoice-plan-detail-next-invoice-date-label">
                      <strong>{t('Next invoice date')}</strong>
                    </Table.Cell>
                    <Table.Cell data-testid="invoice-plan-detail-next-invoice-date-value">
                      <strong>
                        {selectedInvoicePlan.dateSend && !isEnded ? (
                          <DateTime epoch={selectedInvoicePlan.dateSend} format={'MM/DD/YYYY'} />
                        ) : (
                          'n/a'
                        )}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell data-testid="invoice-plan-detail-next-due-date-label">
                      <strong>{t('billing.invoiceDetail.Nextduedate')}</strong>
                    </Table.Cell>
                    <Table.Cell data-testid="invoice-plan-detail-next-due-date-value">
                      <strong>
                        {selectedInvoicePlan.dateDue && !isEnded ? (
                          <DateTime epoch={selectedInvoicePlan.dateDue} format={'MM/DD/YYYY'} />
                        ) : (
                          'n/a'
                        )}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                  {selectedInvoicePlan.dateEnd ? (
                    <Table.Row>
                      <Table.Cell data-testid="invoice-plan-detail-end-date-label">
                        <strong>{t('End Date')}</strong>
                      </Table.Cell>
                      <Table.Cell data-testid="invoice-plan-detail-end-date-value">
                        <strong>
                          <DateTime epoch={selectedInvoicePlan.dateEnd} format={'MM/DD/YYYY'} />
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                  <Table.Row>
                    <Table.Cell data-testid="invoice-plan-detail-interval-label">
                      <strong>{t('Billing Interval')}</strong>
                    </Table.Cell>
                    <Table.Cell data-testid="invoice-plan-detail-interval-value">
                      <strong>{t(selectedInvoicePlan.billingInterval)}</strong>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {!!selectedInvoicePlan?.invoiceItemList && (
                <InvoiceItemList invoiceItemList={selectedInvoicePlan?.invoiceItemList} isEditable={false} />
              )}
              <Table singleLine compact basic="very" className="billing-summary">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="4">{<FeeDisclaimer monetization={monetization} />}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Card>
  );
}
