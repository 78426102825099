import _ from 'lodash';
import { LOCATIONS_FETCHED, LOCATION_SELECTED, LOCATION_SELECTION_CLEARED, LOCATION_UPDATED } from '../actions/types';

const initialState = {
  selectedLocation: {},
  count: 0,
  list: [],
};

const locations = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS_FETCHED: {
      const list = action.locations.list.filter((location) => location?.isArchived !== true);

      return {
        ...state,
        count: action.locations.count,
        list,
      };
    }
    case LOCATION_SELECTED: {
      return { ...state, selectedLocation: action.location };
    }
    // Replace selected with the newly updated data.
    // New data is fetched by the listener as soon as listener receives the update.
    case LOCATION_UPDATED: {
      return {
        ...state,
        selectedLocation: _.find(state.list, { id: state.selectedLocation.id }),
      };
    }
    case LOCATION_SELECTION_CLEARED: {
      return { ...state, selectedLocation: {} };
    }

    default: {
      return state;
    }
  }
};

export default locations;
