import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganization, useOrganizationContactsListener } from '../../hooks/useOrganizations';

import { useContacts } from '../../contacts';
import useRooms from '../../hooks/useRooms';
import { useUser } from '../../hooks/useUser';

import { logError } from '../../rollbar';
import { ReportTypeEnum } from '../enums';
import { fetchTimecards, saveTimecard } from '../timecardsAPI';
import {
  generateDailyHours,
  generateDailyHoursCSV,
  generateDailyHoursPDF,
  generateTotalHours,
  generateTotalHoursCSV,
  generateTotalHoursPDF,
} from '../timecardsUtils';
import {
  DailyHoursType,
  StaffDisplayType,
  StaffMapType,
  TimecardType,
  TimesheetFiltersType,
  TotalHoursType,
} from '../types';

import PageHeader from '../../Components/Shared/PageHeader';
import { EmptyReport, ExportToolbar } from '../../reports';

import AddTimecard from './AddTimecard';
import DailyHoursTable from './DailyHoursTable';
import TimesheetFiltersForm from './TimesheetFiltersForm';
import TotalHoursTable from './TotalHoursTable';

type TimesheetPageProps = {
  onClick?: () => void;
};

const TimesheetPage: React.FC<TimesheetPageProps> = () => {
  const { t } = useTranslation();
  const { allStaffContacts: staff } = useContacts(); // allStaffContacts is a custom hook that returns all contacts with type staff. Added this to show the name of the deleted staff in the timesheet.
  const rooms: any[] = useRooms();
  const user: any = useUser();
  const { isTimecardEnabled } = useFlags();

  const [timecards, setTimecards] = useState<TimecardType[] | undefined>();
  const [filters, setFilters] = useState<TimesheetFiltersType | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [timecardToSave, setTimecardToSave] = useState<TimecardType | undefined>();

  const organization = useOrganization();
  // Adding the staff listener at the top level of the timesheet page, to prevent it
  // from being re-rendered when the filters are changed.
  useOrganizationContactsListener(organization.id, [
    {
      field: 'type',
      operation: '==',
      value: 'staff',
    },
  ]);

  useEffect(() => {
    (async () => {
      if (!filters) return;
      try {
        const timecardsLocal = await fetchTimecards(filters);
        setTimecards(timecardsLocal);
      } catch (err) {
        logError('Error fetching timecards', err);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters, loading]);
  const setRefreshTimeCard = async () => {
    const emptyFilters = {
      startDate: new Date(),
      endDate: new Date(),
      reportType: ReportTypeEnum.DAILY_HOURS,
      roomId: '',
      contactId: '',
      uid: '',
    };
    onFilter(filters || emptyFilters);
  };
  useEffect(() => {
    (async () => {
      if (!timecardToSave) return;
      try {
        await saveTimecard(timecardToSave);
        setTimecards((prev) =>
          prev?.map((timecard) => (timecard.id === timecardToSave.id ? timecardToSave : timecard))
        );
      } catch (err) {
        logError('Error saving timecards', err);
      } finally {
        setTimecardToSave(undefined);
        setLoading(false);
      }
    })();
  }, [timecardToSave]);

  const onFilter = useCallback(
    (filters: any) => {
      setLoading(true);
      setFilters({ ...filters, organizationId: organization.id });
    },
    [organization.id]
  );

  const onClear = useCallback(() => {
    setFilters(undefined);
    setTimecards(undefined);
  }, []);

  const onSaveTimecard = useCallback(
    (timecard: TimecardType) => {
      setLoading(true);
      setTimecardToSave(timecard);
    },
    [setTimecardToSave]
  );

  const staffMap = useMemo<StaffMapType>(() => {
    if (!user || user.isStaffNonOrganizationAdmin) return {};

    const staffDisplayArray = staff.map((contact: any) => ({
      uid: contact.uid ?? '',
      contactId: contact.id ?? '',
      displayName: contact.displayName ?? '',
      photoURL: contact.photoURL ?? '',
      status: contact.status ?? '',
    }));
    const currentStaffDisplay: StaffMapType = {
      [user.uid]: {
        uid: user.uid ?? '',
        contactId: user.contactId ?? '',
        displayName: user.displayName ?? '',
        photoURL: user.photoURL ?? '',
        status: user.status ?? '',
      },
    };

    const reduced = staffDisplayArray.reduce((map: StaffMapType, staffDisplay: StaffDisplayType) => {
      map[staffDisplay.uid] = staffDisplay;
      return map;
    }, currentStaffDisplay);

    return reduced;
  }, [staff, user]);

  const roomsMap = useMemo<Record<string, any>>(() => {
    return rooms.reduce(
      (map: any, room: any) => {
        map[room.id] = room;
        return map;
      },
      {} as Record<string, any>
    );
  }, [rooms]);

  const totalHours = useMemo<TotalHoursType[]>(() => {
    if (!timecards || !staffMap || !roomsMap) return [];
    return generateTotalHours(timecards, staffMap, roomsMap);
  }, [roomsMap, staffMap, timecards]);

  const dailyHours = useMemo<DailyHoursType[]>(() => {
    if (!timecards || !staffMap || !roomsMap) return [];
    return generateDailyHours(timecards, staffMap, roomsMap);
  }, [roomsMap, staffMap, timecards]);

  const onExportPDF = useCallback(() => {
    if (filters?.reportType === ReportTypeEnum.DAILY_HOURS) generateDailyHoursPDF(dailyHours);
    else if (filters?.reportType === ReportTypeEnum.TOTAL_HOURS) generateTotalHoursPDF(totalHours);
  }, [dailyHours, filters?.reportType, totalHours]);

  const onExportCSV = useCallback(() => {
    if (filters?.reportType === ReportTypeEnum.DAILY_HOURS) generateDailyHoursCSV(dailyHours);
    else if (filters?.reportType === ReportTypeEnum.TOTAL_HOURS) generateTotalHoursCSV(totalHours);
  }, [dailyHours, filters?.reportType, totalHours]);

  return (
    <div className="container px-5">
      <PageHeader pageName={'timecards.timesheetPage'} classes="timesheets-page" />
      <Heading1 onSave={onSaveTimecard} setRefreshTimeCard={setRefreshTimeCard} isTimecardEnabled={isTimecardEnabled}>
        {t('timecards.staffTimesheet')}
      </Heading1>
      <TimesheetFiltersForm onFilter={onFilter} onClear={onClear} loading={loading} />
      {timecards?.length ? (
        <div>
          <ExportToolbar onExportPDF={onExportPDF} onExportCSV={onExportCSV} />
          {filters?.reportType === ReportTypeEnum.DAILY_HOURS ? (
            <DailyHoursTable
              timecards={timecards}
              dailyHours={dailyHours}
              onSave={onSaveTimecard}
              onClear={onClear}
              setRefreshTimeCard={setRefreshTimeCard}
            />
          ) : (
            <TotalHoursTable totalHours={totalHours} />
          )}
        </div>
      ) : (
        <EmptyReport />
      )}
    </div>
  );
};

function Heading1({
  children,
  onSave,
  setRefreshTimeCard,
  isTimecardEnabled,
}: {
  children: React.ReactNode;
  onSave: any;
  setRefreshTimeCard: any;
  isTimecardEnabled: boolean;
}) {
  return (
    <div className="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
      <h1 data-testid="timesheet-heading" className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
        {children}
      </h1>
      {isTimecardEnabled && (
        <div>
          <AddTimecard onSave={onSave} setRefreshTimeCard={setRefreshTimeCard} />
        </div>
      )}
    </div>
  );
}
export default TimesheetPage;
