import { Segment, Header } from 'semantic-ui-react';

const PageTitle = ({ count = null, title, RightComponent = null }) => {
  const counter = count ? `(${count})` : '';

  return (
    <Segment id="page-title" clearing basic className="no-padding">
      <Header as="h4" floated="right" className="no-margin">
        {RightComponent && <RightComponent />}
      </Header>
      <Header as="h1" floated="left" data-testid={`${title}-title`}>
        {`${title} ${counter}`}
      </Header>
    </Segment>
  );
};

export default PageTitle;
