import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import Validator from 'validator';
import { isEmpty, omit } from 'lodash';

import { logError } from '../../rollbar';
import { InlineError } from '../../Components/Messages';

import { fetchEmailUsage } from '../../api/firebase/users';
import { phoneNumberFormat, phoneNumberParse } from '../../helpers/utils';
import { canSendInvitation, useContacts } from '../../contacts';

import { FormComponentProps, PHONE_NUMBER_MASK } from '../../common';
import { ParentType } from '../types';
import { DEFAULT_PARENT } from '../consts';

type ParentEditFormProps = FormComponentProps<ParentType> & {
  parent?: ParentType; // If parent is provided, it will be used as the initial value
  parents?: ParentType[]; // If parents is provided, it will be used to check if the email is already in use
  onChangeParent?: (parent: ParentType) => void;
};

function ParentEditForm({
  title,
  hasTitle = true,
  isSaving,
  onSave,
  parent: parentParam,
  parents = [],
  onChangeParent,
}: ParentEditFormProps) {
  const { t } = useTranslation();

  const [parent, setParent] = useState<ParentType>(parentParam ?? { ...DEFAULT_PARENT });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isValidating, setIsValidating] = useState(false);
  const { contacts } = useContacts();

  const viewTitle = hasTitle ? t(title || 'students.newParentFormTitle') : '';

  const readonly = useMemo<boolean>(() => {
    return !!parent.contact?.id || !!parent.isInterestedFamily;
  }, [parent]);

  // pass in the parent so we can use either the state variable 'parent' or the prop 'parentParam'
  const canSendInvitationToParent = useCallback((parentLocal: ParentType): boolean => {
    return !parentLocal.contact || (parentLocal.contact && canSendInvitation(parentLocal.contact));
  }, []);

  const shouldShowSendInviteCheckbox = useMemo<boolean>(() => {
    return canSendInvitationToParent(parent);
  }, [parent, canSendInvitationToParent]);

  // pass in the email so we can use either the state variable 'parent' or the value in onChangeEmail
  const isEmailInUse = useCallback(
    (email: string): boolean => {
      if (!email) return false;

      const contact = contacts.find((contact: any) => contact.email === email && !parent.contact?.id);
      if (contact) return true;

      // return an array of parents that have this email. If more than one, then it's already in use
      const parentFound = parents.find(
        (p: ParentType) =>
          p.email === email && p.uuid !== parent.uuid && !parent.contact?.id && !parent.isInterestedFamily
      );
      return !!parentFound;
    },
    [contacts, parent, parents]
  );

  type ValidateType = {
    errors: Record<string, string>;
    emailUsage: any;
  };
  const validate = useCallback(async (): Promise<ValidateType> => {
    const errorsLocal: Record<string, string> = {};
    if (isValidating) return { errors: errorsLocal, emailUsage: {} };

    setIsValidating(true);

    if (!parent.firstName) {
      errorsLocal['firstName'] = t('First Name is required');
    }
    if (!parent.lastName) {
      errorsLocal['lastName'] = t('Last Name is required');
    }
    if (parent.phone) {
      if (!Validator.isMobilePhone(parent.phone, 'en-US')) errorsLocal['phone'] = t('Phone is invalid');
    }
    if (parent.shouldSendInvitation && !parent.email) {
      errorsLocal['email'] = t('Email is required');
    }
    if (parent.email) {
      if (!Validator.isEmail(parent.email)) {
        errorsLocal['email'] = t('Email is invalid');
      }
      if (isEmailInUse(parent.email)) {
        errorsLocal['email'] = t('students.emailExistsInContacts');
      }
    }
    // Checking if email is used in another org requires calling a cloud function
    // That's an expensive operation, so we only do it if there are no other errors
    let emailUsage = { hasAuthUsage: false, uid: null } as any;
    if (isEmpty(errorsLocal) && parent.email && !parent.contact?.id) {
      emailUsage = await fetchEmailUsage(parent.email);
      if (emailUsage.hasRootUser) {
        errorsLocal['email'] = t('This email is already in use by another user. Please use a different email address');
      }
    }
    return { errors: errorsLocal, emailUsage };
  }, [isEmailInUse, parent, isValidating, t]);

  const onSaveLocal = useCallback(
    ({ hasAuthUser, uid }: any) => {
      if (!isEmpty(errors)) return;
      try {
        setErrors({});
        const phone = parent.phone ? phoneNumberFormat(parent.phone) : '';
        onSave?.({ data: { ...parent, phone, hasAuthUser, uid } });
      } catch (error: any) {
        logError('ParentEditForm: useEffect: catch', error);
        onSave?.({ errors: { message: error.message } });
      }
    },
    [errors, onSave, parent]
  );

  useEffect(() => {
    if (parentParam) {
      const phone = parentParam.phone ? phoneNumberParse(parentParam.phone) : '';
      setParent({ ...parentParam, phone, shouldSendInvitation: canSendInvitationToParent(parentParam) });
    }
  }, [parentParam, canSendInvitationToParent]);

  useEffect(() => {
    if (!isSaving || isValidating) return;

    async function saveIfNoValidationErrors() {
      setErrors({});
      const { errors: errorsLocal, emailUsage } = await validate();
      if (!isEmpty(errorsLocal)) {
        setErrors(errorsLocal);
        onSave?.({ errors: errorsLocal });
      } else {
        onSaveLocal(emailUsage);
      }
      setIsValidating(false);
    }
    saveIfNoValidationErrors();
  }, [isSaving, onSave, validate, parent, errors, t, onSaveLocal, isValidating]);

  const onChange = useCallback(
    (e: any, { name, value }: any) => {
      setErrors((prev) => omit(prev, name));
      const newParent = { ...parent, [name]: value };
      setParent(newParent);
      onChangeParent?.(newParent);
    },
    [onChangeParent, parent]
  );

  // This is getting messy... If the email is in use, set the error, and the local parent state, but
  // don't call onChangeParent because we don't want to update the parent in the parent list
  const onChangeEmail = useCallback(
    (e: any, data: any) => {
      if (isEmailInUse(data.value)) {
        setParent((prev) => ({ ...prev, email: data.value }));
        setErrors((prev) => ({ ...prev, email: t('students.emailExistsInContacts') }));
      } else {
        onChange(e, data);
      }
    },
    [isEmailInUse, onChange, t]
  );
  const onChangeMasked = useCallback(
    (e: any) => {
      onChange(e, { name: e.target.name, value: e.target.value });
    },
    [onChange]
  );
  return (
    <div className="parent-edit-form" data-testid="parent-edit-form">
      {!!viewTitle && <h2 data-testid="parent-edit-title">{viewTitle}</h2>}
      <Form.Group widths="equal">
        <Form.Field error={!!errors.firstName}>
          <Form.Input
            disabled={readonly}
            required={!readonly}
            name="firstName"
            type="text"
            value={parent.firstName ?? ''}
            placeholder={t('First Name')}
            label={t('First Name')}
            onChange={onChange}
            data-testid="parent-first-name"
          />
          {errors.firstName && <InlineError text={errors.firstName} data-testid="error-parent-first-name" />}
        </Form.Field>
        <Form.Field error={!!errors.lastName}>
          <Form.Input
            disabled={readonly}
            required={!readonly}
            name="lastName"
            type="text"
            value={parent.lastName ?? ''}
            placeholder={t('Last Name')}
            label={t('Last Name')}
            onChange={onChange}
            data-testid="parent-last-name"
          />
          {errors.lastName && <InlineError text={errors.lastName} data-testid="error-parent-last-name" />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field error={!!errors.email}>
          <Form.Input
            disabled={readonly}
            required={!!parent.shouldSendInvitation && !readonly}
            type="text"
            id="email"
            name="email"
            value={parent.email ?? ''}
            onChange={onChangeEmail}
            label={t('Email')}
            placeholder={t('Email')}
            data-testid="parent-email"
          />
          {errors.email && <InlineError text={errors.email} data-testid="error-parent-email" />}
        </Form.Field>
        <Form.Field error={!!errors.phone}>
          <Form.Input
            disabled={readonly}
            type="text"
            id="phone"
            name="phone"
            onChange={onChangeMasked}
            label={t('Mobile Phone')}
            control={MaskedInput}
            mask={PHONE_NUMBER_MASK}
            guide={false}
            value={parent.phone ?? ''}
            placeholder={'(123) 456-7890'}
            data-testid="parent-phone"
          />
          {errors.phone && <InlineError text={errors.phone} data-testid="error-parent-phone" />}
        </Form.Field>
      </Form.Group>
      {shouldShowSendInviteCheckbox && (
        <Form.Group widths="2">
          <Form.Field>
            <Form.Checkbox
              name="shouldSendInvitation"
              checked={parent.shouldSendInvitation}
              label={t('students.shouldSendInviteLabel')}
              onChange={(e, { checked }) => {
                setErrors((prev) => omit(prev, 'email'));
                setParent((prev) => ({ ...prev, shouldSendInvitation: checked }));
              }}
              data-testid="parent-create-invite"
            />
            <p>
              <small>{t('students.shouldSendInviteNote')}</small>
            </p>
          </Form.Field>
        </Form.Group>
      )}
    </div>
  );
}

export default ParentEditForm;
