import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Container, Header, Segment } from 'semantic-ui-react';
import { useUser } from '../../hooks/useUser';
import { useOrganization } from '../../hooks/useOrganizations';

const PageTitle = () => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const user = useUser();

  const today = moment().format('dddd');

  return (
    <Segment basic clearing>
      <Header sub style={{ marginTop: '1em' }} content={organization?.name ?? ''} />

      <Header
        as="h1"
        style={{ marginTop: 0 }}
        content={t('Happy {{today}}, {{name}}', {
          today: t(today),
          name: user && user.firstName,
        })}
      />
    </Segment>
  );
};

const DashboardHeader = () => {
  return (
    <Container>
      <PageTitle />
    </Container>
  );
};

export default DashboardHeader;
