import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { WsDropdownOptionType, WsDropdownProps } from './types';

import WsLabel from './WsLabel';
import WsValidationError from './WsValidationError';

const EMPTY_OPTION: WsDropdownOptionType<any> = {
  id: '',
  name: '',
};

/**
 * @deprecated The component should not be used anymore. Use Combobox/Select from ws-common instead.
 */

export function WsCombobox({
  defaultValue = '',
  error,
  label,
  name = '',
  options = [],
  placeholder = '',
  required,
  value,
  onChange,
  disabled,
  'data-testid': dataTestId = '',
}: WsDropdownProps<any>) {
  const { t } = useTranslation();
  const [query, setQuery] = useState(defaultValue);

  const filteredOptions = useMemo(() => {
    return !query ? options : options.filter((option) => option.name.toLowerCase().includes(query.toLowerCase()));
  }, [options, query]);

  const optionsMap = useMemo<Record<string, any>>(() => {
    return options.reduce(
      (map, option) => {
        map[option.id] = option;
        return map;
      },
      {} as Record<string, any>
    );
  }, [options]);

  const selected = useMemo(() => {
    return optionsMap[value] ?? EMPTY_OPTION;
  }, [optionsMap, value]);

  const onChangeLocal = useCallback(
    (option: WsDropdownOptionType<any>) => {
      onChange?.({ name, value: option.id, text: option.name });
    },
    [name, onChange]
  );

  return (
    <div>
      <Combobox value={selected} onChange={onChangeLocal} data-testid={`${dataTestId}`} disabled={disabled}>
        {({ open }: any) => (
          <>
            <WsLabel htmlFor={name} required={required} data-testid={`${dataTestId}-label`}>
              {label}
            </WsLabel>
            <div className="relative">
              <Combobox.Input
                className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                onChange={(e) => setQuery(e.target.value)}
                displayValue={(option: WsDropdownOptionType<any>) => (option ? `${option.name}` : '')}
                placeholder={placeholder}
                data-testid={dataTestId}
              />
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                data-testid={`${dataTestId}-button`}
              >
                <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </Combobox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredOptions.map((option) => (
                    <Combobox.Option
                      key={`${option.id}`}
                      className={({ active }: { active: boolean }) =>
                        `${
                          active ? 'bg-gray-400 text-white' : 'text-gray-800'
                        } relative cursor-default select-none py-2 pl-3 pr-9`
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={selected ? 'font-semibold' : 'block truncate font-normal'}>
                            {t(option.name)}
                          </span>

                          {selected ? (
                            <span
                              className={`${
                                active ? 'text-white' : 'text-indigo-600'
                              } absolute inset-y-0 right-0 flex items-center pr-4`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
      <WsValidationError error={error} data-testid={`${dataTestId}-error`} />
    </div>
  );
}
export default WsCombobox;
