import { storage } from '../../api/firebase';
import { logError } from '../../rollbar';
import { AttendanceActivityType } from './types';

// Export the export utils here, so anyone using them will import from this file
export * from './export';

export async function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = src;
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
}

export async function getSignatureImage(signatureUri) {
  try {
    if (signatureUri) {
      let pngUrl = signatureUri.replace('/svg/', '/png/');
      pngUrl = pngUrl.replace('.svg', '_200x200.png');
      const url = await storage().ref(pngUrl).getDownloadURL();
      const image = await addImageProcess(url);
      return image;
    } else {
      return '-';
    }
  } catch (err) {
    logError('Image not found: ', signatureUri, err);
    return '-';
  }
}

export function matchAttendanceActivities(activities: any[]): AttendanceActivityType[] {
  try {
    const matchedActivities = [] as any[];
    const leftToMatch = {} as any;

    activities.forEach((activity) => {
      if (activity[activity.type]?.data?.signature) delete activity[activity.type].data.signature;

      if (!activity?.student?.id) {
        logError(`No StudentId found for activity - ${activity.id}`);
        throw Error(`No StudentId found for activity - ${activity.id}`);
      }

      if (activity?.type && activity.type === 'pickup') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkOut: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            type: activity?.type,
          },
          checkIn: {},
        };

        matchedActivities.push(activityData);
        leftToMatch[activity?.student?.id] = {
          index: matchedActivities.length - 1,
          type: 'pickup',
        };
      }

      if (activity?.type && activity.type === 'absent') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkIn: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            type: activity?.type,
          },
          checkOut: {},
        };

        matchedActivities.push(activityData);
        delete leftToMatch[activity?.student?.id];
      }

      if (activity?.type === 'dropoff') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkIn: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            preCheck: activity[activity.type]?.data?.preCheck,
            preCheckData: activity[activity.type]?.data?.preCheckData,
            type: activity?.type,
          },
          checkOut: {},
        };
        if (
          activity?.student?.id &&
          leftToMatch[activity.student.id] &&
          leftToMatch[activity.student.id].type === 'pickup'
        ) {
          matchedActivities[leftToMatch[activity.student.id].index] = {
            ...matchedActivities[leftToMatch[activity.student.id].index],
            id: matchedActivities[leftToMatch[activity.student.id].index].id + '-' + activityData.id,
            checkIn: { ...activityData.checkIn },
          };
          delete leftToMatch[activity.student.id];
        } else {
          matchedActivities.push(activityData);
          delete leftToMatch[activity.student.id];
        }
      }
    });
    return matchedActivities;
  } catch (error) {
    logError('Error in matchFetchedActivities: ', error);
    throw error;
  }
}
