import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import { WsLabel, WsValidationError } from '../../../../common';
import { Button } from '@wonderschool/common-base-ui';
import { usePrimaryLocation } from '../../../../hooks/useLocations';
import { useOrganization } from '../../../../hooks/useOrganizations';
import { FinancialSummaryFiltersType, FinancialSummaryFiltersProps } from './types';
import { getMonthOptions, getStartEndMonths, getYearOptions } from './FinancialSummaryUtils';
import FinanacialSummaryCheckboxDropdown from './FinanacialSummaryCheckboxDropdown';
import { MONTH_LIST } from './constants';

export const FinancialSummaryFiltersForm: React.FC<FinancialSummaryFiltersProps> = ({
  onFilter,
  loading = false,
  filters: filtersParam,
}) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const location = usePrimaryLocation();

  const currentYear: number = moment().tz(location?.timezone)?.year();

  const monthOptions = getMonthOptions(t);
  //TODO: update start year value once confirm from Product team
  const yearOptions = getYearOptions(2000, Number(currentYear));
  const defaultFilters = useMemo<FinancialSummaryFiltersType>(() => {
    return {
      organizationId: organization?.id ?? '1',
      locationId: location?.id ?? '1',
      selectedMonths: [],
      selectedYear: currentYear?.toString(),
      ...(filtersParam ?? {}),
    };
  }, [organization?.id, location?.id, filtersParam, currentYear]);

  const [selectedMonths, setSelectedMonths] = useState<string[]>(MONTH_LIST);
  const [selectedYear, setSelectedYear] = useState<string>(currentYear?.toString());
  const [filters, setFilters] = useState(defaultFilters);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const createSearchConditions = useCallback(() => {
    if (selectedMonths?.length > 0) {
      const { start, end } = getStartEndMonths(selectedMonths, selectedYear, location?.timezone);

      const startDate = start?.toDate();
      const endDate = end?.toDate();
      return [
        {
          field: 'date',
          operation: '>=',
          value: startDate,
        } as const,
        {
          field: 'date',
          operation: '<=',
          value: endDate,
        } as const,
      ];
    } else {
      return [];
    }
  }, [selectedMonths, selectedYear, location?.timezone]);
  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!selectedMonths || selectedMonths.length === 0) {
      errorsLocal.selectedMonths = t('errors.validation.financialSummary.months');
    }
    return errorsLocal;
  }, [t, selectedMonths]);
  const onFilterLocal = useCallback(() => {
    const errorsLocal = validate();
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      return;
    } else {
      setErrors(errorsLocal);
    }
    onFilter?.(filters);
  }, [filters, onFilter, validate]);

  useEffect(() => {
    if (selectedMonths?.length > 0) {
      const searchConditions = createSearchConditions();
      setFilters((prev) => ({
        ...prev,
        selectedMonths,
        searchConditions,
      }));
    }
  }, [selectedMonths, setFilters, createSearchConditions]);
  useEffect(() => {
    const searchConditions = createSearchConditions();
    setFilters((prev) => ({
      ...prev,
      selectedYear,
      searchConditions,
    }));
  }, [selectedYear, setFilters, createSearchConditions]);

  const handleSelectMonth = (selectedOptions: string[]) => {
    setSelectedMonths(selectedOptions);
  };
  const handleSelectYear = (selectedOptions: string[]) => {
    setSelectedYear(selectedOptions[0] || '');
  };

  return (
    <div className="container flex h-full w-full flex-col items-center gap-2">
      <div className="items-top h-full` mt-2 flex w-full flex-row gap-5">
        <Form.Group className="fs-filter-container">
          <Form.Field error={errors.selectedMonths}>
            <WsLabel htmlFor={'selectedMonths'} required={true} data-testid={`select-month-label`}>
              {t('financialSummary.selectMonth.title')}
            </WsLabel>
            {monthOptions && (
              <FinanacialSummaryCheckboxDropdown
                id="selectedMonths"
                placeholder={t('financialSummary.selectMonth.placeholder')}
                options={monthOptions}
                className={errors.selectedMonths ? 'fs-error' : ''}
                onSelect={handleSelectMonth}
                selectAllLable={'financialSummary.dropdown.allMonths'}
                isMultiSelect={true}
                initialValue={monthOptions.map((month) => month.value)}
              />
            )}
            <WsValidationError error={errors.selectedMonths} data-testid="selected-months-error" />
          </Form.Field>
          <Form.Field className="fs-filter-mt-10">
            <WsLabel htmlFor={'selectedYear'} required={true} data-testid={`select-year-label`}>
              {t('financialSummary.selectYear.title')}
            </WsLabel>
            {yearOptions && (
              <FinanacialSummaryCheckboxDropdown
                id="selectedYear"
                placeholder={t('financialSummary.selectYear.placeholder')}
                options={yearOptions}
                onSelect={handleSelectYear}
                isMultiSelect={false}
                initialValue={[selectedYear]}
              />
            )}
          </Form.Field>
          <Form.Field className="fs-mt-20">
            <Button
              onClick={() => onFilterLocal()}
              primary
              loading={loading}
              disabled={loading}
              data-testid="filter-button"
              label={t('financialSummary.createReport.label')}
            />
          </Form.Field>
        </Form.Group>
      </div>
    </div>
  );
};
export default FinancialSummaryFiltersForm;
