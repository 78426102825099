import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  FoodProgramReportsPage as FoodProgramReportsPageCommon,
  FoodProgramQueryProvider,
} from '@wonderschool/common-food-program-ui';

import { getTitlePrefix } from '../../config/env';
import { useAuthUser } from '../../hooks/useUser';
import { PAGE_NAMES, useSegmentPage } from '../../segment';
import { getCurrentEnvironment } from '../../common/utils/environmentUtils';

export const FoodProgramReportsPage = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.title');
  const { token } = useAuthUser();
  useSegmentPage(PAGE_NAMES.foodProgramReports);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', {
          pageName: title,
        })}
      </title>
      <body />
    </Helmet>
  );

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <FoodProgramReportsPageCommon />
    </FoodProgramQueryProvider>
  );
};
export default FoodProgramReportsPage;
