import { isNil, omitBy } from 'lodash';
import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { LDContext } from 'launchdarkly-react-client-sdk';

import { useUser } from '../hooks/useUser';
import { useOrganization } from '../hooks/useOrganizations';
import { logError } from '../rollbar';
import { setLDClient } from './ldClient';

type LDUserMin = {
  uid: string;
  displayName?: string;
  email?: string;
};
type LDOrgganizationMin = {
  id: string;
  name: string;
};

export const makeLDContext = (user?: LDUserMin, organization?: LDOrgganizationMin): LDContext => {
  return {
    kind: 'multi',
    user: omitBy(
      {
        key: user?.uid ?? 'anonymous-user',
        name: user ? user?.displayName : 'anonymous-user', // user!.displayName might not exist. if that's the case, do not set
        email: user?.email,
        anonymous: !user?.uid,
      },
      isNil
    ),
    organization: omitBy(
      {
        key: organization?.id ?? 'anonymous-organization',
        name: organization ? organization?.name : 'anonymous-organization', // organization!.displayName might not exist. if that's the case, do not set
        anonymous: !organization?.id,
      },
      isNil
    ),
  };
};

export default function LaunchDarklyContextInitializer() {
  const user = useUser();
  const organization = useOrganization();
  const ldClient = useLDClient();

  useEffect(() => {
    const doInitialize = async () => {
      if (!ldClient) return;

      setLDClient(ldClient);

      try {
        const ldContext = makeLDContext(user, organization);
        await ldClient.identify(ldContext);
      } catch (error) {
        logError('Error initializing LaunchDarkly', error);
      }
    };
    doInitialize();
  }, [user, organization, ldClient]);
  return <div />;
}
