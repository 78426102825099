// returns an array of activities
import moment from 'moment';

import { getDocumentsAsArray } from '../api/firebase/firestore';
import { formatStringAsLocaleMillisOrNull, DefaultDateFormat } from '../helpers/dates';
import { logError } from '../rollbar';

import { ActivityType, ActivitiesFiltersType, QueryConditionType } from './types';

const paths = {
  activities: (organizationId: string) => `organizations/${organizationId}/activities`,
};
export async function fetchActivities(filters: ActivitiesFiltersType): Promise<ActivityType[]> {
  const activityTypes = Array.isArray(filters.activityTypes) ? filters.activityTypes : [filters.activityTypes];

  const conditions: QueryConditionType[] = [
    {
      field: 'type',
      operation: 'in',
      value: activityTypes,
    },
    {
      field: 'activityTime',
      operation: '>=',
      value: formatStringAsLocaleMillisOrNull(moment(filters.startDate).startOf('day')),
    },
    {
      field: 'activityTime',
      operation: '<=',
      value: moment(filters.endDate, DefaultDateFormat).isSame(moment(new Date(), DefaultDateFormat))
        ? formatStringAsLocaleMillisOrNull(moment(new Date()))
        : formatStringAsLocaleMillisOrNull(moment(filters.endDate).endOf('day')),
    },
  ];

  if (filters.roomId) {
    conditions.push({
      field: 'room',
      operation: '==',
      value: filters.roomId,
    });
  }

  if (filters.studentId) {
    conditions.push({
      field: 'student.id',
      operation: '==',
      value: filters.studentId,
    });
  }
  try {
    const activities = await getDocumentsAsArray({
      path: paths.activities(filters.organizationId),
      conditions,
      orderBy: [
        { field: 'activityTime', direction: 'desc' },
        { field: 'addedBy.createdAt', direction: 'desc' },
      ],
    });
    return activities.list ?? [];
  } catch (error) {
    logError(error);
    throw error;
  }
}
