import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import { getRoomById } from '../../redux/actions/roomActions';

const StudentRooms = ({ rooms = [] }) => {
  const { t } = useTranslation();
  const roomList = useMemo(
    () =>
      rooms
        ?.filter((r: any) => r?.length) // Filter room IDs which are not null or empty.
        ?.map((roomId) => getRoomById(roomId)?.name) // Get each room ID's name.
        .join(', '), // Join room names with a comma.
    [rooms]
  );
  if (!roomList.length) return <Label basic>{t('No room selected')}</Label>;
  return <Label color="black">{roomList}</Label>;
};

export default StudentRooms;
