import { connect } from 'react-redux';
import { Segment, Grid } from 'semantic-ui-react';
// import Avatar from 'react-avatar';

// Import utils.
import UserProfileForm from '../Forms/UserProfileForm';

const Profile = () => {
  return (
    <Segment>
      <Grid stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={16}>
            <UserProfileForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(Profile);
