function convertToCents(amount) {
  return Math.round(amount * 100);
}

function convertToDollars(amount) {
  return amount / 100;
}

module.exports = {
  convertToCents,
  convertToDollars,
};
