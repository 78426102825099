import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Segment, Grid, Header, Button, Table, Modal } from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { formatFullName, currencyFormatter, getFormatter, centsToDollars } from '../../../../helpers/utils';
import {
  refreshInvoiceLinks,
  setOrganizationInvoicePlan,
  updateInvoiceStatus,
  updateOrganizationInvoice,
} from '../../../../api/firebase/invoices';
import {
  BILLING_FEE_TYPE,
  InvoiceStatus,
  hasParentPaidInvoice,
  getBillingFeeByType,
  getWSFeeListItemDescription,
  isV2Invoice,
  mapV2InvoiceItems,
  mapV2InvoicePayments,
  InvoiceItemCategory,
  getProcessingFeeLineItem,
} from '../../../../helpers/invoices';
import { InvoiceStatus as InvoiceStatusComponent } from '../../../../Components/Billing/Invoices';
import InvoiceActionForm from './InvoiceActionForm';

// Import style
import './InvoiceDetail.scss';
import { isEmpty } from 'lodash';
import { useStudent } from '../../../../students/studentsHooks';
import { LoadingIndicator } from '../../../Shared/BusyIndicator';
import DateTime from '../../../DateTime/DateTime';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  getInvoiceItems,
  getInvoicePayments,
  sendInvoice,
  setInvoicePaidManually,
  setInvoiceUncollectible,
  setInvoiceVoid,
} from '../../../../api/payments/payments';
import AlertMessage from '../../../Shared/AppAlert';
import { INVOICE_STATUS as INVOICE_STATUS_V2 } from '../../../../api/payments/enums';
import { USER_STATUS } from '../../../../helpers/userStatus';
import { FeeDisclaimer } from '../../../Shared/FeeDisclaimer';

function InvoiceDetailContainer({ invoices, invoiceType, currentOrganization }) {
  const { isNewVoidFlowEnabled, isInvoicePlanServiceDatesEnabled } = useFlags();

  const [selectedInvoice, setSelectedInvoice] = useState(invoices[invoiceType]?.selected);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [invoiceUpdateData, setInvoiceUpdateData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [invoiceSentAlert, setInvoiceSentAlert] = useState(false);
  const [showUpdateStatusError, setShowUpdateStatusError] = useState(false);
  const [isPendingUpdateStatus, setIsPendingUpdateStatus] = useState(false);
  const [showPendingUpdateStatusModal, setShowPendingUpdateStatusModal] = useState(false);
  const [invoiceLinks, setInvoiceLinks] = useState({
    invoicePdf: selectedInvoice?.stripe?.invoicePdf,
    invoiceUrl: selectedInvoice?.stripe?.invoiceUrl,
    lastUrlRefresh: selectedInvoice?.stripe?.lastUrlRefresh,
  });

  const { t } = useTranslation();

  const organizationId = currentOrganization.id;

  let responsibleForBilling = null;
  const student = useStudent(selectedInvoice.student && selectedInvoice.student.id);
  if (student && student.responsibleForBilling) responsibleForBilling = student.responsibleForBilling;

  useEffect(() => {
    if (!selectedInvoice) setSelectedInvoice(invoices[invoiceType]?.selected);

    const doUpdateInvoice = async () => {
      if (invoiceUpdateData && selectedInvoice) {
        if (!invoiceUpdateData.id) invoiceUpdateData.id = selectedInvoice.id;

        if (invoiceType === 'recurring') {
          invoiceUpdateData.dateDue = Date.now();
          await setOrganizationInvoicePlan(organizationId, invoiceUpdateData);
        } else {
          await updateOrganizationInvoice(organizationId, invoiceUpdateData);
        }
        const updatedInvoice = { ...selectedInvoice, ...invoiceUpdateData };
        setInvoiceUpdateData(null);
        setSelectedInvoice(updatedInvoice);
      }
    };
    doUpdateInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, selectedInvoice, invoiceUpdateData]);

  useEffect(() => {
    const fetchV2InvoiceData = async () => {
      const [itemsResponse, paymentsResponse] = await Promise.all([
        getInvoiceItems(selectedInvoice.id),
        getInvoicePayments(selectedInvoice.id),
      ]);

      const items = mapV2InvoiceItems(itemsResponse.data);
      const payments = mapV2InvoicePayments(paymentsResponse.data);

      setSelectedInvoice((prev) => ({
        ...prev,
        invoiceItemList: items,
        payments: payments,
      }));
    };

    if (isV2Invoice(selectedInvoice)) {
      fetchV2InvoiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoice.id]);

  useEffect(() => {
    const getNewLinksIfExpired = async () => {
      const { createdAt, stripe } = selectedInvoice;
      if (!stripe?.invoiceId) return;

      const createdAtMoment = moment(createdAt);
      const lastUrlRefreshMoment = moment(invoiceLinks.lastUrlRefresh);
      // Stripe invoice links expire after 30 days. If a new one is generated, it will expire
      // after 10 days.
      const areInvoiceLinksExpired =
        createdAtMoment.isBefore(moment().subtract(29, 'days')) &&
        (!invoiceLinks.lastUrlRefresh || lastUrlRefreshMoment.isBefore(moment().subtract(9, 'days')));

      if (areInvoiceLinksExpired) {
        setIsLoading(true);
        const { data } = await refreshInvoiceLinks(stripe.invoiceId);
        setInvoiceLinks(data);
        setIsLoading(false);
      }
    };

    getNewLinksIfExpired();
  }, [invoiceLinks, selectedInvoice]);

  const renderPendingUpdateStatusModal = () => (
    <div className="pending-update-status-modal">
      {showUpdateStatusError ? (
        <div className="update-status-error">
          <span>{t('billing.invoiceDetails.updateStatusError')}</span>
          <Button
            className="update-status-error-close"
            primary
            content={t('Close')}
            onClick={() => {
              setShowPendingUpdateStatusModal(false);
              setShowUpdateStatusError(false);
            }}
          />
        </div>
      ) : null}
      {isPendingUpdateStatus ? <span>{t('billing.invoiceDetails.updateStatusPending')}</span> : null}
    </div>
  );

  const wasAlreadySent = Boolean(selectedInvoice?.sentAt);

  const handleOnSendFailed = (error) => {
    if (error) console.error(error);

    setInvoiceSentAlert({
      type: 'error',
      message: 'Something went wrong, please try again',
    });

    setIsSending(false);
  };

  const onSendInvoice = async () => {
    setIsSending(true);
    setShowSendModal(false);

    let updatedFields;

    try {
      updatedFields = await sendInvoice(selectedInvoice.id, wasAlreadySent);
      if (!updatedFields) return handleOnSendFailed();
    } catch (error) {
      return handleOnSendFailed(error);
    }

    setInvoiceSentAlert({
      type: 'success',
      message: 'billing.invoice.sent',
    });
    setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    setIsSending(false);
  };

  const onSetVoidV2 = async () => {
    try {
      const updatedFields = await setInvoiceVoid(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error) {
      setInvoiceSentAlert({
        type: 'error',
        message: error.message,
      });
    }
    setShowActionsModal(false);
  };

  const onSetPaidManuallyV2 = async () => {
    try {
      const updatedFields = await setInvoicePaidManually(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error) {
      setInvoiceSentAlert({
        type: 'error',
        message: error.message,
      });
    }
    setShowActionsModal(false);
  };

  const onSetUncollectibleV2 = async () => {
    try {
      const updatedFields = await setInvoiceUncollectible(selectedInvoice.id);
      setSelectedInvoice({ ...selectedInvoice, ...updatedFields });
    } catch (error) {
      setInvoiceSentAlert({
        type: 'error',
        message: error.message,
      });
    }
    setShowActionsModal(false);
  };

  const renderConfirmSendModal = () => (
    <div className="confirm-send-modal">
      {wasAlreadySent ? (
        <span>
          {t('billing.invoice.confirmResend1')}
          <b>{t('billing.invoice.confirmResend2')}</b>
          {t('billing.invoice.confirmResend3')}
        </span>
      ) : (
        <span>{t('billing.invoice.confirmSend')}</span>
      )}
      <Button primary style={{ marginTop: '1.5rem' }} loading={isSending} disabled={isSending} onClick={onSendInvoice}>
        {t('Confirm')}
      </Button>
    </div>
  );

  const onV2StatusSave = (data) => {
    const { status, userStatus } = data;
    if (status === 'void') return onSetVoidV2();
    if (userStatus === USER_STATUS.PAID_MANUALLY || userStatus === USER_STATUS.PAID) return onSetPaidManuallyV2();
    if (status === 'uncollectible') return onSetUncollectibleV2();
  };

  const onStatusSave = async (data) => {
    const { status, ...dataWithoutStatus } = data;
    const stripeInvoiceId = selectedInvoice.stripe?.invoiceId;

    if (isV2Invoice(selectedInvoice)) return onV2StatusSave(data);

    if (stripeInvoiceId && status === 'void' && isNewVoidFlowEnabled) {
      setShowActionsModal(false);
      setIsPendingUpdateStatus(true);
      setShowPendingUpdateStatusModal(true);

      let updateError;

      try {
        const { error } = await updateInvoiceStatus(stripeInvoiceId, data.status);
        updateError = error;
      } catch (error) {
        updateError = error;
      }

      setIsPendingUpdateStatus(false);

      if (updateError) {
        setShowUpdateStatusError(true);
      } else {
        setSelectedInvoice({ ...selectedInvoice, status: InvoiceStatus.VOID });
        setInvoiceUpdateData(dataWithoutStatus);
        setShowPendingUpdateStatusModal(false);
      }
    } else {
      setInvoiceUpdateData(data);
      setShowActionsModal(false);
    }
  };

  return (
    <Container fluid className="no-padding no-margin">
      <Grid stackable columns="equal">
        <Grid.Column>
          {renderSummary(organizationId, selectedInvoice)}
          {renderDetails(selectedInvoice)}
          {renderPayments(selectedInvoice)}
        </Grid.Column>
      </Grid>
    </Container>
  );

  function renderSummary(organizationId, selectedInvoice) {
    const hasInvoiceBeenPaid = hasParentPaidInvoice(selectedInvoice?.payments);

    const invoiceRows =
      selectedInvoice &&
      selectedInvoice.invoiceItemList &&
      selectedInvoice.invoiceItemList.map((item, index) => {
        // We only display the convenience fee to the provider once the parent has paid the invoice
        let displayItem = true;
        if (item.category?.toLowerCase() === InvoiceItemCategory.CONVENIENCE_FEE && !hasInvoiceBeenPaid) {
          displayItem = false;
        }

        return (
          <>
            {displayItem && (
              <>
                <Table.Row key={index} verticalAlign="middle">
                  <Table.Cell data-testid={`invoice-detail-category-${index}`}>{item.category}</Table.Cell>
                  <Table.Cell data-testid={`invoice-detail-item-${index}`}>{item.item}</Table.Cell>
                  <Table.Cell data-testid={`invoice-detail-notes-${index}`}>{item.notes}</Table.Cell>
                  <Table.Cell data-testid={`invoice-detail-amount-${index}`} textAlign="right">
                    {currencyFormatter(item.amount, { precision: 2 })}
                  </Table.Cell>
                </Table.Row>
                {item.discounts.map((discount, j) => (
                  <Table.Row key={`d-${index}-${j}`} disabled verticalAlign="middle">
                    <Table.Cell data-testid={`invoice-detail-category-${index}-discount-${j}`}>
                      {discount.discountType}
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell data-testid={`invoice-detail-amount-${index}-discount-${j}`} textAlign="right">
                      {'(-' + getFormatter(discount.amountType)(discount.amount) + ')'}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </>
        );
      });

    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let billedTo = t('No parents found :(');

    if (selectedInvoice.billedTo) {
      billedTo = selectedInvoice.billedTo.displayName;
    } else if (responsibleForBilling) {
      billedTo = responsibleForBilling.displayName;
    } else if (!isEmpty(family)) {
      billedTo = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
          // check if fullName exists
          const fullName = formatFullName(family[fam], true);
          return fullName === '' ? null : fullName;
        })[0];
    }

    const paidAmount = selectedInvoice.paidAmount ?? 0;
    const processingFeeLineItem = hasInvoiceBeenPaid
      ? getProcessingFeeLineItem(selectedInvoice?.invoiceItemList)
      : null;
    const ProcessingFeeAmount = processingFeeLineItem ? processingFeeLineItem?.amount : 0;

    return (
      <Segment clearing>
        <Modal
          size="mini"
          open={showPendingUpdateStatusModal}
          onClose={() => {
            if (isPendingUpdateStatus) return;
            setShowPendingUpdateStatusModal(false);
            setShowUpdateStatusError(false);
          }}
          content={renderPendingUpdateStatusModal()}
        />
        <Modal
          size="tiny"
          open={showActionsModal}
          onClose={() => setShowActionsModal(false)}
          header={t('Change invoice status')}
          content={
            <InvoiceActionForm
              invoice={selectedInvoice}
              invoiceType={invoiceType}
              onCancel={() => setShowActionsModal(false)}
              onSave={onStatusSave}
            />
          }
        />
        <Modal
          size="mini"
          open={showSendModal}
          onClose={() => setShowSendModal(false)}
          content={renderConfirmSendModal()}
          closeIcon={!isSending}
        />
        <AlertMessage alert={invoiceSentAlert} setAlert={setInvoiceSentAlert} />

        {renderPageTitle(currentOrganization, selectedInvoice)}

        <Segment className="no-shadow no-border top-border">
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>
                <Table compact basic="very" className="billing-summary">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell data-testid="invoice-detail-billed-to">{t('Billed to')}</Table.Cell>
                      <Table.Cell data-testid="invoice-detail-billed-to-value">{billedTo}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>

              <Grid.Column>
                <Table singleLine compact basic="very" className="billing-summary">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell data-testid="invoice-detail-invoice-number">{t('Invoice number')}</Table.Cell>
                      <Table.Cell data-testid="invoice-detail-invoice-number-value">
                        {selectedInvoice && selectedInvoice.id}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell data-testid="invoice-detail-invoice-date">{t('Due date')}</Table.Cell>
                      <Table.Cell data-testid="invoice-detail-invoice-date-value">
                        {selectedInvoice && selectedInvoice.dateDue && (
                          <DateTime epoch={selectedInvoice.dateDue} format={'MM/DD/YYYY'} />
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell data-testid="invoice-detail-invoice-notes">{t('Notes')}</Table.Cell>
                      <Table.Cell className="invoice-note" data-testid="invoice-detail-invoice-notes-value">
                        {selectedInvoice.notes || t('Thank you for your business')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Table stackable compact="very" basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell data-testid="invoice-detail-category">{t('Category')}</Table.HeaderCell>
                      <Table.HeaderCell data-testid="invoice-detail-item">{t('Item')}</Table.HeaderCell>
                      <Table.HeaderCell data-testid="invoice-detail-notes">{t('Notes')}</Table.HeaderCell>
                      <Table.HeaderCell data-testid="invoice-detail-amount" textAlign="right">
                        {t('Amount')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>{invoiceRows}</Table.Body>
                </Table>

                <Table stackable compact="very" basic="very" className="billing-summary">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell data-testid="invoice-detail-total" textAlign="right">
                        {t('Total')}
                      </Table.Cell>
                      <Table.Cell data-testid="invoice-detail-total-value" textAlign="right">
                        {selectedInvoice &&
                          currencyFormatter(selectedInvoice.total + ProcessingFeeAmount, {
                            precision: 2,
                          })}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell data-testid="invoice-detail-amount-paid" textAlign="right">
                        {processingFeeLineItem
                          ? t('invoiceDetailContainerAmountPaidByFamily')
                          : t('billing.invoiceDetails.amountPaid')}
                      </Table.Cell>
                      <Table.Cell data-testid="invoice-detail-amount-paid-value" textAlign="right">
                        (-
                        {selectedInvoice &&
                          currencyFormatter(paidAmount, {
                            precision: 2,
                          })}
                        )
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell data-testid="invoice-detail-amount-due" textAlign="right">
                        {t('billing.invoiceDetails.amountDue')}
                      </Table.Cell>
                      <Table.Cell data-testid="invoice-detail-amount-due-value" textAlign="right">
                        {selectedInvoice &&
                          currencyFormatter(selectedInvoice.total - paidAmount + ProcessingFeeAmount, {
                            precision: 2,
                          })}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan="4">
                        {!hasInvoiceBeenPaid && <FeeDisclaimer monetization={selectedInvoice.monetization} />}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment>
    );
  }

  function renderDetails(selectedInvoice) {
    const family = selectedInvoice && selectedInvoice.student && selectedInvoice.student.family;
    let email = t('billing.invoiceDetail.Noemailfound');

    if (selectedInvoice.billedTo) {
      email = selectedInvoice.billedTo.email;
    } else if (responsibleForBilling) {
      email = responsibleForBilling.email;
    } else if (!isEmpty(family)) {
      email = Object.keys(family)
        .filter((fam) => !!family[fam].email)
        .map((fam) => {
          // check if email exists
          if (family[fam] && family[fam].email) {
            return family[fam].email;
          }

          return null;
        })[0];
    }

    const revenueShareBillingFee = getBillingFeeByType(selectedInvoice?.billingFees, BILLING_FEE_TYPE.REVENUE_SHARE);
    const processingBillingFee = getBillingFeeByType(selectedInvoice?.billingFees, BILLING_FEE_TYPE.PROCESSING_FEE);

    const renderBillingFeeRow = (fee, feeType, descriptionKey) =>
      fee && (
        <Table.Row key={feeType}>
          <Table.Cell data-testid={`invoice-detail-${feeType}`}>{t(descriptionKey)}</Table.Cell>
          <Table.Cell data-testid={`invoice-detail-${feeType}-value`}>
            (-{currencyFormatter(centsToDollars(fee.amount), { precision: 2 })})
            {fee.description && (
              <span className="ws-fee-list-item-description-text">
                (
                <span key={`${fee.amount}-${fee.description}-${fee.feeType}`}>
                  {t(getWSFeeListItemDescription(fee), {
                    descriptionValue: fee.description,
                  })}
                </span>
                )
              </span>
            )}
          </Table.Cell>
        </Table.Row>
      );

    const totalFees = (revenueShareBillingFee?.amount || 0) + (processingBillingFee?.amount || 0);
    const amountDeposited = selectedInvoice.total - centsToDollars(totalFees);

    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header data-testid="invoice-detail-details-header" as="h2" content={t('Details')} />
        </Segment>

        <Segment className="no-shadow no-border no-padding no-margin top-border">
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell data-testid="invoice-detail-id">Id</Table.Cell>
                <Table.Cell data-testid="invoice-detail-id-value">{selectedInvoice && selectedInvoice.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell data-testid="invoice-detail-created-by">{t('Created')}</Table.Cell>
                <Table.Cell data-testid="invoice-detail-created-by-value">
                  {selectedInvoice?.createdBy &&
                    selectedInvoice?.createdBy?.displayName &&
                    selectedInvoice.createdBy.displayName + ', '}
                  {selectedInvoice && selectedInvoice.createdAt && (
                    <DateTime epoch={selectedInvoice.createdAt} format={'MM/DD/YYYY h:mm A'} />
                  )}
                </Table.Cell>
              </Table.Row>
              {selectedInvoice?.updatedBy && (
                <Table.Row>
                  <Table.Cell data-testid="invoice-detail-updated-by">{t('Updated')}</Table.Cell>
                  <Table.Cell data-testid="invoice-detail-updated-by-value">
                    {selectedInvoice?.updatedBy &&
                      selectedInvoice?.updatedBy?.displayName &&
                      selectedInvoice?.updatedBy?.displayName + ', '}
                    {selectedInvoice && selectedInvoice?.updatedBy && selectedInvoice?.updatedBy?.dateTime && (
                      <DateTime epoch={selectedInvoice?.updatedBy?.dateTime} format={'MM/DD/YYYY h:mm A'} />
                    )}
                  </Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell data-testid="invoice-detail-finalized-by">{t('Finalized')}</Table.Cell>
                <Table.Cell data-testid="invoice-detail-finalized-by-value">
                  {selectedInvoice && selectedInvoice.createdAt && (
                    <DateTime epoch={selectedInvoice.createdAt} format={'MM/DD/YYYY h:mm A'} />
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell data-testid="invoice-detail-email">{t('Email invoice sent to')}</Table.Cell>
                <Table.Cell data-testid="invoice-detail-email-value">{email}</Table.Cell>
              </Table.Row>
              {isInvoicePlanServiceDatesEnabled &&
                !!selectedInvoice.dateServiceStart &&
                !!selectedInvoice.dateServiceEnd && (
                  <Table.Row>
                    <Table.Cell data-testid="invoice-detail-service-interval">
                      {t('invoiceplan.serviceInterval')}
                    </Table.Cell>
                    <Table.Cell data-testid="invoice-detail-service-interval-value">
                      <DateTime epoch={selectedInvoice.dateServiceStart} format={'MM/DD/YYYY'} /> -{' '}
                      <DateTime epoch={selectedInvoice.dateServiceEnd} format={'MM/DD/YYYY'} />
                    </Table.Cell>
                  </Table.Row>
                )}
              {selectedInvoice.status === InvoiceStatus.PAID && selectedInvoice?.billedTo?.displayName && (
                <Table.Row>
                  <Table.Cell data-testid="invoice-detail-paid-by">{t('Paid by')}</Table.Cell>
                  <Table.Cell data-testid="invoice-detail-paid-by-value">
                    {selectedInvoice.billedTo.displayName}
                  </Table.Cell>
                </Table.Row>
              )}
              {isLoading && <LoadingIndicator />}
              {selectedInvoice?.invoicePlanId ? (
                <Table.Row>
                  <Table.Cell data-testid="invoice-detail-invoice-plan-id">
                    {t('invoiceDetailContainerInvoicePlanId')}
                  </Table.Cell>
                  <Table.Cell data-testid="invoice-detail-invoice-plan-id-value">
                    {selectedInvoice.invoicePlanId}
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {invoiceLinks.invoiceUrl ? (
                <Table.Row>
                  <Table.Cell data-testid="invoice-detail-invoice-link">
                    {t('invoiceDetailContainerInvoiceLink')}
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      href={invoiceLinks.invoiceUrl}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="invoice-detail-invoice-link-value"
                    >
                      {t('invoiceDetailContainerInvoiceLinkCaption')}
                    </a>
                    {invoiceLinks.invoicePdf ? (
                      <>
                        {' '}
                        |{' '}
                        <a
                          href={invoiceLinks.invoicePdf}
                          target="_blank"
                          rel="noreferrer"
                          data-testid="invoice-detail-invoice-pdf-link"
                        >
                          PDF
                        </a>
                      </>
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {revenueShareBillingFee || processingBillingFee ? (
                <>
                  <Table.Row>
                    <Table.Cell data-testid="invoice-total">{t('invoiceDetailContainerAmountPaidByFamily')}</Table.Cell>
                    <Table.Cell data-testid="invoice-total-value">
                      {currencyFormatter(selectedInvoice.total, { precision: 2 })}
                    </Table.Cell>
                  </Table.Row>

                  {renderBillingFeeRow(revenueShareBillingFee, 'ws-fee', 'invoiceDetailContainerWSFee')}
                  {renderBillingFeeRow(processingBillingFee, 'processing-fee', 'invoiceDetailContainerConvenienceFee')}

                  <Table.Row>
                    <Table.Cell data-testid="invoice-detail-amount-deposited-to-provider" className="sum-top-border">
                      {t('invoiceDetailContainerAmountDepositedToProvider')}
                    </Table.Cell>
                    <Table.Cell
                      data-testid="invoice-detail-amount-deposited-to-provider-value"
                      className="sum-top-border"
                    >
                      {currencyFormatter(amountDeposited, { precision: 2 })}
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : null}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }

  function renderPayments(selectedInvoice) {
    const payments = selectedInvoice?.payments?.map((payment, index) => {
      const latestStatusFields = isV2Invoice(selectedInvoice)
        ? {
            status: selectedInvoice.status,
            statusDescription: selectedInvoice.statusDescription,
          }
        : {
            userStatus: selectedInvoice.userStatus,
            userStatusDescription: selectedInvoice.userStatusDescription,
          };
      return (
        <Table.Row key={payment.createdAt}>
          <Table.Cell data-testid={`invoice-detail-payment-amount-value-${index}`}>
            {payment.paidAmount && currencyFormatter(payment.paidAmount / 100, { precision: 2 })}
          </Table.Cell>
          <Table.Cell data-testid={`invoice-detail-payment-date-value-${index}`}>
            {<DateTime epoch={payment.createdAt} format={'MM/DD/YYYY h:mm A'} />}
          </Table.Cell>
          <Table.Cell data-testid={`invoice-detail-payment-status-value-${index}`}>
            {renderInvoiceStatus({
              ...payment,
              ...(index == selectedInvoice.payments.length - 1 ? latestStatusFields : {}),
            })}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Segment clearing>
        <Segment basic clearing className="no-padding">
          <Header as="h2" content={t('Payments')} />
        </Segment>

        <Segment className="no-shadow no-border top-border">
          <Table singleLine basic="very">
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell data-testid="invoice-detail-payment-amount">{t('Amount')}</Table.HeaderCell>
                <Table.HeaderCell data-testid="invoice-detail-payment-date">{t('Process Date')}</Table.HeaderCell>
                <Table.HeaderCell data-testid="invoice-detail-payment-status">{t('Status')}</Table.HeaderCell>
              </Table.Row>

              {payments ? (
                payments
              ) : (
                <Table.Row key={'noPayment'} textAlign="center">
                  <Table.Cell colSpan={6} data-testid="invoice-detail-no-payments-made">
                    {t('No payments made')}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }

  function renderPageTitle(organization, selectedInvoice) {
    const showActionsButton =
      selectedInvoice &&
      !selectedInvoice.alreadyPaid &&
      !selectedInvoice.manuallyPaid &&
      ![InvoiceStatus.PROCESSING, InvoiceStatus.VOID, InvoiceStatus.PAID].includes(selectedInvoice.status);

    const showSendButton =
      isV2Invoice(selectedInvoice) &&
      [
        INVOICE_STATUS_V2.SCHEDULED,
        INVOICE_STATUS_V2.INVOICE_SENT,
        INVOICE_STATUS_V2.NOTIFICATION_SENT,
        INVOICE_STATUS_V2.UNCOLLECTIBLE,
      ].includes(selectedInvoice.status);

    return (
      <Segment basic clearing className="no-padding no-border no-margin">
        {showActionsButton && renderActionsButton()}

        {showSendButton ? (
          <Button
            data-testid="send-invoice-button"
            primary={!isSending}
            floated="right"
            onClick={() => {
              setShowSendModal(true);
              setInvoiceSentAlert(null);
            }}
            loading={isSending}
            disabled={isSending}
          >
            {t(wasAlreadySent ? 'billing.invoice.resendInvoice' : 'billing.invoice.sendInvoice')}
          </Button>
        ) : null}

        <Header as="h2" floated="left">
          <span data-testid="invoice-detail-title">{t('Summary')}</span>{' '}
          <span data-testid="invoice-detail-status">{renderInvoiceStatus(selectedInvoice)}</span>
        </Header>
      </Segment>
    );
  }

  function renderInvoiceStatus(selectedInvoice) {
    return <InvoiceStatusComponent invoice={selectedInvoice} />;
  }

  function renderActionsButton() {
    return (
      <Button
        data-testid="invoice-detail-actions-button"
        primary
        icon="ellipsis horizontal"
        floated="right"
        loading={!!invoiceUpdateData}
        content={t('Actions')}
        onClick={() => {
          setInvoiceSentAlert(false);
          setShowActionsModal(true);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices,
    currentOrganization: state.organizations.currentOrganization,
  };
};

export default connect(mapStateToProps)(InvoiceDetailContainer);
