import { useMemo } from 'react';
import { ClaimsManagementPage, FoodProgramQueryProvider } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { useAuthUser } from '../../../hooks/useUser';
import { useRoutes } from '../../../navigation/navigationHooks';
import { RouteNameEnum } from '../../../navigation/types';
import useSegmentPage from '../../../segment/hooks/useSegmentPage';
import { PAGE_NAMES } from '../../../segment/types';
import { getCurrentEnvironment } from '../../../common/utils/environmentUtils';

const ClaimsList = () => {
  const environment = useMemo(() => getCurrentEnvironment(), []);
  const { t } = useTranslation();
  const title = t('food.claims.list');
  const { gotoRouteByName } = useRoutes();
  const { token } = useAuthUser();
  useSegmentPage(PAGE_NAMES.foodProgramClaims);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body className="dashboard" />
    </Helmet>
  );

  if (!token) return null;

  return (
    <FoodProgramQueryProvider authToken={token} environment={environment}>
      {renderPageHead()}
      <ClaimsManagementPage
        onEditClaim={(claimId: string) => {
          gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIM_UPDATE, [{ name: 'claimId', value: claimId }]);
        }}
        onCreateClaim={() => gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_CLAIM_CREATE)}
      />
    </FoodProgramQueryProvider>
  );
};
export default ClaimsList;
