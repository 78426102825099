import { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';

import classnames from 'classnames/bind';
import AppStoreLinksContainer from '../Components/Dashboard/AppStoreLinksContainer';
import DashboardHeader from '../Components/Dashboard/DashboardHeader';
import Invitations from '../Components/Dashboard/Invitations';
import RecentPhotos from '../Components/Dashboard/RecentPhotos';
import StatSummary from '../Components/Dashboard/StatSummary';
import TodaysActivityFeed from '../Components/Dashboard/TodaysActivityFeed';
import { OnboardingBanner } from '../Components/Onboarding';
import { useOnboardingBannerState } from '../Components/Onboarding/onboardingHooks';
import PageHeader from '../Components/Shared/PageHeader';
import { useOrganization, useOrganizationContactsListener } from '../hooks/useOrganizations';
import { useUser } from '../hooks/useUser';
import { paths } from '../navigation/paths';
import { PAGE_NAMES, useSegmentPage } from '../segment';
import StudentsGalleryParent from '../students/components/gallery/StudentsGalleryParent';

import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './Dashboard.module.scss';

const cx = classnames.bind(styles);

export default function Dashboard() {
  const FLORIDA_ID = process.env.REACT_APP_FLORIDA_NETWORK_ID;
  const organization = useOrganization();
  useOrganizationContactsListener(organization.id);

  useSegmentPage(PAGE_NAMES.dashboard);

  const { isParent } = useUser();
  const { isFloridaNetworkEnabled } = useFlags();

  const { collapseOnboardingBanner, expandOnboardingBanner, isCollapsed } = useOnboardingBannerState();

  const onClickUpDown = useCallback(
    (_isCollapsed) => {
      _isCollapsed ? collapseOnboardingBanner() : expandOnboardingBanner();
    },
    [collapseOnboardingBanner, expandOnboardingBanner]
  );

  if (isFloridaNetworkEnabled && organization?.networks?.length && organization?.networks?.includes(FLORIDA_ID)) {
    return <Redirect to={paths.updateWebsite} />;
  }

  return (
    <div className={cx({ withBackground: isParent })}>
      <PageHeader pageName={'Dashboard'} classes="dashboard" />
      <DashboardHeader />
      <Segment basic>
        <Container>
          <Grid container stackable columns={1}>
            <OnboardingBanner collapsed={isCollapsed} onClickUpDown={onClickUpDown} />
            {isParent && (
              <>
                <AppStoreLinksContainer />
                <Grid.Column width={16}>
                  <StudentsGalleryParent />
                </Grid.Column>
              </>
            )}
            <StatSummary />
            <Invitations />
            <RecentPhotos />
            <TodaysActivityFeed />
          </Grid>
        </Container>
      </Segment>
    </div>
  );
}
