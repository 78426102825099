import { useCallback } from 'react';
import i18n from 'i18next';
import userflow from 'userflow.js';
import { useDispatch } from 'react-redux';

import { functions } from '../../api/firebase';
import { logError } from '../../rollbar';

import { UserflowEventEnum } from './enums';

import { useOrganization } from '../../hooks/useOrganizations';
import { useAuthUser } from '../../hooks/useUser';

import { userflowInitializedAction } from './userflowRedux';

export const useUserflow = () => {
  const { currentUser } = useAuthUser();
  const currentOrganization = useOrganization();

  const dispatch = useDispatch();

  const userflowInitialize = useCallback(async () => {
    if (
      !currentUser?.uid ||
      !currentOrganization?.id ||
      currentOrganization.fetching !== false // explicitly check for false
    )
      return;

    try {
      // Get verified user with userflow signature.
      const data = await userflowIdentifyVerify(currentOrganization?.id);
      const { user, organization, signature } = data.data;

      if (!user?.uid || !organization?.id || !signature?.user?.length || !signature?.organization?.length) return;

      //Check the website language for userflow popup
      const lang = i18n.language || 'en';

      // Initialize userflow.
      userflow.init(process.env.REACT_APP_USERFLOW_TOKEN ?? '<REACT_APP_USERFLOW_TOKEN>');
      // userflow identify user and organization.
      // verify user identity as per https://userflow.com/docs/dev/identity-verification
      try {
        await userflow.identify(
          user.uid,
          { ...user, locale_code: lang === 'en' ? 'en-US' : 'es-ES' },
          {
            signature: signature.user,
          }
        );
      } catch (error) {
        logError(`Error occurred in userflow.identify`, error, { error });
      }

      // Add organization to userflow group.
      try {
        await userflow.group(organization.id, organization, {
          signature: signature.organization,
        });
      } catch (error) {
        logError(`Error occurred in userflow.group`, error, { error });
      }
      dispatch(userflowInitializedAction());
    } catch (error) {
      logError(`Error occurred in userflowInitialize`, error, { error });
    }
  }, [currentOrganization, currentUser, dispatch]);

  // Wrapper around userflow.start
  const userflowStart = useCallback((flowId: string): void => {
    userflow.start(flowId);
  }, []);

  // Wrapper around userflow.track
  const userflowTrack = useCallback(async (event: UserflowEventEnum, options?: any): Promise<void> => {
    try {
      await userflow.track(event, options ?? {});
    } catch (error) {
      logError(`Error occurred in userflowTrack`, error, { error });
    }
  }, []);

  return { userflowInitialize, userflowStart, userflowTrack };
};

async function userflowIdentifyVerify(organizationId: string): Promise<any> {
  return await functions().httpsCallable('callables-trackers-userflowIdentifyVerify')({ organizationId });
}
