import React from 'react';

const Star = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 16 15">
      <path
        fill="#FEBE19"
        fillRule="evenodd"
        d="M9.724 5.088L7.608 0 5.492 5.088 0 5.528l4.185 3.584-1.279 5.36L7.608 11.6l4.703 2.872-1.279-5.36 4.185-3.584-5.493-.44zm3.016 1.245l-3.704-.297-1.428-3.432-1.427 3.432-3.704.297L5.299 8.75l-.862 3.616 3.171-1.938 3.172 1.938-.862-3.616 2.822-2.417z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Star;
