import { useTranslation } from 'react-i18next';
import { WsSpinner, WsWidgetSizeEnum } from '../../../../common';
import PageHeader from '../../../../Components/Shared/PageHeader';
import PageTitle from '../../../../Components/Shared/PageTitle';
import FinancialSummaryFiltersForm from './FinancialSummaryFiltersForm';
import { FinancialSummaryFiltersType } from './types';
import { useCallback, useMemo, useState } from 'react';
import './FinancialSummary.scss';
import { useFinancialSummary } from './financialSummaryHooks';
import { EmptyReport } from '../../../../reports';
import FinancialSummaryReport from './FinancialSummaryReport';
import { MONTH_LIST } from './constants';
import FinancialSummaryOverview from './FinancialSummaryOverview';
import emptyDataReportImage from '../../../../styles/img/empty-data-report.svg';

const FinancialSummaryContainer: React.FC = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<FinancialSummaryFiltersType>();

  const { financialSummary, isLoading } = useFinancialSummary(filters) || { financialSummary: [], isLoading: false };

  const onFilter = useCallback((filters: FinancialSummaryFiltersType) => {
    setFilters(filters);
  }, []);

  const emptyTitle = useMemo<string | undefined>(() => {
    const translatedMonthList = filters?.selectedMonths?.map((monthKey) => t(monthKey)) || [];
    const reportRange = translatedMonthList.join(', ') + ' ' + filters?.selectedYear?.toString();
    return filters
      ? t('financialSummary.report.nodata.error', { range: reportRange })
      : t('financialSummary.report.required.error');
  }, [filters, t]);

  const selectedYear = filters?.selectedYear;
  const selectedMonths = [...(filters?.selectedMonths || [])].sort((a, b) => {
    return MONTH_LIST.indexOf(a) - MONTH_LIST.indexOf(b);
  });
  // Remove undefined values from the array
  const reportColumns = [selectedYear, ...selectedMonths, 'financialSummary.report.title.total'].filter(
    Boolean
  ) as string[];

  return (
    <>
      {isLoading && <WsSpinner hasOverlay={true} spinnerColor="sky" size={WsWidgetSizeEnum.LARGE} />}
      <div className="container px-5" data-testid={'financial-summary-container'}>
        <PageHeader pageName={'financialSummary.title'} classes="attendance" />
        <PageTitle title={t('financialSummary.title')} />
        <FinancialSummaryFiltersForm onFilter={onFilter} loading={isLoading} />
        <FinancialSummaryOverview loading={isLoading} financialSummary={financialSummary} filters={filters} />
        <div className="h-full w-full">
          {isLoading ? null : financialSummary?.length === 0 ? (
            <EmptyReport title={emptyTitle} img={filters ? emptyDataReportImage : ''} />
          ) : (
            <>
              <div className="padding top">
                <FinancialSummaryReport data={financialSummary} columns={reportColumns} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default FinancialSummaryContainer;
