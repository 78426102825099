import { isProd } from '../config/env';

/**
 * These functions are only meant to be called from the hooks.
 */
const SHOULD_SEND_ANALYTICS = process.env.REACT_APP_SEGMENT_ENABLED === 'true';

const FUNCTION_NAME = {
  track: 'track',
  identify: 'identify',
  page: 'page',
};

export function callSegmentIdentify(segmentClient, data) {
  callSegmentFunction(segmentClient, FUNCTION_NAME.identify, data);
}
export function callSegmentPage(segmentClient, data) {
  callSegmentFunction(segmentClient, FUNCTION_NAME.page, data);
}
export function callSegmentTrack(segmentClient, data) {
  callSegmentFunction(segmentClient, FUNCTION_NAME.track, data);
}

function callSegmentFunction(segmentClient, functionName, data) {
  try {
    if (SHOULD_SEND_ANALYTICS) {
      segmentClient[functionName](data);
    } else if (!isProd()) {
      console.log(`Segment ${functionName}`, data);
    }
  } catch (error) {
    console.log(`Segment ${functionName}`, error, data);
  }
}
