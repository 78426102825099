import {
  Avatar,
  DataTable,
  InfoIcon,
  Label,
  PDFModal,
  ReportToolbar,
  Tooltip,
  WidgetSizeEnum,
} from '@wonderschool/common-base-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userHasPermission } from '../../api/firebase/account';
import { fetchBillingDetails } from '../../api/firebase/helpers/users';
import { fetchOrganizationUsers } from '../../api/firebase/users';
import { useContacts } from '../../contacts/contactsHooks';
import { phoneNumberParse } from '../../helpers/utils';
import useRooms from '../../hooks/useRooms';
import { WsApp, WsCheckmark } from '../../icons';
import { logError } from '../../rollbar';
import NoResults from '../Shared/NoResults';

import { Student } from '@wonderschool/ws-types';
import { getLocations } from '../../redux/actions/locationActions';
import { generateParentsListingCSV } from './generateParentsListingCSV';
import { generateParentsListingPDF } from './generateParentsListPDF';
import { InviteStatus } from './InviteStatus';

export function ParentsListingTable({ studentsList, stateFilter }) {
  const { t } = useTranslation();
  const canListBilling = userHasPermission('can_list_billing');
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [studentBillingDetails, setStudentBillingDetails] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const { contacts } = useContacts();
  const locations = getLocations();
  const locationName = locations[0]?.name;
  const rooms = useRooms();
  const organizationId = studentsList[0]?.organization || studentsList[0]?.defaultOrganization;

  async function getOrgUser() {
    const allUsers = await fetchOrganizationUsers(organizationId);
    if (allUsers) {
      setAllUsers(allUsers.list);
    }
  }

  useEffect(() => {
    getOrgUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsList, contacts]);

  /**
   * Get the relationship by family member
   * - If the relationship notes exists, then return the relationshipNotes
   * - If the relationships notes do not exist return 'Other'
   * - If the relationship does not exist return '-'
   * - If the relationship is parent return 'Parent'
   * @param member object with the family member data
   */
  const getRelationshipByMember = (member: any) => {
    if (member.relationship) {
      if (member.relationship === 'parent') {
        return t('ParentOnly');
      } else {
        // return Other if the relationship is emergencyContact but the relationshipNotes is empty else return relationshipNotes
        return member.relationshipNotes ? member.relationshipNotes : t('Other');
      }
    } else {
      return '-';
    }
  };

  // Filter students by autopay.
  if (stateFilter.filters.autopay) {
    if (stateFilter.filters.autopay === 'yes') {
      studentsList = studentsList.filter((student: any) => studentBillingDetails.includes(student.id));
    } else {
      studentsList = studentsList.filter((student: any) => !studentBillingDetails.includes(student.id));
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const studentsListIds = studentsList.map((obj: { id: any }) => obj.id);
      try {
        studentsListIds.forEach(async (id: any) => {
          const billing = await fetchBillingDetails(organizationId, id);
          if (billing.data.hasPaymentMethod === true && billing.data.canChargeAutomatically === true) {
            setStudentBillingDetails((prev) => [...prev, billing.studentId]);
          }
        });
      } catch (e) {
        logError('Error trying to get billing details for responsible parent.', e);
      }
    };
    if (canListBilling) {
      fetchData();
    }
  }, [canListBilling, organizationId, studentsList]);

  const renderParentsDisplay = (student: Student) => {
    if (student?.family && Object.values(student?.family).length > 0) {
      return Object.values(student?.family).map((member: any, index: number) => {
        return (
          <div key={index} className="my-4">
            <div className="text-gray-900">{member.displayName ? member.displayName : '-'}</div>
            <div className="mt-1 font-normal text-gray-700">{getRelationshipByMember(member)}</div>
          </div>
        );
      });
    } else {
      return '-';
    }
  };

  const renderParentsDetails = (student: Student) => {
    if (student?.family && Object.values(student?.family).length > 0) {
      return Object.values(student?.family).map((member: any, index: number) => {
        return (
          <div key={index} className="my-4">
            <div className="mt-1 text-gray-900">{member.phone ? phoneNumberParse(member.phone) : '-'}</div>
            <div className="mt-1 text-gray-900">{member.email ? member.email : '-'}</div>
          </div>
        );
      });
    } else {
      return '-';
    }
  };

  function renderParentsAppOpen(student: any) {
    if (student?.family && Object.values(student?.family).length > 0) {
      return Object.values(student?.family).map((member: any, index: number) => {
        if (!allUsers) return false;
        const foundUser = allUsers.find((user) => user.relationship === 'parent' && user.email === member.email);
        if (foundUser) {
          return (
            <div key={index} className="my-6">
              {foundUser.stats?.appLastOpened ? <WsApp /> : '-'}
            </div>
          );
        }
        return (
          <div key={index} className="my-6">
            -
          </div>
        );
      });
    } else {
      return <div className="my-6">-</div>;
    }
  }

  const renderParentsPaymentDetails = (student: any) => {
    if (student?.family && Object.values(student?.family).length > 0) {
      return Object.values(student?.family).map((member: any, index: number) => {
        return (
          <div key={index} className="my-6">
            <div className="text-gray-900">
              {student.responsibleForBilling?.email === member.email ? 'Primary' : '-'}
            </div>
            <div className="text-gray-600">
              {student.responsibleForBilling?.email === member.email && studentBillingDetails.includes(student.id)
                ? 'AutoPay'
                : ''}
            </div>
          </div>
        );
      });
    }
  };

  const renderParentsPickUpDetails = (student: Student) => {
    if (student?.family && Object.values(student?.family).length > 0) {
      return Object.values(student?.family).map((member: any, index: number) => {
        return (
          <div key={index} className="my-6">
            <div className="text-gray-900">{member.allowLoginWithPin ? <WsCheckmark /> : '-'}</div>
          </div>
        );
      });
    } else {
      return <div>-</div>;
    }
  };

  const columns = [
    {
      label: 'Students',
      fieldName: 'studentName',
      renderCell: (studentsList: any) => {
        return (
          <div className="flex items-center">
            <div className="h-11 w-11 flex-shrink-0">
              <div className="flex h-11 w-11 justify-center rounded-full text-center">
                {studentsList.picture ? (
                  <Avatar name={studentsList.picture} size={WidgetSizeEnum.MEDIUM} photoURL={studentsList.picture} />
                ) : (
                  <Avatar name={studentsList.displayName} size={WidgetSizeEnum.MEDIUM} />
                )}
              </div>
            </div>
            <div className="ml-4 cursor-default">
              <div className="font-medium text-gray-900">{studentsList.displayName}</div>
              <div className="mt-1 font-normal text-gray-700">
                {rooms.find((room) => room.id === studentsList.rooms[0])?.name ?? '-'}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Family and Contacts',
      fieldName: 'FamilyandContacts',
      renderCell: (studentsList: Student) => {
        return renderParentsDisplay(studentsList);
      },
    },
    {
      label: 'Contacts Info',
      fieldName: 'ContactsInfo',
      renderCell: (studentsList: Student) => {
        return renderParentsDetails(studentsList);
      },
    },
    {
      label: (
        <Label extraClasses="flex flex-row align-middle mb-0">
          {t('Pickup')}
          <Tooltip content={t('ShowsPickupStudent')} action="hover">
            <InfoIcon className={'ml-1 h-5'} />
          </Tooltip>
        </Label>
      ),
      fieldName: 'Pickup',
      renderCell: (studentsList: Student) => {
        return renderParentsPickUpDetails(studentsList);
      },
    },
    {
      label: (
        <Label extraClasses="flex flex-row align-middle mb-0">
          {t('Payment')}
          <Tooltip content={t('ShowsPaymentTooltip')} action="hover">
            <InfoIcon className={'ml-1 h-5'} />
          </Tooltip>
        </Label>
      ),
      fieldName: 'Payment',
      renderCell: (studentsList: Student) => {
        return renderParentsPaymentDetails(studentsList);
      },
    },
    {
      label: (
        <Label extraClasses="flex flex-row align-middle mb-0">
          {t('App')}
          <Tooltip content={t('ShowsAppTooltip')} action="hover">
            <InfoIcon className={'ml-1 h-5'} />
          </Tooltip>
        </Label>
      ),
      fieldName: 'App',
      renderCell: (studentsList: Student) => {
        return renderParentsAppOpen(studentsList);
      },
    },
    {
      label: (
        <Label extraClasses="flex flex-row align-middle mb-0">
          {t('Invite')}
          <Tooltip content={t('ShowsInviteStatusTooltip')} className="max-w-32" action="hover">
            <InfoIcon className={'ml-1 h-5'} />
          </Tooltip>
        </Label>
      ),
      fieldName: 'Invite',
      renderCell: (studentsList: Student) => {
        return InviteStatus(studentsList, t);
      },
    },
  ];

  const rowClasses = 'even:bg-gray-100 even:border-b border-gray-300 min-h-24';
  const studentsListReport = useMemo<any>(() => {
    return studentsList
      .flatMap((student: any) => {
        if (student.family && Object.values(student.family).length > 0) {
          return Object.values(student.family).map((family: any) => {
            return {
              studentName: student?.displayName,
              rooms: rooms.find((room: any) => room.id === student.rooms[0])?.name,
              contacts: `${family?.displayName} (${family?.relationship})`,
              email: family?.email ? family.email : '',
              phone: family?.phone ? phoneNumberParse(family.phone) : '',
              pickup: family?.allowLoginWithPin ? (family.allowLoginWithPin ? 'Yes' : 'No') : '',
              payment: family?.email === student.responsibleForBilling?.email ? 'Primary Payer' : '-',
              autopay:
                family?.email === student.responsibleForBilling?.email && studentBillingDetails?.includes(student.id)
                  ? 'AutoPay'
                  : '',
              app: family.stats?.appLastOpened ? 'Yes' : 'No',
              invite: family.status,
              locationName: locationName,
            };
          });
        }
      })
      .filter((s: any) => !!s);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExportPDF = useCallback(() => {
    setIsPDFModalOpen(true);
  }, []);

  const onExportCSV = useCallback(() => {
    generateParentsListingCSV(studentsList, rooms, studentBillingDetails, locationName);
  }, [rooms, studentsList, studentBillingDetails, locationName]);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {studentsList.length === 0 ? (
          <tr className="block text-center">
            <td colSpan={7} className="m-auto block text-center">
              <NoResults title="NoParentsFound" subTitle="NoParentsFoundSubTitle" data-testid="no-contacts-students" />
            </td>
          </tr>
        ) : (
          <>
            <ReportToolbar onExportPDF={onExportPDF} onExportCSV={onExportCSV} />
            <DataTable
              columns={columns}
              rowClasses={rowClasses}
              headerClasses="text-center"
              data={studentsList}
            ></DataTable>
            <PDFModal
              isOpen={isPDFModalOpen}
              report={studentsListReport}
              pdfGenerator={generateParentsListingPDF as any}
              onClose={() => setIsPDFModalOpen(false)}
            />
          </>
        )}
      </div>
    </>
  );
}

export default ParentsListingTable;
