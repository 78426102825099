import { useTranslation } from 'react-i18next';
import { Button, Container, Header, Image, Segment } from 'semantic-ui-react';

// Import components
import { routes } from '../config/routes';
import { PAGE_NAMES, useSegmentPage } from '../segment';
import wsLogo from '../styles/img/wonderschool/logo-base.png';

const Error = () => {
  const { t } = useTranslation();
  useSegmentPage(PAGE_NAMES.error);

  return (
    <Container className="no-padding no-margin">
      <Segment basic>
        <Image src={wsLogo} size="medium" centered />
      </Segment>
      <Header textAlign="center" as="h1" content={t('Oops, something went wrong!')} />

      <Segment basic textAlign="center">
        <Button
          color="red"
          icon="sign-out"
          content={t('Try signing out')}
          onClick={() => {
            window.location.replace(routes.signout);
          }}
        />{' '}
        <Button
          color="blue"
          primary
          icon="redo"
          content={t('Try starting over')}
          onClick={() => {
            window.location.replace(routes.home);
          }}
        />
      </Segment>
    </Container>
  );
};

export default Error;
