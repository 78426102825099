import { Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import components.
import UserProfileMenu from './UserProfileMenu';
import ChangeLanguage from '../../Components/Shared/ChangeLanguage';
import { FeatureFlagsLink } from '../../feature-flags';

import { routes } from '../../config/routes';

import WSA from '../../WSA';

const TopNavigation = ({ viewport = 'desktop', handleShowMenu, history }) => {
  const { t } = useTranslation();
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();

  // don't show if there's no user
  if (!isReady || !isAuthenticated) return null;

  const nav =
    viewport === 'mobile' ? (
      <Menu id="main-menu" stackable>
        <Menu.Item
          onClick={() => {
            handleShowMenu();
            history.push(routes.signout);
          }}
          content={t('Sign out')}
          style={{ cursor: 'pointer' }}
        />

        <Menu.Item>
          <FeatureFlagsLink mobile />
        </Menu.Item>

        <Menu.Item>
          <ChangeLanguage />
        </Menu.Item>

        <Menu.Item>
          <UserProfileMenu viewport="desktop" />
        </Menu.Item>
      </Menu>
    ) : (
      <Menu borderless className="top-nav">
        <Menu.Menu position="right">
          <Menu.Item>
            <FeatureFlagsLink mobile={false} />
          </Menu.Item>

          <Menu.Item>
            <ChangeLanguage />
          </Menu.Item>

          <UserProfileMenu />
        </Menu.Menu>
      </Menu>
    );

  return nav;
};

export default TopNavigation;
