import React from 'react';

const Information = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="#2269AD"
        fillRule="evenodd"
        d="M8 2.498a5.502 5.502 0 100 11.005A5.502 5.502 0 008 2.498zM1.498 8a6.503 6.503 0 1113.005 0A6.503 6.503 0 011.498 8zm5.335 2.335a.5.5 0 01.5-.5h.273V8H7.34a.5.5 0 010-1h.767a.5.5 0 01.5.5v2.334h.267a.5.5 0 110 1h-1.54a.5.5 0 01-.5-.5zm1.07-5.504a.667.667 0 11-.003 0h.002z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Information;
