import { useTranslation } from 'react-i18next';
import { Card, Divider } from 'semantic-ui-react';

// utils
enum StatsEnum {
  CHECKEDIN = 'checkedIn',
  STUDENTS = 'students',
  ROOMS = 'rooms',
  STAFF = 'staff',
  TRANSACTIONS = 'transactions',
  CLOCKEDIN = 'clockedIn',
}

interface LocationStatsProps {
  stats: {
    [key in StatsEnum]?: any;
  };
}

type LocationOverviewItemProps = {
  title: string;
  value: number;
  highlight?: boolean;
};
const LocationOverviewItem = ({ title, value, highlight }: LocationOverviewItemProps) => (
  <div
    className={
      'ml-0 mr-auto flex space-x-2 text-nowrap border-none py-1.5' +
      (highlight === true ? ' rounded-lg border bg-purple-50 px-3' : '')
    }
  >
    <span>{title}:</span>
    <strong>{value}</strong>
  </div>
);

const LocationOverviewTable: React.FC<LocationStatsProps> = ({ stats = {} }) => {
  const { t } = useTranslation();

  if (!stats) return null;

  const totalStudents = stats[StatsEnum.STUDENTS]?.total ?? 0;
  const totalStaff = stats[StatsEnum.STAFF]?.total ?? 0;
  const checkedInStudents = stats[StatsEnum.CHECKEDIN]?.total ?? 0;
  const clockedInStaff = stats[StatsEnum.CLOCKEDIN]?.total ?? 0;

  return (
    <div className="flex flex-wrap gap-x-6">
      <div className="flex flex-col">
        <LocationOverviewItem title={t('Total Students')} value={totalStudents} />
        <LocationOverviewItem title={t('Checked in students')} value={checkedInStudents} highlight />
      </div>
      <div className="flex flex-col">
        <LocationOverviewItem title={t('Total Staff')} value={totalStaff} />
        <LocationOverviewItem title={t('Clocked in staff')} value={clockedInStaff} highlight />
      </div>
    </div>
  );
};

const LocationStatsOverview: React.FC<LocationStatsProps> = ({ stats = {} }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card.Header as="h3">{t('School Overview')}</Card.Header>
      <LocationOverviewTable stats={stats} />
      <Divider />
    </>
  );
};

export default LocationStatsOverview;
