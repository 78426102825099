import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUser } from './useUser';
// Import actions
import { invoicePlansFetched } from '../redux/actions/invoiceActions';
import { fetchOrganizationInvoicePlans } from '../api/firebase/invoices';

type Invoice = {
  list: {
    id: string;
    deletedAt?: string;
  }[];
  count: number;
};

export function useInvoicePlans(organizationId: string) {
  const dispatch = useDispatch();
  const { isLocationAdminHighestClaim, locations } = useUser();
  const [loading, setLoading] = useState(false);
  const [invoicePlans, setInvoicePlans] = useState<Invoice>();
  const [error, setError] = useState();

  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoading(true);

        const conditions: { field: string; operation: string; value: string[] }[] = [];

        // if highest claim is locationAdmin && locations are assigned
        if (isLocationAdminHighestClaim && locations) {
          conditions.push({
            field: 'location',
            operation: 'in',
            value: Object.keys(locations),
          });
        }

        const _invoicePlans = await fetchOrganizationInvoicePlans(organizationId, conditions);

        const existingPlansList = (_invoicePlans?.list || []).filter(({ deletedAt }) => !deletedAt);

        const existingPlans = {
          ..._invoicePlans,
          list: existingPlansList,
          count: existingPlansList.length,
        };

        setInvoicePlans(existingPlans);

        dispatch(invoicePlansFetched(existingPlans));
      } catch (err: any) {
        console.log(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    if (!loading && !invoicePlans) {
      doFetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  return { loading, error, invoicePlans };
}
