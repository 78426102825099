import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { auth, firestore } from '../../../api/firebase';
import { useStudents } from '../../studentsHooks';
import { renderDate } from '../../../utils/date';
import FormSelector from '../../../Components/FormSelector';
import LocationPicker from '../../../Components/Locations/LocationPicker';
import InlineError from '../../../Components/Messages/InlineError';
import InvitationConfirmationDialog from './InvitationConfirmationDialog';

const defaultInvitation = {
  firstName: '',
  lastName: '',
  email: '',
  type: 'enrollment',
  formId: '',
  location: '',
  completed: false,
};

const InvitationForm = () => {
  const { t } = useTranslation();
  const {
    selectedStudent: { id, firstName, lastName, displayName = '', organization, enrollmentStatus },
  } = useStudents();

  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invitationData, setInvitationData] = useState(defaultInvitation);
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleInvitationDataChange = (_, { name, value }) => {
    setInvitationData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = useCallback(() => {
    const errors = {};

    if (!invitationData.firstName) errors.invitationFirstName = t('Parent first name is required');
    if (!invitationData.lastName) errors.invitationLastName = t('Parent last name is required');
    if (!invitationData.email) errors.invitationEmail = t('Parent email is required');
    if (!invitationData.location) errors.invitationLocation = t('Please select location');
    if (!invitationData.formId) errors.invitationFormId = t('Please select enrollment form');

    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, [invitationData, t]);

  const saveData = async () => {
    try {
      setErrors({});
      setSaving(true);

      const createdAt = firestore.FieldValue.serverTimestamp();

      const createdBy = {
        uid: auth().currentUser.uid,
        email: auth().currentUser.email,
      };

      await firestore()
        .collection('invitations')
        .add({
          ...invitationData,
          displayName: `${invitationData.firstName} ${invitationData.lastName}`,
          student: {
            firstName,
            lastName,
            displayName: displayName || `${firstName} ${lastName}`,
            id,
            organization,
          },
          entityPath: `organizations/${organization}/students/${id}`,
          organization,
          createdAt,
          createdBy,
        });

      // Update previous invitation to be archived
      if (invitationData.id) {
        await firestore().doc(`invitations/${invitationData.id}`).update({ archived: true, completed: true });
      }

      setInvitationData(defaultInvitation);
      setSaving(false);
      setShowModal(false);
      return null;
    } catch (error) {
      console.error(error);
      setSaving(false);
      setErrors({
        submit: error?.message || 'Unable to save enrollment invitation',
      });
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      setShowConfirmation(true);
    }
  };

  // If no id or student is already enrolled, dont show anything.
  if (!id || enrollmentStatus) return null;

  return (
    <>
      <InvitationConfirmationDialog
        open={showConfirmation}
        loading={saving}
        onClose={() => setShowConfirmation(false)}
        onYes={saveData}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>{t('Enrollment Form')}</Grid.Column>
          <Grid.Column width={10}>
            {invitationData?.sentAt ? (
              <Grid.Row>
                <b>
                  {t('Sent')} {renderDate(invitationData.sentAt, 'MM/DD/YYYY h:mm A')}
                </b>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Row>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                  }}
                >
                  {t('Send Enrollment Form')}
                </a>
              </Grid.Row>
              <Modal open={showModal} size="small" closeOnEscape={false} closeIcon onClose={() => setShowModal(false)}>
                <Modal.Header>{t('Send enrollment form')}</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <Form onSubmit={handleSubmit} error={!!errors} loading={saving} noValidate>
                      <Form.Field error={!!errors.invitationFirstName}>
                        <Form.Input
                          type="text"
                          required
                          id="invitationFirstName"
                          name="firstName"
                          value={invitationData?.firstName}
                          onChange={handleInvitationDataChange}
                          label={t('Parent First Name')}
                          placeholder={t('First name')}
                        />
                        {errors.invitationFirstName && <InlineError text={errors?.invitationFirstName} />}
                      </Form.Field>
                      <Form.Field error={!!errors.invitationLastName}>
                        <Form.Input
                          type="text"
                          required
                          id="invitationLastName"
                          name="lastName"
                          value={invitationData?.lastName}
                          onChange={handleInvitationDataChange}
                          label={t('Parent Last Name')}
                          placeholder={t('Last name')}
                        />
                        {errors.invitationLastName && <InlineError text={errors?.invitationLastName} />}
                      </Form.Field>
                      <Form.Field error={!!errors.invitationEmail}>
                        <Form.Input
                          type="text"
                          required
                          id="invitationEmail"
                          name="email"
                          value={invitationData?.email}
                          onChange={handleInvitationDataChange}
                          label={t('Parent email')}
                          placeholder={t('Email')}
                        />
                        {errors.invitationEmail && <InlineError text={errors?.invitationEmail} />}
                      </Form.Field>

                      <Form.Field error={!!errors.invitationLocation}>
                        <LocationPicker
                          id="location"
                          name="location"
                          label={t('Location')}
                          placeholder={t('Select location')}
                          value={invitationData.location}
                          selection
                          search
                          required
                          onChange={handleInvitationDataChange}
                        />
                        {errors.invitationLocation && <InlineError text={errors.invitationLocation} />}
                      </Form.Field>

                      <FormSelector
                        required
                        name="formId"
                        label="Enrollment Form"
                        placeholder="Select enrollment form"
                        error={errors?.invitationFormId}
                        location={invitationData?.location}
                        value={invitationData?.formId}
                        onChange={handleInvitationDataChange}
                      />

                      <Button floated="right" primary style={{ margin: '20px 0' }} loading={saving}>
                        {t('Send enrollment form')}
                      </Button>
                    </Form>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default InvitationForm;
