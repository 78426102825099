import { useState, useCallback } from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { isLocal } from '../../config/env';
import { networkOptions } from '../../config';

import { useNetworksDataToSeed, useNetworks } from '../networksHooks';

import NetworkDetails from './NetworkDetails';
import NetworksTable from './NetworksTable';

export function NetworksAdminContainer() {
  const { t } = useTranslation();
  const { networks, doFetchNetworks, isLoading } = useNetworks();

  const [setNetworksDataToSeed] = useNetworksDataToSeed();
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState();

  const onClose = useCallback(() => {
    setOpenDetails(false);
  }, []);

  const onSave = useCallback(() => {
    setSelectedNetwork(null);
    setOpenDetails(false);
    doFetchNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Segment basic clearing>
        {renderCommandSection()}
        <Header
          as="h1"
          content={t('Networks ({{networkCount}})', {
            networkCount: networks?.length || 0,
          })}
        />
      </Segment>
      <NetworksTable
        networks={networks}
        onSelect={(network) => {
          setSelectedNetwork(network);
          setOpenDetails(true);
        }}
      />

      {openDetails && <NetworkDetails network={selectedNetwork} onClose={onClose} onSave={onSave} open={openDetails} />}
    </>
  );

  function renderCommandSection() {
    return (
      <>
        {isLocal() && (
          <Button
            primary
            floated="right"
            loading={isLoading}
            onClick={() => {
              setNetworksDataToSeed(networkOptions);
            }}
          >
            {t('Seed Network Data')}
          </Button>
        )}
        <Button
          primary
          loading={isLoading}
          floated="right"
          onClick={() => {
            setOpenDetails(true);
            setSelectedNetwork(null);
          }}
        >
          {t('Add Network')}
        </Button>
      </>
    );
  }
}
export default NetworksAdminContainer;
