import { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { Form, Segment, Radio } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import components
import InlineError from '../../../Messages/InlineError';
import ShowErrors from '../../../Messages/ShowError';
import { USER_STATUS, USER_STATUS_DESCRIPTION } from '../../../../helpers/userStatus';
import { InvoiceStatus } from '../../../../helpers/invoices';

class InvoiceActionForm extends Component {
  state = {
    data: {
      status: '',
      notes: '',
    },

    errors: {},
  };

  onChange = (e, { name, value, checked }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: !_.isUndefined(checked) ? checked : value,
      },
      errors: _.omit(this.state.errors, name),
    });
  };

  handleRadioChange = (e, { name, value }) => this.setState({ data: { ...this.state.data, [name]: value } });

  maskedOnChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: _.omit(this.state.errors, e.target.name),
    });
  };

  validate = (data) => {
    const errors = {};

    if (!data.status) errors.status = this.props.t('Please select Status');

    return errors;
  };

  saveChanges = async () => {
    const {
      invoice,
      currentOrganization: { id },
      user,
    } = this.props;
    const {
      data: { status, notes },
    } = this.state;

    const isMarkedAsPaid = status === 'paid';

    if (id && invoice.id) {
      const invoiceData = {
        id: invoice.id,
        status: isMarkedAsPaid ? invoice.status : status,
        manuallyPaid: isMarkedAsPaid,
        ...(isMarkedAsPaid
          ? { userStatus: USER_STATUS.PAID_MANUALLY, userStatusDescription: USER_STATUS_DESCRIPTION.PAID_MANUALLY }
          : {}),
      };
      if (notes) invoiceData.notes = notes;
      if (this.props.onSave) {
        invoiceData.updatedBy = { id: user.uid, displayName: user.displayName, dateTime: Date.now() };
        this.props.onSave(invoiceData);
      }
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      this.saveChanges();
    }
  };

  render() {
    const { data, loading, errors } = this.state;
    const { t, invoice } = this.props;

    return (
      <Segment basic textAlign="left">
        <ShowErrors errors={errors} />

        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Field>
            <Radio
              data-testid="invoice-action-paid-radio"
              name="status"
              label={
                <label>
                  <div>{t('Paid')}</div>
                  <div className="text-muted">{t('Payment was collected manually.')}</div>
                </label>
              }
              value="paid"
              checked={data.status === 'paid'}
              onChange={this.handleRadioChange}
            />
          </Form.Field>

          <Form.Field>
            <Radio
              data-testid="invoice-action-void-radio"
              name="status"
              label={
                <label>
                  <div>{t('Void')}</div>
                  <div className="text-muted">
                    {t('This invoice was accidentally finalized or contains a mistake.')}
                  </div>
                </label>
              }
              value="void"
              checked={data.status === 'void'}
              onChange={this.handleRadioChange}
            />
          </Form.Field>

          {invoice.status !== InvoiceStatus.UNCOLLECTIBLE ? (
            <Form.Field>
              <Radio
                data-testid="invoice-action-uncollectible-radio"
                name="status"
                label={
                  <label>
                    <div>{t('Uncollectible')}</div>
                    <div className="text-muted">
                      {t(
                        'Payment of this invoice is not expected. It is still possible to collect payment should your customer attempt to pay.'
                      )}
                    </div>
                  </label>
                }
                value="uncollectible"
                checked={data.status === 'uncollectible'}
                onChange={this.handleRadioChange}
              />
            </Form.Field>
          ) : null}

          <Form.Field error={!!errors.note}>
            <Form.TextArea
              data-testid="invoice-action-notes"
              type="text"
              id="notes"
              name="notes"
              value={data.notes}
              onChange={this.onChange}
              label={t('Add internal note')}
              placeholder={t('Add a note...')}
            />
            {errors.notes && <InlineError text={errors.notes} />}
          </Form.Field>

          <br />
          <br />
          <Form.Group>
            <Form.Button
              data-testid="invoice-action-update-status-btn"
              primary
              content={t('invoiceAction.updateStatus')}
            />

            <Form.Button
              data-testid="invoice-action-cancel-btn"
              basic
              content={t('Cancel')}
              onClick={(e) => {
                if (e) e.preventDefault();
                if (this.props.onCancel) this.props.onCancel();
              }}
            />
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  invoices: state.invoices,
  user: state.user,
});

export default withTranslation()(connect(mapStateToProps)(InvoiceActionForm));
