import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';

// Import component
import ReportTotalSummary from './AgingTotalSummary';
import { formatNumberShorthand } from '../../../../helpers/utils';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import styles from './AgingList.module.scss';

function getColumns(t) {
  return [
    {
      dataField: 'displayName',
      text: t('Student'),
    },
    {
      dataField: 'first',
      text: t('{{range}} Days', { range: '1-6' }),
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'second',
      text: t('{{range}} Days', { range: '7-13' }),
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'third',
      text: t('{{range}} Days', { range: '14-20' }),
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'fourth',
      text: t('{{range}} Days', { range: '21-28' }),
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'fifth',
      text: t('{{range}} Days', { range: '29+' }),
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
    {
      dataField: 'total',
      text: 'Total',
      formatter: (cell) => `$${formatNumberShorthand(cell)}`,
      classes: () => styles.currencyCell,
    },
  ];
}

const AgingList = ({ totalByEmployee, totalSummary }) => {
  const { t } = useTranslation();

  const columns = getColumns(t);
  return (
    <ToolkitProvider
      bootstrap4
      keyField="id"
      data={totalByEmployee}
      columns={columns}
      search={{
        searchFormatted: true,
      }}
      exportCSV
    >
      {(props) => (
        <Grid stackable>
          <Grid.Column width={16}>
            <ReportTotalSummary totalSummary={totalSummary} />
          </Grid.Column>

          <Grid.Column width={16} className="bootstrap-iso">
            <BootstrapTable
              classes="w-auto w-md-100"
              wrapperClasses="table-responsive"
              bordered={false}
              rowClasses={styles.bstRow}
              headerClasses={styles.header}
              {...props.baseProps}
            />
          </Grid.Column>
        </Grid>
      )}
    </ToolkitProvider>
  );
};

AgingList.propTypes = {
  totalByEmployee: PropTypes.array,
  totalSummary: PropTypes.object,
};

export default AgingList;
