import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Container, Header, Message, Segment, Icon, Step, Grid, Loader, Dimmer, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import WSA from '../../WSA';
import { routes } from '../../config/routes';

// Import components.
import SetupRooms from './SetupRooms';
import SetupLocations from './SetupLocations';
import SetupStaff from './SetupStaff';
import SetupStudents from './SetupStudents';

import { isOrganizationSetupComplete } from '../../helpers/organizations';

// Import actions
import { startOrganizationRoomsListener } from '../../redux/actions/roomActions';
import { startOrganizationLocationsListener } from '../../redux/actions/locationActions';
import { startOrganizationStudentsListener } from '../../students/studentsRedux';
import { startOrganizationStatsListener } from '../../redux/actions/statsActions';
import { startOrganizationContactsListener } from '../../contacts/contactsRedux';

const StepDefinitions = {
  Location: {
    key: 'firstLocationCreated',
    componentName: 'Locations',
    title: 'Program Information',
    description: 'Add your locations',
  },
  Room: {
    key: 'firstRoomCreated',
    componentName: 'Rooms',
    title: 'Rooms',
    description: 'Add your rooms',
  },
  Staff: {
    key: 'firstStaffCreated',
    componentName: 'Staff',
    title: 'Staff',
    description: 'Add your staff',
  },
  Student: {
    key: 'firstStudentCreated',
    componentName: 'Students',
    title: 'Students',
    description: 'Add your students',
  },
};
// I think this is the old flow which is now not used and replace by Onboarding flow.
const steps = [StepDefinitions.Location, StepDefinitions.Room, StepDefinitions.Staff, StepDefinitions.Student];

const fccSteps = [StepDefinitions.Location, StepDefinitions.Staff, StepDefinitions.Student];
class Setup extends Component {
  state = {
    activeStep: '',
    currentOrganizationId: '',
    useStaffOverride: true,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.useStaffOverride) {
      // Yes, it's not a great pattern to use state derived from props:
      // https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#when-to-use-derived-state
      // but here we use it as a one-way only change from true to false if necessary.
      // This supports the incomplete onboarding flow.
      // As long as the override is active & staff count is 1 or less (can be 0 before loading), keep it active
      return {
        ...prevState,
        useStaffOverride: prevState.useStaffOverride && nextProps.staffCount <= 1,
      };
    }
    return prevState;
  }

  componentDidMount = () => {
    this.startListeners();
    this.selectNextStep();
  };

  componentDidUpdate(prevProps) {
    const currentSetupComplete = isOrganizationSetupComplete(this.props.currentOrganization);
    const prevSetupComplete = isOrganizationSetupComplete(prevProps.currentOrganization);

    if (
      prevProps.currentOrganization.id !== this.props.currentOrganization.id ||
      prevSetupComplete !== currentSetupComplete
    ) {
      this.selectNextStep();
      this.startListeners();
    }
  }

  componentWillUnmount = () => {
    this.unsubscribeListeners();
  };

  startListeners = () => {
    const { currentOrganization } = this.props;
    const setupComplete = isOrganizationSetupComplete(currentOrganization);
    const { id } = currentOrganization;

    if (this.isSetupRoute() && currentOrganization.id && setupComplete === false) {
      this.unSubscribeOrganizationContactsListener = this.props.startOrganizationContactsListener(id);
      this.unSubscribeOrganizationRoomsListener = this.props.startOrganizationRoomsListener(id);
      this.unSubscribeOrganizationLocationsListener = this.props.startOrganizationLocationsListener(id);
      this.unSubscribeOrganizationStudentsListener = this.props.startOrganizationStudentsListener(id);
      this.unSubscribeOrganizationStatsListener = this.props.startOrganizationStatsListener(id);
    }
  };

  unsubscribeListeners = () => {
    if (this.unSubscribeOrganizationContactsListener) this.unSubscribeOrganizationContactsListener();
    if (this.unSubscribeOrganizationRoomsListener) this.unSubscribeOrganizationRoomsListener();
    if (this.unSubscribeOrganizationLocationsListener) this.unSubscribeOrganizationLocationsListener();
    if (this.unSubscribeOrganizationStudentsListener) this.unSubscribeOrganizationStudentsListener();
    if (this.unSubscribeOrganizationStatsListener) this.unSubscribeOrganizationStatsListener();
  };

  getStepList = () => {
    const {
      currentOrganization: { programType },
    } = this.props;

    const isFCC = programType && programType === 'familyChildCare';
    return isFCC ? fccSteps : steps;
  };

  selectNextStep = () => {
    const { currentOrganization } = this.props;
    const { activeStep } = this.state;
    const stepList = this.getStepList();
    const setupComplete = isOrganizationSetupComplete(currentOrganization);

    if (!currentOrganization || !currentOrganization.setup || setupComplete) {
      return;
    }

    const firstIncompleteStep = (
      _.find(
        stepList,
        (step) =>
          currentOrganization.setup[step.key] === false ||
          // staff override handling
          (step.key === StepDefinitions.Staff.key && this.state.useStaffOverride)
      ) ?? stepList[0]
    ).key;

    if (!firstIncompleteStep) return;
    const nextStep = _.find(stepList, {
      key: firstIncompleteStep,
    }).componentName;

    if (_.isEmpty(activeStep)) {
      this.handleStepClick(nextStep);
    }
  };

  isSetupRoute = () => {
    const { match } = this.props;
    return match.path === routes.setup;
  };

  renderSetupStatusMessage() {
    const { currentOrganization } = this.props;
    if (this.isSetupRoute()) return null;
    if (!currentOrganization.setup) return null;

    const setupComplete = isOrganizationSetupComplete(this.props.currentOrganization);

    if (currentOrganization && !setupComplete)
      return (
        <Message info size="big">
          <Icon name="info" />
          <Trans i18nKey="Please complete setting up your account">
            Please complete setting up your account by going to the{' '}
            <strong>
              <em>
                <NavLink to={routes.setup}>setup</NavLink>
              </em>
            </strong>{' '}
            page.
          </Trans>
        </Message>
      );
  }

  handleStepClick = (componentName) => {
    const { useStaffOverride } = this.state;
    // special case for handling staff step (#STAFF_OVERRIDE)
    // if current value is true && step is Staff, set to false, else use current value
    // essentially, the first time you click on staff step, the override will be disabled
    const nextStaffOverride =
      useStaffOverride === true && componentName === StepDefinitions.Staff.componentName ? false : useStaffOverride;

    this.setState({
      activeStep: componentName,
      useStaffOverride: nextStaffOverride,
    });
  };

  renderCheckList = (checklistSteps) => {
    const { activeStep } = this.state;
    const { currentOrganization, t } = this.props;

    if (!this.isSetupRoute()) return;

    const setupComplete = isOrganizationSetupComplete(this.props.currentOrganization);

    if (!setupComplete)
      return (
        <>
          <Step.Group fluid vertical>
            {checklistSteps.map((step, idx) => (
              <Step
                key={step.key}
                link
                onClick={() => this.handleStepClick(step.componentName)}
                title={
                  /* ex: 1. Locations */
                  `${idx + 1}. ${t(step.title)}`
                }
                active={activeStep === step.componentName}
                icon={{ name: 'circle outline' }}
                completed={
                  !currentOrganization.setup
                    ? false
                    : /* #STAFF_OVERRIDE: creating the signup user ensures that at least one staff is created,
                       * which satisfies the backend requirements for staff step completeness.
                       * but that's rather odd for a onboarding flow to complete that step.
                       * so, we use a local override until the step is rendered at least once.
                       */
                      step.key === StepDefinitions.Staff.key && this.state.useStaffOverride
                      ? false
                      : currentOrganization.setup[step.key]
                }
              />
            ))}
          </Step.Group>
        </>
      );
  };

  renderStepContent = () => {
    const { activeStep } = this.state;
    if (!this.isSetupRoute()) return;

    switch (activeStep) {
      case StepDefinitions.Location.componentName:
        return <SetupLocations />;
      case StepDefinitions.Room.componentName:
        return <SetupRooms />;
      case StepDefinitions.Staff.componentName:
        return <SetupStaff />;
      case StepDefinitions.Student.componentName:
        return <SetupStudents />;
      default:
        return <div>{activeStep}</div>;
    }
  };

  /**
   * This loader will be displayed only during first organization creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeOrganizationLoader = () => {
    const {
      currentOrganization: { setup },
      t,
    } = this.props;

    if (_.isEmpty(setup) && this.isSetupRoute())
      return (
        <Dimmer page active inverted>
          <Loader inline="centered" indeterminate content={t('Just a moment while we setup your organization...')} />
        </Dimmer>
      );
  };

  renderWelcomeMessage = () => {
    const { currentOrganization, t } = this.props;
    const setupComplete = isOrganizationSetupComplete(currentOrganization);

    if (setupComplete)
      return (
        <Container style={{ width: 500 }}>
          <Segment className="auth-wrapper">
            <Header as="h1" content={t("You're all set.")} />

            <p>{t("Now, let's make parents super delighted with daily updates and make your life super easy.")}</p>

            <div>
              <img
                src="https://64.media.tumblr.com/tumblr_me6kekAKIE1r982mk.gif"
                style={{ width: '100%' }}
                alt="Yes!"
              />
            </div>

            <Button
              fluid
              size="medium"
              content={t('Go to your Dashboard')}
              color="green"
              icon="flag checkered"
              onClick={() => {
                // perform a hard redirect to avoid empty dashboard
                window.location.assign(routes.home);
              }}
            />
          </Segment>
        </Container>
      );
  };

  renderSetupGrids = () => {
    const setupComplete = isOrganizationSetupComplete(this.props.currentOrganization);

    if (setupComplete === false)
      return (
        <Grid>
          <Grid.Column computer={6} tablet={7} mobile={12}>
            <Segment basic className="setup-step">
              {
                /* Render a different setup for FCCs. */
                this.renderCheckList(this.getStepList())
              }
            </Segment>
          </Grid.Column>
          <Grid.Column computer={10} tablet={9} mobile={12}>
            {this.renderStepContent()}
          </Grid.Column>
        </Grid>
      );
  };

  render() {
    const { isReady, isAuthenticated } = this.props;
    if (!isReady || !isAuthenticated) {
      return null;
    }
    return (
      <Segment basic>
        {this.renderFirstTimeOrganizationLoader()}
        {this.renderSetupStatusMessage()}
        {this.renderWelcomeMessage()}
        {this.renderSetupGrids()}
      </Segment>
    );
  }
}

Setup.defaultProps = {
  currentOrganization: {
    id: '',
    setup: {
      firstLocationCreated: false,
      firstRoomCreated: false,
      firstStaffCreated: false,
      firstStudentCreated: false,
    },
    setupComplete: false,
  },
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  staffCount: state.staff?.count ?? 0,
});
const mapAuthContextToProps = ({ isReady, isAuthenticated }) => {
  return {
    isReady,
    isAuthenticated,
  };
};

export default WSA.Components.mapAuthContextToProps(
  mapAuthContextToProps,
  withTranslation()(
    withRouter(
      connect(mapStateToProps, {
        startOrganizationContactsListener,
        startOrganizationLocationsListener,
        startOrganizationRoomsListener,
        startOrganizationStudentsListener,
        startOrganizationStatsListener,
      })(Setup)
    )
  )
);
