import { useEffect, useRef } from 'react';
import { Container, Dimmer, Image, Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import WSA from '../WSA';
import { getTitlePrefix } from '../config/env';
import { routes } from '../config/routes';
import { changeLanguage } from '../i18n';

// need this when we handle sign up
import { userSaveSignUpData } from '../redux/actions/userActions';

// Import components
import wsLogo from '../styles/img/wonderschool/logo-base.png';
import { logError } from '../rollbar';
import { useDispatch } from 'react-redux';

const TIMEOUT = 10000; // 10 seconds
enum SignInStatus {
  None,
  Started,
  Finished,
}
const AuthReturn = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const didSdkSigninRef = useRef<SignInStatus>(SignInStatus.None);
  const isSignupRef = useRef<boolean>(false);

  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();

  useEffect(() => {
    async function completeSignIn() {
      if (isReady && didSdkSigninRef.current === SignInStatus.None) {
        // only try sign in once
        didSdkSigninRef.current = SignInStatus.Started; // intermediate truthy value

        try {
          // sdk sign in
          const removeHashAfter = true;
          const result = await WSA.Auth.completeSignin(removeHashAfter);
          if (!result) {
            throw new Error('CompleteSignin Result was empty');
          }
          const { user, isSignup, selectedLanguage } = result;
          isSignupRef.current = !!isSignup; // save for later use in other loops

          const signupData = isSignup ? result.signupData : await WSA.Signup.getSavedSignupData(user.user);
          if (selectedLanguage && selectedLanguage !== i18n.language) {
            changeLanguage(selectedLanguage);
          }
          // save signup data for later
          dispatch(userSaveSignUpData(signupData));

          // mark signin complete
          didSdkSigninRef.current = SignInStatus.Finished;
        } catch (err: any) {
          logError(`Signin Completion Failed: ${err?.message}`, err, { err, isReady, didSdkSigninRef });
          if (history) history.replace(routes.authError);
        }
      }

      // need a separate loop to wait for event triggers to update
      if (didSdkSigninRef.current === SignInStatus.Finished && isReady && isAuthenticated && history) {
        if (isSignupRef.current) {
          history.replace(routes.verify);
        } else {
          history.replace(routes.home);
        }
      }
    }
    completeSignIn();
  });

  // taking too long, give up and go to error page
  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace(routes.authError);
    }, TIMEOUT);
    return () => clearTimeout(timer);
  });

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: t('Redirecting') })}
      </title>
      <body className="auth auth-return" />
    </Helmet>
  );

  return (
    <Container style={{ width: 500 }}>
      {renderPageHead()}

      <Segment basic>
        <Image src={wsLogo} size="medium" centered />
      </Segment>

      <Dimmer page active inverted>
        <Loader inline="centered">{t('Redirecting ...')}</Loader>
      </Dimmer>
    </Container>
  );
};

export default AuthReturn;
