import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, DropdownItemProps, Form } from 'semantic-ui-react';

import { parseCurrency, capitalize } from '../../../helpers/utils';
import { FormComponentProps } from '../../../common';

import { Dropdown } from '../../../Components/Shared/Dropdown';
import { CurrencyInput } from '../../../Components/Shared/NumericInput';
import { WsAddCircle } from '../../../icons';

import { BillingIntervalEnum } from '../../enums';
import { FeeType, TuitionAndFeesType, TuitionType } from '../../types';

type TuitionAndFeesFormProps = FormComponentProps<TuitionAndFeesType> & {
  tuitionAndFees: TuitionAndFeesType;
};

const TuitionAndFeesForm: React.FC<TuitionAndFeesFormProps> = ({ title, tuitionAndFees, isSaving, onSave }) => {
  const { t } = useTranslation();
  const [tuition, setTuition] = useState<TuitionType>(tuitionAndFees?.tuition ?? {});
  const [fees, setFees] = useState<FeeType[]>(tuitionAndFees?.fees ?? []);

  useEffect(() => {
    if (isSaving && onSave) {
      const nonEmptyFees: FeeType[] = fees.filter((fee) => fee.name || fee.amount);
      onSave?.({ data: { tuition, fees: nonEmptyFees } });
    }
  }, [fees, isSaving, onSave, tuition]);

  useEffect(() => {
    setTuition(tuitionAndFees?.tuition ?? {});
    setFees(tuitionAndFees?.fees ?? []);
  }, [tuitionAndFees?.fees, tuitionAndFees?.tuition]);

  const feesErrors = useMemo(() => {
    const errorsLocal: Record<string, string> = {};
    fees.forEach((fee, index) => {
      if (!fee.name) {
        errorsLocal[`feeName${index}`] = t('enrollments.tuitionAdditionalFeeNameRequired');
      }
      if (!fee.amount) {
        errorsLocal[`feeAmount${index}`] = t('enrollments.tuitionAdditionalFeeAmountRequired');
      }
    });
    return errorsLocal;
  }, [fees, t]);

  const tuitionErrors = useMemo(() => {
    const errorsLocal: Record<string, string> = {};
    if (!tuition.billingInterval) {
      errorsLocal['billingInterval'] = t('enrollments.tuitionBillingIntervalRequired');
    }
    if (!tuition.amount) {
      errorsLocal['amount'] = t('enrollments.tuitionAmountRequired');
    }
    return errorsLocal;
  }, [tuition, t]);

  const errors = useMemo(() => {
    return { ...tuitionErrors, ...feesErrors };
  }, [feesErrors, tuitionErrors]);

  const isValid = useMemo(() => {
    return Object.keys(errors).length === 0;
  }, [errors]);

  const billingIntervalOptions = useMemo<DropdownItemProps[]>(() => {
    return Object.values(BillingIntervalEnum).map((billingInterval) => ({
      key: billingInterval,
      value: billingInterval,
      text: t(capitalize(billingInterval)),
    }));
  }, [t]);

  return (
    <div className="tuition-fees-form" data-testid="tuition-fees-form">
      <h2 data-testid="tuition-fees-form-title">{t(title ?? 'enrollments.tuitionAndFeesFormTitle')}</h2>
      <Form.Group widths="equal">
        <Form.Field error={false} data-testid="error-tution-billing-interval">
          <Dropdown
            value={tuition.billingInterval ?? ''}
            placeholder={t('enrollments.tuitionBillingIntervalPlaceholder')}
            label={t('enrollments.tuitionBillingIntervalLabel')}
            onChange={(e: any, { value }: any) => {
              setTuition((prev: TuitionType) => ({ ...prev, billingInterval: value }));
            }}
            options={billingIntervalOptions}
            translator={t}
            isForm={true}
            data-testid="tution-billing-interval"
          />
        </Form.Field>
        <Form.Field error={false} data-testid="error-tution-amount">
          <Form.Input
            type="text"
            value={tuition.amount ? tuition.amount / 100 : ''}
            placeholder={t('enrollments.tuitionAmountPlaceholder')}
            label={t('enrollments.tuitionAmountLabel')}
            onBlur={(e: any) => {
              const value = e.target.value;
              setTuition((prev: TuitionType) => ({ ...prev, amount: parseCurrency(value) * 100 }));
            }}
            control={CurrencyInput}
            data-testid="tution-amount"
          />
        </Form.Field>
      </Form.Group>
      <Divider />
      {!!fees?.length && <h2>{t('enrollments.tuitionAdditionalFeesTitle')}</h2>}
      {fees?.map((fee: FeeType, index: number) => (
        <Form.Group widths="equal" key={index} className="fee-row">
          <Form.Input
            name={`feeName${index}`}
            type="text"
            value={fee.name ?? ''}
            placeholder={t('enrollments.tuitionAdditionalFeeNamePlaceholder')}
            label={t('enrollments.tuitionAdditionalFeeNameLabel', { index: index + 1 })}
            onChange={(e: any, { value }: any) => {
              const newFee = { ...fee, name: value };
              setFees((prev: FeeType[]) => prev.map((f, i) => (i === index ? newFee : f)));
            }}
            data-testid="error-tution-additional-fee-name"
          />
          <Form.Input
            name={`feeAmount${index}`}
            type="text"
            value={fee.amount ? fee.amount / 100 : ''}
            placeholder={t('enrollments.tuitionAdditionalFeeAmountPlaceholder')}
            label={t('enrollments.tuitionAdditionalFeeAmountLabel')}
            onBlur={(e: any) => {
              const value = e.target.value;
              const newFee = { ...fee, amount: parseCurrency(value) * 100 };
              setFees((prev: FeeType[]) => prev.map((f, i) => (i === index ? newFee : f)));
            }}
            control={CurrencyInput}
            data-testid="error-tution-add-fee-amount"
          />
          <Form.Button
            basic
            className="ws-icon-button"
            icon="trash alternate outline"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFees((prev) => prev.filter((f, i) => i !== index));
            }}
            data-testid="tution-delete-btn"
          />
        </Form.Group>
      ))}
      <div>
        <a
          href="#"
          className={'ws-icon-link' + (!isValid ? ' disabled' : '')}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // validate fees before adding a new one
            if (isValid) setFees((prev: FeeType[]) => [...prev, { name: '', amount: 0 }]);
          }}
          data-testid={'ws-icon-link' + (!isValid ? ' disabled' : '')}
        >
          <WsAddCircle data-testid="ws-additional-fee" />
          <span>{t('enrollments.tuitionAddFeeLink')}</span>
        </a>
      </div>
    </div>
  );
};

export default TuitionAndFeesForm;
