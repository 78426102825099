import { Card, Label, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Import style
import cardStyles from '../Shared/CardHelpers/CardStyles.module.scss';

const icons = {
  ios: 'apple',
  android: 'android',
};

const DeviceCard = ({ device, onClick }) => {
  const { t } = useTranslation();

  return (
    <Card
      className="shadow"
      key={device.id}
      onClick={onClick}
      header={<Card.Header className={cardStyles.textTruncate}>{t(device.name)}</Card.Header>}
      meta={
        <div>
          <div className={cardStyles.textTruncate}>
            {t('username')}: <code>{device.email}</code>
          </div>
          <div className={cardStyles.textTruncate}>
            {t('password')}: <code>{device.password}</code>
          </div>
        </div>
      }
      extra={
        <div>
          <Icon size="big" name={icons[device.type]} />
          <Label content={device.enabled ? t('Enabled') : t('Disabled')} color={device.enabled ? 'green' : 'red'} />
        </div>
      }
    />
  );
};

export default DeviceCard;
