import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Image, Menu, Sidebar } from 'semantic-ui-react';

// Import helpers.
import { useNetworkLogo } from '../../hooks/useNetworksLogo';

// Import components.
import LeftNavigation from './LeftNavigation';

import WSA from '../../WSA';

const DesktopNavigation = ({
  viewport,
  location: { pathname },
  animation,
  direction,
  visible,
  sidebarWidth,
  staticContext: _staticContext,
  ...rest
}) => {
  const { isReady, isAuthenticated } = WSA.Components.useWSAuth();
  const logoUrl = useNetworkLogo();

  // Remove/hide navbar on invoice pages.
  if (
    pathname?.startsWith('/invoice/') ||
    !(isReady && isAuthenticated) // user is logged in but the org is still loading
  )
    return null;

  return (
    <Sidebar
      id="main-menu"
      as={Menu}
      vertical
      size="large"
      animation="uncover"
      direction="left"
      visible={visible}
      borderless
      data-testid="main-menu"
      {...rest}
    >
      <div className={`position: absolute; width: ${sidebarWidth}px`}>
        <Menu.Item style={{ paddingTop: '1.2rem', paddingBottom: '1.2rem' }}>
          <Image as={Link} to="/" src={logoUrl} style={{ width: '65%' }} data-testid="logo" />
        </Menu.Item>
        <LeftNavigation />
      </div>
    </Sidebar>
  );
};

const mapStateToProps = (_state) => ({
  //
});

export default withRouter(connect(mapStateToProps, {})(DesktopNavigation));
