import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Icon, Segment, Header } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import actions
import { roomSelected } from '../../redux/actions/roomActions';

// Import Components
import RoomCard from './RoomCard';
import RoomForm from '../Forms/RoomForm';
import withSlidingPanel from '../Shared/withSlidingPanel';
import withPermission from '../Shared/withPermission';

const SlidingRoomFormAdd = withSlidingPanel(RoomForm, {
  title: 'Add A Room',
});

const SlidingRoomFormEdit = withSlidingPanel(RoomForm, {
  title: 'Edit Room',
});

const CreateRoomCard = ({ t, ...rest }) => {
  return (
    <Card className="add-new" data-testid="add-new-room" {...rest}>
      <Card.Content>
        <div className={'center'}>
          <Card.Header as={'h4'}>
            <Icon name="plus" data-testid="add-new-room-icon" />
          </Card.Header>
          <Card.Description data-testid="add-new-room-plus-card">{t('Add new room')}</Card.Description>
        </div>
      </Card.Content>
    </Card>
  );
};

const CreateRoomCardWithPermission = withPermission(CreateRoomCard, 'can_create_room');

class RoomsList extends Component {
  state = {
    isAddOpen: false,
    isEditOpen: false,
  };

  renderPageTitle = () => {
    const {
      rooms: { list },
      t,
    } = this.props;

    if (list) {
      return (
        <Segment basic clearing>
          <Header as="h1" floated="left">
            {t('Rooms')} ({list.length})
          </Header>
        </Segment>
      );
    }

    return (
      <Segment basic clearing>
        <Header as="h1" floated="left">
          {t('Rooms')}
        </Header>
      </Segment>
    );
  };

  renderRoomList = () => {
    const {
      rooms: { list },
      itemsPerRow,
      t,
    } = this.props;

    return (
      <Card.Group stackable itemsPerRow={itemsPerRow || 4}>
        <CreateRoomCardWithPermission
          onClick={() => this.setState({ isAddOpen: true })}
          t={t}
          data-testid="create-room-card-with-permission"
        />
        {list.map((room) => (
          <RoomCard
            key={room.id}
            room={room}
            onClick={() => {
              this.props.roomSelected(room);
              this.setState({ isEditOpen: true });
            }}
          />
        ))}
      </Card.Group>
    );
  };

  renderSlidingForms = () => {
    return (
      <div>
        <SlidingRoomFormAdd isOpen={this.state.isAddOpen} onClose={() => this.setState({ isAddOpen: false })} />
        <SlidingRoomFormEdit
          isOpen={this.state.isEditOpen}
          onClose={() => {
            this.setState({ isEditOpen: false });
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderPageTitle()}
        {this.renderRoomList()}
        {this.renderSlidingForms()}
      </div>
    );
  }
}

RoomsList.defaultProps = {
  itemsPerRow: 4,
};

RoomsList.propTypes = {
  rooms: PropTypes.shape({
    selectedRoom: PropTypes.shape({
      id: PropTypes.string,
    }),
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  roomSelected: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  rooms: state.rooms,
  currentOrganization: state.organizations.currentOrganization,
});

export default withTranslation()(connect(mapStateToProps, { roomSelected })(RoomsList));
