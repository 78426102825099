import { TrashIcon } from '@heroicons/react/24/outline';
import { Button, Combobox, DatePicker, Input, SlideOver } from '@wonderschool/common-base-ui';
import dayjs from 'dayjs';
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatepickerDateType, WsAvatar, WsDropdownOptionType, WsInputEventType } from '../../common';
import { formatHoursFromMinutes } from '../../helpers/dates';
import useRooms from '../../hooks/useRooms';
import { logError } from '../../rollbar';
import { TimecardStatusEnum } from '../enums';
import { convertTo24Hour, parseDateString, toDisplayTime } from '../timecardsUtils';
import { TimecardType } from '../types';
import { TimecardFormDataType } from './timecardTypes';
import { calculateClockInOutTimeDifference } from './utils/calculateClockInOutTimeDifference';
import { TimecardEditModalFormValidationErrors, validateTimeCards } from './utils/validateTimeCards';

type TimecardEditModalProps = {
  timecard?: TimecardType;
  staff?: any;
  onClose?: () => void;
  onSave: (timecard: TimecardType) => void;
  switchDeleteModal: (shouldDelete: boolean) => void;
  setOpenCancelModal: (shouldCancel: boolean) => void;
  openEditModal: boolean;
  setOpenEditModal: (shouldOpen: boolean) => void;
  openCancelModal: boolean;
  openDeleteModal: boolean;
};

type DropdownOptionType = WsDropdownOptionType<string>;

export default function TimecardEditModal({
  timecard,
  staff,
  onClose,
  onSave,
  switchDeleteModal,
  setOpenCancelModal,
  openDeleteModal,
  openEditModal,
  setOpenEditModal,
  openCancelModal,
}: TimecardEditModalProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<TimecardFormDataType>(getInitialTimecardValues(timecard));
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<TimecardEditModalFormValidationErrors>({});
  const [cancelModal] = useState(openCancelModal);

  const rooms: any[] = useRooms();

  const options: DropdownOptionType[] = useMemo(() => {
    const optionsLocal = rooms?.map((room) => ({
      id: room.id,
      name: room.name,
    }));
    return optionsLocal ?? [];
  }, [rooms]);

  function onChange({ name, value }: WsInputEventType<DatepickerDateType | string>) {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  }

  function onChangeTime(event: ChangeEvent<HTMLInputElement>) {
    setFormData((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setErrors((prev) => ({ ...prev, [event.target.name]: '' }));
  }

  function closeWithCheck() {
    if (!cancelModal) {
      setOpenEditModal(false);
      onCloseLocal();
      return;
    }

    if (openDeleteModal) {
      return;
    }

    setOpenCancelModal(true);
  }

  function onCloseLocal() {
    setIsSaving(false);
    setErrors({});
    setFormData({} as TimecardFormDataType);
    onClose?.();
  }

  function onSaveLocal(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const { totalTime } = calculateClockInOutTimeDifference(formData);
    const resultingErrors = validateTimeCards({
      formData,
      timeDifferenceInMinutes: totalTime,
    });

    if (Object.keys(resultingErrors).length > 0) {
      setErrors(resultingErrors);
      return;
    }

    setIsSaving(true);

    const { clockedInDate, clockedInTime, clockedOutDate, clockedOutTime } = formData;
    const clockedIn = dayjs(clockedInDate).format('MM/DD/YYYY') + ' ' + clockedInTime;
    try {
      const timecardLocal = {
        ...timecard,
        roomId: formData.roomId,
        clockedIn: parseDateString(clockedIn),
      } as TimecardType;

      if (clockedOutDate) {
        const clockedOut =
          dayjs(clockedOutDate).format('MM/DD/YYYY') + (clockedOutTime ? ' ' + clockedOutTime : ' 00:00 am');
        timecardLocal.clockedOut = parseDateString(clockedOut);
        timecardLocal.status = TimecardStatusEnum.CLOCKED_OUT;
      }
      onSave?.(timecardLocal);
      onCloseLocal();
    } catch (error) {
      logError('TimecardEditModal: failed saving timecard', timecard, error);
    } finally {
      setIsSaving(false);
      setErrors({});
      setOpenEditModal(false);
    }
  }

  const timeDifference = calculateClockInOutTimeDifference(formData);

  return (
    <div className="md:w-50 w-full">
      <SlideOver hasOverlay onClose={closeWithCheck} isOpen={openEditModal} title={t('timecards.editTimesheet')}>
        <div>
          <label className="flex gap-3 text-gray-800">{t('timecards.staffMember')}</label>
          <div className="mt-2 flex gap-3 rounded-md border p-2">
            <div className="flex items-center justify-center gap-3">
              <WsAvatar photoURL={staff?.photoURL} size="small" data-testid="timecard-edit-avatar" />
              <div className="items-center justify-center gap-3 text-sm">{staff?.displayName ?? t('Unknown')}</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="gap-5 text-gray-800">
              <Combobox
                label={t('Room')}
                name={'roomId'}
                onChange={onChange}
                options={options}
                placeholder={t('Select a room')}
                value={formData?.roomId ?? ''}
                required={true}
                data-testid="timecard-edit-room"
              />
            </div>
          </div>
          <hr className="mt-6 text-gray-400" />
          <label className="my-4 flex gap-3 text-lg font-medium">{t('timecards.clockedIn')}</label>
          <div className="flex">
            <div className="flex">
              <div className="mr-2 w-3/6 md:w-full">
                <DatePicker
                  name="clockedInDate"
                  label={t('Date')}
                  required={true}
                  placeholder="Select Date"
                  error={translateWithFallbackToEmpty(errors.clockedInDate, t)}
                  value={formData.clockedInDate ?? ''}
                  onChange={(date) => onChange({ name: 'clockedInDate', value: date })}
                  maxDate={new Date()}
                  data-testid="timecard-edit-clocked-in-date"
                />
              </div>
              <div className="ml-2 w-4/6 w-max min-w-max">
                <Input
                  inputType="time"
                  placeholder="HH:MM:AM/PM"
                  name="clockedInTime"
                  required={true}
                  label={t('Time')}
                  value={formData?.clockedInTime && convertTo24Hour(formData?.clockedInTime)}
                  error={translateWithFallbackToEmpty(errors.clockedInTime, t)}
                  onChange={onChangeTime}
                  data-testid="timecard-edit-clocked-in-time"
                />
              </div>
            </div>
          </div>
          <label className="my-4 flex gap-3 text-lg font-medium">{t('timecards.clockedOut')}</label>
          <div className="flex">
            <div className="flex">
              <div className="mr-2 w-3/6 text-left md:w-full">
                <DatePicker
                  name="clockedOutDate"
                  label={t('Date')}
                  required={timeDifference.isOvernight}
                  placeholder="Select Date"
                  minDate={formData.clockedInDate ?? 1}
                  maxDate={new Date()}
                  error={translateWithFallbackToEmpty(errors.clockedOutDate, t)}
                  value={formData.clockedOutDate}
                  onChange={(value) => {
                    setFormData((prev) => ({ ...prev, clockedOutDate: value }));
                  }}
                  data-testid="timecard-add-clocked-in-date"
                />
              </div>
              <div className="ml-2 w-4/6 w-max min-w-max">
                <Input
                  inputType="time"
                  name="clockedOutTime"
                  required={true}
                  label={t('Time')}
                  value={formData?.clockedOutTime && convertTo24Hour(formData?.clockedOutTime)}
                  error={translateWithFallbackToEmpty(errors.clockedOutTime, t)}
                  onChange={onChangeTime}
                  data-testid="timecard-edit-clocked-out-time"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-6 text-gray-400" />
        <div className="py-2">
          {timeDifference.totalTime && (
            <label className="text-base font-bold leading-5 text-gray-800">
              {t('timecards.totalHours', {
                totalTime: formatHoursFromMinutes(timeDifference.totalTime),
                overnight: timeDifference.isOvernight ? ` (${t('timecards.overnight')})` : '',
              })}
            </label>
          )}
        </div>
        <div
          className="w-full gap-3"
          onClick={() => switchDeleteModal(true)}
          style={{ background: '#F6F8FF', borderRadius: 8, padding: 20, gap: 32 }}
        >
          <button className="font-bold text-red-500">
            <TrashIcon className="h-6 w-6 gap-3" />
            <span className="p-2.5">{t('timecards.deleteTimecard')}</span>
          </button>
        </div>
        <div className="absolute bottom-4 right-0 w-full bg-white">
          <div className="my-6">
            <div className="inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-end gap-3 px-2">
            <div className="w-3/6 gap-3 md:w-1/6">
              <Button
                onClick={() => setOpenCancelModal(true)}
                extraClasses="w-full text-center"
                data-testid="timecard-edit-save"
              >
                {t('timecards.cancel')}
              </Button>
            </div>
            <div className="w-3/6 gap-3 md:w-1/6">
              <Button
                primary
                onClick={onSaveLocal}
                disabled={isSaving}
                extraClasses="w-full text-center"
                data-testid="timecard-edit-save"
              >
                {t('timecards.update')}
              </Button>
            </div>
          </div>
        </div>
      </SlideOver>
    </div>
  );
}

function getInitialTimecardValues(timecard?: TimecardType): TimecardFormDataType {
  if (!timecard) {
    return {
      clockedInDate: new Date(),
      clockedInTime: dayjs(new Date()).format('HH:mm'),
    };
  }

  const { clockedIn, clockedOut } = timecard;
  const { clockedInTime, clockedOutTime } = toDisplayTime(timecard, true);

  return {
    roomId: timecard.roomId,
    clockedInDate: clockedIn,
    clockedInTime: clockedInTime ?? '',
    clockedOutDate: clockedOut || new Date(),
    clockedOutTime: clockedOutTime ?? '',
  };
}

function translateWithFallbackToEmpty(key: string | undefined, translationFunction: (key: string) => string): string {
  if (key === undefined || key === null || key === '') {
    return '';
  }

  return translationFunction(key);
}
