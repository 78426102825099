import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Grid, Header, Modal } from 'semantic-ui-react';

import { formatFullName } from '../../helpers/utils';
import { formatTimestampAsStringWithTimezone } from '../../helpers/dates';

export function HealthCheckModal({ activity, onClose, open }) {
  const { t } = useTranslation();
  const students = useSelector((state) => state.students?.list) || [];
  const [preCheckUrl, setPreCheckUrl] = useState('');

  useEffect(() => {
    const url = activity?.checkIn?.preCheck ? new URL(activity?.checkIn?.preCheck) : '';
    setPreCheckUrl(url);
  }, [activity]);

  if (!activity) return null;
  const student = students.find((student) => student.id === activity.student.id);

  return (
    <Modal
      onClose={onClose}
      open={open}
      closeIcon
      size="small"
      closeOnDimmerClick={false}
      data-testid="att-health-check-modal"
    >
      <Modal.Content className="health-check-modal">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header size="large">Health Check Form</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column floated="left" width={7}>
              {`${formatFullName(student)} ${student.nickName ? `(${student.nickName})` : ''}`}
            </Grid.Column>
            <Grid.Column floated="right" width={7} textAlign="right">
              {formatTimestampAsStringWithTimezone(activity.activityTime, activity.timezone, 'MM/DD/YYYY')}
            </Grid.Column>
          </Grid.Row>
          <Divider className="margin-top-0" />
          {preCheckUrl && (
            <>
              <Grid.Row>
                <Grid.Column>
                  <Container>
                    <Header size="small">{t('Health check - symptoms:')}</Header>
                    <p>{preCheckUrl.searchParams.getAll('symptoms').join(', ') || '-'}</p>
                  </Container>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header size="small">{t('Health check - notes:')}</Header>
                  <p>{preCheckUrl.searchParams.get('notes') || '-'}</p>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

export default HealthCheckModal;
