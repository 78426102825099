import { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Icon, Header, Button, Loader, Dimmer } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

// Import hoc
import withSlidingPanel from '../Shared/withSlidingPanel';

// Import components
import RoomForm from '../Forms/RoomForm';
import RoomsList from '../Rooms/RoomsList';

const SlidingRoomsForm = withSlidingPanel(RoomForm, {
  title: 'Add A Room',
  subtitle: 'Add a room in your organization',
  width: '70%',
});

class SetupRooms extends Component {
  state = { isOpen: false, added: false };

  renderAddBlock = () => {
    const {
      rooms: { list },
      t,
    } = this.props;

    if (list.length) return null;

    return (
      <Segment basic clearing textAlign="center">
        <Segment basic>
          <Icon name="cubes" size="huge" color="red" />
          <Header as={'h1'} content={t('Add your rooms')} />
          <Header as={'h3'} className="no-weight" content={t("Magic happens in these rooms. Let's add them...")} />
        </Segment>
        <Segment basic>
          <SlidingRoomsForm
            isOpen={this.state.isOpen}
            onClose={(status) => {
              this.setState({ isOpen: false, added: status === 'added' });
            }}
          />
          <Button content={t('Add Rooms')} primary size="large" onClick={() => this.setState({ isOpen: true })} />
        </Segment>
      </Segment>
    );
  };

  /**
   * This loader will be displayed only during first room creation.
   * Sometimes cloud triggers are slow so this is used as a visual queue.
   */
  renderFirstTimeLoader = () => {
    const {
      currentOrganization: { setup },
      t,
    } = this.props;

    if (this.state.added && !setup.firstRoomCreated)
      return (
        <Dimmer active inverted>
          <Loader inline="centered" indeterminate content={t('Just a moment while we add your first room...')} />
        </Dimmer>
      );
  };

  renderListBlock = () => {
    const {
      rooms: { list },
    } = this.props;

    if (!list.length) return null;
    return <RoomsList />;
  };
  render() {
    return (
      <Segment basic>
        {/* {this.renderFirstTimeLoader()} */}
        {this.renderAddBlock()}
        {this.renderListBlock()}
      </Segment>
    );
  }
}

SetupRooms.propTypes = {};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms,
});
export default withTranslation()(connect(mapStateToProps)(SetupRooms));
