import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Message, Segment } from 'semantic-ui-react';
import useOrganizationImpersonation from '../../hooks/useOrganizationImpersonation';

const NotificationBanner = () => {
  const { isImpersonatingOrganization, activeOrganization, switchToOrganization } = useOrganizationImpersonation();

  if (!isImpersonatingOrganization) return null;

  return (
    <Segment basic textAlign="center">
      <Message warning className="major impersonation">
        {`You are impersonating ${activeOrganization?.name}. (${activeOrganization.id})`}
        &nbsp;&nbsp;
        <Link
          to=""
          onClick={async (e) => {
            e.preventDefault();
            await switchToOrganization(null);
          }}
          style={{ color: '#fff' }}
          data-testid="stop-impersonating"
        >
          Stop Impersonating &#8594;
        </Link>
      </Message>
    </Segment>
  );
};

export const ImpersonateBanner = NotificationBanner;

const ImpersonationButton = ({ organization }) => {
  const { switchToOrganization, isImpersonatingOrganization } = useOrganizationImpersonation();

  const { t } = useTranslation();

  // If organization id is not supplied, don't render button.
  if (!organization.id) return null;

  return (
    <Button
      basic
      primary
      size="mini"
      content={isImpersonatingOrganization ? t('Stop impersonating') : t('Impersonate')}
      onClick={async () => {
        if (isImpersonatingOrganization) {
          // If impersonating, switch back to own organization (Stop impersonating).
          await switchToOrganization(null);
        } else {
          await switchToOrganization(organization.id);
        }
      }}
      data-testid={`impersonation ${isImpersonatingOrganization}`}
    />
  );
};

export default ImpersonationButton;
