import { ChangeEvent, FormEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Checkbox, CheckboxProps, Form, Image, InputOnChangeData, Segment, TextAreaProps } from 'semantic-ui-react';
import { isEmpty, isUndefined, omit } from 'lodash';
import MaskedInput from 'react-text-mask';
import Validator from 'validator';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { InlineError, ShowErrors } from '../../Components/Messages';
import { Dropdown } from '../../Components/Shared/Dropdown';
import ProfilePictureUploader from '../../Components/Upload/ProfilePictureUploader';
import StatePicker from '../../Components/Shared/StatePicker';

import { userHasPermission } from '../../api/firebase/account';
import { useSelectedStudent } from '../studentsHooks';

import { dateFormatter, toDateObject } from '../../helpers/dates';
import { formatFullName, phoneNumberFormat } from '../../helpers/utils';
import { getRandomStudentPicture } from '../studentsUtils';
import { DatePicker } from '../../Components/Shared/DatePicker';
import { useOrganization } from '../../hooks/useOrganizations';
import { ethnicityOptions, raceOptions } from '../../config';
import { organizationAddStudent, organizationUpdateStudent } from '../studentsRedux';
import { RouteNameEnum, useRoutes } from '../../navigation';
import { FoodProgramTierEnum } from '../../common';

interface Props {
  onClose?: (x?: string) => undefined;
  done?: () => undefined;
  hideCancel: boolean;
}

interface PersonalFormData {
  id: string;
  firstName: string;
  middleName: string;
  displayName?: string;
  lastName: string;
  nickName: string;
  birthday: string; // null; // dateFormatter(birthday)
  gender: string;
  race: string;
  ethnicity: string;
  allergies: string;
  medications: string;
  doctorName: string;
  doctorPhone: string; // phoneNumberParse(doctorPhone)
  notes: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  organization: string;
  picture: string;
  enrollmentStatus: boolean; // false
  photosAllowed: boolean; // true
  foodProgramTier: FoodProgramTierEnum; // 0 means not enrolled in food program
}

const foodProgramTierOptions = [
  { text: 'No', value: FoodProgramTierEnum.TIER_0 },
  { text: 'Yes Tier 1', value: FoodProgramTierEnum.TIER_1 },
  { text: 'Yes Tier 2', value: FoodProgramTierEnum.TIER_2 },
];
export default function StudentPersonalInformationForm({ onClose, done, hideCancel = false }: Props) {
  const selectedStudent = useSelectedStudent();
  const currentOrganization = useOrganization();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PersonalFormData>(selectedStudent);
  const dispatch = useDispatch();
  const router = useRoutes();
  const { foodProgramFlag } = useFlags();

  const onChange = (
    e: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    { name, value, checked }: InputOnChangeData | CheckboxProps | TextAreaProps
  ) => {
    setData({
      ...data,
      [name]: !isUndefined(checked) ? checked : value,
    });
    setErrors(omit(errors, name));
  };

  const maskedOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setErrors(omit(errors, e.target.name));
  };

  const handleBirthdayChange = (e: ChangeEvent<HTMLSelectElement>, { name, value, valid }: InputOnChangeData) => {
    setData({
      ...data,
      [name]: value,
    });

    if (valid) {
      setErrors(omit(errors, name));
    }
  };

  const validate = () => {
    const localErrors: Record<string, string> = {};

    if (!data.firstName) localErrors.firstName = t('First Name is required');
    if (!data.lastName) localErrors.lastName = t('Last Name is required');
    if (!data.race) localErrors.race = t('Race is required');
    if (!data.ethnicity) localErrors.ethnicity = t('Ethnicity is required');
    if (data.doctorPhone) {
      if (!Validator.isMobilePhone(data.doctorPhone, 'en-US')) {
        localErrors.doctorPhone = t('Phone is invalid');
      }
    }
    setErrors(localErrors);
    return localErrors;
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const localErrors = validate();

    if (isEmpty(localErrors)) {
      const { id, doctorPhone, picture, birthday, displayName, ...rest } = data;

      setLoading(true);

      const formData = {
        birthday: toDateObject(birthday),
        displayName: formatFullName(rest),
        doctorPhone: phoneNumberFormat(doctorPhone),
        picture: picture ? picture : getRandomStudentPicture(),
        ...rest,
        // TODO: remove this when studentsRedux.js is converted to typescript
        combinedFamily: null,
      };

      if (currentOrganization && currentOrganization.id) {
        if (id) {
          try {
            // Update.
            await dispatch(
              organizationUpdateStudent(currentOrganization.id, {
                id,
                ...formData,
                organization: currentOrganization.id,
              })
            );

            // Used to close wrapper HOC. e.g Sliding panel, modal...
            onClose?.('updated');
            // this.props.onClose('updated');
            // return;
            setLoading(false);
            // this.setState({ loading: false });
            done?.();
          } catch (error: any) {
            setLoading(false);
            setErrors({ 'Unable to Update': error.message });
          }
        } else {
          // New entry.
          try {
            await dispatch(
              organizationAddStudent(currentOrganization.id, {
                ...formData,
                organization: currentOrganization.id,
              })
            );
            // Update setup flags.
            setLoading(false);
            // Used to close wrapper HOC. e.g Sliding panel, modal...
            onClose?.('added');
          } catch (error: any) {
            setLoading(false);
            setErrors({ 'Unable to Add': error?.message });
          }
        }
      }
    }
  };

  const isSetupRoute = () => {
    const isSetup = router.getActiveRoute(RouteNameEnum.SETUP);
    return isSetup;
  };

  const saveChanges = async () => {
    const { id, doctorPhone, picture, birthday, displayName, ...rest } = data;

    if (currentOrganization && currentOrganization.id) {
      setLoading(true);

      const formData = {
        id,
        birthday: toDateObject(birthday),
        displayName: formatFullName(rest),
        doctorPhone: phoneNumberFormat(doctorPhone),
        picture: picture ? picture : getRandomStudentPicture(),
        // TODO: remove this when studentsRedux.js is converted to typescript
        combinedFamily: null,
        ...rest,
      };

      try {
        await organizationUpdateStudent(currentOrganization.id, formData);
        setLoading(false);
        // Used to close wrapper HOC. e.g Sliding panel, modal...
        onClose?.('saved');
      } catch (error: any) {
        setLoading(false);
        setErrors({ 'Unable save data': error?.message });
      }
    }
  };
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <Segment basic textAlign="left">
        <ShowErrors errors={errors} />
        <Form
          id="student-personal-information-form"
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          loading={loading}
          noValidate
          data-testid="student-personal-info-form"
        >
          <Form.Group widths="equal">
            <Form.Field error={!!errors.firstName} data-testid="first-name-field-error">
              <Form.Input
                required
                type="text"
                id="firstName"
                name="firstName"
                value={data.firstName}
                onChange={onChange}
                label={t('First Name')}
                placeholder={t('First Name')}
                data-testid="first-name"
              />
              {errors.firstName && <InlineError text={errors.firstName} data-testid="first-name-error-msg" />}
            </Form.Field>

            <Form.Field error={!!errors.middleName} width={6} data-testid="middle-name-field-error">
              <Form.Input
                type="text"
                id="middleName"
                name="middleName"
                value={data.middleName}
                onChange={onChange}
                label={t('Middle Name')}
                placeholder={t('Middle Name')}
                data-testid="middle-name"
              />
              {errors.middleName && <InlineError text={errors.middleName} data-testid="middle-name-error-msg" />}
            </Form.Field>

            <Form.Field error={!!errors.lastName} data-testid="last-name-field-error">
              <Form.Input
                required
                type="text"
                id="lastName"
                name="lastName"
                value={data.lastName}
                onChange={onChange}
                label={t('Last Name')}
                placeholder={t('Last Name')}
                data-testid="last-name"
              />
              {errors.lastName && <InlineError text={errors.lastName} data-testid="last-name-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.nickName} data-testid="nick-name-field-error">
              <Form.Input
                type="text"
                id="nickName"
                name="nickName"
                value={data.nickName}
                onChange={onChange}
                label={t('Nickname')}
                placeholder={t('Nickname')}
                data-testid="nick-name"
              />
              {errors.nickName && <InlineError text={errors.nickName} data-testid="nick-name-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field width={16} data-testid="birthday-field">
              <DatePicker
                id="birthday"
                name="birthday"
                value={data.birthday ? dateFormatter(data.birthday) : ''}
                onChange={handleBirthdayChange}
                label={t('Birthday')}
                placeholder={t('Select Birthday')}
                maxDate={moment()}
                error={!!errors.birthday}
                closable
                data-testid="birthday"
              >
                {errors.birthday && <InlineError text={errors.birthday} data-testid="birthday-error-msg" />}
              </DatePicker>
            </Form.Field>

            <Form.Field error={!!errors.gender} data-testid="gender-field">
              <Dropdown
                translator={t}
                id="gender"
                name="gender"
                label={t('Gender')}
                placeholder="Gender"
                selectOnBlur={false}
                value={data.gender}
                onChange={onChange}
                isForm
                options={[
                  { text: 'Male', value: 'Male' },
                  { text: 'Female', value: 'Female' },
                  { text: 'Other', value: 'Other' },
                ]}
                data-testid="gender"
              />
              {errors.gender && <InlineError text={errors.gender} data-testid="gender-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.race} data-testid="race-field-error">
              <Dropdown
                translator={t}
                isForm
                id="race"
                name="race"
                label={t('Race')}
                placeholder="Race"
                required
                selectOnBlur={false}
                value={data.race}
                onChange={onChange}
                options={raceOptions}
                data-testid="race"
              />
              {errors.race && <InlineError text={errors.race} data-testid="race-error-msg" />}
            </Form.Field>

            <Form.Field error={!!errors.ethnicity} data-testid="ethnicity-field-error">
              <Dropdown
                translator={t}
                isForm
                id="ethnicity"
                name="ethnicity"
                label={t('Ethnicity')}
                placeholder="Ethnicity"
                required
                selectOnBlur={false}
                value={data.ethnicity}
                onChange={onChange}
                options={ethnicityOptions}
                data-testid="ethnicity"
              />
              {errors.ethnicity && <InlineError text={errors.ethnicity} data-testid="ethnicity-error-msg" />}
            </Form.Field>
          </Form.Group>
          {foodProgramFlag && (
            <Form.Group width={8}>
              <Form.Field>
                <Dropdown
                  fluid
                  translator={t}
                  isForm
                  id="foodProgramTier"
                  name="foodProgramTier"
                  label={t('food.tierDropdownLabel')}
                  placeholder={t('food.tierDropdownPlaceholder')}
                  selectOnBlur={false}
                  value={data.foodProgramTier}
                  defaultValue={FoodProgramTierEnum.TIER_0}
                  onChange={onChange}
                  options={foodProgramTierOptions}
                  data-testid="food-program-tier"
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Field error={!!errors.photosAllowed} data-testid="photos-allowed-field-error">
            <Form.Radio
              toggle
              id="photosAllowed"
              name="photosAllowed"
              label={t('Photos Allowed')}
              onChange={onChange}
              checked={data.photosAllowed}
              control={Checkbox}
              data-testid="photos-allowed"
            />
            {errors.photosAllowed && <InlineError text={errors.photosAllowed} data-testid="photos-allowed-error-msg" />}
          </Form.Field>

          <Form.Group widths="equal">
            {userHasPermission('can_edit_student') && data.id && currentOrganization.id && (
              <Form.Field
                error={!!errors.picture}
                label={t('Student Photo')}
                control={ProfilePictureUploader}
                uploadPath={`organizations/${currentOrganization.id}/students/${data.id}`}
                title="Uploading Student Picture"
                onUploaded={(imageUrl?: string) => {
                  setData({
                    ...data,
                    picture: imageUrl ?? '',
                  });
                  if (!isSetupRoute()) saveChanges();
                }}
                data-testid="upload-photo"
              >
                {errors.picture && <InlineError text={errors.picture} data-testid="upload-photo-error-msg" />}
              </Form.Field>
            )}
            {data && data.picture && (
              <Segment basic>
                <Image src={data.picture} size="medium" rounded data-testid="picture" />
              </Segment>
            )}
          </Form.Group>

          <br />
          <br />

          <Form.Field error={!!errors.address1} data-testid="address1-field-error">
            <Form.Input
              type="text"
              id="address1"
              name="address1"
              value={data.address1}
              onChange={onChange}
              label={t('Address 1')}
              placeholder={t('Address 1')}
              data-testid="address1"
            />
            {errors.address1 && <InlineError text={errors.address1} data-testid="address1-name-error-msg" />}
          </Form.Field>

          <Form.Field error={!!errors.address2} data-testid="address2-field-error">
            <Form.Input
              type="text"
              id="address2"
              name="address2"
              value={data.address2}
              onChange={onChange}
              label={t('Address 2')}
              placeholder={t('Address 2')}
              data-testid="address2"
            />
            {errors.address2 && <InlineError text={errors.address2} data-testid="address2-error-msg" />}
          </Form.Field>

          <Form.Field error={!!errors.city} data-testid="city-field-error">
            <Form.Input
              type="text"
              id="city"
              name="city"
              value={data.city}
              onChange={onChange}
              label={t('City')}
              placeholder={t('City')}
              data-testid="city"
            />
            {errors.city && <InlineError text={errors.city} data-testid="city-error-msg" />}
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field error={!!errors.state} data-testid="state-field-error">
              <Form.Field
                id="state"
                name="state"
                label={t('State')}
                control={StatePicker}
                placeholder={t('State')}
                value={data.state}
                selection
                search
                onChange={onChange}
                data-testid="state"
              />
              {errors.state && <InlineError text={errors.state} data-testid="state-error-msg" />}
            </Form.Field>

            <Form.Field error={!!errors.zipcode} data-testid="zipcode-field-error">
              <Form.Input
                type="text"
                id="zipcode"
                name="zipcode"
                value={data.zipcode}
                onChange={onChange}
                label={t('Zip Code')}
                placeholder={t('Zip Code')}
                data-testid="zipcode"
              />
              {errors.zipcode && <InlineError text={errors.zipcode} data-testid="zipcode-error-msg" />}
            </Form.Field>
          </Form.Group>

          <br />
          <br />

          <Form.Field error={!!errors.allergies} data-testid="allergies-field-error">
            <Form.Input
              type="text"
              id="allergies"
              name="allergies"
              value={data.allergies}
              onChange={onChange}
              label={t('Allergies')}
              placeholder={t('If there are any allergies, add it here')}
              data-testid="allergies"
            />
            {errors.allergies && <InlineError text={errors.allergies} data-testid="allergies-error-msg" />}
          </Form.Field>

          <Form.Field error={!!errors.medications} data-testid="medications-field-error">
            <Form.Input
              type="text"
              id="medications"
              name="medications"
              value={data.medications}
              onChange={onChange}
              label={t('Medications')}
              placeholder={t('If there are any medications, add it here')}
              data-testid="medications"
            />
            {errors.medications && <InlineError text={errors.medications} data-testid="medications-error-msg" />}
          </Form.Field>

          <Form.Group widths="equal" data-testid="doctorName-field-error">
            <Form.Field error={!!errors.doctorName}>
              <Form.Input
                type="text"
                id="doctorName"
                name="doctorName"
                value={data.doctorName}
                onChange={onChange}
                label={t('Doctor Name')}
                placeholder={t('Dr. Jane Doe')}
                data-testid="doctorName"
              />
              {errors.doctorName && <InlineError text={errors.doctorName} data-testid="doctorName-error-msg" />}
            </Form.Field>

            <Form.Field error={!!errors.doctorPhone} data-testid="doctorPhone-field-error">
              <Form.Input
                type="text"
                id="doctorPhone"
                name="doctorPhone"
                onChange={maskedOnChange}
                label={t('Phone Number')}
                control={MaskedInput}
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={data.doctorPhone}
                guide={false}
                placeholder={'(123) 456-7890'}
                data-testid="doctorPhone"
              />
              {errors.doctorPhone && <InlineError text={errors.doctorPhone} data-testid="doctorPhone-error-msg" />}
            </Form.Field>
          </Form.Group>

          <Form.Field error={!!errors.notes} data-testid="notes-field-error">
            <Form.TextArea
              type="text"
              id="notes"
              name="notes"
              value={data.notes}
              onChange={onChange}
              label={t('Notes')}
              placeholder={t('Add any relevant notes')}
              data-testid="notes"
            />
            {errors.notes && <InlineError text={errors.notes} data-testid="notes-error-msg" />}
          </Form.Field>

          <br />
          <br />

          <Form.Group>
            <Form.Button primary content={t('Save')} data-testid="save-personalinfo-btn" />
            {!hideCancel && (
              <Form.Button
                basic
                content={t('Cancel')}
                onClick={(e) => {
                  if (e) e.preventDefault();
                  onClose?.();
                }}
                data-testid="cancel-personalinfo-details-btn"
              />
            )}
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
}
