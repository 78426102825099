import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
// import { Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useCallback, useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { formatFullName } from '../../helpers/utils';
import { Student } from '../../students/types';
import styles from './CheckedInStats.module.scss';
import CheckedInStudentsModal from './CheckedInStudentsModal';

const cx = classNames.bind(styles);

type Actor = {
  id: string;
  name: string;
  photoURL: string;
  type: string;
};
type Staff = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  picture: string;
  photoURL: string;
  type: string;
};
type Room = {
  id: string;
  name: string;
};

type CheckedInStatsProps = {
  roomsWithStudents: Record<string, Student[]>;
  roomsWithStaff: Record<string, Staff[]>;
  rooms: Room[];
  stats: {
    students: Record<string, number> | null;
    checkedIn: Record<string, number> | null;
    clockedIn: Record<string, number> | null;
  };
};
const CheckedInStats = ({
  roomsWithStudents,
  roomsWithStaff,
  rooms = [],
  stats: { students = null, checkedIn = null, clockedIn = null },
}: CheckedInStatsProps) => {
  const { t } = useTranslation();

  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

  const createActors = useCallback<(students?: Student[], staff?: Staff[]) => Actor[]>((students = [], staff = []) => {
    const studentActors = students.map((student) => ({
      id: student.id,
      name: formatFullName(student),
      photoURL: student.picture,
      type: 'student',
    }));
    const staffActors = staff.map((staff) => ({
      id: staff.id,
      name: formatFullName(staff),
      photoURL: staff.photoURL ?? staff.picture ?? null,
      type: 'staff',
    }));
    return [...studentActors, ...staffActors];
  }, []);

  if (!students || !roomsWithStudents || !roomsWithStaff) return null;

  const avatarsPerRoom = 4;
  const numberOfAvatars = avatarsPerRoom - 1;

  return (
    <>
      <div className="">
        <div className="flex justify-between p-4 text-lg">
          <div className="flex gap-2">
            <span className="font-normal">{t('Rooms')}:</span>
            <span className="font-semibold">{rooms.length}</span>
          </div>
        </div>
        <div className="flex gap-2 overflow-auto p-2">
          {rooms.map((room, i) => {
            if (isEmpty(room)) return null;
            const roomCheckedInCount = checkedIn ? checkedIn[`room-${room.id}`] ?? 0 : 0;
            const roomClockedInCount = clockedIn ? clockedIn[`room-${room.id}`] ?? 0 : 0;
            const totalCount = roomCheckedInCount + roomClockedInCount;
            const showMore = totalCount > avatarsPerRoom;
            const showModal = totalCount > 0;
            const actorsInRoom = createActors(roomsWithStudents[room.id], roomsWithStaff[room.id]);
            const actorsToDisplay = showMore ? actorsInRoom?.slice(0, numberOfAvatars) : actorsInRoom;
            const moreCount = totalCount - numberOfAvatars;
            const moreNum = '+' + Math.min(moreCount, 99);

            const handleRoomClick = () => {
              if (showModal) {
                setSelectedRoom(room);
              }
            };
            return (
              <div
                key={room.id + i}
                className="gap-2 rounded-lg border border-gray-200 bg-gray-100"
                onClick={handleRoomClick}
              >
                <div className="clamp-2 flex w-64 flex-col justify-between p-4">
                  <div className="flex flex-row gap-1">
                    <div className="mb-0 text-lg">{room.name ?? t('No Room Name')}</div>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <div className="flex flex-col items-center justify-center">
                      <span className={cx('countHeading')}> {roomClockedInCount}</span>
                      <span className={cx('countTitle')}>{t('Staff')}</span>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <span className={cx('countHeading')}> {roomCheckedInCount}</span>
                      <span className={cx('countTitle')}>{t('Students')}</span>
                    </div>
                  </div>
                  <div className={cx('roomRatioWrap', 'flex flex-col gap-y-4')}>
                    <RatioLabel checkedInCount={roomCheckedInCount} clockedInCount={roomClockedInCount} />
                    <div className="flex min-h-8 w-full place-content-end">
                      {actorsToDisplay?.map((actor) => {
                        return (
                          <Avatar
                            key={actor.id}
                            name={actor.name}
                            round
                            maxInitials={3}
                            size="28"
                            alt={actor.name}
                            src={actor.photoURL}
                            className={cx('roomAvatar')}
                          />
                        );
                      })}
                      {showMore && (
                        <Avatar
                          key={room.id}
                          name={moreNum}
                          color="#919191"
                          round
                          size="28"
                          textSizeRatio={3}
                          initials={(name) => name}
                          className={cx('roomAvatar')}
                          alt={t('Show more')}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <CheckedInStudentsModal
        roomName={rooms.find((room) => room === selectedRoom)?.name ?? ''}
        students={Array.from((selectedRoom && roomsWithStudents[selectedRoom.id]?.values()) ?? [])}
        staff={Array.from((selectedRoom && roomsWithStaff[selectedRoom.id]?.values()) ?? [])}
        onClose={() => setSelectedRoom(null)}
        open={!!selectedRoom}
      />
    </>
  );
};

function RatioLabel({ checkedInCount, clockedInCount }) {
  const { t } = useTranslation();

  if (checkedInCount === 0 || clockedInCount === 0) return <span></span>;
  const ratio = checkedInCount / clockedInCount;
  const ratioFloor = Math.floor(ratio);
  const ratioString = ratio === ratioFloor ? ratio.toFixed(0) : ratio.toFixed(1);
  const ratioLabel = `1:${ratioString} ${t('Ratio')}`;

  return <span className={cx('ratioStaffPerStudents')}>{ratioLabel}</span>;
}
export default CheckedInStats;
