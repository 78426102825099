import { firestore } from '.';
import { batchSaveDocuments, getDocumentsAsArray, startCollectionListener } from './firestore';

import { logError } from '../../rollbar';

const paths = {
  location: (organizationId, locationId) => `organizations/${organizationId}/locations/${locationId}`,
  locations: (organizationId) => `organizations/${organizationId}/locations`,
};

export const addOrganizationLocation = async (organizationId, locationData, programType = null) => {
  try {
    if (programType && programType === 'familyChildCare') {
      const locationId = `${organizationId}-primaryLocation`;
      return await firestore().collection(paths.locations(organizationId)).doc(locationId).set(locationData);
    }
    return await firestore().collection(paths.locations(organizationId)).add(locationData);
  } catch (error) {
    logError('Could not create location', error, { organizationId, locationData });
    throw new Error(error);
  }
};

export const updateOrganizationLocation = (organizationId, { id, ...locationData }) => {
  return firestore().doc(paths.location(organizationId, id)).update(locationData);
};

export function organizationLocationsOnListen({ organizationId, validLocationIds }, next, error, complete) {
  const conditions =
    validLocationIds?.length > 0
      ? [
          {
            field: firestore.FieldPath.documentId(),
            operation: 'in',
            value: validLocationIds,
          },
        ]
      : [];

  const options = {
    path: paths.locations(organizationId),
    conditions,
    // only pass the orderBy option if conditions are empty, otherwise the request can't find data
    ...(conditions.length === 0 && {
      orderBy: [{ field: 'name', direction: 'asc' }],
    }),
  };

  return startCollectionListener(options, next, error, complete);
}

export const fetchOrganizationLocations = (organizationId, conditions = []) => {
  return getDocumentsAsArray({
    path: paths.locations(organizationId),
    conditions,
    orderBy: [{ field: 'name', direction: 'asc' }],
  });
};
export const getPrimaryLocationDoc = async (organizationId) => {
  let querySnapshot = await firestore()
    .collection(paths.locations(organizationId))
    .where('primary', '==', true)
    .limit(1)
    .get();

  // If no primary location is found, return the first location
  if (querySnapshot.empty) {
    querySnapshot = await firestore()
      .collection(paths.locations(organizationId))
      .where('active', '==', true)
      .limit(1)
      .get();
  }
  return !querySnapshot.empty ? querySnapshot.docs[0] : null;
};
export const getPrimaryLocation = async (organizationId) => {
  const locationDoc = await getPrimaryLocationDoc(organizationId);
  if (!locationDoc?.exists) return null;
  return { id: locationDoc.id, ...locationDoc.data() };
};

export const updateOrganizationLocations = async (organizationId, locations) => {
  if (!locations?.length) return null;

  const locationsDocRef = firestore().collection(paths.locations(organizationId)).doc();

  const batch = firestore().batch();

  for (const location of locations) {
    const { id, ...locationData } = location;
    if (id) {
      const locationDocRef = firestore().doc(paths.location(organizationId, id));
      batch.set(locationDocRef, locationData);
    } else {
      batch.set(locationsDocRef, locationData);
    }
  }
  batch.commit();
};

//This goes through the saveDocument api, which will inspect the data for an id and organization
//If the id is present, it will update the document, otherwise it will create a new one. It strips it out before sending to firestore
export const saveLocations = async (locations) => {
  return batchSaveDocuments({
    documents: locations,
    collectionPath: (location) => {
      return paths.locations(location.organization);
    },
  });
};
