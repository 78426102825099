import { useTranslation } from 'react-i18next';

// Import style
import { Button } from '@wonderschool/common-base-ui';
import { userHasPermission } from '../../../../api/firebase/account';
import { InvoicePlanStatus, getUpdatedBillingDates } from '../../../../helpers/invoicePlans';
import '../InvoiceDetail/InvoiceDetail.scss';

export function InvoicePlanActionButtons({
  showActionButtons,
  loading,
  selectedInvoicePlan,
  onChangeStatus,
  onClickEdit,
}) {
  const { t } = useTranslation();
  const isActive = selectedInvoicePlan.status === InvoicePlanStatus.ACTIVE;
  const isPaused = selectedInvoicePlan.status === InvoicePlanStatus.PAUSED;
  const isScheduled = selectedInvoicePlan.status === InvoicePlanStatus.SCHEDULED;
  return (
    <div className="flex gap-2">
      {showActionButtons && userHasPermission('can_edit_billing') && (
        <>
          {isPaused && (
            <Button
              data-testid="resume-invoice-plan-button"
              primary
              loading={loading}
              onClick={() => {
                const dates = getUpdatedBillingDates(selectedInvoicePlan);
                selectedInvoicePlan.dateDue = dates.dateDue;
                selectedInvoicePlan.dateSend = dates.dateSend;
                selectedInvoicePlan.isArchived = false;
                onChangeStatus(InvoicePlanStatus.ACTIVE);
              }}
            >
              {t('Resume Invoice Plan')}
            </Button>
          )}

          {isActive && (
            <Button
              data-testid="pause-invoice-plan-button"
              primary
              loading={loading}
              onClick={() => onChangeStatus(InvoicePlanStatus.PAUSED)}
            >
              {t('Pause Invoice Plan')}
            </Button>
          )}

          {isActive && !isScheduled && (
            <Button
              data-testid="send-invoices-now-button"
              primary
              loading={loading}
              onClick={() => onChangeStatus(InvoicePlanStatus.SCHEDULED)}
            >
              {t('Send Invoices Now')}
            </Button>
          )}

          <Button primary loading={loading} onClick={onClickEdit} data-testid="edit-invoice-plan">
            {t('Edit')}
          </Button>
        </>
      )}
    </div>
  );
}
